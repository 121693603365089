import { Form as Unform } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

function Form({ children, ...props }) {
  return (
    <Container>
      <Unform {...props}>{children}</Unform>
    </Container>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit() {},
};

export default Form;
