/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Fieldset } from './TrailTab/styles';

import CtaPrimaryImage from '~/assets/images/tooltips/events/cta-primary-field.png';
import CtaPrimarySubscribedImage from '~/assets/images/tooltips/events/cta-primary-subscribed-field.png';
import CtaSecondaryImage from '~/assets/images/tooltips/events/cta-secondary-field.png';
import ExtraSourceImage from '~/assets/images/tooltips/events/extra-source-field.png';
import WallapaperImage from '~/assets/images/tooltips/events/wallpaper-field.png';
import OfferModalImage from '~/assets/images/tooltips/events/offer-modal-field.png';
import { Check, FileInput, Input } from '~/components/HookForm';
import { Alert } from '~/components/ui/alert';

export function DetailTab() {
  const { watch } = useFormContext();

  const hasInternalSubscription = watch('has_internal_subscription');
  const hasReferral = watch('has_referral');
  const hasOfferModal = watch('has_offer_modal');

  return (
    <div className="flex flex-col gap-2">
      <Alert
        title="Configurações dos Detalhes"
        description="Essas configurações ajustam detalhes visuais na platafroma e alguns redirecionamentos."
        className="mb-4"
      />

      <Fieldset>
        <legend>Inscrição</legend>

        <section>
          <Check
            name="has_internal_subscription"
            label="Inscrição pela plataforma"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />

          <Input
            name="external_subscription_url"
            label="URL da Página de inscrição"
            description="Links da Landing PAGE, caso a inscrição não seja realizada pela plataforma."
            placeholder="http://nlw.rocketseat.com.br"
            disabled={hasInternalSubscription}
          />
        </section>
      </Fieldset>

      <Fieldset>
        <legend>Usuário não inscrito</legend>

        <section>
          <Input
            required
            name="cta_text_no_subscriber"
            label="CTA Botão primário (Usuário NÃO inscrito)"
            description={`Texto do CTA primário para usuários ainda não inscritos.
                <img src="${CtaPrimaryImage}" className="w-[500px]" />
            `}
            placeholder="Inscreva-se agora"
          />
        </section>

        <section>
          <Input
            required
            name="lp_cta_text"
            label="CTA Botão secundário"
            description={`É o texto do botão secundário. Ele aparece apenas para usuários não inscritos.
              <img src="${CtaSecondaryImage}" className="w-[500px]" />  
            `}
            placeholder="Mais informações"
          />

          <Input
            required
            name="lp_cta_url"
            label="URL Botão secundário"
            description={`URL do botão secundário
              <img src="${CtaSecondaryImage}" className="w-[500px]" />  
            `}
            placeholder="https://www.rocketseat.com.br/eventos/nlw"
          />
        </section>
      </Fieldset>
      <Fieldset>
        <legend>Usuário inscrito</legend>

        <section>
          <Input
            required
            name="cta_text_subscriber"
            label="CTA Botão primário (Usuário inscrito)"
            description={`Texto do CTA primário para usuários inscritos
              <img src="${CtaPrimarySubscribedImage}" className="w-[500px]" />  
            `}
            placeholder="Ir para o evento"
          />
        </section>

        <section>
          <Check
            label="Exibir link de referral"
            name="has_referral"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />

          <Input
            name="referral_text"
            label="CTA Botão secundário (Usuário inscrito)"
            description={`É o texto que aparece no botão secundário pós inscrição
              <img src="${CtaSecondaryImage}" className="w-[500px]" />  
            `}
            placeholder="Convidar pessoas"
            disabled={!hasReferral}
          />

          <Input
            name="referral_url"
            label="URL Botão secundário (Usuário inscrito)"
            description={`Link do botão secundário pós inscrição.
              <img src="${CtaSecondaryImage}" className="w-[500px]" />
            `}
            placeholder="https://www.rocketseat.com.br/eventos/nlw/inscricao/"
            disabled={!hasReferral}
          />
        </section>

        <section>
          <Input
            name="challenges_text"
            label="Texto explicativo dos Checkpoints/Desafios"
            description="Texto que fica na sidebar na aba dos desafios."
            placeholder="Complete a missão e concorra a camisetas exclusivas"
          />

          <Input
            name="referral_url"
            label="URL Botão secundário (Usuário inscrito)"
            description="Link do botão secundário pós inscrição."
            placeholder="Convidar pessoas"
          />
        </section>
      </Fieldset>
      <Fieldset>
        <legend>Countdown</legend>

        <section>
          <FileInput
            label="Background do Countdown"
            name="countdown_background"
            placeholder="Upload background do Countdown"
            description={`Este background fica na página do countdown, sem ele o fundo fica totalmente escuro.:
            <img src="${CtaPrimarySubscribedImage}" className="w-[500px]" />  
          `}
            path="platform"
          />
        </section>
      </Fieldset>

      <Fieldset>
        <legend>Materiais extras</legend>

        <section>
          <Input
            name="event_guide_url"
            label="Guia do Evento"
            description="Link - geralmente do notion - para o guia do evento, caso exista."
            placeholder="Link do notion do guia do evento"
          />

          <Input
            label="Material complementar"
            name="extra_source"
            placeholder="Link do notion com material complementar para auxiliar os alunos"
            description={`O material extra é o URL dos conteúdos adicionais para os alunos que fica disponível na aba de materiais da página da sala de aula, como na imagem abaixo.
            <img src="${ExtraSourceImage}" className="w-[500px]" />  
          `}
          />

          <Input
            label="Wallpaper"
            name="wallpaper"
            placeholder="Link para drive para download do wallpaper"
            description={`Link para download dos wallpapers do evento que fica disponível na aba de materiais da página da sala de aula, como na imagem abaixo.
            <img src="${WallapaperImage}" className="w-[500px]" />  
          `}
          />
        </section>
      </Fieldset>

      <Fieldset>
        <section>
          <Check
            label="Exibir modal de oferta ao final da aula"
            name="has_offer_modal"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />

          <Input
            name="offer_modal_title"
            label="Headline da oferta"
            description={`Headline exibida no modal.
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
            placeholder="Você ganhou um presente exclusivo."
            disabled={!hasOfferModal}
          />
        </section>

        <section>
          <Input
            name="offer_modal_cta_text"
            label="Texto do botão CTA"
            description={`Link do botão secundário pós inscrição.
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
            placeholder="https://www.rocketseat.com.br/eventos/nlw/inscricao/"
            disabled={!hasOfferModal}
          />

          <Input
            name="offer_modal_cta_link"
            label="URL Botão CTA"
            description={`Link do Botão de CTA da oferta
              <img src="${OfferModalImage}" className="w-[500px]" />
            `}
            placeholder="https://www.rocketseat.com.br/eventos/nlw/inscricao/"
            disabled={!hasOfferModal}
          />

          <Input
            name="offer_modal_video"
            label="Código do vídeo do Youtube"
            description={`Código do vídoe do youtube que será exibido.
              <img src="${OfferModalImage}" className="w-[500px]" />

              <b>Exemplo:</b>
              <p style="display: flex; items-align:center;">
              https://www.youtube.com/watch?v=<span style="border: 1px solid red; padding: 1.5px 2px; display: inline-block; border-radius: 5px; background: #0a0a0a">
                UVlFt05vND4
              </p>
              </span>`}
            placeholder="xlH6zmdGz-E"
            disabled={!hasOfferModal}
          />
        </section>
      </Fieldset>

      <Fieldset>
        <section>
          <Input
            name="whatsapp_url"
            label="Link do grupo do WhatsApp"
            description="Link para o grupo de WhatsApp do evento."
            placeholder="https://chat.whatsapp.com/..."
          />
        </section>
      </Fieldset>
    </div>
  );
}
