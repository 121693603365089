import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError, useCan } from '~/utils';

import schema from './schema';

function ForumsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [forum, setForum] = useState({});
  const isUpdating = !!match.params.id;
  const can = useCan();

  const viewOnly = !can('edit_forums');

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/forums', id, data);

      toast.success('Fórum salvo com sucesso!');
      history.push('/forums');
    } catch ({ response }) {
      setSubmitting(false);
      showError(response);
    }
  }

  useEffect(() => {
    async function loadForum(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/forums/${id}`);

        setForum(response);
      } catch ({ response }) {
        showError(response);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadForum(id);
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar forum</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} fórum`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" to="/forums">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={forum} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" readOnly={viewOnly} />
        </section>
        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

ForumsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ForumsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default ForumsForm;
