import React from 'react';

import { ContentSection } from './ContentSection';
import { EventSection } from './EventSection';
import { OfferSection } from './OfferSection';

export const remindersFormSectionsMapper = {
  FEATURE: null,
  OFFER: <OfferSection />,
  EVENT: <EventSection />,
  CONTENT: <ContentSection />,
};
