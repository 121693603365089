/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';

import { api as apiStudent } from '@shared/skylab/services';
import { Container } from './styles';

import { Dropdown } from '~/components/Form';
import Button from '~/components/Button';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError, useCan } from '~/utils';
import { revalidateJourneyCache } from '~/api/mutations/revalidateJourneyCache';

function System() {
  const can = useCan();

  const [loadingClearCache, setLoadingClearCache] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [loadingMaintenance, setLoadingMaintenance] = useState(false);
  const [loadingClearCacheCdn, setLoadingClearCacheCdn] = useState(false);
  const [
    loadingClearCacheJourneyNodes,
    setLoadingClearCacheJourneyNodes,
  ] = useState(false);

  const [loadingClearCacheTags, setLoadingClearCacheTags] = useState(false);
  const [loadingClearCacheJourneys, setLoadingClearCacheJourneys] = useState(
    false,
  );
  const [loadingCatalogSync, setLoadingCatalogSync] = useState(false);
  const [loadingJourneyRevalidate, setLoadingJourneyRevalidate] = useState(
    false,
  );

  const [submittingUnification, setSubmittingUnification] = useState(false);

  const journeyViewOnly = !can('edit_journeys');

  async function handleClearCache() {
    if (loadingClearCache) {
      return;
    }

    try {
      setLoadingClearCache(true);
      await api.delete(`/users/clear-cache`);
      toast.success('Os caches dos usuários foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCache(false);
    }
  }

  async function handleClearJourneyNodeCache() {
    if (loadingClearCacheJourneyNodes) {
      return;
    }

    try {
      setLoadingClearCacheJourneyNodes(true);
      await api.delete(`/journey-nodes/clear-cache`);
      toast.success('Os caches dos Journey Nodes foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCacheJourneyNodes(false);
    }
  }

  async function handleActiveMaintenance() {
    try {
      setLoadingMaintenance(true);

      const { data } = await api.put('maintenance', {
        id: maintenance.id,
      });
      setMaintenance(data);

      if (data.on) {
        toast.success('Manutenção ativa!');
      } else {
        toast.success('Manutenção finalizada!');
      }
    } catch (response) {
      showError(response);
    } finally {
      setLoadingMaintenance(false);
    }
  }

  useEffect(() => {
    async function setMaintenanceStatus() {
      const { data } = await apiStudent.get('maintenance');
      setMaintenance(data);
    }

    setMaintenanceStatus();
  }, []);

  async function handleClearCacheCdn() {
    if (loadingClearCacheCdn) {
      return;
    }

    try {
      setLoadingClearCacheCdn(true);
      await api.delete(`/cdn/clear-cache`);
      toast.success('Os caches dos assets da CDN foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCacheCdn(false);
    }
  }

  async function handleClearCacheTags() {
    if (loadingClearCacheTags) {
      return;
    }

    try {
      setLoadingClearCacheTags(true);
      await api.delete(`/clear-cache/tags`);
      toast.success('Os caches tags foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCacheTags(false);
    }
  }

  async function handleClearCacheJourneys() {
    if (loadingClearCacheJourneys) {
      return;
    }

    try {
      setLoadingClearCacheJourneys(true);
      await api.delete(`/clear-cache/journeys`);
      toast.success('Os caches journeys foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCacheTags(false);
    }
  }

  async function handleCatalogSync() {
    if (loadingCatalogSync) {
      return;
    }

    try {
      setLoadingCatalogSync(true);
      await api.put(`/catalog/cache/update`);
      toast.success('As jornadas foram cacheadas novamente.');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingCatalogSync(false);
    }
  }

  async function handleJourneyRevalidate() {
    if (loadingJourneyRevalidate) {
      return;
    }

    try {
      setLoadingJourneyRevalidate(true);

      await revalidateJourneyCache();

      toast.success('O cache das jornadas foram revalidados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingJourneyRevalidate(false);
    }
  }

  const loadJourneys = useCallback(async () => {
    const { data } = await api.get('/journeys?per_page=1000');

    return data.data;
  }, []);

  const handleUnificationLessonJourneySubmit = useCallback(async (data) => {
    setSubmittingUnification(true);

    try {
      await api.post('/journeys/unifications', data);

      toast.success('A unificação das jornadas ocorreu com sucesso!');
    } catch (err) {
      showError(err);
    } finally {
      setSubmittingUnification(false);
    }
  }, []);

  return (
    <Page>
      <header>
        <h1>Configurações do sistema</h1>
      </header>
      <Container>
        <li>
          <div className="container">
            <span className="title">Limpar cache do usuário</span>
            <span className="description">
              Limpando dos caches as informações das&nbsp;
              <b>contas</b>
              &nbsp;e&nbsp;
              <b>dashboards</b>
              &nbsp;dos usuários novos dados serão pegos do banco de dados
              novamente.
            </span>
          </div>
          <div className="action">
            <Button onClick={handleClearCache} loading={loadingClearCache}>
              Resetar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Limpar cache da CDN</span>
            <span className="description">
              Limpando os caches dos assets da&nbsp;
              <b>CDN</b>
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleClearCacheCdn}
              loading={loadingClearCacheCdn}
            >
              Resetar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Limpar cache das Jornadas</span>
            <span className="description">
              Limpa o cache das jornadas, para atualizar a página interna da
              jornada.
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleClearCacheJourneys}
              loading={loadingClearCacheJourneys}
            >
              Resetar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Limpar cache dos Journey Nodes</span>
            <span className="description">
              Limpando dos caches as informações dos&nbsp;
              <b>journey nodes</b>
              &nbsp;.
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleClearJourneyNodeCache}
              loading={loadingClearCacheJourneyNodes}
            >
              Resetar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Limpar cache das tags</span>
            <span className="description">
              Limpando dos caches as informações das&nbsp;
              <b>tags</b>, o catálogo atualizará as tags disponíveis.
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleClearCacheTags}
              loading={loadingClearCacheTags}
            >
              Resetar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Sincronizar cache das jornadas</span>
            <span className="description">
              Caso as jornadas não estiverem retornando os dados corretamente no
              catálogo pode ser que contenha uma ou mais jornadas que não
              estejam cacheadas devidamente, para isso sincronize novamente para
              que tudo fique de acordo como deveria.
              <br />
              <br />
              <b>
                IMPORTANTE: Essa sincronização é automatica no momento que a
                jornada é salvo, apenas executar em caso de todo o cache ser
                apagado.
              </b>
            </span>
          </div>
          <div className="action">
            <Button onClick={handleCatalogSync} loading={loadingCatalogSync}>
              Sincronizar
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Revalidar cache das jornadas</span>
            <span className="description">
              Caso a página de jornada não estiver retornando os dados
              atualizados pode ser que o cache não tenha sido atualizado ainda,
              para isso revalide o cache para que tudo fique de acordo como
              deveria.
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleJourneyRevalidate}
              loading={loadingJourneyRevalidate}
            >
              Revalidar cache
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Ativar manutenção</span>
            <span className="description">
              Ao ativar, os usuários serão redirecionados para uma página de
              aviso sobre a manutenção. Ao desativar, os usuários poderão
              navegar pela plataforma normalmente.
            </span>
          </div>
          <div className="action">
            <Button
              onClick={handleActiveMaintenance}
              loading={loadingMaintenance}
            >
              {maintenance.on ? 'Desativar' : 'Ativar'}
            </Button>
          </div>
        </li>
        <li>
          <div className="container">
            <span className="title">Unificação das jornadas</span>
            <span className="description">
              Faz a unificação das jornadas com seus respectivos clusters,
              grupos de aulas e aulas.
            </span>
          </div>
          <div className="action">
            <Form onSubmit={handleUnificationLessonJourneySubmit}>
              <Dropdown
                label="Jornadas"
                name="journeys"
                multiple
                load={loadJourneys}
                getOptionLabel={(option) => `${option.title} - ${option?.slug}`}
                disabled={journeyViewOnly}
                placeholder="Selecione uma ou mais jornadas (opcional)"
              />
              {!journeyViewOnly && (
                <Button
                  type="submit"
                  size="block"
                  loading={submittingUnification}
                >
                  Salvar
                </Button>
              )}
            </Form>
          </div>
        </li>
      </Container>
    </Page>
  );
}

export default System;
