import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Scope } from '@rocketseat/unform';
import { FaFolder } from 'react-icons/fa';

import { Title } from './styles';
import Field from './components/Field';

import ButtonComponent from '~/components/Button';

export default function AttachmentsForm({ task, isUpdating }) {
  const [fields, setFields] = useState([]);

  function handleAdd() {
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        deleted: false,
      },
    ]);
  }

  useEffect(() => {
    if (!isUpdating) {
      handleAdd();
    } else {
      setFields(
        task?.attachments?.map((attachment) => ({
          ...attachment,
          deleted: false,
        })),
      );
    }
  }, [task?.attachments]);

  return (
    <>
      <header style={{ margin: '3rem 0 1rem' }}>
        <Title>Lista de anexos</Title>

        <ButtonComponent icon={FaFolder} color="success" onClick={handleAdd}>
          NOVO ANEXO
        </ButtonComponent>
      </header>

      {fields?.map((field, index) => {
        const isFirst = index === 0;

        return (
          <Scope
            path={`attachments[${index}]`}
            key={`attachment-field-${field.id}`}
          >
            <Field field={field} isFirst={isFirst} setFields={setFields} />
          </Scope>
        );
      })}
    </>
  );
}

AttachmentsForm.propTypes = {
  task: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  isUpdating: PropTypes.bool.isRequired,
};
