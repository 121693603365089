import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    getValue: (element) => {
      return <td key={`title-${element.id}`}>{element.tagged_title}</td>;
    },
  },
  {
    label: 'Slug',
    value: 'slug',
  },
];

function LessonGroupsList() {
  return (
    <PagedList
      resource="lesson-groups"
      header="Grupo de aulas"
      columns={columns}
    />
  );
}

export default LessonGroupsList;
