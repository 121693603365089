import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -24px;
`;

export const Title = styled.h3`
  margin-bottom: 8px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
