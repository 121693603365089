import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@rocketseat/unform';
import Markdown from 'react-markdown';

import Field from '../Field';
import { MarkdownWrapper } from './styles';

export default function MarkdownInput({ name, label, placeholder }) {
  const ref = useRef();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [markdown, setMarkdown] = useState(defaultValue || '');

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, ref.current]);

  return (
    <Field name={name}>
      {label && (
        <label
          htmlFor={fieldName}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {label}
        </label>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '36px',
        }}
      >
        <textarea
          ref={ref}
          id={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(e) => setMarkdown(e.target.value)}
        />

        <MarkdownWrapper>
          <span>Preview</span>
          <Markdown
            allowedTypes={['text', 'paragraph', 'strong', 'emphasis', 'link']}
            unwrapDisallowed
          >
            {markdown}
          </Markdown>
        </MarkdownWrapper>
      </div>

      {error && <span className="error">{error}</span>}
    </Field>
  );
}

MarkdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

MarkdownInput.defaultProps = {
  label: null,
  placeholder: null,
};
