import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import SweetAlert2 from 'react-sweetalert2';

import { Button } from './styles';
import { teamsTypes } from '../Form';

import PagedList from '~/components/PagedList';
import api from '~/services/api';

export const contractTypes = [
  { value: 'scholarships', label: 'Bolsa' },
  { value: 'partnerships', label: 'Parceria' },
  { value: 'trial', label: 'Trial' },
  { value: 'licenses', label: 'Licenças' },
];

const columns = [
  {
    label: 'Contrato',
    value: 'name',
    getValue: (element) => {
      const productTitle = element?.products?.map((product) => product?.title);

      const productTitles =
        productTitle?.length > 1
          ? `${productTitle.slice(0, 1)}...`
          : productTitle;

      return (
        <td key={element.id}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <b>{element.title}</b>
            <span>{productTitles}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Empresa',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <b>{element?.company?.name}</b>
            <span>{element?.company?.business_name}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Tipo',
    getValue: (element) => {
      const value = contractTypes.find((type) => type.value === element?.type)
        ?.label;
      return <td>{value}</td>;
    },
  },
  {
    label: 'Período',
    getValue: (element) => {
      const startDate = format(element?.start_date, 'DD/MM/YY');
      const endDate = format(element?.end_date, 'DD/MM/YY');

      const parsedString = `${startDate} a ${endDate}`;
      return <td>{parsedString}</td>;
    },
  },
  {
    label: 'Status',
    getValue: (element) => {
      return <td>{element?.is_active ? 'Vigente' : 'Inativo'}</td>;
    },
  },
  {
    label: 'Licenças',
    getValue: (element) => {
      return <td>{`${element.active_licenses}/${element?.licenses_total}`}</td>;
    },
  },
];

export default function ContractsList() {
  const [companies, setCompanies] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState(null);

  const filters = [
    {
      label: 'Empresa',
      name: 'company_id',
      data: companies.map((company) => ({
        value: company.id,
        label: company.name,
      })),
    },
    {
      label: 'Time',
      name: 'team_id',
      data: teamsTypes?.map((team) => ({
        label: team.label,
        options: teams
          ?.filter((item) => item?.type === team.value)
          .map((item) => ({
            value: item.id,
            label: item.title,
          })),
      })),
    },
    {
      label: 'Tipo',
      name: 'type',
      data: contractTypes,
    },
    {
      label: 'Status',
      name: 'status',
      data: [
        { value: 'inactive', label: 'Inativo' },
        { value: 'active', label: 'Vigente' },
      ],
    },
  ];

  useEffect(() => {
    async function loadCompanies() {
      const { data } = await api.get('/b2b/companies', {
        params: { per_page: 999 },
      });

      setCompanies(data.data);
      setLoading(false);
    }

    async function loadTeams() {
      const { data } = await api.get('/teams', {
        params: { withType: true, per_page: 999 },
      });

      setTeams(data.data);
      setLoading(false);
    }

    loadTeams();
    loadCompanies();
  }, []);

  async function handleRemoveContract() {
    try {
      await api.delete(`/b2b/contracts/${selectedContract?.id}`);

      toast.success('Contrato excluído com sucesso');
    } catch {
      toast.error('Não foi possível excluir o contrato');
    }

    setSelectedContract(null);
  }

  if (loading) {
    return 'Carregando...';
  }

  return (
    <>
      <PagedList
        resource="b2b/contracts"
        header="Contratos"
        columns={columns}
        hideSearchInput
        createPermission="btb:contracts:licenses_create"
        filtersText={[
          {
            name: 'title',
            label: 'Contrato',
          },
        ]}
        filters={filters}
        customActions={(contract) => {
          return (
            <Button type="button" onClick={() => setSelectedContract(contract)}>
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedContract}
        title="Excluir contrato?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveContract()}
        onResolve={() => setSelectedContract(null)}
      />
    </>
  );
}
