import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { FileInput, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { Tag } from '~/components/TagForms/tag';

const schema = Yup.object().shape({
  title: Yup.string().required(),
  slug: Yup.string(),
  icon: Yup.string(),
  contentTags: Yup.array().of(Yup.string()),
});

export default function TrailsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [trail, setTrail] = useState({
    title: '',
    icon: '',
    slug: '',
    contentTags: [],
  });

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/trails', id, data);

      toast.success('Trilha salva com sucesso!');
      history.push('/trails');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadData(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/trails/${id}`);

        setTrail(response);
      } catch (err) {
        toast.error('Erro ao buscar dados da trilha.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadData(id);
    }
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} Trilha`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/trails">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={trail} onSubmit={handleSubmit}>
        <section>
          <FileInput
            description="Ícone da trilha"
            label="Ícone da trilha"
            name="icon"
            placeholder="Ícone da trilha"
            path="platform"
          />
        </section>

        <section>
          <Input
            name="title"
            placeholder="Nome da trilha"
            label="Nome da trilha"
          />

          <Input
            name="slug"
            placeholder="Slug"
            label="Slug"
            description="Se não preenchido, será gerado automaticamente."
          />
        </section>

        <section>
          <Tag
            name="contentTags"
            label="Tecnologias"
            placeholder="Selecione uma ou mais tecnologias"
            type="TECHNOLOGY"
            options={trail.contentTags}
            multiple
          />
        </section>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

TrailsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
