/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { RiArticleLine } from 'react-icons/ri';

import AdminForm from './AdminForm';

import Button from '~/components/Button';
import { Checkbox, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import Separator from '~/components/Separator';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  cnpj: Yup.string(),
  business_name: Yup.string(),
  managers: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string().when('deleted', {
        is: false,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),
      email: Yup.string().when('deleted', {
        is: false,
        then: Yup.string()
          .email('O formato deve ser de um e-mail válido')
          .required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),
      role: Yup.string().when('deleted', {
        is: false,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),
      deleted: Yup.string(),
    }),
  ),
  is_college: Yup.bool(),
  documents: Yup.array().of(Yup.string()),
});

export default function CompaniesForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({
    name: '',
    cpnj: '',
    business_name: '',
    is_college: false,
    managers: [],
    documents: [],
  });
  const [documents, setDocuments] = useState([]);
  const [isCollege, setIsCollege] = useState(company.is_college);

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);
      const { id } = match.params;

      await api.postOrPut('/b2b/companies', id, {
        ...data,
        cnpj: data.cnpj.replace(/[^a-zA-Z0-9]/g, ''),
        users: data.managers
          .filter(({ email, name, role }) => email && name && role)
          .map(({ deleted, ...manager }) => ({
            ...manager,
            _destroy: deleted === 'true',
          })),
      });

      toast.success('Empresa salva com sucesso!');
      history.push('/b2b/companies');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  const loadDocuments = async () => {
    setIsLoading(true);

    const { data: response } = await api.get('/documents', {
      params: {
        perPage: 999,
      },
    });

    setIsLoading(false);
    setDocuments(response.data);
  };

  useEffect(() => {
    async function loadCompany(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/b2b/companies/${id}`);

        setCompany(response);
        setIsCollege(response.is_college);
      } catch (err) {
        toast.error('Erro ao buscar dados da empresa.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadCompany(id);
    }
    loadDocuments();
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} Empresa`}</h1>

        <div>
          <Button
            icon={RiArticleLine}
            color="success"
            to={`/b2b/contracts?company_id=${company?.id}`}
          >
            Contratos
          </Button>
          <Button icon={MdClose} color="cancel" to="/b2b/companies">
            Cancelar
          </Button>
          <Button form="company-form" type="submit" loading={isSubmitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="company-form"
        schema={schema}
        initialData={company}
        onSubmit={handleSubmit}
      >
        <section>
          <Input
            name="name"
            label={getLabel('Nome', 'Nome registrado da empresa.', true)}
          />

          <Input
            name="cnpj"
            label={getLabel('CNPJ', 'Número de registro da empresa.', false)}
            mask="99.999.999/9999-99"
          />
        </section>

        <section>
          <Input
            name="business_name"
            label={getLabel(
              'Apelido',
              'Nome para identificação da empresa internamente.',
              false,
            )}
          />
        </section>

        <section>
          <Checkbox
            label="É uma graduação?"
            name="is_college"
            checked={isCollege}
            onChange={(e) => setIsCollege(e.target.checked)}
          />
        </section>

        <section>
          {isCollege && (
            <Dropdown
              label="Documentos"
              name="documents"
              description="Documentos obrigatórios que os usuários devem fornecer ao vincularem-se a um contrato com essa empresa."
              multiple
              getOptionLabel={(option) => option.name}
              options={documents}
            />
          )}
        </section>

        <Separator label="GESTORES" style={{ marginTop: 30 }} />

        <AdminForm company={company} isUpdating={isUpdating} />
      </Form>
    </Page>
  );
}

CompaniesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
