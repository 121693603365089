import React, { useState } from 'react';

import { FaRegTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import PagedList from '~/components/PagedList';
import Button from '~/components/Button';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },

  {
    label: 'Slug',
    value: 'slug',
  },

  {
    label: 'Habilitado',
    value: 'is_active',

    getValue: (element) => {
      const isActive = element.is_active;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[featured=true]:text-green-600 data-[featured=true]:font-bold"
          data-featured={isActive}
        >
          {isActive ? 'Sim' : 'Não'}
        </td>
      );
    },
  },
];

export default function ExperimentsList() {
  const [selectedExperiment, setSelectedExperiment] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function handleRemoveExperiment() {
    try {
      await api.delete(`/experiments/${selectedExperiment.id}`);
      toast.success('Experimento excluído com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir o experimento');
    } finally {
      setShouldRefetch(false);
      setSelectedExperiment(null);
    }
  }

  return (
    <>
      <PagedList
        columns={columns}
        header="Experimentos"
        resource="experiments"
        refetchData={shouldRefetch}
        createPermission="login_admin"
        customActions={(banner) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedExperiment(banner)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedExperiment}
        title={`Excluir ${selectedExperiment?.title}?`}
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveExperiment()}
        onResolve={() => setSelectedExperiment(null)}
      />
    </>
  );
}
