import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.aside`
  width: 280px;
  background: #302b3e;
  height: 100%;
  color: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;

  header {
    background: #7159c1;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      img {
        height: 32px;
        width: 43px;
        padding-right: 15px;
        margin-right: 15px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }

      strong {
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
`;

export const Menu = styled.section`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    background: #272333;
    border-left: 2px solid black;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 12px;
    border: 2px solid #272333;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: gray;
  }

  nav {
    margin-top: 50px;

    &:last-child {
      margin-bottom: 50px;
    }

    strong {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 600;
      margin-left: 30px;
      text-transform: uppercase;
    }

    ul {
      margin-top: 10px;

      li {
        a {
          display: flex;
          align-items: center;
          padding: 12px 30px;
          color: rgba(255, 255, 255, 0.8);
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          transition: all 0.1s;

          svg {
            color: #fff;
            margin-right: 15px;
          }
        }
      }
    }
  }
`;

export const Link = styled(NavLink).attrs({ activeClassName: 'active' })`
  &:hover,
  &.active {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  mark {
    background: rgba(255, 255, 255, 0.12);
    color: inherit;
  }
`;
