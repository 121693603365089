import React, { useEffect, useState } from 'react';

import { FaCheckCircle, FaRegTrashAlt } from 'react-icons/fa';
import { RiCloseCircleFill } from 'react-icons/ri';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Qtd. de tarefas',
    getValue: (element) => {
      const numTasks = Number(element.__meta__.tasks_count);

      const label = numTasks > 1 ? 'Tarefas' : 'Tarefa';

      return (
        <td key={element.id}>
          {numTasks}
          {' '}
          {label}
        </td>
      );
    },
  },
];

export default function LevelsList() {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(false);

  const filters = [
    {
      label: 'Objetivo',
      name: 'goal_id',
      data: goals.map((goal) => ({
        value: goal.id,
        label: goal.title,
      })),
    },
  ];

  useEffect(() => {
    async function loadGoals() {
      const { data } = await api.get('/guide/goals', {
        params: {
          per_page: 999,
        },
      });

      setGoals(data.data);
      setLoading(false);
    }

    loadGoals();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  async function handleRemoveLevel() {
    try {
      await api.delete(`/guide/levels/${selectedLevel?.id}`);

      toast.success('Fase excluída com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir a fase');
    }

    setShouldRefetch(false);
    setSelectedLevel(null);
  }

  return (
    <>
      <PagedList
        resource="guide/levels"
        header="Fases"
        columns={columns}
        refetchData={shouldRefetch}
        filters={filters}
        createPermission="create_levels"
        customActions={(level) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedLevel(level)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedLevel}
        title="Excluir fase?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveLevel()}
        onResolve={() => setSelectedLevel(null)}
      />
    </>
  );
}
