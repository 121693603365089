export const frequencyOptions = [
  { value: 'DAILY', label: 'Diária' },
  { value: 'WEEKLY', label: 'Semanal' },
  { value: 'MONTHLY', label: 'Mensal' },
  { value: 'YEARLY', label: 'Anual' },
];

export const detectContentBodyFormat = (input) => {
  const htmlRegex = /<[^>]+>/;
  const markdownRegex = /(\*\*|__|~~|`{1,3}|!\[.*\]\(.*\)|\[.*\]\(.*\)|#{1,6}\s|>\s|\* |- |\d+\.\s)/;

  if (htmlRegex.test(input)) {
    return 'HTML';
  }
  if (markdownRegex.test(input)) {
    return 'MD';
  }

  return 'TXT';
};
