import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdArrowBack, MdAdd } from 'react-icons/md';

import { Empty } from './styles';

import Button from '~/components/Button';
import { Table, Actions } from '~/components/Table';
import { Page } from '~/pages/styles';
import api from '~/services/api';
import { useCan } from '~/utils';

function UsersPioneerStatusList({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const can = useCan();

  async function loadData() {
    setLoading(true);

    const { data: response } = await api.get(
      `/pioneer-status?userId=${match.params.id}`,
    );

    setLoading(false);
    setData(response);
  }

  useEffect(() => {
    loadData();
  }, []);

  function emptyData() {
    return (
      <Empty>
        <h1>Este usuário não possui nenhum registro Pioneer</h1>
      </Empty>
    );
  }

  function renderPioneerStatus() {
    return (
      <Table loading={loading}>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Condição</th>
            <th>Status</th>
            <th>Aguardando atualização</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((pioneerStatus) => (
            <tr key={pioneerStatus.id}>
              <td>
                {pioneerStatus.purchase?.product ||
                  'Não está associado a nenhum produto'}
              </td>
              <td>{pioneerStatus.condition}</td>
              <td>{pioneerStatus.status}</td>
              <td>{pioneerStatus.pending_upgrade ? 'Sim' : 'Não'}</td>
              <td width={120}>
                <Actions
                  mainAction={() =>
                    history.push(
                      `/users/pioneer-status/${pioneerStatus.id}/edit`,
                    )
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Page>
      <header>
        <h1>Compras</h1>

        <div>
          <Button
            icon={MdArrowBack}
            color="success"
            to={`/users/edit/${match.params.id}`}
          >
            Voltar
          </Button>

          {can('edit_users') && (
            <Button
              icon={MdAdd}
              color="success"
              to={`/users/pioneer-status/${match.params.id}/new`}
            >
              Novo
            </Button>
          )}
        </div>
      </header>

      {(loading && renderPioneerStatus()) || data.length
        ? renderPioneerStatus()
        : emptyData()}
    </Page>
  );
}

UsersPioneerStatusList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default UsersPioneerStatusList;
