import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { isArray } from 'lodash';

import AttachmentsForm from './AttachmentsForm';

import Button from '~/components/Button';
import { Checkbox, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  is_final: Yup.string(),
  validation_type: Yup.string().required('Campo obrigatório'),
  validation_params: Yup.object().shape({
    id: Yup.array().of(Yup.string()),
    count: Yup.number(),
  }),
  attachments: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      type: Yup.string().when('deleted', {
        is: false,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),
      title: Yup.string().when('deleted', {
        is: false,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),
      value: Yup.object().shape({
        link: Yup.string(),
        file: Yup.string(),
        lesson: Yup.string(),
        journey: Yup.string(),
      }),
      deleted: Yup.boolean(),
    }),
  ),
});

export const validationTypes = [
  { value: 'MANUAL', label: 'Inserção manual' },
  { value: 'FORUM', label: 'Interação no fórum' },
  { value: 'JOURNEY_FINISH', label: 'Finalizar uma jornada' },
  { value: 'CERTIFICATE', label: 'Emitir Certificado' },
  { value: 'CHALLENGE', label: 'Realizar Desafio prático' },
  { value: 'MICRO_CERTIFICATE', label: 'Emitir Micro-Certificado' },
  { value: 'PLATFORM_TOUR', label: 'Tour da plataforma' },
  { value: 'PROFILE_INFOS', label: 'Completar o perfil' },
  { value: 'STUDY_GOALS', label: 'Definir meta de estudos' },
  { value: 'JOURNEY_PROGRESS', label: 'Progresso de jornada' },
  { value: 'JOURNEY_START', label: 'Início de jornada' },
];

export const quantityInputs = [
  'CERTIFICATE',
  'MICRO_CERTIFICATE',
  'CHALLENGE',
  'JOURNEY_PROGRESS',
  'FORUM',
];

export default function TasksForm({ match, history }) {
  const [selectedRule, setSelectedRule] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [journeysList, setJourneysList] = useState(false);
  const [task, setTask] = useState({
    title: '',
    description: '',
    validation_type: '',
    validation_params: {
      id: '',
      count: 0,
    },
    is_final: false,
    attachments: [
      {
        id: '',
        type: '',
        title: '',
        value: { link: '', file: '', lesson: '', journey: '' },
        deleted: false,
      },
    ],
  });

  const isQuantityInput = quantityInputs.includes(selectedRule?.id);

  const isJourneyInput = selectedRule?.id.includes('JOURNEY');

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      const { id } = match.params;
      const filteredAttachments = data?.attachments?.filter((attachment) => {
        if (!attachment?.id) {
          return !attachment.deleted;
        }

        return attachment;
      });

      await api.postOrPut('/guide/tasks', id, {
        ...data,
        attachments: filteredAttachments?.map(({ deleted, ...attachment }) => ({
          ...attachment,
          value: JSON.stringify(attachment.value),
          _destroy: deleted,
        })),
      });

      toast.success('Tarefa salva com sucesso!');
      history.push('/guide/tasks');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  const loadJourneys = async () => {
    try {
      const response = await api.get(`journeys`, {
        params: {
          per_page: 999,
        },
      });
      setJourneysList(response.data.data);

      return response.data.data;
    } catch (err) {
      toast.error('Erro ao buscar dados das jornadas.');
    }
  };

  useEffect(() => {
    async function loadTask(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/guide/tasks/${id}`);

        const { label, value } = validationTypes.find(
          (type) => type.value === response.validation_type,
        );

        if (!selectedRule) {
          setSelectedRule({
            id: value,
            title: label,
          });
        }

        const journeys = await loadJourneys();

        const isArrayOfIds = isArray(response.validation_params.id);

        if (isArrayOfIds) {
          setTask({
            ...response,
            validation_params: {
              ...response.validation_params,
              id: response.validation_params.id?.map((journeyId) => {
                const journey = journeys.find(
                  (currentJourney) => currentJourney?.id === journeyId,
                );

                return {
                  id: journeyId,
                  title: journey?.title,
                };
              }),
            },
          });
        } else {
          setTask(response);
        }
      } catch (err) {
        toast.error('Erro ao buscar dados da fase.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadTask(id);
    } else {
      const defaultLoadJourneys = async () => {
        await loadJourneys();
      };

      defaultLoadJourneys();
    }
  }, [match.params]);

  if (isLoading) {
    return 'Carregando...';
  }

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} Tarefa`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/guide/tasks">
            Cancelar
          </Button>
          <Button form="task-form" type="submit" loading={isSubmitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="task-form"
        schema={schema}
        initialData={task}
        onSubmit={handleSubmit}
      >
        <section>
          <Input name="title" label={getLabel('Título', '', true)} />
        </section>

        <section>
          <Input
            multiline
            name="description"
            label={getLabel(
              'Descrição',
              'Descreva o contexto do objetivo',
              false,
            )}
          />
        </section>

        <section style={{ margin: '10 0 20 0' }}>
          <Checkbox
            name="is_final"
            label={getLabel(
              'Tarefa Final',
              'Selecione se a tarefa for a última para concluir a fase',
              true,
            )}
          />
        </section>

        <section style={{ zIndex: 2, position: 'relative' }}>
          <Dropdown
            name="validation_type"
            options={validationTypes.map((validationType) => ({
              id: validationType.value,
              title: validationType.label,
            }))}
            value={selectedRule}
            onChange={setSelectedRule}
            label={getLabel(
              'Tipo de validação',
              'Insira aqui a validação a ser feita, antes de essa tarefa poder ser concluída',
              true,
            )}
          />

          <>
            {isJourneyInput && (
              <Dropdown
                name="validation_params.id"
                options={journeysList}
                multiple
                label={getLabel(
                  'Jornadas obrigatórias',
                  'Selecione as jornadas respectivas para validarem',
                  true,
                )}
                disabled={isLoading}
              />
            )}

            {isQuantityInput && (
              <Input
                name="validation_params.count"
                label={getLabel(
                  'Quantidade da validação',
                  'Insira aqui a quantidade específica para o tipo de validação escolhido',
                  true,
                )}
                type="number"
                style={{ height: 50 }}
              />
            )}
          </>
        </section>

        <AttachmentsForm task={task} isUpdating={isUpdating} />
      </Form>
    </Page>
  );
}

TasksForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
