const { HiDocumentSearch } = require('react-icons/hi');
const {
  IoHourglass,
  IoCloseCircle,
  IoCheckmarkCircle,
} = require('react-icons/io5');

export const DocumentStatusValues = {
  PENDING: 'PENDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const DocumentStatusLabels = {
  PENDING: 'Pendente',
  UNDER_REVIEW: 'Em análise',
  REJECTED: 'Reprovado',
  APPROVED: 'Aprovado',
};

export const DocumentStatusIcons = {
  PENDING: IoHourglass,
  UNDER_REVIEW: HiDocumentSearch,
  REJECTED: IoCloseCircle,
  APPROVED: IoCheckmarkCircle,
};

export const DocumentStatusDetails = {
  PENDING: {
    value: DocumentStatusValues.PENDING,
    label: DocumentStatusLabels.PENDING,
    icon: DocumentStatusIcons.PENDING,
    color: '#F48F56',
  },
  UNDER_REVIEW: {
    value: DocumentStatusValues.UNDER_REVIEW,
    label: DocumentStatusLabels.UNDER_REVIEW,
    icon: DocumentStatusIcons.UNDER_REVIEW,
    color: '#7159C1',
  },
  REJECTED: {
    value: DocumentStatusValues.REJECTED,
    label: DocumentStatusLabels.REJECTED,
    icon: DocumentStatusIcons.REJECTED,
    color: '#FF0000',
  },
  APPROVED: {
    value: DocumentStatusValues.APPROVED,
    label: DocumentStatusLabels.APPROVED,
    icon: DocumentStatusIcons.APPROVED,
    color: '#008000',
  },
};

export const DocumentStatusReviewed = [
  DocumentStatusValues.APPROVED,
  DocumentStatusValues.REJECTED,
];
