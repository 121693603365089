/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { startCase } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';

import Field from '../Field';
import { Container, Option } from './styles';

const Check = forwardRef(
  (
    {
      label,
      name,
      required,
      options,
      description,
      onClick,
      readOnly,
      defaultValue,
    },
    ref,
  ) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    function handleChange(option, onChange) {
      if (readOnly) {
        return;
      }

      onChange(option?.value);

      if (onClick) {
        onClick(option);
      }
    }

    function getOptionLabel(option) {
      let { label: optionLabel } = option;

      if (optionLabel) {
        return optionLabel;
      }

      optionLabel = String(option.value);

      return startCase(optionLabel);
    }

    return (
      <Field label={label} name={name}>
        {label && (
          <label
            htmlFor={name}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}

            {required && <span style={{ color: 'red' }}>*</span>}

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Container readOnly={readOnly} ref={ref}>
              {options.map((option) => (
                <Option
                  key={String(option.value)}
                  type="button"
                  selected={field?.value === option?.value}
                  onClick={() => {
                    if (option.value === field.value) {
                      handleChange({ ...option, value: null }, field.onChange);
                      return;
                    }

                    handleChange(option, field.onChange);
                  }}
                >
                  {getOptionLabel(option)}
                </Option>
              ))}
            </Container>
          )}
        />
        {errors[name] && <span className="error">{errors[name].message}</span>}
      </Field>
    );
  },
);

Check.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,

  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  description: PropTypes.string,
  defaultValue: PropTypes.any,
  required: PropTypes.bool,
};

Check.defaultProps = {
  onClick: null,
  readOnly: false,
  required: false,
  description: null,
  defaultValue: null,
};

export default Check;
