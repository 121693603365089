import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Form, Input, Choice } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError, useCan } from '~/utils';

import schema from './schema';
import { ChoiceContainer } from './styles';

export default function RolesForm({ match, history }) {
  const [permissionLoading, setPermissionLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [role, setRole] = useState({ permissions: [] });
  const [permissions, setPermissions] = useState([]);
  const can = useCan();

  const viewOnly = !can('edit_roles');

  const isUpdating = !!match.params.id;

  const renderPermissions = useMemo(() => {
    return (
      <Choice
        multiple
        name="permissions"
        options={permissions}
        disabled={viewOnly}
      />
    );
  }, [permissions, role.permissions]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);
      const { id } = match.params;
      await api.postOrPut('/roles', id, data);
      toast.success('Grupo registrado com sucesso!');

      history.push('/roles');
    } catch ({ response }) {
      showError(response);
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadPermissions() {
      try {
        let { data } = await api.get('/permissions');

        data = data.map((permission) => ({
          label: permission.name,
          value: permission.id,
        }));

        setPermissions(data);
      } catch ({ response }) {
        showError(response);
      } finally {
        setPermissionLoading(false);
      }
    }

    loadPermissions();
  }, []);

  useEffect(() => {
    async function loadRole(id) {
      try {
        setRoleLoading(true);

        const { data } = await api.get(`/roles/${id}`);

        data.permissions = data.permissions.map((permission) => permission.id);

        setRole(data);
      } catch ({ response }) {
        showError(response);
      } finally {
        setRoleLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadRole(id);
    }
  }, []);

  return (
    <Page loading={permissionLoading || roleLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} grupo`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/roles">
            Cancelar
          </Button>
        </div>
      </header>
      <Form schema={schema} initialData={role} onSubmit={handleSubmit}>
        <section>
          <Input label="Nome" name="name" readOnly={viewOnly} />
          <Input label="Slug" name="slug" readOnly={viewOnly} />
        </section>

        <ChoiceContainer>{renderPermissions}</ChoiceContainer>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

RolesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RolesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};
