import React from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';

import { Container } from './styles';

import { Input } from '~/components/HookForm';

export default function AccessExtensionSection({ offerProducts }) {
  const { control } = useFormContext();

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="uppercase !text-gray-500 !bg-white">Produtos</legend>

      {offerProducts?.map(({ product, id }, index) => (
        <section key={`offer-product-[${id}]`}>
          <Container>
            <fieldset>
              <legend className="uppercase !text-gray-500 !bg-white">
                <strong className="lowercase">{`[${product.identifier}]`}</strong>
                {` ${product.title}`}
              </legend>

              <Controller
                name={`access_extension_products[${index}].id`}
                control={control}
                defaultValue={product.id}
                render={({ field }) => {
                  return <Input {...field} type="hidden" />;
                }}
              />

              <Controller
                name={`access_extension_products[${index}].identifier`}
                control={control}
                defaultValue={product.identifier}
                render={({ field }) => {
                  return <Input {...field} type="hidden" />;
                }}
              />

              <Controller
                name={`access_extension_products[${index}].title`}
                control={control}
                defaultValue={product.title}
                render={({ field }) => {
                  return <Input {...field} type="hidden" />;
                }}
              />

              <Controller
                name={`access_extension_products[${index}].offerProductId`}
                control={control}
                defaultValue={id}
                render={({ field }) => {
                  return <Input {...field} type="hidden" />;
                }}
              />

              <section>
                <Controller
                  name={`access_extension_products[${index}].numberOfMonths`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        min="0"
                        type="number"
                        label="Meses adicionais de acesso"
                        description="A quantidade de meses adicionais de acesso que o aluno terá ao produto."
                        onInput={(e) => {
                          e.target.value = Math.abs(e.target.value);
                        }}
                      />
                    );
                  }}
                />
              </section>
            </fieldset>
          </Container>
        </section>
      ))}
    </fieldset>
  );
}

AccessExtensionSection.propTypes = {
  offerProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isRenewable: PropTypes.bool,
      periodType: PropTypes.string,
      periodInterval: PropTypes.number,
      product: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        identifier: PropTypes.string,
      }),
    }),
  ).isRequired,
};
