import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';

import { Container } from './styles';

const Checkbox = forwardRef(({ name, label, description, ...rest }, ref) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Container>
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        render={({ field }) => (
          <label htmlFor={name}>
            <input
              type="checkbox"
              id={name}
              name={name}
              aria-label={name}
              checked={field.value}
              onChange={field.onChange}
              {...rest}
              {...field}
              ref={ref || field.ref || null}
            />
            <span>{label}</span>

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
                style={{ marginLeft: 10 }}
              />
            )}
          </label>
        )}
      />
      {errors[name] && <span className="error">{errors[name].message}</span>}
    </Container>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Checkbox.defaultProps = {
  description: null,
};

export default Checkbox;
