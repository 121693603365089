import styled from 'styled-components';

export const RequiredFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .error {
    color: #dd5554;
    font-size: 12px;
    margin-top: -0.7rem;
    margin-bottom: 0.7rem;
  }
`;

export const ErrorIndicatorBullet = styled.div`
  @keyframes bounce {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(-0.2rem);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #dd5554;
  animation: bounce 0.5s infinite;
`;
