/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/control-has-associated-label */

import React from 'react';
import PropTypes from 'prop-types';

import { MdClose, MdSearch } from 'react-icons/md';

export const Search = ({ value, onChange }) => (
  <div className="px-8 mt-10 -mb-3">
    <label className="relative flex items-center gap-3 border-b border-b-white/10 py-2">
      <MdSearch size={20} className="shrink-0 text-white/50" />

      <input
        type="text"
        placeholder="Buscar"
        className="w-full bg-transparent"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => e.key === 'Escape' && onChange('')}
        id="navSearch"
      />

      {!!value.length && (
        <button type="button" onClick={() => onChange('')}>
          <MdClose
            size={16}
            className="text-white/50 hover:text-white/70 transition-colors"
          />
        </button>
      )}
    </label>
  </div>
);

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
