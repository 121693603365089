import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Input, Checkbox } from '~/components/Form';

function UsersAddressForm({
  addressType,
  handleBlurZipcode,
  isForeign: defaultForeign,
  setForeign: setParentForeign,
  readOnly,
}) {
  const [isForeign, setForeign] = useState(defaultForeign);

  function handleChange(e) {
    setForeign(e.target.checked);
    setParentForeign(e.target.checked);
  }

  function renderLocalForm() {
    return (
      <>
        <section>
          <Input
            label="CEP"
            name="postal_code"
            onBlur={(e) => handleBlurZipcode(e.target.value, addressType)}
            readOnly={readOnly}
          />
          <Input label="Logradouro" name="street_name" readOnly={readOnly} />
          <Input label="Número" name="number" readOnly={readOnly} />
        </section>

        <section>
          <Input label="Complemento" name="complement" readOnly={readOnly} />
          <Input label="Bairro" name="neighborhood" readOnly={readOnly} />
          <Input label="Cidade" name="city" readOnly={readOnly} />
          <Input label="UF" name="state" readOnly={readOnly} />
        </section>
      </>
    );
  }

  function renderInternationalForm() {
    return (
      <div>
        <section>
          <Input name="country" label="País" readOnly={readOnly} />
        </section>

        <section>
          <Input
            name="foreign_address"
            label="Endereço completo"
            multiline
            readOnly={readOnly}
          />
        </section>
      </div>
    );
  }

  return (
    <>
      {isForeign ? renderInternationalForm() : renderLocalForm()}

      <Checkbox
        name="foreign_country"
        label="Endereço internacional"
        onChange={handleChange}
        disabled={readOnly}
      />
    </>
  );
}

UsersAddressForm.propTypes = {
  addressType: PropTypes.string.isRequired,
  handleBlurZipcode: PropTypes.func.isRequired,
  isForeign: PropTypes.bool.isRequired,
  setForeign: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

UsersAddressForm.defaultProps = {
  readOnly: false,
};

export default UsersAddressForm;
