/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { MdAdd, MdClose, MdSave } from 'react-icons/md';
import * as Yup from 'yup';
import { Scope } from '@rocketseat/unform';

import { OptionForm } from '../OptionForm';
import { List } from '../List';
import { Container, Footer, SideInfo } from './styles';

import Button from '~/components/Button';
import { Check, Form, Input } from '~/components/Form';

const schema = Yup.object().shape({
  question: Yup.object().shape({
    title: Yup.string().required('Título é um campo obrigatório.'),
    type: Yup.string().required('Tipo é um campo obrigatório.'),
    related_lesson_id: Yup.string(),
  }),
});

export function QuestionForm({ onSubmit, onCancel, initialData }) {
  const [options, setOptions] = useState(initialData?.options || []);
  const [showOptionForm, setShowOptionForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const isUpdatingQuestion = !!initialData;

  function handleOptionSubmit(option) {
    if (!selectedOption) {
      setOptions((prev) => [...prev, option]);
    } else {
      setOptions((prev) => {
        return prev.map((item) =>
          item.id === selectedOption.id && item.tempId === selectedOption.tempId
            ? { ...item, ...option }
            : item,
        );
      });
      setShowOptionForm(false);
    }
  }

  function handleOptionSelect(question) {
    if (!showOptionForm) {
      setShowOptionForm(true);
      setSelectedOption(question);
    }
  }

  function handleCreateOptionButton() {
    setShowOptionForm((prev) => !prev);
    setSelectedOption(null);
  }

  function handleQuestionSubmit(formData) {
    formData.question.tempId = String(Date.now());

    onSubmit({ ...formData.question, options });
  }

  function handleCancel() {
    const textAreaRef = containerRef.current.querySelector(
      "textarea[id='question.title']",
    );

    const hasValue = !!textAreaRef.value;

    if (hasValue) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Deseja realmente cancelar?')) {
        onCancel();
      }

      return;
    }

    onCancel();
  }

  return (
    <Container ref={containerRef}>
      <Form
        schema={schema}
        initialData={{ question: { type: 'radio', ...initialData } }}
        onSubmit={handleQuestionSubmit}
      >
        <h2>{`${isUpdatingQuestion ? 'Atualizar' : 'Adicionar'} pergunta`}</h2>

        <Scope path="question">
          <section>
            <Input
              label="Título da pergunta"
              name="title"
              placeholder="Título da pergunta"
              multiline
            />

            <SideInfo>
              <Check
                label="Tipo"
                name="type"
                options={[
                  { value: 'radio', label: 'Radio (resposta única)' },
                  {
                    value: 'checkbox',
                    label: 'Checkbox (múltiplas respostas)',
                  },
                ]}
              />

              <Input
                label="ID do Conteúdo relacionado (Aula)"
                name="related_lesson_id"
                placeholder="ID da aula"
              />
            </SideInfo>
          </section>
        </Scope>

        {options.length > 0 && (
          <section>
            <List
              disabled={showOptionForm}
              title="Respostas"
              data={options}
              onChange={setOptions}
              onItemClick={handleOptionSelect}
            />
          </section>
        )}

        <Footer>
          {!showOptionForm && (
            <Button icon={MdAdd} onClick={handleCreateOptionButton}>
              Adicionar resposta
            </Button>
          )}

          <div>
            <Button
              icon={MdClose}
              color="danger"
              onClick={handleCancel}
              disabled={showOptionForm}
            >
              Cancelar
            </Button>

            <Button
              icon={MdSave}
              color="success"
              type="submit"
              disabled={showOptionForm}
            >
              Salvar
            </Button>
          </div>
        </Footer>
      </Form>

      {showOptionForm && (
        <OptionForm
          onCancel={handleCreateOptionButton}
          onSubmit={(option) => handleOptionSubmit(option)}
          initialData={selectedOption}
        />
      )}
    </Container>
  );
}

QuestionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        is_correct: PropTypes.bool.isRequired,
      }),
    ),
  }),
};

QuestionForm.defaultProps = {
  initialData: null,
};
