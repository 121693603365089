import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 50px;
  border: 1px solid #f7f7f8;
  border-radius: 4px;

  li:not(:first-child) {
    border-top: 1px solid #f7f7f8;
  }
  li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px 20px;
    .container {
      display: flex;
      flex-direction: column;
      padding-right: 8px;

      .title {
        font-size: 17px;
        font-weight: bold;
        color: #302b3e;
      }

      .description {
        font-size: 14px;
        color: rgba(48, 43, 62, 0.5);
      }
    }

    .action {
      display: flex;
      padding-left: 8px;

      form {
        max-width: 500px;
        flex: 1;
        display: flex;
        flex-direction: column;

        button {
          width: calc(100% - 10px);
        }
      }
    }
  }
`;
