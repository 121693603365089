/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MdAdd, MdSearch } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';

import { useCoupons } from '@shared/skylab/graphql/pluto';
import { types } from '../Form/options';

import ClipboardButton from '~/components/ClipboardButton';
import { Filters, SearchForm } from '~/components/PagedList/styles';
import { Actions, Table } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { getSearchParam, useCan } from '~/utils';
import { Input } from '~/components/Form';
import Button from '~/components/Button';
import { Page } from '~/pages/styles';

const ITEMS_PER_PAGE = 20;

const columns = [
  {
    label: 'Título',
    value: 'title',
    getValue: (element) => {
      return (
        <section style={{ display: 'flex', flexDirection: 'column' }}>
          <strong
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            {element.title}
          </strong>
          <span style={{ fontSize: 12 }}>{element.description}</span>
        </section>
      );
    },
  },
  {
    label: 'Código',
    getValue: (element) => {
      return (
        <section style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <strong
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            {element.code}
          </strong>

          <ClipboardButton
            title="Copiar ID"
            value={element.code}
            style={{
              fontSize: 10,
              height: 20,
              width: 20,
            }}
          />
        </section>
      );
    },
  },
  {
    label: 'Tipo',
    getValue: (element) => {
      const type = types.find((item) => item.id === element.type);

      return <div>{type?.title || '-'}</div>;
    },
  },
  {
    label: 'Restringido?',
    getValue: (element) => (
      <input type="checkbox" checked={element.isRestricted} />
    ),
  },
  {
    label: 'Habilitado',
    getValue: (element) => <input type="checkbox" checked={element.active} />,
  },
  {
    label: 'De',
    getValue: (element) => `${format(element?.startsAt, 'DD/MM/YYYY HH:mm')}`,
  },
  {
    label: 'Até',
    getValue: (coupon) => `${format(coupon?.endsAt, 'DD/MM/YYYY HH:mm')}`,
  },
];

function CouponsList({ match, location, history }) {
  const can = useCan();
  const searchParam = getSearchParam(location);
  const [search, setSearch] = useState(searchParam || '');
  const [selectedType, setSelectedType] = useState(null);

  const currentPage = Number(match.params.page || 1);
  const offset = (currentPage - 1) * ITEMS_PER_PAGE;

  const { loading, data, refetch } = useCoupons({
    variables: {
      offset,
      limit: ITEMS_PER_PAGE,
      search: searchParam,
      type: selectedType,
    },
  });

  const couponsData = data?.coupons || {
    nodes: [],
    totalCount: 0,
  };

  const coupons = couponsData.nodes;

  useEffect(() => {
    refetch({
      offset,
      limit: ITEMS_PER_PAGE,
      search: searchParam,
      type: selectedType,
    });
  }, [match.params.page, location.search, selectedType]); // eslint-disable-line

  function handleSubmitSearch(e) {
    e.preventDefault();

    history.push(`/coupons/1/?s=${escape(search)}`);
  }

  function handlePaginate(newPage) {
    let searchQuery = searchParam;

    searchQuery = searchQuery ? `/?s=${searchQuery}` : '';
    history.push(`/coupons/${newPage}${searchQuery}`);
  }

  function renderHeader() {
    return (
      <header>
        <h1>Cupons</h1>

        <div className="w-full justify-end">
          <SearchForm onSubmit={handleSubmitSearch}>
            <Filters>
              <Select
                name="type"
                placeholder="Tipo"
                options={[
                  { value: null, label: 'Todos' },
                  ...types.map((item) => ({
                    value: item.id,
                    label: item.title,
                  })),
                ]}
                isClearable
                onChange={(data) => {
                  setSelectedType(data.value);
                }}
              />
            </Filters>

            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />

            <Button type="submit" icon={MdSearch} color="success" />
          </SearchForm>

          {can('edit_coupons') && (
            <Button icon={MdAdd} color="success" to="/coupons/new">
              Novo
            </Button>
          )}
        </div>
      </header>
    );
  }

  function renderEmptyData() {
    return (
      <div style={{ marginTop: '1rem' }}>
        <p>Nenhum registro encontrado</p>
      </div>
    );
  }

  const isNoCouponsToShowCase = !loading && !coupons.length;
  const pageCount = Math.ceil(couponsData.totalCount / ITEMS_PER_PAGE);

  return (
    <Page>
      {renderHeader()}

      {isNoCouponsToShowCase ? (
        renderEmptyData()
      ) : (
        <>
          <Table loading={loading}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.label}>{column.label}</th>
                ))}
                <th />
              </tr>
            </thead>

            <tbody>
              {coupons.map((coupon) => (
                <tr key={coupon.id}>
                  {columns.map((column) => (
                    <td key={`${coupon.id}-${column.value}`}>
                      {column.getValue?.(coupon) || coupon[column.value]}
                    </td>
                  ))}

                  <td width={120}>
                    <Actions href={encodeURI(`/coupons/edit/${coupon.code}`)} />
                    <ClipboardButton title="Copiar ID" value={coupon.id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            activePage={currentPage}
            pageCount={pageCount}
            total={couponsData.totalCount}
            perPage={ITEMS_PER_PAGE}
            onChange={handlePaginate}
          />
        </>
      )}
    </Page>
  );
}

CouponsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      page: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(CouponsList);
