import PropTypes from 'prop-types';
import React from 'react';

// import { Input as InputUnform } from '@rocketseat/unform';
import { FaSpinner } from 'react-icons/fa';

import Field from '../Field';
import InputDefault from './Input';
import { Container, FeedBack, Loading } from './styles';

function InputField({ label, name, disabled, type, unform, loading, ...rest }) {
  let Input;

  if (unform) {
    Input = InputDefault;
  } else {
    Input = 'input';
  }

  return (
    <Field name={name} disabled={disabled} type={type}>
      <Container>
        <Input
          label={label}
          name={name}
          disabled={disabled}
          type={type}
          {...rest}
        />
        {loading && (
          <FeedBack>
            <Loading>
              <FaSpinner size={16} color="#ccc" />
            </Loading>
          </FeedBack>
        )}
      </Container>
    </Field>
  );
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  unform: PropTypes.bool,
  loading: PropTypes.bool,
};

InputField.defaultProps = {
  label: '',
  disabled: false,
  placeholder: '',
  type: 'text',
  unform: true,
  loading: false,
};

export default InputField;
