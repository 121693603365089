export const reminderFormCategoryOptions = [
  { id: 'event', value: 'EVENT', label: 'Evento' },
  { id: 'feature', value: 'FEATURE', label: 'Novidade da plataforma' },
  { id: 'content', value: 'CONTENT', label: 'Conteúdo' },
  { id: 'offer', value: 'OFFER', label: 'Oferta' },
];

export const reminderFormRepeatOptions = [
  { id: 'daily', value: 'DAILY', label: 'Diariamente' },
  { id: 'weekly', value: 'WEEKLY', label: 'Semanalmente' },
  { id: 'monthly', value: 'MONTHLY', label: 'Mensalmente' },
];
