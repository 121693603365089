import { transparentize } from 'polished';
import styled from 'styled-components';

export const ChallengeLink = styled.p`
  margin-bottom: 15px;

  strong {
    font-weight: 600;
  }

  a {
    color: #7159c1;
    font-weight: bold;
  }
`;

export const Divider = styled.div`
  position: relative;
  text-align: center;
  margin: 20px 0 15px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #302b3e;

  &::before,
  &::after {
    content: '';
    width: calc(50% - 80px);
    height: 1px;
    position: absolute;
    border-top: 1px dashed #000;
    border-color: ${transparentize(0.9, '#302B3E')};
  }

  &::before {
    left: 0;
    top: 10px;
  }

  &::after {
    right: 0;
    top: 10px;
  }
`;
