/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useMemo, useState } from 'react';

import { EyeOff, GiftIcon, Plus } from 'lucide-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { PrizeItem } from './PrizeItem';
import { Fieldset } from './styles';

import { Alert } from '~/components/ui/alert';
import { cn } from '~/lib/utils';
import Button from '~/components/Button';

const mgmDefault = {
  title: null,
  imageUrl: null,
  placement: null,
};

export function MGMTab() {
  const { control, getValues } = useFormContext();

  const mgmFields = useFieldArray({
    control,
    name: 'mgm',
  });

  const mgm = useWatch({
    control,
    name: `mgm`,
  });

  const [selected, setSelected] = useState(0);

  const selectedPrize = useMemo(() => {
    return mgmFields.fields[selected];
  }, [mgmFields.fields, selected]);

  const handleAdd = useCallback(() => {
    mgmFields.append(mgmDefault);
  }, [mgmFields]);

  const handleRemove = useCallback(
    (itemIndex) => {
      const isNew = false;

      if (isNew) {
        return mgmFields.remove(itemIndex);
      }

      const mgmItem = getValues(`mgm[${itemIndex}]`);

      mgmFields.update(itemIndex, {
        ...mgmItem,
        _destroy: true,
      });
    },
    [mgmFields, getValues],
  );

  const handleRestore = useCallback(
    (itemIndex) => {
      const mgmItem = getValues(`mgm[${itemIndex}]`);

      delete mgmItem._destroy;

      mgmFields.update(itemIndex, mgmItem);
    },
    [mgmFields, getValues],
  );

  const renderMGMListMenu = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        {mgmFields.fields.map((field, fieldIndex) => {
          const isDisabled = field?._destroy;
          const isSelected = field.id === selectedPrize?.id;

          const title =
            mgm?.[fieldIndex]?.title || field?.title || 'Nova premiação';

          return (
            <button
              onClick={() => setSelected(fieldIndex)}
              key={`button-mgm-${fieldIndex}`}
              type="button"
              className={cn(
                'w-full text-left p-4 hover:bg-zinc-200 rounded-sm flex items-center gap-2',
                isSelected ? `bg-zinc-200` : `bg-zinc-50`,
                isDisabled ? 'opacity-40 line-through' : '',
              )}
            >
              <GiftIcon size={16} />
              <span className="flex-1">{title}</span>

              {isDisabled && <EyeOff size={16} />}
            </button>
          );
        })}
      </div>
    );
  }, [mgmFields.fields, selectedPrize?.id, mgm]);

  const renderMGMPrizes = useMemo(() => {
    return mgmFields.fields.map((field, fieldIndex) => {
      return (
        <PrizeItem
          item={field}
          index={fieldIndex}
          remove={() => handleRemove(fieldIndex)}
          restore={() => handleRestore(fieldIndex)}
          key={field?.id}
          className={fieldIndex === selected ? 'block' : 'hidden'}
        />
      );
    });
  }, [mgmFields.fields, handleRemove, handleRestore, selected]);

  return (
    <div className="flex flex-col w-full">
      <Alert
        title="Configurações do MGM"
        description="Essas configurações são responsáveis por gerenciar os prêmios do MGM (Member Get Member)."
        className="mb-4"
      />

      <Fieldset>
        <legend>Prêmiações</legend>

        <div className="grid grid-cols-[300px_auto] w-full gap-4">
          <div className="flex flex-col">
            <header className="w-full items-center flex gap-2 justify-between mb-2">
              <Button icon={Plus} color="success" onClick={handleAdd}>
                Adicionar
              </Button>
            </header>

            {renderMGMListMenu}
          </div>

          <div className="flex flex-col w-full">{renderMGMPrizes}</div>
        </div>
      </Fieldset>
    </div>
  );
}
