import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';
import React from 'react';

import PagedList from '~/components/PagedList';

function formatDate(field) {
  return (element) => {
    return (
      <td key={`${field}-${element.id}`}>
        {element[field]
          ? format(element[field], 'DD/MM/YYYY', { locale: pt })
          : 'Não definido'}
      </td>
    );
  };
}

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'Início da entrega',
    value: 'start_at',
    getValue: formatDate('start_at'),
  },
  {
    label: 'Término da entrega',
    value: 'end_at',
    getValue: formatDate('end_at'),
  },
];

function ChallengesList() {
  return (
    <PagedList resource="challenges" header="Desafios" columns={columns} />
  );
}

export default ChallengesList;
