import React from 'react';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: '',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.icon_url && (
            <Thumbnail>
              <img src={element.icon_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
  },
];

export default function NotificationCategoryGroupsList() {
  return (
    <>
      <PagedList
        resource="notifications/categories/groups"
        header="Contexto"
        hideSearchInput
        createPermission="create_category_groups"
        columns={columns}
        filtersText={[
          {
            name: 'title',
            label: 'Título',
          },
        ]}
      />
    </>
  );
}
