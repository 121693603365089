import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdClose, MdVideocam } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  path: Yup.string().required('Diretório é um campo obrigatório'),
});

function VideoCategoriesForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [category, setCategory] = useState({});
  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/video-categories', id, data);

      toast.success('Categoria salva com sucesso!');
      history.push('/video-categories');
    } catch ({ response }) {
      showError(response);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadCategories(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/video-categories/${id}`);

        setCategory(response);
      } catch ({ response }) {
        showError(response);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadCategories(id);
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} categoria de vídeo`}</h1>

        <div>
          <Button
            icon={MdVideocam}
            color="success"
            to={`/video-categories/${match.params.id}/videos`}
            disabled={!isUpdating}
          >
            Vídeos
          </Button>

          <Button icon={MdClose} color="cancel" to="/video-categories">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={category} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" />
          <Input
            label="Diretório"
            name="path"
            placeholder="ex: modulo-1/introducao"
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

VideoCategoriesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

VideoCategoriesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default VideoCategoriesForm;
