import React, { useCallback, useContext, useMemo } from 'react';

import { MdAdd, MdEdit } from 'react-icons/md';

import JourneyContext from '../context';
import { Container } from './styles';

import Button from '~/components/Button';

export default function JourneyItem() {
  const { journey, selectCurrent, addNode } = useContext(JourneyContext);

  const handleAddNewNode = useCallback(
    (e) => {
      e.stopPropagation();
      addNode();
    },
    [addNode],
  );

  const handleSelectCurrent = useCallback(() => {
    selectCurrent('journey');
  }, [selectCurrent]);

  const render = useMemo(() => {
    return (
      <Container
        onClick={handleSelectCurrent}
        selected={journey.current === 'journey'}
      >
        <div>
          <header>
            <MdEdit size={12} />
            <b>{journey.title || 'Adicionar jornada'}</b>
            {journey.slug && <small>{` · ${journey.slug}`}</small>}
          </header>
          <p>
            <small>{journey.description}</small>
          </p>
          {journey.scope && (
            <p>
              <small className="tag">{journey.scope}</small>
            </p>
          )}
        </div>

        {journey.title && (
          <Button
            icon={MdAdd}
            iconSize={26}
            type="button"
            onClick={handleAddNewNode}
          />
        )}
      </Container>
    );
  }, [handleSelectCurrent, journey, handleAddNewNode]);

  return render;
}
