import styled from 'styled-components';

export const Badge = styled.span`
  background: black;
  border-radius: 4px;
  color: white;

  font-size: 12px;

  padding: 2px 4px;
  margin-left: 8px;
`;
