/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  useProduct,
  useOffers,
  useUpsertProductMutation,
  useLazyOffer,
} from '@shared/skylab/graphql/pluto';
import { handleBonusFormErrors } from './bonus-form.errors';
import { normalizeOfferToForm, normalizeProductsToForm } from './bonus.utils';
import { bonusTypesMapper } from './bonus-types.mapper';
import { bonusFormSchema } from './bonus-form.schema';

export function useBonusForm({ identifier, history }) {
  const { data: productData, error } = useProduct({
    skip: !identifier,
    variables: { identifier },
  });

  const product = productData?.product || {};

  const [upsertBonus, { loading: submitting }] = useUpsertProductMutation({
    onError: ({ message }) => toast.error(handleBonusFormErrors(message)),

    onCompleted: () => {
      toast.success('Bônus salvo com sucesso!');
      history.push('/bonuses');
    },
  });

  const { data: offersData } = useOffers({
    variables: { isPurchaseable: true },
  });

  const offers = offersData?.offers || [];
  const bonus = product || {};

  const form = useForm({
    values: {
      identifier: bonus?.identifier,

      ...product,

      type: bonus?.metadata?.type
        ? {
            value: bonus?.metadata?.type,
            label: bonusTypesMapper[product?.metadata?.type],
          }
        : null,

      offer: normalizeOfferToForm(offers, bonus),
      description: bonus?.metadata?.description,
      reserva_item_type: bonus?.metadata?.reserva_item_type,

      extra_content_products: normalizeProductsToForm(
        bonus?.metadata?.products,
      ),

      access_extension_products: normalizeProductsToForm(
        bonus?.metadata?.products,
      ),
    },

    resolver: yupResolver(bonusFormSchema),
  });

  const currentBonusType = form.watch('type') || {};
  const selectedOffer = form.watch('offer') || {};

  const [fetchOffer, { data: { offer } = {} }] = useLazyOffer({
    variables: { slug: selectedOffer?.value || '' },
  });

  function loadOffer({ value }) {
    if (!value) {
      return;
    }

    fetchOffer({ variables: { slug: value } });
  }

  function getOfferProducts() {
    if (
      currentBonusType.value !== 'ACCESS_EXTENSION' &&
      !bonus?.metadata?.products?.length > 0
    ) {
      return [];
    }

    if (
      currentBonusType.value === 'ACCESS_EXTENSION' &&
      bonus?.metadata?.products?.length > 0 &&
      offer?.offerProducts?.length > 0
    ) {
      return bonus?.metadata?.products
        ?.map((product) => {
          const offerProduct = offer.offerProducts.find(
            (offerProduct) => offerProduct.product.id === product.id,
          );

          if (!offerProduct) {
            return null;
          }

          return {
            ...product,
            ...offerProduct,
          };
        })
        ?.filter(Boolean);
    }

    return offer?.offerProducts || [];
  }

  const [offerProducts, setOfferProducts] = useState(getOfferProducts);

  useEffect(() => {
    if (
      ['ACCESS_EXTENSION'].includes(currentBonusType.value) &&
      selectedOffer?.value
    ) {
      fetchOffer({
        variables: {
          slug: selectedOffer.value,
        },
      }).then(({ data }) => {
        setOfferProducts(data?.offer?.offerProducts || []);
      });
    }
  }, [currentBonusType.value, selectedOffer?.value]);

  useEffect(() => {
    if (!selectedOffer?.value && !bonus?.metadata?.offer) {
      return;
    }

    fetchOffer({
      variables: { slug: selectedOffer?.value || bonus?.metadata?.offer },
    });
  }, [bonus?.metadata?.offer]);

  const shouldDisplayOfferProductsForm =
    bonus?.metadata?.products?.length > 0 || offer?.offerProducts?.length > 0;

  return {
    offer,
    form,
    bonus,
    error,
    offers,
    loadOffer,
    submitting,
    upsertBonus,
    offerProducts,
    selectedOffer,
    currentBonusType,
    shouldDisplayOfferProductsForm,
  };
}
