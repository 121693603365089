/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { Tabs, TabList, Tab, PanelList, Panel } from 'react-tabtab';

import { EventTab } from './Tabs/EventTab';
import { HubspotTab } from './Tabs/HubspotTab';
import { TrailTab } from './Tabs/TrailTab';
import { CertificateTab } from './Tabs/CertificateTab';
import { CatalogTab } from './Tabs/CatalogTab';
import { OfferTab } from './Tabs/OfferTab';
import { EventFormProvider, useEventForm } from './use-event-form.hook';
import { DetailTab } from './Tabs/DetailTab';
import { CheckpointTab } from './Tabs/CheckpointTab';
import { IntegrationTab } from './Tabs/IntegrationTab';
import { MGMTab } from './Tabs/MGMTab';

import Button from '~/components/Button';
import api from '~/services/api';
import { Container } from '~/components/HookForm/Form/styles';
import { transformDateFromDatePicker } from '~/utils';
import { ErrorIndicator } from '~/components/HookForm';

const schema = Yup.object().shape({
  title: Yup.string().required('Nome do evento é um campo obrigatório'),
  slug: Yup.string().required('Slug é um campo obrigatório'),
  icon: Yup.string().nullable(),
  banner: Yup.string().nullable(),
  start_date: Yup.string().required('Data de início é um campo obrigatório'),
  end_date: Yup.string().nullable(),
  available_at: Yup.string().required('Disponível em é um campo obrigatório'),
  unavailable_at: Yup.string().nullable(),
  offers_starts_at: Yup.string().required(
    'Data de início da oferta é um campo obrigatório',
  ),
  offers_ends_at: Yup.string().required(
    'Data de fim da oferta é um campo obrigatório',
  ),
  keyword: Yup.string().nullable(),

  cta_text_no_subscriber: Yup.string().required('Campo Obrigatório'),
  cta_text_subscriber: Yup.string().required('Campo Obrigatório'),
  lp_cta_text: Yup.string().required('Campo Obrigatório'),
  lp_cta_url: Yup.string().required('Campo Obrigatório'),
  has_referral: Yup.boolean(),
  referral_text: Yup.string().nullable(),
  referral_url: Yup.string().nullable(),
  extra_source: Yup.string().nullable(),
  wallpaper: Yup.string().nullable(),
  challenges_text: Yup.string().required('Campo Obrigatório'),
  has_offer_modal: Yup.boolean(),
  offer_modal_title: Yup.string().nullable(),
  offer_modal_cta_text: Yup.string().nullable(),
  offer_modal_cta_link: Yup.string().nullable(),
  offer_modal_video: Yup.string().nullable(),
  has_internal_subscription: Yup.boolean(),
  external_subscription_url: Yup.string().nullable(),

  certificate_available_at: Yup.string().required(
    'Data de certificação é um campo obrigatório',
  ),

  eventTrails: Yup.array().of(
    Yup.object().shape({
      eventTrailId: Yup.string(),
      trail: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
      educators: Yup.array().of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        }),
      ),
      metadata: Yup.object().shape({
        has_vsl_on_certificate: Yup.boolean().default(false),
        vsl_on_certificate_video: Yup.string().nullable(),
        vsl_on_certificate_video_duration_in_seconds: Yup.string().nullable(),
      }),
      trailLessons: Yup.array().of(
        Yup.object().shape({
          available_at: Yup.string().required(
            'Data de exibição é um campo obrigatório',
          ),
          release_at: Yup.string().required(
            'Data de liberação é um campo obrigatório',
          ),
          lesson: Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          }),
          metadata: Yup.object().shape({
            has_offer_modal: Yup.boolean().notRequired().default(false),
            offer_modal_title: Yup.string().nullable(),
            offer_modal_cta_text: Yup.string().nullable(),
            offer_modal_cta_link: Yup.string().nullable(),
            offer_modal_video: Yup.string().nullable(),
          }),
        }),
      ),
    }),
  ),

  checkpoints: Yup.array().of(
    Yup.object().shape({
      available_at: Yup.string().required(
        'Disponível em é um campo obrigatório',
      ),
      unavailable_at: Yup.string().required(
        'Indisponível em é um campo obrigatório',
      ),
      title: Yup.string().required('O título é obrigatório'),
      description: Yup.string().required('A descrição é obrigatória'),
      award: Yup.string().required('O prêmio é obrigatório'),
      url: Yup.string().required('O link do checkpoint é obrigatório'),
      metadata: Yup.object().shape({
        description_complement: Yup.string().required(
          'A descrição complementar da tag é obrigatória',
        ),
        tag_text: Yup.string().required('O texto da tag é obrigatório'),
      }),
    }),
  ),
});

export function EventFormComponent({ match, history }) {
  const { event } = useEventForm();
  const isUpdating = !!match.params?.id;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: event.title,
      slug: event.slug,
      description: event.description,
      is_active: event.is_active,
      is_visible: event.is_visible,
      place: event?.place || 'Online',
      price: event?.price || null,
      icon: event.icon,
      banner: event.banner,
      color: event?.metadata?.color,
      event_guide_url: event?.metadata?.event_guide_url,
      start_date: event.start_date ? new Date(event.start_date) : undefined,
      end_date: event.end_date ? new Date(event.end_date) : undefined,
      redirect_url: event.redirect_url,

      // Details
      cta_text_no_subscriber:
        event?.metadata?.cta_text_no_subscriber || 'Inscreva-se agora',
      cta_text_subscriber:
        event?.metadata?.cta_text_subscriber || 'Ir para o evento',
      lp_cta_text: event?.metadata?.lp_cta_text || 'Mais informações',
      lp_cta_url:
        event?.metadata?.lp_cta_url ||
        'https://www.rocketseat.com.br/eventos/nlw',
      has_referral: event?.metadata?.has_referral,
      referral_text: event?.metadata?.referral_text || 'Convidar pessoas',
      referral_url:
        event?.metadata?.referral_url ||
        'https://www.rocketseat.com.br/eventos/nlw/inscricao/',
      challenges_text:
        event?.metadata?.challenges_text ||
        'Complete a missão e concorra a camisetas exclusivas',
      countdown_background: event?.metadata?.countdown_background || null,
      extra_source: event?.metadata?.extra_source,
      wallpaper: event?.metadata?.wallpaper,
      checkpoints_description: event?.metadata?.checkpoints_description,
      checkpoints_award_image: event?.metadata?.checkpoints_award_image,

      has_offer_modal: event?.metadata?.has_offer_modal || false,
      offer_modal_title:
        event?.metadata?.offer_modal_title ||
        'Você ganho um presente exclusivo!',
      offer_modal_cta_text:
        event?.metadata?.offer_modal_cta_text || 'Resgatar presente',
      offer_modal_cta_link:
        event?.metadata?.offer_modal_cta_link ||
        'http://app.rocketseat.com.br/cart/rocketseat-one',
      offer_modal_video: event?.metadata?.offer_modal_video || '',
      whatsapp_url: event?.metadata?.whatsapp_url,

      has_internal_subscription:
        event?.metadata?.has_internal_subscription !== false,
      external_subscription_url:
        event?.metadata?.external_subscription_url ||
        'https://www.rocketseat.com.br/eventos/nlw/inscricao/',

      // Offer
      offer: event.offer,

      offers_starts_at: event.offers_starts_at
        ? new Date(event.offers_starts_at)
        : undefined,
      offers_ends_at: event.offers_ends_at
        ? new Date(event.offers_ends_at)
        : undefined,
      available_at: event.available_at
        ? new Date(event.available_at)
        : undefined,
      unavailable_at: event.unavailable_at
        ? new Date(event.unavailable_at)
        : undefined,

      // Certificate
      has_certificate: event.has_certificate,
      keyword: event.keyword,
      certificate_available_at: event.certificate_available_at
        ? new Date(event.certificate_available_at)
        : undefined,
      certificate_unavailable_at: event.certificate_unavailable_at
        ? new Date(event.certificate_unavailable_at)
        : undefined,

      // Event Trails
      eventTrails:
        event?.eventTrails?.map((eventTrail) => ({
          eventTrailId: eventTrail.id,
          trail: {
            value: eventTrail.trail.id,
            label: eventTrail.trail.title,
          },
          description: eventTrail.description,
          community_url: eventTrail.community_url,
          project_image_url: eventTrail.project_image_url,
          project_description: eventTrail.project_description,
          metadata: {
            has_vsl_on_certificate:
              eventTrail.metadata?.has_vsl_on_certificate || false,
            vsl_on_certificate_video:
              eventTrail.metadata?.vsl_on_certificate_video,
            vsl_on_certificate_video_duration_in_seconds:
              eventTrail.metadata?.vsl_on_certificate_video_duration_in_seconds,
          },
          educators: eventTrail.educators.map((educator) => ({
            value: educator.id,
            label: educator.name,
          })),
          certificate: {
            label: eventTrail.certificate_course_slug,
            value:
              eventTrail.certificate_course_id &&
              eventTrail.certificate_course_slug
                ? {
                    courseId: eventTrail.certificate_course_id,
                    courseSlug: eventTrail.certificate_course_slug,
                  }
                : null,
          },
          journeyNode: eventTrail.journeyNode?.id
            ? {
                value: eventTrail.journeyNode?.id,
                label: eventTrail.journeyNode?.title,
              }
            : null,
          trailLessons: eventTrail.trailLessons.map((trailLesson) => ({
            trailLessonId: trailLesson.id,
            lesson: {
              value: trailLesson.lesson.id,
              label: trailLesson.lesson.title,
            },
            available_at: trailLesson.available_at
              ? new Date(trailLesson.available_at)
              : undefined,
            release_at: trailLesson.release_at
              ? new Date(trailLesson.release_at)
              : undefined,
            is_live: !!trailLesson.is_live,

            metadata: {
              has_offer_modal: trailLesson?.metadata?.has_offer_modal || false,
              offer_modal_title:
                trailLesson?.metadata?.offer_modal_title ||
                'Você ganho um presente exclusivo!',
              offer_modal_cta_text:
                trailLesson?.metadata?.offer_modal_cta_text ||
                'Resgatar presente',
              offer_modal_cta_link:
                trailLesson?.metadata?.offer_modal_cta_link ||
                'http://app.rocketseat.com.br/cart/rocketseat-one',
              offer_modal_video: trailLesson?.metadata?.offer_modal_video || '',
            },
          })),
        })) || [],

      checkpoints:
        event?.checkpoints?.map((checkpoint) => ({
          checkpointId: checkpoint.id,
          title: checkpoint.title,
          description: checkpoint.description,
          award: checkpoint.award,
          url: checkpoint.url,
          priority: checkpoint.priority,
          metadata: checkpoint.metadata,
          available_at: checkpoint.available_at
            ? new Date(checkpoint.available_at)
            : undefined,
          unavailable_at: checkpoint.unavailable_at
            ? new Date(checkpoint.unavailable_at)
            : undefined,
        })) || [],

      mgm:
        event?.prizes?.map((prize) => ({
          prizeId: prize.id,
          title: prize.title,
          imageUrl: prize.image_url,
          placement: prize.placement,
        })) || [],

      // Hubspot
      hubspot_form_id: event.hubspot_form_id,
      hubspot_portal_id: event.hubspot_portal_id,
      hubspot_trail_id: event.hubspot_trail_id,

      // Catalog
      catalog_link: event.catalog_link,
      journey: event.journey?.id
        ? {
            value: event.journey?.id,
            label: event.journey?.title,
          }
        : null,
    },
  });

  const { errors } = form.formState;

  async function handleSubmit(data) {
    data.start_date = transformDateFromDatePicker(data.start_date);
    data.end_date = transformDateFromDatePicker(data.end_date);
    data.available_at = transformDateFromDatePicker(data.available_at);
    data.unavailable_at = transformDateFromDatePicker(data.unavailable_at);

    // Details
    data.metadata = {
      color: data?.color,
      event_guide_url: data?.event_guide_url || null,
      cta_text_no_subscriber: data.cta_text_no_subscriber,
      cta_text_subscriber: data.cta_text_subscriber,
      lp_cta_text: data.lp_cta_text,
      lp_cta_url: data.lp_cta_url,
      has_referral: data.has_referral,
      referral_text: data.referral_text,
      referral_url: data.referral_url,
      challenges_text: data.challenges_text,
      countdown_background: data.countdown_background,
      extra_source: data.extra_source,
      wallpaper: data.wallpaper,
      checkpoints_description: data.checkpoints_description,
      checkpoints_award_image: data.checkpoints_award_image,
      has_offer_modal: data.has_offer_modal,
      offer_modal_title: data.offer_modal_title,
      offer_modal_cta_text: data.offer_modal_cta_text,
      offer_modal_cta_link: data.offer_modal_cta_link,
      offer_modal_video: data.offer_modal_video,
      whatsapp_url: data?.whatsapp_url,

      has_internal_subscription: data?.has_internal_subscription,
      external_subscription_url: data?.external_subscription_url,
    };

    // Offer
    data.offerSlug = data.offer?.value || null;
    data.offers_starts_at = transformDateFromDatePicker(data.offers_starts_at);
    data.offers_ends_at = transformDateFromDatePicker(data.offers_ends_at);

    // Certificate
    data.certificate_available_at = transformDateFromDatePicker(
      data.certificate_available_at,
    );

    // Catalog
    data.journey_id = data.journey?.value;

    // Event Trails
    data.eventTrails = data.eventTrails.map((eventTrail) => {
      return {
        ...eventTrail,
        projectImageUrl: eventTrail.project_image_url,
        projectDescription: eventTrail.project_description,
        eventTrailId: eventTrail.eventTrailId,
        trailId: eventTrail.trail.value,
        certificate_course_id: eventTrail.certificate?.value?.courseId || null,
        certificate_course_slug:
          eventTrail.certificate?.value?.courseSlug || null,
        description: eventTrail.description,
        community_url: eventTrail.community_url,
        educators: eventTrail.educators.map((educator) => educator.value),
        journey_node_id: eventTrail.journeyNode?.value || null,
        trailLessons: eventTrail.trailLessons.map((trailLesson) => ({
          ...trailLesson,
          trailLessonId: trailLesson.trailLessonId,
          lesson_id: trailLesson.lesson.value,
          available_at: transformDateFromDatePicker(trailLesson.available_at),
          release_at: transformDateFromDatePicker(trailLesson.release_at),
          is_live: trailLesson.is_live,

          metadata: trailLesson.metadata,
        })),
        metadata: {
          has_vsl_on_certificate: eventTrail.metadata.has_vsl_on_certificate,
          vsl_on_certificate_video:
            eventTrail.metadata.vsl_on_certificate_video,
          vsl_on_certificate_video_duration_in_seconds:
            eventTrail.metadata.vsl_on_certificate_video_duration_in_seconds,
        },
      };
    });

    data.checkpoints = data.checkpoints.map((checkpoint) => ({
      ...checkpoint,
      checkpointId: checkpoint.checkpointId,
      title: checkpoint.title,
      description: checkpoint.description,
      award: checkpoint.award,
      url: checkpoint.url,
      priority: checkpoint.priority,
      metadata: checkpoint.metadata,
      available_at: transformDateFromDatePicker(checkpoint.available_at),
      unavailable_at: transformDateFromDatePicker(checkpoint.unavailable_at),
    }));

    try {
      const { id } = match?.params;

      const { data: response } = await api.postOrPut('/events', id, data);

      const hasErrors = response?.errors?.length > 0;

      if (hasErrors) {
        response.errors.forEach((error) => {
          toast.error(error);
        });

        return;
      }

      toast.success('Evento salvo com sucesso!', {
        delay: 2,
        closeButton: (
          <div style={{ marginLeft: 16 }}>
            <Button
              onClick={() => history.push(`/events/edit/${response.data.id}`)}
              style={{ background: 'rgba(0,0,0,0.6)' }}
            >
              Voltar
            </Button>
          </div>
        ),
      });
      history.push('/events');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      toast.error('Algo deu errado.');
    }
  }

  const defaultTabIndex = useMemo(
    () => Number(new URLSearchParams(window.location.search).get('tab') || 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.search],
  );

  return (
    <>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Evento`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/events">
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Tabs
              showModalButton={false}
              defaultIndex={defaultTabIndex}
              onTabChange={(tabIndex) => {
                const params = new URLSearchParams({ tab: tabIndex });

                window.history.replaceState(
                  {},
                  '',
                  `${window.location.pathname}?${params.toString()}`,
                );
              }}
            >
              <TabList>
                <Tab>
                  DADOS DO EVENTO
                  <ErrorIndicator
                    hasError={Object.keys(errors).some((errorKey) =>
                      ['title', 'slug', 'available_at', 'start_date'].includes(
                        errorKey,
                      ),
                    )}
                  />
                </Tab>

                <Tab>DETALHES</Tab>

                <Tab>
                  TRILHAS
                  <ErrorIndicator hasError={!!errors?.trails} />
                </Tab>

                <Tab>OFERTA</Tab>

                <Tab>CHECKPOINTS</Tab>

                <Tab>MGM</Tab>

                <Tab>
                  CERTIFICADO
                  <ErrorIndicator
                    hasError={Object.keys(errors).some((errorKey) =>
                      ['has_certificate'].includes(errorKey),
                    )}
                  />
                </Tab>

                <Tab>HUBSPOT</Tab>

                <Tab>CATÁLOGO</Tab>

                <Tab>INTEGRAÇÃO</Tab>
              </TabList>

              <PanelList>
                <Panel>
                  <EventTab />
                </Panel>

                <Panel>
                  <DetailTab />
                </Panel>

                <Panel>
                  <TrailTab />
                </Panel>

                <Panel>
                  <OfferTab />
                </Panel>

                <Panel>
                  <CheckpointTab />
                </Panel>

                <Panel>
                  <MGMTab />
                </Panel>

                <Panel>
                  <CertificateTab />
                </Panel>

                <Panel>
                  <HubspotTab />
                </Panel>

                <Panel>
                  <CatalogTab />
                </Panel>

                <Panel>
                  <IntegrationTab />
                </Panel>
              </PanelList>
            </Tabs>

            <Button
              type="submit"
              size="block"
              loading={form.formState.isSubmitting}
            >
              Salvar
            </Button>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

export default function EventsForm({ match, history }) {
  return (
    <EventFormProvider eventId={match?.params?.id}>
      <EventFormComponent match={match} history={history} />
    </EventFormProvider>
  );
}

EventsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
