/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input, FileInput } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/certificates.api';

const schema = Yup.object().shape({
  name: Yup.string(),
  slug: Yup.string(),
  avatar: Yup.string(),
  signature: Yup.string(),
});

function EducatorsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [educator, setEducator] = useState({
    name: '',
    slug: null,
    avatar: '',
    signature: '',
  });
  const isUpdating = !!match.params.id;

  useEffect(() => {
    async function loadEducators(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/educators/${id}`);

        setEducator(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do educador.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadEducators(id);
    }
  }, [match.params]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      if (isUpdating) {
        const { id } = match.params;

        api.put(`/educators/${id}`, data);

        toast.success('Educador atualizado com sucesso!');
      } else {
        await api.post('/educators', data);

        toast.success('Educador criado com sucesso!');
      }

      history.push('/certificate-service/educators');
    } catch (err) {
      toast.error('Algo deu errado.');
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} educador`}</h1>

        <div>
          <Button
            icon={MdClose}
            color="cancel"
            to="/certificate-service/educators"
          >
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={educator} onSubmit={handleSubmit}>
        <section>
          <Input name="name" label="Nome" placeholder="nome" autoFocus />

          <Input
            name="slug"
            label="Slug"
            placeholder="Slug"
            description="Slug é opcional, caso não seja informado, será gerado automaticamente."
          />
        </section>

        <section>
          <FileInput
            label="Avatar"
            name="avatar"
            placeholder="Avatar"
            path="educators/avatar"
          />

          <FileInput
            label="Assinatura"
            name="signature"
            placeholder="Assinatura"
            path="educators/signature"
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

EducatorsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default EducatorsForm;
