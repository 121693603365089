import styled from 'styled-components';

export const MarkdownContainer = styled.div`
  a {
    color: #333;
    text-decoration: underline;
    font-weight: medium;
  }

  ul {
    padding: 12px 0 12px 20px;
    list-style: disc;
  }
`;
