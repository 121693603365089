import * as Yup from 'yup';

const addressSchema = Yup.object().shape({
  postal_code: Yup.string(),
  street_name: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  neighborhood: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
});

const foreignAddressSchema = Yup.object().shape({
  foreign_country: Yup.bool(),
  foreign_address: Yup.string(),
  country: Yup.string(),
});

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('E-mail é um campo obrigatório'),
  phone: Yup.string(),
  document: Yup.string(),
  billingAddress: Yup.object().when('$billingForeign', {
    is: true,
    then: foreignAddressSchema,
    otherwise: addressSchema,
  }),
  discord: Yup.string(),
  github: Yup.string(),
  linkedin: Yup.string(),
  twitter: Yup.string(),
  pioneer: Yup.bool(),
  is_verified_phone: Yup.bool(),
  password: Yup.string().when('$updatePassword', {
    is: true,
    then: Yup.string()
      .min(4, 'Senha deve ter mais que 4 caracteres')
      .required('Senha é obrigatório'),
    otherwise: Yup.string(),
  }),
  password_confirmation: Yup.string().when('$updatePassword', {
    is: true,
    then: Yup.string()
      .min(4, 'Senha deve ter mais que 4 caracteres')
      .required('Senha é obrigatório'),
    otherwise: Yup.string(),
  }),
  teams: Yup.array(Yup.string()),
  badges: Yup.array(Yup.string()),
  roles: Yup.array(Yup.string()),
});

export default schema;
