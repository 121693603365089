import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Scope } from '@rocketseat/unform';
import { MdAdd, MdDelete, MdRestore } from 'react-icons/md';

import { RuleContainer } from './styles';

import { Dropdown, Input } from '~/components/Form';
import Button from '~/components/Button';
import api from '~/services/api';

export default function TeamsForm({ trial }) {
  const [fields, setFields] = useState([]);
  const [teams, setTeams] = useState([]);

  function handleAdd() {
    setFields([
      ...fields,
      {
        id: null,
        deleted: false,
      },
    ]);
  }

  function handleRemove(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? true : field.deleted,
      })),
    );
  }

  function handleRestore(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? false : field.deleted,
      })),
    );
  }

  useEffect(() => {
    setFields(trial.teams);
  }, [trial.teams]);

  useEffect(() => {
    async function loadTeams() {
      const { data } = await api.get('/teams');

      setTeams(data.data);
    }

    loadTeams();
  }, []);

  return (
    <>
      {fields.map((rule, index) => (
        <Scope path={`teams[${index}]`} key={`field-team-${rule.id}`}>
          <RuleContainer isDeleted={rule.deleted}>
            {rule.deleted && (
              <main>
                <Button icon={MdRestore} onClick={() => handleRestore(rule.id)}>
                  Restaurar
                </Button>
              </main>
            )}

            <Input type="hidden" name="id" />
            <Input type="hidden" name="deleted" value={rule.deleted} />

            <section>
              <Dropdown
                label="Time"
                name="id"
                options={teams}
                disabled={false}
              />

              <Button icon={MdDelete} onClick={() => handleRemove(rule.id)}>
                Remover
              </Button>
            </section>
          </RuleContainer>
        </Scope>
      ))}

      <Button icon={MdAdd} onClick={handleAdd}>
        Nova regra
      </Button>
    </>
  );
}

TeamsForm.propTypes = {
  trial: PropTypes.shape({
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
};
