/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FaInfoCircle } from 'react-icons/fa';
import { FileImage, Upload } from 'lucide-react';

import Field from '../Field';
import { Container, Loading } from './styles';

import api from '~/services/api';

const FileInput = forwardRef(
  (
    { name, label, placeholder, type, path, responseKey, description, ...rest },
    ref,
  ) => {
    const {
      control,
      setValue,
      formState: { errors },
    } = useFormContext();
    const [loading, setLoading] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);

    const currentFile = useWatch({
      control,
      name,
    });

    const props = {
      ...rest,
      id: name,
      name,
      'aria-label': name,
    };

    function handleProgress(progressEvent) {
      const progressEventPercent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );

      setProgressPercent(progressEventPercent);
    }

    async function handleChange(event) {
      try {
        const data = new FormData();

        setLoading(true);
        setProgressPercent(0);

        data.append('type', type);
        data.append('file', event.target.files[0]);

        if (path) data.append('path', path);

        const response = await api.post('files/upload', data, {
          onUploadProgress: handleProgress,
        });

        const fileName = response.data[responseKey || 'name'];
        setValue(name, fileName);
      } catch (err) {
        toast.error('Erro ao enviar arquivo para upload.');
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      if (!currentFile && progressPercent === 100) {
        setProgressPercent(0);
      }
    }, [currentFile]);

    return (
      <Field>
        <>
          {label && (
            <label
              htmlFor={name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {label}

              {description && (
                <FaInfoCircle
                  size={12}
                  data-tooltip-id="tooltip"
                  data-tooltip-content={description}
                />
              )}
            </label>
          )}

          <Container progress={progressPercent} error={errors[name]}>
            {loading ? (
              <Loading />
            ) : (
              <span className="flex items-center gap-2">
                {currentFile ? <FileImage size={16} /> : <Upload size={16} />}
                {currentFile || placeholder}
              </span>
            )}

            <input
              {...props}
              type="file"
              onChange={handleChange}
              ref={ref}
              data-file={currentFile || ''}
            />
          </Container>
          {errors[name] && (
            <span className="error">{errors[name].message}</span>
          )}
        </>
      </Field>
    );
  },
);

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  responseKey: PropTypes.string,
  description: PropTypes.string,
};

FileInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'download',
  path: '',
  responseKey: '',
  description: null,
};

export default FileInput;
