import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';
import Markdown from 'react-markdown';

import Field from '../../Field';
import { MarkdownWrapper } from './styles';

export default function MarkdownInput({
  name,
  label,
  placeholder,
  control,
  allowedTypes,
  maxLength,
  disabled,
}) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const [markdown, setMarkdown] = useState(field.value || '');

  const handleChange = (e) => {
    setMarkdown(e.target.value);
    field.onChange(e);
  };

  return (
    <Field name={name} disabled={disabled}>
      {label && (
        <label
          htmlFor={name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {label}
        </label>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '36px',
        }}
      >
        <div style={{ position: 'relative' }}>
          <textarea
            id={name}
            name={name}
            value={markdown}
            placeholder={placeholder}
            onChange={handleChange}
            ref={field.ref} // Refs para o React Hook Form
            maxLength={maxLength}
            disabled={disabled}
          />

          {maxLength && (
            <span
              style={{
                position: 'absolute',
                right: 10,
                bottom: 10,
                fontSize: 12,
                color: field.value?.length >= maxLength ? '#dd5554' : '#666',
              }}
            >
              {`${field.value?.length || 0} / ${maxLength}`}
            </span>
          )}
        </div>

        <MarkdownWrapper>
          <span>Preview</span>
          <Markdown
            allowedTypes={
              allowedTypes || [
                'text',
                'paragraph',
                'strong',
                'emphasis',
                'link',
              ]
            }
            unwrapDisallowed
          >
            {markdown}
          </Markdown>
        </MarkdownWrapper>
      </div>

      {fieldState.error && (
        <span className="error">{fieldState.error.message}</span>
      )}
    </Field>
  );
}

MarkdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

MarkdownInput.defaultProps = {
  label: null,
  placeholder: null,
  allowedTypes: null,
  maxLength: null,
  disabled: false,
};
