import { gql } from '@apollo/client';

export const STEP = gql`
  query Step($id: String!) {
    step(id: $id) {
      id
      slug
      title
      hasAnswered
      criteria {
        id
        title
      }
      selfEvaluation {
        id
        relatedJourney
      }
    }
  }
`;

export const ANSWERS_FILTERS = gql`
  query AnswersFilters {
    answersFilters {
      abilities
      skills
    }
  }
`;

export const USER_AVERAGE = gql`
  query UserAverage {
    userAverage {
      title
      average
    }
  }
`;

export const ANSWERS_HISTORY = gql`
  query AnswersHistory(
    $relatedJourney: String!
    $searchAbility: String
    $searchSkill: String
    $offset: Int
    $limit: Int
  ) {
    answersHistory(
      relatedJourney: $relatedJourney
      searchAbility: $searchAbility
      searchSkill: $searchSkill
      offset: $offset
      limit: $limit
    ) {
      nodes {
        score
        node
        skill
        criterion
      }
      totalCount
    }
  }
`;

export const STEPS = gql`
  query Steps($offset: Int, $limit: Int) {
    steps(offset: $offset, limit: $limit) {
      nodes {
        id
        title
        slug
        selfEvaluation {
          relatedJourney
        }
      }
      totalCount
    }
  }
`;

export const SELF_EVALUATIONS = gql`
  query SelfEvaluations {
    selfEvaluations {
      id
      title
      relatedJourney
    }
  }
`;

export const CRITERIA = gql`
  query Criteria($offset: Int, $limit: Int) {
    criteria(offset: $offset, limit: $limit) {
      nodes {
        id
        title
      }
      totalCount
    }
  }
`;
