import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: flex-start !important;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  form {
    display: flex;
    margin-right: 10px;
  }
`;

export const FileInputContainer = styled.div`
  display: flex;
  gap: 10px;

  label[for='upload-csv'] {
    cursor: pointer;

    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    background: #7159c1;
    padding: 0 12px;
    border-radius: 4px;
    color: #fff;

    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;

    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  input[type='file'] {
    display: none;
  }

  > a {
    background: #7159c1;
    padding: 0 12px;
    color: #fff;
    height: 36px;

    border-radius: 4px;

    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 6px;

    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
`;
