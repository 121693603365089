import styled from 'styled-components';

export const Badge = styled.span`
  background: #302b3e;
  border 3px solid #302b3e;
  border-radius: 6px;
  color: white;

  font-size: 12px;

  padding: 2px 4px;
  margin-left: 8px;
`;
