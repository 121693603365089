/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { differenceInDays, format } from 'date-fns';

import { Container } from './styles';

import api from '~/services/api';
import SpinnerLoading from '~/components/SpinnerLoading';

const productTitles = {
  ignite: 'Ignite',
  explorer: 'Explorer',
  'ignite-bonus': 'Mini Cursos',
};

export const UserProducts = forwardRef(({ userId }, ref) => {
  const [userProducts, setUserProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    try {
      setLoading(true);
      const { data: response } = await api.get(`/users/${userId}/products`);

      setUserProducts(
        response.map((product) => ({
          title: productTitles[product.product],
          startDate: format(new Date(product.startDate), 'DD/MM/YYYY'),
          endDate: format(new Date(product.endDate), 'DD/MM/YYYY'),
          remain: differenceInDays(new Date(product.endDate), new Date()),
        })),
      );
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    reload() {
      loadData();
    },
  }));

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {userProducts.map((product) => (
        <section>
          <strong>{product.title}</strong>

          <footer>
            <span>
              Início do acesso: <b>{product.startDate}</b>
            </span>
            <span>
              Fim do acesso: <b>{product.endDate}</b>
            </span>
            <span>
              Restam: <b>{product.remain} dias</b>
            </span>
          </footer>
        </section>
      ))}

      {loading && <SpinnerLoading />}
    </Container>
  );
});

UserProducts.propTypes = {
  userId: PropTypes.string.isRequired,
};
