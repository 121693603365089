import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input, SortableList } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  slug: Yup.string(),
  tags: Yup.string(),
  groups: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      deleted: Yup.boolean().default(false),
      created: Yup.boolean().default(false),
    }),
  ),
});

function ClustersForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cluster, setCluster] = useState({});
  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      const groups = data.groups.map((group) => {
        const created = group.created || false;
        const deleted = group.deleted || false;

        return {
          id: group.id,
          created: created && !deleted,
          deleted,
        };
      });

      const newData = {
        ...data,
        groups,
      };

      await api.postOrPut('/clusters', id, newData);

      toast.success('Cluster salvo com sucesso!');
      setSubmitting(false);
      history.push('/clusters');
    } catch (err) {
      setSubmitting(false);
      toast.error('Algo deu errado');
    }
  }

  async function loadLessonGroups() {
    const { data: response } = await api.get('/lesson-groups?showAll=true');
    const { data } = response;

    return data;
  }

  useEffect(() => {
    async function loadCluster(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/clusters/${id}`);

        setCluster(response);
      } catch (err) {
        toast.error('Não foi possível');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadCluster(id);
    }
  }, []);

  function getLabel(cluster) {
    return cluster.tagged_title;
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} cluster`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/clusters">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={cluster} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug do cluster (gerado automaticamente)"
          />
        </section>

        <section>
          <Input name="tags" placeholder="Tags do cluster (ex: #Tag1 #Tag2)" />
        </section>

        <section>
          <SortableList
            label="Grupos de aulas"
            name="groups"
            resource="lesson-groups"
            load={loadLessonGroups}
            getOptionLabel={getLabel}
            includeDeletedEntries
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

ClustersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ClustersForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default ClustersForm;
