import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { addHours } from 'date-fns';

import { TeamsUsersForm } from './TeamUsers';

import Button from '~/components/Button';
import { Check, Datepicker, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError, useCan } from '~/utils';

function UsersPurchasesForm({ match, history, location }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [purchase, setPurchase] = useState({});
  const [teams, setTeams] = useState([]);
  const [plans, setPlans] = useState([]);
  const isUpdating = !!match.params.id && !location.pathname.includes('/new');
  const can = useCan();

  const viewOnly = !can('edit_purchases');

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      let { id } = match.params;
      let dataAsInput = data;

      if (!isUpdating) {
        dataAsInput = {
          ...data,
          user_id: id,
          start_date: addHours(data.start_date, 3),
          end_date: addHours(data.end_date, 3),
          canceled_at: addHours(data.canceled_at, 3),
          refunded_at: addHours(data.refunded_at, 3),
        };

        id = null;
      }

      await api.postOrPut('purchases', id, dataAsInput);

      toast.success('Compra salva com sucesso!');
      history.goBack();
    } catch (err) {
      showError(err);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadTeams() {
      const { data } = await api.get('/teams');

      setTeams(data.data);
    }

    async function loadPlans() {
      const { data } = await api.get('/plans');

      setPlans(data.data);
    }

    async function loadPurchase(id) {
      try {
        setLoading(true);

        const { data } = await api.get(`/purchases/${id}`);

        await Promise.all([loadTeams(), loadPlans()]);

        setPurchase(data);
      } catch (err) {
        showError(err);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id && isUpdating) {
      loadPurchase(id);
    } else {
      loadTeams();
      loadPlans();
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar compra</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} compra`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" onClick={history.goBack}>
            Cancelar
          </Button>
        </div>
      </header>

      <Form initialData={purchase} onSubmit={handleSubmit}>
        <section>
          <Input label="Transação" name="transaction" readOnly={viewOnly} />
          <Input
            label="Transação Externa"
            name="transaction_ext"
            readOnly={viewOnly}
          />
          <Input
            label="Engine de Pagamento"
            name="payment_engine"
            readOnly={viewOnly}
          />
          <Input
            label="Tipo de Pagamento"
            name="payment_type"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Input label="Cupom" name="coupon" readOnly={viewOnly} />
          <Input label="Produto" name="product" readOnly={viewOnly} />
          <Input label="Off" name="off" readOnly={viewOnly} />
          <Input label="Status" name="status" readOnly={viewOnly} />
        </section>

        <section>
          <Datepicker
            label="Data inicio"
            name="start_date"
            readOnly={viewOnly}
          />
          <Datepicker label="Data fim" name="end_date" readOnly={viewOnly} />
        </section>

        <section>
          <Input label="Valor" name="price" type="number" readOnly={viewOnly} />
          <Input label="Moeda" name="currency" readOnly={viewOnly} />
          <Input label="Referência" name="referral" readOnly={viewOnly} />
        </section>

        <section>
          <Datepicker
            label="Data da Garantia"
            name="warranty_date"
            disabled={viewOnly}
          />
          <Datepicker
            label="Data de Cancelamento"
            name="canceled_at"
            disabled={viewOnly}
          />
          <Datepicker
            label="Data de Reembolso"
            name="refunded_at"
            disabled={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            label="Time"
            name="team_id"
            options={teams}
            disabled={viewOnly}
          />
          <Dropdown
            label="Plano"
            name="plan_id"
            options={plans}
            disabled={viewOnly}
          />
        </section>

        <section>
          <Check
            label="Recebeu Upgrade do One?"
            name="is_one_upgraded"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />

          <Check
            label="É uma recorrencia?"
            name="is_recurrence"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Input
            label="ID da Oferta no Pluto"
            name="pluto_offer_id"
            readOnly={viewOnly}
          />
        </section>

        <Input label="Metadata" name="metadata" multiline />
        <section />

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>

      {isUpdating && (
        <TeamsUsersForm
          purchaseId={purchase.id}
          userId={purchase.user_id}
          teams={purchase.teams}
          viewOnly={viewOnly}
        />
      )}
    </Page>
  );
}

UsersPurchasesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

UsersPurchasesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default UsersPurchasesForm;
