import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

export const Actions = styled.div`
  display: flex;
  gap: 6px;

  svg#correct-svg {
    cursor: default;
  }
`;
