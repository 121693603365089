import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import { Scope } from '@rocketseat/unform';
import { RiMailSendLine, RiUserAddLine } from 'react-icons/ri';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdRestore } from 'react-icons/md';

import { Button, ButtonsContainer, ManagerContainer, Title } from './styles';

import ButtonComponent from '~/components/Button';
import { Dropdown, Input } from '~/components/Form';
import api from '~/services/api';
import { getLabel } from '~/utils';

const roles = [
  { id: 'owner', title: 'Proprietário' },
  { id: 'manager', title: 'Gestor' },
  { id: 'analyst', title: 'Analista' },
];

export default function AdminForm({ company, isUpdating }) {
  const [fields, setFields] = useState([]);

  function handleAdd() {
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        deleted: false,
      },
    ]);
  }

  function handleRemove(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? true : field.deleted,
      })),
    );
  }

  function handleRestore(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? false : field.deleted ?? false,
      })),
    );
  }

  async function inviteEmail(userId, companyId) {
    try {
      await api.post('/b2b/companies/managers/invite', {
        user_id: userId,
        company_id: companyId,
      });

      toast.success('E-mail enviado com sucesso!');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  useEffect(() => {
    if (!isUpdating) {
      handleAdd();
    } else {
      setFields(company?.managers);
    }
  }, [company?.managers]);

  return (
    <>
      <header style={{ margin: '3rem 0 1rem' }}>
        <Title>Lista de administradores</Title>

        <ButtonComponent
          icon={RiUserAddLine}
          color="success"
          onClick={handleAdd}
        >
          NOVO USUÁRIO
        </ButtonComponent>
      </header>

      {fields?.map((manager, index) => {
        const isFirst = index === 0;

        return (
          <Scope
            path={`managers[${index}]`}
            key={`field-manager-${manager.id}`}
          >
            <ManagerContainer isDeleted={manager.deleted}>
              {manager.deleted && (
                <main>
                  <ButtonComponent
                    icon={MdRestore}
                    onClick={() => handleRestore(manager.id)}
                  >
                    Restaurar
                  </ButtonComponent>
                </main>
              )}
              <Input type="hidden" name="id" />
              <Input type="hidden" name="deleted" value={manager.deleted} />

              <section>
                <Input
                  name="name"
                  placeholder="Nome do admin"
                  style={{ height: 50 }}
                  label={
                    isFirst
                      ? getLabel(
                          'Nome',
                          'Nome completo do administrador.',
                          true,
                        )
                      : null
                  }
                />

                <Input
                  name="email"
                  placeholder="mail@mail.com"
                  style={{ height: 50 }}
                  label={
                    isFirst
                      ? getLabel(
                          'E-mail',
                          'E-mail para acesso a plataforma.',
                          true,
                        )
                      : null
                  }
                />

                <Dropdown
                  name="role"
                  options={roles}
                  disabled={false}
                  placeholder="Selecione..."
                  label={
                    isFirst
                      ? getLabel(
                          'Permissão',
                          'Tipo de permissão da plataforma.',
                          true,
                        )
                      : null
                  }
                />

                <ButtonsContainer isFirst={isFirst}>
                  <Button
                    type="button"
                    onClick={() => handleRemove(manager.id)}
                  >
                    <FaRegTrashAlt className="icon" />
                  </Button>

                  <Button
                    type="button"
                    onClick={() =>
                      inviteEmail(manager.id, manager?.pivot?.company_id)
                    }
                    disabled={!(manager.id && manager?.pivot?.company_id)}
                  >
                    <RiMailSendLine className="icon" />
                  </Button>
                </ButtonsContainer>
              </section>
            </ManagerContainer>
          </Scope>
        );
      })}
    </>
  );
}

AdminForm.propTypes = {
  company: PropTypes.shape({
    managers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  isUpdating: PropTypes.bool.isRequired,
};
