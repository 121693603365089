import React from 'react';

import { format } from 'date-fns';
import { FaEye } from 'react-icons/fa';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: '',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.icon_url && (
            <Thumbnail>
              <img src={element.icon_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'slug',
    value: 'slug',
  },
  {
    label: 'Data de Início',
    value: 'start_date',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.start_date
            ? format(new Date(element.start_date), 'DD/MM/YYYY')
            : '-'}
        </td>
      );
    },
  },
  {
    label: 'Data de Fim',
    value: 'end_date',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.end_date
            ? format(new Date(element.end_date), 'DD/MM/YYYY')
            : '-'}
        </td>
      );
    },
  },
  {
    label: 'Visível',
    value: 'is_visible',
    decorate: (value) => {
      const color = value ? 'green' : 'red';
      return <FaEye color={color} />;
    },
  },
];

export default function EventsList() {
  return (
    <PagedList
      resource="events"
      header="Eventos"
      columns={columns}
      createPermission="edit_lessons"
    />
  );
}
