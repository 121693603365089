/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react';

import debounce from 'debounce-promise';

import { useLazyProducts } from '@shared/skylab/graphql/pluto';

import { Dropdown } from '~/components/HookForm';
import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';

export default function ExtraContentSection() {
  const [fetchProducts] = useLazyProducts();

  async function loadProducts() {
    const { data } = await fetchProducts();
    const products = data?.products || [];

    return products.map((product) => ({
      title: product.title,
      identifier: product.identifier,

      value: product.id,
      label: `[${product.identifier}] ${product.title}`,
    }));
  }

  const loadAsyncProducts = useCallback(
    debounce(loadProducts, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="uppercase !text-gray-500 !bg-white">
        Conteúdo extra
      </legend>

      <Dropdown
        async
        required
        multiple
        isClearable
        name="extra_content_products"
        label="Produtos"
        placeholder="Selecione os produtos"
        load={loadAsyncProducts}
      />
    </fieldset>
  );
}
