/* eslint-disable import/no-named-as-default */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import { Container, ButtonContainer } from './styles';

import { Input } from '~/components/Form';
import { getLabel } from '~/utils';
import Button from '~/components/Button';
import api from '~/services/api';

export function CreateLicenseForm({
  show,
  contractId,
  loadLicenses,
  setShowCreateLicense,
}) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit() {
    try {
      setIsSubmitting(true);
      await api.post('/b2b/licenses', {
        contract_id: contractId,
        email,
      });

      toast.success('Licença criada com sucesso');
    } catch {
      toast.error('Não foi possível criar a licença');
    }
    setIsSubmitting(false);
    loadLicenses();
    setShowCreateLicense(false);
  }

  return (
    <SweetAlert2 show={show} showConfirmButton={false}>
      <Container>
        <h1>Criar usuário</h1>
        <p>Insira o e-mail do usuário que voce deseja incluir nas licenças</p>

        <Input
          name="email"
          placeholder="mail@mail.com"
          label={getLabel('E-mail', 'E-mail para acesso a plataforma.', true)}
          onChange={(ev) => setEmail(ev.target.value)}
        />

        <ButtonContainer>
          <Button
            type="button"
            color="cancel"
            onClick={() => setShowCreateLicense(false)}
          >
            Cancelar
          </Button>

          <Button
            loading={isSubmitting}
            type="button"
            onClick={() => handleSubmit()}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Container>
    </SweetAlert2>
  );
}

CreateLicenseForm.propTypes = {
  show: PropTypes.bool.isRequired,
  contractId: PropTypes.string.isRequired,
  loadLicenses: PropTypes.func.isRequired,
  setShowCreateLicense: PropTypes.func.isRequired,
};
