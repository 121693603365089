import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';
import ptBr from 'date-fns/locale/pt-BR';
import { FaInfoCircle } from 'react-icons/fa';

import { Container } from './styles';
import Field from '../Field';

registerLocale('pt-BR', ptBr);

const Datepicker = forwardRef(
  (
    {
      label,
      name,
      required,
      description = null,
      showTimeSelect,
      timeIntervals,
      placeholder,
      disabled,
      customInput,
      ...rest
    },
    ref,
  ) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    return (
      <Field label={label} name={name}>
        {label && (
          <label
            htmlFor={name}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}
            {required && <span style={{ color: 'red' }}>*</span>}
            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}

        <Container>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <ReactDatePicker
                {...rest}
                {...field}
                ref={ref || field.ref || null}
                timeFormat="HH:mm"
                timeIntervals={timeIntervals}
                dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                timeCaption="Horário"
                valueName="selected"
                customInput={customInput || <input {...rest} />}
                locale={ptBr}
                selected={field?.value}
                onChange={field?.onChange}
                showTimeSelect={showTimeSelect}
                disabled={disabled}
                placeholderText={placeholder}
              />
            )}
          />
        </Container>

        {errors[name] && <span className="error">{errors[name].message}</span>}
      </Field>
    );
  },
);

Datepicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  timeIntervals: PropTypes.number,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  customInput: PropTypes.element,
};

Datepicker.defaultProps = {
  label: '',
  required: false,
  showTimeSelect: false,
  disabled: false,
  timeIntervals: 60,
  description: null,
  placeholder: null,
  customInput: null,
};

export default Datepicker;
