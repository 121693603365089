import React from 'react';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: 'Thumb',
    value: 'thumbnail',
    getValue: (element) => {
      const { user } = element;
      return (
        <td key={element.id}>
          {user.avatar_url && (
            <Thumbnail>
              <img src={user.avatar_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Nome',
    value: 'name',
    getValue: (element) => {
      const { user } = element;

      return (
        <td key={element.id}>
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{user.name}</b>
            <span>{user.email}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Ocupação',
    value: 'role',
  },
  {
    label: 'Sobre',
    value: 'about',
    getValue: (educator) => {
      return (
        <td
          key={educator.id}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '0',
          }}
        >
          {educator.about}
        </td>
      );
    },
  },
];

function EducatorsList() {
  return (
    <PagedList
      resource="educators"
      header="Educadores"
      columns={columns}
      createPermission="edit_users"
    />
  );
}

export default EducatorsList;
