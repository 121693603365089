import { gql } from '@apollo/client';

export const CREATE_ANSWER = gql`
  mutation CreateAnswer($data: CreateAnswerInput!) {
    createAnswer(data: $data) {
      id
    }
  }
`;

export const UPSERT_STEP = gql`
  mutation UpsertStep($data: UpsertStepInput!) {
    upsertStep(data: $data) {
      id
    }
  }
`;
