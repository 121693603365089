import { format } from 'date-fns';
import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Expert',
    getValue: (element) => <td>{element.expert.name}</td>,
  },
  {
    label: 'Título',
    getValue: (element) => {
      const lessonEntries = element.entries.filter(
        (entry) => entry.type === 'lesson',
      );

      const value = lessonEntries.length ? lessonEntries.pop().title : '-';

      return <td>{value}</td>;
    },
  },
  {
    label: 'Status',
    getValue: (element) => {
      const { status } = element;

      switch (status) {
        case 'awaiting-review':
          return <td>Aguardando análise</td>;
        case 'changes-requested':
          return <td>Submissão solicitada</td>;
        case 'approved':
          return <td>Aprovado</td>;
        case 'rejected':
          return <td>Rejeitado</td>;
        default:
          return <td>-</td>;
      }
    },
  },
  {
    label: 'Enviado em',
    getValue: (element) => (
      <td>{format(element.created_at, 'DD/MM/YYYY - HH:mm')}</td>
    ),
  },
];

function LessonGroupsList() {
  const filters = [
    {
      label: 'Status',
      name: 'status',
      data: [
        {
          value: 'awaiting-review',
          label: 'Aguardando análise',
        },
        {
          value: 'changes-requested',
          label: 'Submissão solicitada',
        },
        {
          value: 'approved',
          label: 'Aprovado',
        },
        {
          value: 'rejected',
          label: 'Rejeitado',
        },
      ],
    },
  ];

  return (
    <PagedList
      resource="lesson-submissions"
      header="Submissões de aulas"
      columns={columns}
      ignorePermissions
      filters={filters}
    />
  );
}

export default LessonGroupsList;
