import styled from 'styled-components';

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:nth-child(2) {
      gap: 0;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 1rem;
`;

export const JourneyContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > section {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;

    > div {
      background: #f3f3f3;
      border-radius: 10px;
      text-align: center;

      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      padding: 1rem 1rem 0.5rem;

      span {
        font-size: 0.875rem;
      }
    }
  }
`;
