import axios from 'axios';

const mgmApi = axios.create({
  baseURL: `${process.env.REACT_APP_MGM_URL}`,
  headers: {
    AUTH_ACCESS_TOKEN: process.env.REACT_APP_MGM_AUTH_ACCESS_TOKEN,
  },
});

mgmApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('@Skylab:token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default mgmApi;
