/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';

import DayPickerInput, { DateUtils } from 'react-day-picker';
import SweetAlert2 from 'react-sweetalert2';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { FaInfoCircle } from 'react-icons/fa';

import { remindersFormSectionsMapper } from './Sections';
import {
  ReminderFormProvider,
  useReminderForm,
} from '../use-reminder-form.hook';
import { reminderFormSchema } from '../reminder-form.schema';
import {
  reminderFormRepeatOptions,
  reminderFormCategoryOptions,
} from '../reminder-form.options';
import { reminderFormMapper } from '../reminder-form-payload.mapper';

import { Container } from '~/components/HookForm/Form/styles';
import {
  Input,
  Check,
  Dropdown,
  FileInput,
  Datepicker,
} from '~/components/HookForm';
import Button from '~/components/Button';
import api from '~/services/api';
import 'react-day-picker/lib/style.css';
import Field from '~/components/HookForm/Field';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '~/utils';

export function RemindersFormComponent({ match, history }) {
  const overwriteFeaturedReminderRef = useRef(false);
  const { reminder, featuredReminder, defaultValues } = useReminderForm();
  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const isUpdating = !!match?.params?.id;

  const shouldWarnForAnExistentFeaturedReminder = useMemo(() => {
    if (match?.params?.id === featuredReminder?.id) return false;

    if (featuredReminder) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredReminder?.id, match?.params?.id]);

  const persistedExceptionDates =
    reminder?.reminderRecurrences?.exception_dates
      ?.split(',')
      ?.map((date) => new Date(date)) || [];

  const [exceptionDates, setExceptionDates] = useState(persistedExceptionDates);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(reminderFormSchema),
  });

  const category = form.watch('category');
  const shouldRepeat = form.watch('should_repeat');
  const frequency = form.watch('frequency');

  const remindersFormSection = useMemo(() => {
    if (!category?.value) return null;

    return remindersFormSectionsMapper?.[category?.value] || null;
  }, [category]);

  async function handleSubmit(data) {
    const payload = reminderFormMapper.mapToHttp({
      ...data,
      exception_dates: exceptionDates,
    });

    try {
      const { id } = match?.params || {};

      await api.postOrPut('/reminders', id, payload);

      toast.success('Lembrete salvo com sucesso!');
      history.push('/reminders');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  const onExceptionDatesChange = (day, { selected }) => {
    const newSelectedDays = exceptionDates.concat();

    if (selected) {
      const selectedIndex = newSelectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day),
      );

      newSelectedDays.splice(selectedIndex, 1);
    } else {
      newSelectedDays.push(day);
    }

    setExceptionDates(newSelectedDays);
  };

  return (
    <>
      <SweetAlert2
        show={confirmationModalState}
        title="Já existe um lembrete destacado!"
        html={`Ao destacar este lembrete, você removerá o destaque do lembrete <strong style="color: #DD5554">${featuredReminder?.title}</strong>. Deseja continuar?`}
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={() => {
          overwriteFeaturedReminderRef.current = true;
          setConfirmationModalState(false);
        }}
        onResolve={() => {
          form.setValue('is_featured', !!overwriteFeaturedReminderRef.current);
          setConfirmationModalState(false);
        }}
      />

      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Lembrete`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/reminders">
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <section className="flex flex-col">
              <Dropdown
                required
                isClearable
                name="category"
                label="Categoria do lembrete"
                options={reminderFormCategoryOptions}
                placeholder="Selecione a categoria"
              />

              <section>
                <Input
                  required
                  name="name"
                  label="Nome do lembrete"
                  placeholder="Nome do lembrete"
                />

                <Datepicker
                  required
                  showTimeSelect
                  timeIntervals={5}
                  name="start_date"
                  label="Data inicial"
                  placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
                />

                <Datepicker
                  required
                  showTimeSelect
                  name="end_date"
                  label="Data final"
                  timeIntervals={5}
                  placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
                />

                <Check
                  name="is_free"
                  label="Somente para assinantes?"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              <section>
                <Input
                  name="local"
                  label="Local"
                  placeholder="Local do evento"
                />

                <Input
                  name="cta_label"
                  label="Texto do CTA"
                  placeholder="Ver conteúdo"
                />

                <Input
                  name="cta_link"
                  label="Link do CTA"
                  placeholder="https://www.rocketseat.com.br/formacao/devops"
                />

                <Check
                  name="should_repeat"
                  label="Repetir lembrete?"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              {shouldRepeat && (
                <Dropdown
                  required
                  isClearable
                  name="frequency"
                  options={reminderFormRepeatOptions}
                  onChange={({ value }) => {
                    if (value === 'DAILY') {
                      form.setValue('interval', 1);
                    }
                  }}
                  label="Tipo de frequência de repetição"
                  placeholder="Selecione o tipo de frequência repetição"
                />
              )}

              {shouldRepeat && (
                <section>
                  <Input
                    type="number"
                    name="interval"
                    disabled={frequency?.value === 'DAILY'}
                    label="Intervalo da repetição"
                    placeholder="Intervalo de repetição"
                    description="Intervalo da repetição, caso tenha selecionado repetir lembrete 'Mensalmente', por exemplo, e o intervalo seja 2, o lembrete será exibido a cada 2 meses."
                  />

                  <Datepicker
                    showTimeSelect
                    timeIntervals={5}
                    name="recurrency_end_date"
                    label="Data final da repetição"
                    placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
                  />
                </section>
              )}

              <section className="h-full">
                <Field>
                  <label htmlFor="description">Descrição</label>

                  <Input
                    multiline
                    name="description"
                    className="flex w-full border h-full min-h-40"
                    placeholder='Descrição do lembrete. Ex: "Aprenda a criar um site do zero."'
                  />
                </Field>

                {shouldRepeat && (
                  <Field>
                    <div className="flex items-baseline gap-1">
                      <label htmlFor="exception_dates">Datas de exceção</label>

                      <FaInfoCircle
                        size={12}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Datas em que o lembrete não será exibido no período de repetição."
                      />
                    </div>

                    <DayPickerInput
                      locale="pt-BR"
                      showOutsideDays
                      months={MONTHS}
                      firstDayOfWeek={0}
                      weekdaysLong={WEEKDAYS_LONG}
                      selectedDays={exceptionDates}
                      weekdaysShort={WEEKDAYS_SHORT}
                      onDayClick={onExceptionDatesChange}
                      component={(props) => (
                        <input name="exception_dates" {...props} />
                      )}
                    />
                  </Field>
                )}
              </section>

              <section>
                <FileInput
                  name="banner"
                  path="platform"
                  label="Banner do lembrete"
                  placeholder="Banner do lembrete"
                  description={
                    reminder.banner_url
                      ? `<img src="${reminder.banner_url}" className="w-[500px]" />`
                      : `Dimensões recomendadas: 
                          <div class="flex flex-col">
                            <span>
                              <strong>616</strong>x<strong>264</strong>
                            </span>
                            
                            <span>Para mais informações, consulte https://rseat.in/padrao-banners</span>
                          </div>
                        `
                  }
                />

                <Check
                  name="is_featured"
                  label="Destacar lembrete?"
                  onClick={({ value }) => {
                    if (value && overwriteFeaturedReminderRef.current) {
                      return;
                    }

                    if (value && shouldWarnForAnExistentFeaturedReminder) {
                      setConfirmationModalState(true);
                    }

                    overwriteFeaturedReminderRef.current = false;
                  }}
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />

                <Check
                  name="is_recommended"
                  label="Exibir como recomendado?"
                  description="Exibe o lembrete como recomendado na plataforma para todos os alunos."
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />

                <Check
                  name="is_college"
                  label="Exibir somente no calendário da faculdade?"
                  description="Exibe o lembrete somente no workspace da faculdade"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              {remindersFormSection}

              <Button
                size="block"
                type="submit"
                loading={form.formState.isSubmitting}
              >
                Salvar
              </Button>
            </section>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

export default function RemindersForm({ match, history }) {
  return (
    <ReminderFormProvider reminderId={match?.params?.id}>
      <RemindersFormComponent match={match} history={history} />
    </ReminderFormProvider>
  );
}

RemindersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
