import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FaTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';

import Button from '~/components/Button';
import { Form, Input, Check, Dropdown, SortableList } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { Alert } from '~/components/ui/alert';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  group_id: Yup.string().required('Contexto é um campo obrigatório'),
  channels: Yup.array().of(Yup.string()).required('Campo obrigatório'),
});

function NotificationCategoryForm({ match, history }) {
  const [submitting, setSubmitting] = useState(false);
  const [modalExcludeOpen, setModalExcludeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryGroups, setCategoryGroups] = useState([]);

  const [category, setCategory] = useState({
    title: '',
    icon: '',
    type: '',
    group_id: '',
    channels: [],
  });

  const isUpdating = !!match.params.id;
  const { id } = match.params;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      await api.postOrPut('/notifications/categories', id, data);

      toast.success('Categoria salva com sucesso!');
      history.push('/notifications/categories');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setSubmitting(false);
    }
  }

  async function loadCategory(categoryId) {
    try {
      setIsLoading(true);

      const { data: response } = await api.get(
        `/notifications/categories/${categoryId}`,
      );

      setCategory(response);
    } catch (err) {
      toast.error('Erro ao buscar dados da categoria.');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveCategory(categoryId) {
    try {
      await api.put(`/notifications/categories/${categoryId}`, {
        is_enabled: false,
      });

      toast.success('Categoria excluída com sucesso');
      history.push('/notifications/categories');
    } catch {
      toast.error('Não foi possível excluir a categoria');
    }

    setModalExcludeOpen(false);
  }

  async function loadChannels() {
    const { data: response } = await api.get('/notifications/channels');
    const { data } = response;

    return data;
  }

  const loadCategoryGroups = useCallback(async () => {
    try {
      const { data: response } = await api.get(
        '/notifications/categories/groups',
      );

      const { data } = response;

      setCategoryGroups(data);
    } catch (err) {
      toast.error('Não foi possível carregar o contexto');
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadCategory(id);
    }

    loadCategoryGroups();
  }, [loadCategoryGroups, id]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} Categoria`}</h1>

        <div>
          {isUpdating && (
            <Button
              icon={FaTrashAlt}
              type="button"
              color="danger"
              onClick={() => setModalExcludeOpen(true)}
            >
              EXCLUIR
            </Button>
          )}
          <Button icon={MdClose} color="cancel" to="/notifications/categories">
            CANCELAR
          </Button>
          <Button form="category-form" type="submit" loading={submitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="category-form"
        schema={schema}
        initialData={category}
        onSubmit={handleSubmit}
      >
        <Alert
          title="Categoria da notificação"
          description="A categoria é responsável por agrupar notificações."
          className="mb-4"
        />
        <section>
          <Input
            name="title"
            label={getLabel(
              'Título',
              null,
              true,
              'Será exibido nas configurações do usuário',
            )}
            placeholder="Título da categoria"
          />
        </section>

        <section>
          <Check
            label={getLabel(
              'É obrigatória?',
              null,
              true,
              "Uma vez marcada como 'sim', a notificação não poderá ser desabilitada pelo usuário.",
            )}
            name="type"
            options={[
              { value: 'MANDATORY', label: 'Sim' },
              { value: 'OPTIONAL', label: 'Não' },
            ]}
          />

          <Dropdown
            label={getLabel(
              'Contexto',
              null,
              true,
              'Contexto geral da notificação',
            )}
            required
            name="group_id"
            disabled={!categoryGroups.length}
            placeholder="Selecione o contexto"
            options={categoryGroups}
          />
        </section>

        <section>
          <SortableList
            label={getLabel('Canais', '', true)}
            name="channels"
            resource="notifications/channels"
            load={loadChannels}
          />
        </section>
      </Form>

      <SweetAlert2
        show={modalExcludeOpen}
        title="Excluir categoria?"
        text="Todas as notificações atreladas a esta categorias deixarão de ser enviadas."
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveCategory(id)}
        onResolve={() => setModalExcludeOpen(false)}
      />
    </Page>
  );
}

NotificationCategoryForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

NotificationCategoryForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default NotificationCategoryForm;
