import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdClose, MdPerson } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input, Check } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { useCan } from '~/utils';

import { Divider } from './styles';

const schema = Yup.object().shape({
  approved: Yup.bool()
    .transform((value) => (value === null ? undefined : value))
    .required('Selecione uma opção'),
  reply: Yup.string(),
});

function SensitiveInformationForm({ match, history }) {
  const [data, setData] = useState({
    answered: false,
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const can = useCan();

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.put(`/sensitive-information/${id}`, data);

      toast.success('Solicitação resolvida com sucesso!');
      setSubmitting(false);

      history.push('/sensitive-information');
    } catch (err) {
      toast.error('Ocorreu um erro ao resolver a solicitação.');
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadRequest(id) {
      try {
        const { data: response } = await api.get(
          `/sensitive-information/${id}`,
        );

        setData(response);
      } catch (err) {
        toast.error('Não foi possível carregar os dados da solicitação');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadRequest(id);
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        <h1>Solicitação de alteração de dados</h1>

        <div>
          {data.user && can('view_users') && (
            <Button
              icon={MdPerson}
              color="success"
              to={`/users/edit/${data.user.id}`}
            >
              Ver usuário
            </Button>
          )}
          <Button icon={MdClose} color="cancel" to="/sensitive-information">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={data} onSubmit={handleSubmit}>
        <section>
          <Input
            label="Usuário"
            name="user"
            value={data.user && data.user.name}
            readOnly
          />
          <Input label="Campo" name="field_name" readOnly />
        </section>
        <section>
          <Input label="Valor anterior" name="old_value" readOnly />
          <Input label="Alteração solicitada" name="new_value" readOnly />
        </section>
        <section>
          <Input label="Motivo" name="reason" multiline readOnly />
        </section>

        {(can('reply_sensitive-information') || data.answered) && (
          <>
            <Divider>
              {`${data.answered ? 'Avaliação da' : 'Avaliar'} solicitação`}
            </Divider>

            <section>
              {data.answered ? (
                <Input
                  label="Avaliação"
                  name="answer"
                  value={data.approved ? 'Aprovado' : 'Recusado'}
                  readOnly
                />
              ) : (
                <Check
                  label="Aprovar alteração"
                  name="approved"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              )}
              <Input
                label="Resposta em caso de reprovação (opcional)"
                name="reply"
                multiline
                readOnly={data.answered}
              />
            </section>
          </>
        )}

        {can('reply_sensitive-information') && (
          <Button
            type="submit"
            size="block"
            loading={submitting}
            disabled={data.answered}
          >
            {data.answered
              ? 'Solicitação já respondida'
              : 'Responder solicitação'}
          </Button>
        )}
      </Form>
    </Page>
  );
}

SensitiveInformationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

SensitiveInformationForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default SensitiveInformationForm;
