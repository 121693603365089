import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;

      > * {
        margin-left: 10px;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
`;
