import styled from 'styled-components';

export const Container = styled.div`
  background: #efefef;

  padding: 20px;
  margin-top: 50px;

  table {
    tr {
      td {
        > section {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }

        label {
          background: transparent;
          color: #000;

          padding: 0;
        }
      }
    }
  }
`;
