import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import isEqual from 'lodash/isEqual';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import * as Yup from 'yup';

import { useNotificationForm } from '../notifications-form.hook';

import MessageExample from '~/assets/images/tooltips/notifications/message_example.png';
import MessageExamplePush from '~/assets/images/tooltips/notifications/message_example_push.png';
import MarkdownInput from '~/components/Form/HookForm/MarkdownInput';
import { Checkbox, Dropdown, Input } from '~/components/HookForm';
import { Container } from '~/components/HookForm/Form/styles';
import Separator from '~/components/Separator';
import { Accordion } from '~/components/ui/accordion';
import { Alert } from '~/components/ui/alert';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  category: Yup.object()
    .shape({
      value: Yup.string().required('Categoria é um campo obrigatório'),
    })
    .required('Categoria é um campo obrigatório')
    .nullable(),
  title: Yup.string().required('Título é um campo obrigatório'),
  link: Yup.string()
    .url('Link deve ser uma URL válida')
    .required('Link é um campo obrigatório'),
  subject: Yup.string().required('Mensagem é um campo obrigatório'),
  contents: Yup.array().of(
    Yup.object().shape({
      subject: Yup.string(),
      channel_type: Yup.string(),
      is_customized: Yup.boolean(),
    }),
  ),
});

const ChannelsMessageExample = {
  PLATFORM: MessageExample,
  PUSH: MessageExamplePush,
};

export default function ContentStep() {
  const formRef = useRef(null);

  const {
    setFormRef,
    notification,
    setNotification,
    categoryGroups,
    channels,
    onStepSubmit,
  } = useNotificationForm();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: notification,
  });

  const watchedContents = useWatch({ control: form.control, name: 'contents' });
  const watchedCategory = useWatch({ control: form.control, name: 'category' });

  const customizedChannels = useMemo(() => {
    return (
      watchedCategory?.channels?.filter(({ type }) => type !== 'PLATFORM') || []
    );
  }, [watchedCategory?.channels]);

  const filteredChannels = useMemo(() => {
    return watchedCategory?.channels?.length
      ? watchedCategory?.channels
      : channels;
  }, [channels, watchedCategory?.channels]);

  const hasAdvancedSettings = !!customizedChannels.length;

  const handleChangeCategory = useCallback(
    (value) => {
      const defaultContents = value?.channels?.map((channel) => {
        return {
          channel_type: channel.type,
          is_customized: false,
        };
      });

      form.setValue('contents', defaultContents);
    },
    [form],
  );

  useEffect(() => {
    if (formRef.current) {
      setFormRef(formRef);
    }
  }, [formRef, setFormRef]);

  useEffect(() => {
    const subscription = form.watch(
      debounce((formValues) => {
        if (!isEqual(formValues, notification)) {
          setNotification(formValues);
        }
      }, 100),
    );

    return () => subscription.unsubscribe();
  }, [form, notification, setNotification]);

  return (
    <>
      <Alert
        icon={() => <p className="text-[#7159C1] font-bold -mr-1">1.</p>}
        title="Conteúdo da Notificação"
        description="Configure a mensagem que será exibida na comunicação."
      />

      <Container className="m-0">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onStepSubmit)}
            className="flex flex-col gap-5"
            ref={formRef}
          >
            <section>
              <Dropdown
                required
                isClearable
                name="category"
                label="Categoria"
                placeholder="Selecione"
                description="Tema relacionado a um contexto"
                options={categoryGroups}
                onChange={handleChangeCategory}
              />

              <div className="flex flex-col gap-[5px] self-stretch">
                <span className="flex items-center gap-1 self-stretch font-semibold leading-6">
                  Canais
                  <FaInfoCircle
                    size={12}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="xxx"
                  />
                </span>

                <div className="flex py-[9px] gap-2 flex-wrap">
                  {filteredChannels?.map((channel) => (
                    <small
                      key={channel.slug}
                      className="flex px-[7px] py-1 rounded-[100px] border border-[#DDD] bg-[#EEE]"
                    >
                      <b className="text-[#666] text-[11px]">{channel.title}</b>
                    </small>
                  ))}
                </div>
              </div>
            </section>

            <section>
              <Input
                required
                name="title"
                label="Título"
                description="Título apenas para filtragem e identificação na lista de notificações do admin"
              />
            </section>

            <section>
              <MarkdownInput
                name="subject"
                label={getLabel(
                  'Mensagem',
                  '',
                  true,
                  `Conteúdo principal que será exibido na notificação
                  <img src="${MessageExample}" />
                  `,
                )}
                control={form.control}
                allowedTypes={['text', 'strong', 'emphasis', 'link']}
                maxLength={128}
              />
            </section>

            <section>
              <Input
                required
                name="link"
                label="Link"
                description="Link que o usuário será direcionado ao clicar na notificação"
              />
            </section>

            {hasAdvancedSettings && (
              <>
                <Separator
                  label="Configuração avançada"
                  style={{ marginTop: 30, textTransform: 'uppercase' }}
                />

                <section>
                  <Accordion
                    type="multiple"
                    defaultValue={
                      notification?.contents
                        ?.filter((content) => content.is_customized)
                        .map((content) => content.channel_type) || []
                    }
                  >
                    {customizedChannels?.map((channel, index) => {
                      const { slug, title, type } = channel;

                      const notificationContentIndex =
                        notification?.contents?.findIndex(
                          (content) => content?.channel_type === type,
                        ) ?? -1;

                      const contentIndex =
                        notificationContentIndex >= 0
                          ? notificationContentIndex
                          : index;

                      const isCustomized =
                        watchedContents?.[contentIndex]?.is_customized ?? false;

                      const messageExampleImage = ChannelsMessageExample[type];

                      return (
                        <Accordion.Item key={slug} value={type} title={title}>
                          <Accordion.Content>
                            <div className="grid grid-cols-2 [&>div>label]:w-full">
                              <Checkbox
                                name={`contents.${contentIndex}.is_customized`}
                                label={`Personalizar ${title.toLowerCase()}`}
                                onClick={() => {
                                  form.setValue(
                                    `contents.${contentIndex}.channel_type`,
                                    type,
                                  );
                                }}
                              />
                            </div>

                            <section>
                              <MarkdownInput
                                name={
                                  isCustomized
                                    ? `contents.${contentIndex}.subject`
                                    : 'subject'
                                }
                                label={getLabel(
                                  'Mensagem',
                                  '',
                                  true,
                                  !!messageExampleImage &&
                                    `
                                Conteúdo principal da notificação para ${title}
                                <img src="${messageExampleImage}" />
                              `,
                                )}
                                control={form.control}
                                allowedTypes={[
                                  'text',
                                  'strong',
                                  'emphasis',
                                  'link',
                                ]}
                                maxLength={128}
                                disabled={!isCustomized}
                              />
                            </section>
                          </Accordion.Content>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </section>
              </>
            )}

            <input type="submit" className="hidden" />
          </form>
        </FormProvider>
      </Container>
    </>
  );
}
