import React from 'react';

import { client } from '@shared/skylab/graphql/self-evaluation/client';
import { STEPS } from '@shared/skylab/graphql/self-evaluation/queries';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'Jornada',
    getValue: (el) => (
      <td>{el?.selfEvaluation?.relatedJourney || 'Não informado'}</td>
    ),
  },
];

function NavigationsList() {
  const token = `Bearer ${localStorage.getItem('@Skylab:token')}`;

  const fetchNavigations = async (page = 1) => {
    const perPage = 10;

    const {
      data: { steps },
    } = await client.query({
      fetchPolicy: 'no-cache',
      query: STEPS,
      variables: { limit: perPage, offset: perPage * (page - 1) },
      context: {
        headers: {
          authorization: token,
          AUTH_ACCESS_TOKEN:
            process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
        },
      },
    });

    return {
      data: steps.nodes,
      pageCount: Math.ceil(steps.totalCount / perPage),
      total: steps.totalCount,
      perPage,
    };
  };

  fetchNavigations.resourceSlug = 'navigations';

  return (
    <PagedList
      resource={fetchNavigations}
      header="Autoavaliações"
      columns={columns}
      hideSearch
    />
  );
}

export default NavigationsList;
