import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FaTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';

import Button from '~/components/Button';
import { Form, Input, FileInput } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { Alert } from '~/components/ui/alert';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  icon: Yup.string().required('Ícone é um campo obrigatório'),
  description: Yup.string(),
});

function NotificationCategoryGroupsForm({ match, history }) {
  const [submitting, setSubmitting] = useState(false);
  const [modalExcludeOpen, setModalExcludeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [categoryGroup, setCategoryGroup] = useState({
    title: '',
    icon: '',
    description: '',
  });

  const isUpdating = !!match.params.id;
  const { id } = match.params;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      await api.postOrPut('/notifications/categories/groups', id, data);

      toast.success('Contexto salvo com sucesso!');
      history.push('/notifications/categories/groups');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setSubmitting(false);
    }
  }

  async function loadCategoryGroups(categoryGroupId) {
    try {
      setIsLoading(true);

      const { data: response } = await api.get(
        `/notifications/categories/groups/${categoryGroupId}`,
      );

      setCategoryGroup(response);
    } catch (err) {
      toast.error('Erro ao buscar dados do contexto.');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveCategoryGroup(categoryGroupId) {
    try {
      await api.put(`/notifications/categories/groups/${categoryGroupId}`, {
        is_enabled: false,
      });

      toast.success('Contexto excluído com sucesso');
      history.push('/notifications/categories/groups');
    } catch {
      toast.error('Não foi possível excluir o contexto');
    }

    setModalExcludeOpen(false);
  }

  useEffect(() => {
    if (id) {
      loadCategoryGroups(id);
    }
  }, [id, match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Contexto`}</h1>

        <div>
          {isUpdating && (
            <Button
              icon={FaTrashAlt}
              type="button"
              color="danger"
              onClick={() => setModalExcludeOpen(true)}
            >
              EXCLUIR
            </Button>
          )}
          <Button
            icon={MdClose}
            color="cancel"
            to="/notifications/categories/groups"
          >
            CANCELAR
          </Button>
          <Button
            form="category-groups-form"
            type="submit"
            loading={submitting}
          >
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="category-groups-form"
        schema={schema}
        initialData={categoryGroup}
        onSubmit={handleSubmit}
      >
        <Alert
          title="Contexto da notificação"
          description="O contexto é responsável por agrupar categorias de notificações. Ele vai ser exibido nas configurações do usuário para ativar/desativar canais."
          className="mb-4"
        />
        <section>
          <Input
            name="title"
            label={getLabel(
              'Título',
              null,
              true,
              'Será exibido nas configurações do usuário',
            )}
            placeholder="Título do contexto"
          />

          <FileInput
            label={getLabel(
              'Ícone do contexto',
              null,
              true,
              'Será exibido nas configurações do usuário',
            )}
            name="icon"
            placeholder="Ícone"
            path="notifications/icons"
          />
        </section>

        <section>
          <Input multiline label="Descrição" name="description" />
        </section>
      </Form>

      <SweetAlert2
        show={modalExcludeOpen}
        title="Excluir contexto?"
        text="Todas as notificações atreladas às categorias deste contexto deixarão de ser enviadas."
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveCategoryGroup(id)}
        onResolve={() => setModalExcludeOpen(false)}
      />
    </Page>
  );
}

NotificationCategoryGroupsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

NotificationCategoryGroupsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default NotificationCategoryGroupsForm;
