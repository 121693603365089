import { datadogRum } from '@datadog/browser-rum';

const init = () =>
  datadogRum.init({
    applicationId:
      process.env.REACT_APP_DATADOG_APPLICATION_ID ||
      process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken:
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
      process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: process.env.NODE_ENV,
    service: 'skylab',
    //  version: '1.0.0',
    sampleRate: 0,
    resourceSampleRate: 0,
    trackInteractions: true,
  });

const addContext = (context, value) =>
  datadogRum.addRumGlobalContext(context, value);

const addUserAction = (action, payload) =>
  datadogRum.addUserAction(action, payload);

export default { init, addContext, addUserAction };
