import React from 'react';

import { format } from 'date-fns';

import { Input, Datepicker, Check } from '~/components/HookForm';

export function CertificateTab() {
  return (
    <div>
      <section>
        <Check
          label="Esse evento possuirá certificados?"
          name="has_certificate"
          required
          options={[
            { value: true, label: 'Sim' },
            { value: false, label: 'Não' },
          ]}
        />

        <Input
          name="keyword"
          label="Palavra-chave"
          description="Palavra-chave usada para liberar a certificação para o aluno"
        />
      </section>

      <section>
        <Datepicker
          label="Data de inicio"
          name="certificate_available_at"
          description="Data e hora de liberação do certificado para o evento"
          showTimeSelect
          required
          timeIntervals={5}
          placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
        />

        <Datepicker
          label="Data de Fim"
          name="certificate_unavailable_at"
          description="Data e hora que a certificação fica indisponível"
          showTimeSelect
          required
          timeIntervals={5}
          placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
        />
      </section>
    </div>
  );
}
