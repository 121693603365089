import React from 'react';
import PropTypes from 'prop-types';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { AccordionItem } from './accordion-item';
import { AccordionContent } from './accordion-content';

export const Accordion = ({ children, ...props }) => {
  return (
    <AccordionPrimitive.Root className="w-full" {...props}>
      {children}
    </AccordionPrimitive.Root>
  );
};

Accordion.displayName = 'Accordion';

Accordion.Item = AccordionItem;
Accordion.Item.displayName = 'Accordion.Item';

Accordion.Content = AccordionContent;
Accordion.Content.displayName = 'Accordion.Content';

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
};
