import * as Yup from 'yup';

export const reminderFormSchema = Yup.object().shape({
  category: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .typeError('A categoria é obrigatória.'),

  name: Yup.string().required('O nome é obrigatório.'),
  start_date: Yup.string().required('A data inicial é obrigatória.'),
  end_date: Yup.string().required('A data final é obrigatória.'),

  recurrency_end_date: Yup.string().nullable().when('should_repeat', {
    is: true,
    then: Yup.string(),
  }),
});
