/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdArrowBack } from 'react-icons/md';

import { Header, JourneyContainer, ListContainer } from './styles';

import Button from '~/components/Button';
import { Page } from '~/pages/styles';
import api from '~/services/api';

function UsersContentAccessList({ match }) {
  const [isLoading, setIsLoading] = useState(true);

  const [journeysList, setJourneysList] = useState({
    SPECIALIZATION: [],
    COURSE: [],
    EVENT: [],
    EXTRA: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function loadJourneys() {
    setIsLoading(true);

    try {
      const { data } = await api.get(`/users/${match.params.id}/journeys`);

      setJourneysList(data);
    } finally {
      setIsLoading(false);
    }
  }

  const renderList = (title, data) => {
    return (
      <JourneyContainer>
        <h2>{title}</h2>

        {isLoading ? (
          <p>Carregando...</p>
        ) : data.length ? (
          <section>
            {data.map((journey) => (
              <div key={journey.id}>
                <img
                  src={journey.icon_url}
                  alt={`Icone da jornada ${journey.title}`}
                  width={60}
                  height={60}
                />
                <span>{journey.title}</span>
              </div>
            ))}
          </section>
        ) : (
          <section>Sem registros</section>
        )}
      </JourneyContainer>
    );
  };

  useEffect(() => {
    loadJourneys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Header style={{ marginBottom: 32 }}>
        <h1>Jornadas que o usuário tem acesso</h1>

        <Button
          icon={MdArrowBack}
          color="success"
          to={`/users/edit/${match.params.id}`}
        >
          Voltar
        </Button>
      </Header>

      <ListContainer>
        {renderList('Formações', journeysList.SPECIALIZATION)}
        {renderList('Cursos', journeysList.COURSE)}
        {renderList('Eventos', journeysList.EVENT)}
        {renderList('Conteúdo complementares', journeysList.EXTRA)}
      </ListContainer>
    </Page>
  );
}

UsersContentAccessList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default UsersContentAccessList;
