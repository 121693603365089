export const extractNodeData = (order) => ({
  id,
  title,
  subtitle,
  milestone_description,
  description,
  is_milestone: isMilestone,
  introductory,
  color,
  thumbnail,
  active,
  release_at: releasedAt,
  layout,
  author,
  type,
  lesson_id: lessonId,
  lesson_group_id: lessonGroupId,
  cluster_id: clusterId,
  micro_certificate_id: microCertificateId,
  challenge_id: challengeId,
  category_id: categoryId,
  partnero_id: partneroId,
  deleted,
  premiere,
  module,
  is_bonus: isBonus,
  is_single_class_view: isSingleClassView,
  tag_technologies,
  tag_level,
  tag_skills,
  tag_default,
  has_after_access,
  show_info,
  related_journey_id,
}) => {
  order = deleted ? order : order + 1;

  return {
    id,
    title,
    subtitle,
    milestone_description,
    description,
    is_milestone: isMilestone,
    introductory,
    color,
    thumbnail,
    order,
    active,
    release_at: releasedAt,
    layout,
    author,
    deleted,
    ...(type === 'lesson' && { lesson_id: lessonId }),
    ...(type === 'group' && { lesson_group_id: lessonGroupId }),
    ...(type === 'cluster' && { cluster_id: clusterId }),
    ...(type === 'challenge' && { challenge_id: challengeId }),
    ...(type === 'micro-certificate' && {
      micro_certificate_id: microCertificateId,
    }),
    category_id: categoryId,
    partnero_id: partneroId,
    premiere,
    module,
    is_bonus: isBonus,
    is_single_class_view: isSingleClassView,
    tag_technologies: tag_technologies.map((tag) => (tag?.id ? tag?.id : tag)),
    tag_level,
    tag_skills: tag_skills.map((tag) => (tag?.id ? tag?.id : tag)),
    tag_default: tag_default.map((tag) => (tag?.id ? tag?.id : tag)),
    has_after_access,
    show_info,
    related_journey_id,
  };
};
