import React, { useState } from 'react';

import { format } from 'date-fns';
import { FaRegTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import { reminderFormCategoryOptions } from '../reminder-form.options';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';
import Button from '~/components/Button';
import api from '~/services/api';

const columns = [
  {
    label: '',
    value: 'thumbnail',

    getValue: (element) => (
      <td key={element.id}>
        {element.banner_url && (
          <Thumbnail>
            <img src={element.banner_url} alt="" />
          </Thumbnail>
        )}
      </td>
    ),
  },

  {
    label: 'Título',
    value: 'title',
  },

  {
    label: 'Início',
    value: 'starts_at',

    getValue: (element) => (
      <td key={element.id}>
        {element.starts_at &&
          format(new Date(element.starts_at), 'DD/MM/YYYY [às] HH:mm')}
      </td>
    ),
  },

  {
    label: 'Fim',
    value: 'ends_at',

    getValue: (element) => (
      <td key={element.id}>
        {element.ends_at &&
          format(new Date(element.ends_at), 'DD/MM/YYYY [às] HH:mm')}
      </td>
    ),
  },

  {
    label: 'Tipo',
    value: 'type',

    getValue: (element) => {
      const { label } = reminderFormCategoryOptions.find(
        ({ value }) => value === element?.type,
      ) || { label: 'Não informado' };

      return <td key={element.id}>{label}</td>;
    },
  },

  {
    label: 'Destacado',
    value: 'is_featured',

    getValue: (element) => {
      const isFeatured = element.is_featured;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[featured=true]:text-green-600 data-[featured=true]:font-bold"
          data-featured={isFeatured}
        >
          {isFeatured ? 'Sim' : 'Não'}
        </td>
      );
    },
  },

  {
    label: 'Gratuito',
    value: 'is_free',

    getValue: (element) => {
      const isFree = element.is_free;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[free=true]:text-green-600 data-[free=true]:font-bold"
          data-free={isFree}
        >
          {isFree ? 'Sim' : 'Não'}
        </td>
      );
    },
  },

  {
    label: 'Recorrente',
    value: 'reminderRecurrences',

    getValue: (element) => {
      const isRecurrent = !!element.reminderRecurrences;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[recurrent=true]:text-green-600 data-[recurrent=true]:font-bold"
          data-recurrent={isRecurrent}
        >
          {isRecurrent ? 'Sim' : 'Não'}
        </td>
      );
    },
  },
];

export default function RemindersList() {
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function handleRemoveReminder() {
    try {
      await api.delete(`/reminders/${selectedReminder.id}`);
      toast.success('Lembrete excluído com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir o lembrete');
    } finally {
      setShouldRefetch(false);
      setSelectedReminder(null);
    }
  }

  return (
    <>
      <PagedList
        columns={columns}
        header="Lembretes"
        resource="reminders"
        refetchData={shouldRefetch}
        createPermission="login_admin"
        customActions={(reminder) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedReminder(reminder)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedReminder}
        title={`Excluir ${selectedReminder?.title}?`}
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveReminder()}
        onResolve={() => setSelectedReminder(null)}
      />
    </>
  );
}
