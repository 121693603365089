/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useField } from '@rocketseat/unform';
import { startCase } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';

import Field from '../Field';
import { Container, Option } from './styles';

function Check({ label, name, options, description, onClick, readOnly }) {
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [checked, setChecked] = useState({ value: defaultValue });

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: checked,
    });
  }, [checked, fieldName]);

  function handleChange(option) {
    if (readOnly) {
      return;
    }

    setChecked(option);

    if (onClick) {
      onClick(option);
    }
  }

  function getOptionLabel(option) {
    let { label: optionLabel } = option;

    if (optionLabel) {
      return optionLabel;
    }

    optionLabel = String(option.value);

    return startCase(optionLabel);
  }

  return (
    <Field label={label} name={name}>
      {label && (
        <label
          htmlFor={name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {label}
          {description && (
            <FaInfoCircle
              size={12}
              data-tooltip-id="tooltip"
              data-tooltip-content={description}
            />
          )}
        </label>
      )}
      <Container readOnly={readOnly}>
        {options.map((option) => (
          <Option
            key={String(option.value)}
            type="button"
            selected={checked && checked.value === option.value}
            onClick={() => handleChange(option)}
          >
            {getOptionLabel(option)}
          </Option>
        ))}
      </Container>
      {error && <span>{error}</span>}
    </Field>
  );
}

Check.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  description: PropTypes.string,
};

Check.defaultProps = {
  onClick: null,
  readOnly: false,
  description: null,
};

export default Check;
