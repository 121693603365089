import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Select from 'react-select';
import { MdSearch, MdAdd } from 'react-icons/md';

import {
  types,
  products,
} from '~/pages/content/certificate-service/courses/types';
import api from '~/services/certificates.api';
import { Page } from '~/pages/styles';
import { SearchForm, Filters, Thumbnail } from '~/components/PagedList/styles';
import { Input } from '~/components/Form';
import Button from '~/components/Button';
import { useCan } from '~/utils';
import { Table, Actions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import SpinnerLoading from '~/components/SpinnerLoading';

function CoursesList() {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [typeFilter, setTypeFilter] = useState(null);
  const [productFilter, setProductFilter] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const itemsPerPage = 20;
  const can = useCan();

  useEffect(() => {
    async function loadCourses() {
      try {
        setLoading(true);

        const { data: response } = await api.get('/courses', {
          params: {
            page: currentPage,
            type: typeFilter?.value,
            product: productFilter?.value,
            search,
          },
        });

        setCourses(response.courses);
        setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
        setTotalCount(response.totalCount);
      } catch (err) {
        toast.error('Erro ao buscar cursos.');
      } finally {
        setLoading(false);
      }
    }

    loadCourses();
  }, [currentPage, typeFilter, productFilter, search]);

  function handlePaginate(newPage) {
    setCurrentPage(newPage);
  }

  function handleFilter(name, data) {
    if (name === 'type') {
      setSelectedType(data);
      setTypeFilter(data);
    }

    if (name === 'product') {
      setSelectedProduct(data);
      setProductFilter(data);
    }

    handlePaginate(1);
  }

  function handleSearch(e) {
    e.preventDefault();
    handlePaginate(1);
  }

  function renderHeader() {
    return (
      <header>
        <h1>Lista de Cursos</h1>

        <div>
          {!loading && (
            <Filters>
              <Select
                key="type"
                name="Tipo"
                placeholder="Tipo do curso"
                options={types}
                isClearable
                value={selectedType}
                onChange={(data) => handleFilter('type', data)}
              />

              <Select
                key="product"
                name="Produto"
                placeholder="Produto"
                options={products}
                isClearable
                value={selectedProduct}
                onChange={(data) => handleFilter('product', data)}
              />
            </Filters>
          )}

          <SearchForm onSubmit={handleSearch}>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />
            <Button type="submit" icon={MdSearch} color="success" />
          </SearchForm>

          {can(`create_certificates`) && (
            <Button
              icon={MdAdd}
              color="success"
              to="/certificate-service/course/new"
            >
              Novo
            </Button>
          )}
        </div>
      </header>
    );
  }

  function renderBody() {
    return (
      <Table loading={loading}>
        <thead>
          <tr>
            <th>Ícone</th>
            <th>Título</th>
            <th>Slug</th>
            <th>Tipo</th>
            <th>Produto</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course.id}>
              <td>
                {course.iconUrl && (
                  <Thumbnail>
                    <img src={course.iconUrl} alt="" />
                  </Thumbnail>
                )}
              </td>
              <td>{course.title}</td>
              <td>{course.slug}</td>
              <td>{course.type}</td>
              <td>{course.product}</td>
              <td>
                <Actions
                  href={`/certificate-service/course/edit/${course.id}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Page>
      {renderHeader()}

      {loading && <SpinnerLoading />}

      {!loading && courses.length === 0 && <p>Nenhum curso encontrado.</p>}

      {!loading && courses.length > 0 && renderBody()}

      <Pagination
        activePage={currentPage}
        pageCount={totalPages}
        total={totalCount}
        perPage={itemsPerPage}
        onChange={handlePaginate}
      />
    </Page>
  );
}

CoursesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CoursesList;
