import * as Yup from 'yup';

import { tagFormsSchema } from '~/components/TagForms';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório.'),
  slug: Yup.string(),
  subtitle: Yup.string(),
  milestone_description: Yup.string(),
  description: Yup.string(),
  thumbnail: Yup.string(),
  is_milestone: Yup.boolean().nullable(),
  show_info: Yup.boolean().nullable(),
  introductory: Yup.boolean().nullable(),
  layout: Yup.string().nullable(),
  lesson_id: Yup.string().test(
    'lesson-test',
    'Aula é um campo obrigatório.',
    function testLesson(value) {
      if (this.parent.type === 'lesson') {
        return !!value;
      }
      return true;
    },
  ),
  lesson_group_id: Yup.string().test(
    'lesson-group-test',
    'Grupo de aulas é um campo obrigatório.',
    function testGroup(value) {
      if (this.parent.type === 'group') {
        return !!value;
      }
      return true;
    },
  ),
  cluster_id: Yup.string().test(
    'cluster-test',
    'Cluster é um campo obrigatório.',
    function testCluster(value) {
      if (this.parent.type === 'cluster') {
        return !!value;
      }
      return true;
    },
  ),
  challenge_id: Yup.string().test(
    'lesson-test',
    'Desafio é um campo obrigatório.',
    function testChallenge(value) {
      if (this.parent.type === 'challenge') {
        return !!value;
      }
      return true;
    },
  ),
  micro_certificate_id: Yup.string().test(
    'micro-certificate-test',
    'Micro certificado é um campo obrigatório.',
    function testMicroCertificate(value) {
      if (this.parent.type === 'micro-certificate') {
        return !!value;
      }
      return true;
    },
  ),
  related_journey_id: Yup.string().test(
    'related-journey-test',
    'Jornada relacionada é obrigatório.',
    function testRelatedJourney(value) {
      if (this.parent.type === 'subject') {
        return !!value;
      }
      return true;
    },
  ),
  active: Yup.boolean(),
  premiere: Yup.boolean().nullable(),
  module: Yup.boolean().nullable(),
  release_at: Yup.string().nullable(),
  type: Yup.string(),
  is_bonus: Yup.boolean().nullable(),
  is_single_class_view: Yup.boolean().nullable(),
  has_after_access: Yup.bool(),
  ...tagFormsSchema,
});

export default schema;
