/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { format } from 'date-fns';
import { FormProvider } from 'react-hook-form';

import { StepNavigation } from './components/StepNavigation';
import {
  EnrollmentDocumentsFormProvider,
  useEnrollmentDocumentsForm,
} from './enrollment-documents-form.hook';
import { Status } from './components/Status';
import { DocumentStatusValues as DocumentStatus } from '../../constants';
import { isDocumentReviewed } from './document.utils';

import Button from '~/components/Button';
import Page from '~/components/Page';
import { Input } from '~/components/HookForm';

function EnrollmentsDocumentsFormComponent() {
  const {
    form,
    isLoading,
    currentDocument,
    currentDocumentIndex,
    goBackToDetails,
  } = useEnrollmentDocumentsForm();

  const watchedStatus = form.watch(`${currentDocumentIndex}.status`);

  const formattedSentDate =
    currentDocument?.sended_at &&
    `Enviado em: ${format(new Date(currentDocument.sended_at), 'DD/MM/YY')}`;

  const handleRejectClick = useCallback(() => {
    form.setValue(`${currentDocumentIndex}.status`, DocumentStatus.REJECTED);
  }, [currentDocumentIndex, form]);

  const handleApproveClick = useCallback(() => {
    form.setValue(`${currentDocumentIndex}.status`, DocumentStatus.APPROVED);
    form.setValue(`${currentDocumentIndex}.comment`, null);
  }, [currentDocumentIndex, form]);

  return (
    <Page loading={isLoading}>
      <FormProvider {...form}>
        <form>
          <header>
            <h1>Analisar documentos</h1>

            <div>
              <Button color="cancel" onClick={goBackToDetails}>
                Voltar
              </Button>
            </div>
          </header>

          <div className="flex flex-col gap-6 self-stretch">
            <div className="flex mt-8 py-3 items-center">
              <h2 className="flex-1 text-[#18181B] text-base font-semibold">
                <span className="text-[#7159C1]">
                  {`${currentDocumentIndex + 1}. `}
                </span>
                {currentDocument?.name}
              </h2>

              <span className="text-black text-sm">{formattedSentDate}</span>
            </div>

            <div className="flex h-[800px] w-full">
              <iframe
                src={currentDocument?.path}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                title="PDF Viewer"
              />
            </div>

            {isDocumentReviewed(currentDocument) ? (
              <Status document={currentDocument} />
            ) : (
              <>
                <div className="flex justify-center items-center gap-2 self-stretch">
                  <Button
                    color="danger"
                    outline={watchedStatus !== DocumentStatus.REJECTED}
                    onClick={handleRejectClick}
                  >
                    Reprovar
                  </Button>
                  <Button
                    color="success"
                    outline={watchedStatus !== DocumentStatus.APPROVED}
                    onClick={handleApproveClick}
                  >
                    Aprovar
                  </Button>
                </div>

                {watchedStatus === DocumentStatus.REJECTED && (
                  <Input
                    name={`${currentDocumentIndex}.comment`}
                    label="Motivo da reprovação"
                    multiline
                    required
                  />
                )}
              </>
            )}
          </div>

          <StepNavigation />
        </form>
      </FormProvider>
    </Page>
  );
}

export default function EnrollmentsDocumentsForm({ match, history }) {
  return (
    <EnrollmentDocumentsFormProvider params={match?.params} history={history}>
      <EnrollmentsDocumentsFormComponent match={match} history={history} />
    </EnrollmentDocumentsFormProvider>
  );
}

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      contractId: PropTypes.string,
      notificationId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

EnrollmentsDocumentsFormComponent.propTypes = propTypes;
EnrollmentsDocumentsForm.propTypes = propTypes;
