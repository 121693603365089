import React, { useState } from 'react';

import { FaRegTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Prazo',
    getValue: (element) => {
      const label = element.deadline_in_months > 1 ? 'Meses' : 'Mês';

      return (
        <td key={element.id}>
          {element.deadline_in_months}
          {' '}
          {label}
        </td>
      );
    },
  },
  {
    label: 'Qtd. de fases',
    getValue: (element) => {
      const numLevels = Number(element.levels?.length);

      const label = numLevels > 1 ? 'Fases' : 'Fase';

      return (
        <td key={element.id}>
          {numLevels}
          {' '}
          {label}
        </td>
      );
    },
  },
];

export default function GoalsList() {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function handleRemoveGoal() {
    try {
      await api.delete(`/guide/goals/${selectedGoal?.id}`);

      toast.success('Objetivo excluído com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir o objetivo');
    }

    setShouldRefetch(false);
    setSelectedGoal(null);
  }

  return (
    <>
      <PagedList
        resource="guide/goals"
        header="Objetivos"
        columns={columns}
        refetchData={shouldRefetch}
        createPermission="create_goals"
        customActions={(contract) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedGoal(contract)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedGoal}
        title="Excluir objetivo?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveGoal()}
        onResolve={() => setSelectedGoal(null)}
      />
    </>
  );
}
