import React from 'react';

import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { Tooltip } from 'react-tooltip';

// eslint-disable-next-line import/no-extraneous-dependencies
import { client } from '@shared/skylab/graphql/self-evaluation/client';
import Routes from './routes';
import history from './routes/history';
import store from './store';
import GlobalStyle from './styles/global';
import { TooltipContainer } from './components/TooltipContainer';

import './style.css';

function App() {
  return (
    <>
      <GlobalStyle />
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router history={history}>
            <Routes />
          </Router>
        </Provider>
      </ApolloProvider>
      <ToastContainer />

      <Tooltip
        id="tooltip"
        place="right-start"
        style={{ background: 'rgba(0, 0, 0, 1)', opacity: 1 }}
        className="bg-red-500"
        render={(data) => {
          const { content } = data;

          return (
            <TooltipContainer
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          );
        }}
      />
    </>
  );
}

export default hot(App);
