import PropTypes from 'prop-types';
import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import HeaderBar from '~/components/HeaderBar';
import Nav from '~/components/Nav';

import { Wrapper, Container, Content } from './styles';

function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Nav />
      <Container>
        <DndProvider backend={HTML5Backend}>
          <HeaderBar />
          <Content>{children}</Content>
        </DndProvider>
      </Container>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DefaultLayout;
