import React from 'react';

import { format, isBefore } from 'date-fns';

import ClipboardButton from '~/components/ClipboardButton';
import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Descrição',
    value: 'description',
  },
  {
    label: 'Data limite para resgate',
    value: 'end_date',
    getValue: (element) => {
      if (element.end_date) {
        const endDate = new Date(element.end_date);
        const currentDate = new Date();
        const isPast = isBefore(endDate, currentDate);

        return (
          <td style={{ color: isPast ? 'red' : 'green' }}>
            {format(endDate, 'DD/MM/YYYY')}
          </td>
        );
      }

      return <td>--</td>;
    },
  },
  {
    label: 'Url da API',
    value: 'active',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <strong>{`/trial/redeem/${element.slug}`}</strong>

          <ClipboardButton
            title="Copiar URL"
            value={`/trial/redeem/${element.slug}`}
          />
        </td>
      );
    },
  },
];

export default function TrialsList() {
  return <PagedList resource="trials" header="Trial" columns={columns} />;
}
