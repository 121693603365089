import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { MdEdit, MdArrowDropDown } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, Actions } from './styles';

function TableActions({ children, mainAction, mainIcon, href }) {
  const [isMenuOpen, setMenu] = useState(false);

  const Icon = mainIcon;

  return (
    <Container
      tabIndex="0"
      onBlur={() => setMenu(false)}
      hasChildren={children}
    >
      {href ? (
        <Link to={href}>
          <Icon size={14} />
        </Link>
      ) : (
        <button size="small" onClick={mainAction} type="button">
          <Icon size={14} />
        </button>
      )}

      {children && (
        <>
          <button
            size="small"
            color="danger"
            onClick={() => setMenu(!isMenuOpen)}
            type="button"
          >
            <MdArrowDropDown size={20} />
          </button>

          <Actions isMenuOpen={isMenuOpen}>{children}</Actions>
        </>
      )}
    </Container>
  );
}

TableActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  mainAction: PropTypes.func,
  mainIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  href: PropTypes.string,
};

TableActions.defaultProps = {
  children: null,
  mainIcon: MdEdit,
  href: null,
  mainAction: null,
};

export default TableActions;
