import React, { useState } from 'react';

import { format } from 'date-fns';
import { FaRegTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';
import Button from '~/components/Button';
import api from '~/services/api';
import { isDateInPeriod } from '~/utils';

const columns = [
  {
    label: '',
    value: 'thumbnail',

    getValue: (element) => (
      <td key={element.id}>
        {element.banner_url && (
          <Thumbnail>
            <img src={element.banner_url} alt="" />
          </Thumbnail>
        )}
      </td>
    ),
  },

  {
    label: 'Título',
    value: 'title',
  },

  {
    label: 'Em exibição?',

    getValue: (element) => {
      const isInDisplayPeriod = isDateInPeriod(
        element.start_date,
        element.end_date,
      );

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[in-display-period=true]:text-green-600 data-[in-display-period=true]:font-bold"
          data-in-display-period={isInDisplayPeriod}
        >
          {isInDisplayPeriod ? 'Sim' : 'Não'}
        </td>
      );
    },
  },

  {
    label: 'Início da exibição',
    value: 'start_date',

    getValue: (element) => (
      <td key={element.id}>
        {element.start_date &&
          format(new Date(element.start_date), 'DD/MM/YYYY [às] HH:mm')}
      </td>
    ),
  },

  {
    label: 'Final da exibição',
    value: 'end_date',

    getValue: (element) => (
      <td key={element.id}>
        {element.end_date &&
          format(new Date(element.end_date), 'DD/MM/YYYY [às] HH:mm')}
      </td>
    ),
  },

  {
    label: 'Habilitado',
    value: 'is_enabled',

    getValue: (element) => {
      const isEnabled = element.is_enabled;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[featured=true]:text-green-600 data-[featured=true]:font-bold"
          data-featured={isEnabled}
        >
          {isEnabled ? 'Sim' : 'Não'}
        </td>
      );
    },
  },

  {
    label: 'Exibir para usuários deslogados',
    value: 'display_to_logged_out_users',

    getValue: (element) => {
      const displayToLoggedOutUsers = element.display_to_logged_out_users;

      return (
        <td
          key={element.id}
          className="text-gray-700 data-[free=true]:text-green-600 data-[free=true]:font-bold"
          data-free={displayToLoggedOutUsers}
        >
          {displayToLoggedOutUsers ? 'Sim' : 'Não'}
        </td>
      );
    },
  },
];

export default function BannersList() {
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function handleRemoveBanner() {
    try {
      await api.delete(`/banners/${selectedBanner.id}`);
      toast.success('Banner excluído com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir o banner');
    } finally {
      setShouldRefetch(false);
      setSelectedBanner(null);
    }
  }

  return (
    <>
      <PagedList
        columns={columns}
        header="Banners"
        resource="banners"
        refetchData={shouldRefetch}
        createPermission="login_admin"
        customActions={(banner) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedBanner(banner)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedBanner}
        title={`Excluir ${selectedBanner?.title}?`}
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveBanner()}
        onResolve={() => setSelectedBanner(null)}
      />
    </>
  );
}
