import { format } from 'date-fns';
import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Usuário',
    value: 'user.name',
  },
  {
    label: 'Campo',
    getValue: (element) => {
      switch (element.field) {
        case 'email':
          return <td>E-mail</td>;
        case 'document':
          return <td>CPF/CNPJ</td>;
        default:
          return <td>Não informado</td>;
      }
    },
  },
  {
    label: 'Enviado em',
    getValue: (element) => (
      <td>{format(element.created_at, 'DD/MM/YYYY - HH:mm')}</td>
    ),
  },
];

function SensitiveInformationList() {
  const filters = [
    {
      label: 'Filtro',
      name: 'answered',
      data: [
        {
          value: false,
          label: 'Em aberto',
        },
        {
          value: true,
          label: 'Respondido',
        },
      ],
    },
  ];

  return (
    <PagedList
      resource="sensitive-information"
      header="Alteração de Dados"
      columns={columns}
      filters={filters}
      initialFilters={{
        answered: false,
      }}
      hideCreate
    />
  );
}

export default SensitiveInformationList;
