/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Route } from 'lucide-react';

import { useEventForm } from '../use-event-form.hook';

import { Dropdown, Input } from '~/components/HookForm';
import api from '~/services/api';
import { Alert } from '~/components/ui/alert';
import { JourneyDropdown } from '~/components/HookForm/Dropdown/journeys-dropdown';
import JourneyNodeImage from '~/assets/images/tooltips/events/journey-node-field.png';

export function CatalogTab() {
  const { trails } = useEventForm();
  const { watch, control } = useFormContext();

  const [journeyNodes, setJourneyNodes] = useState([]);

  const eventTrailsFields = useFieldArray({
    control,
    name: 'eventTrails',
  });

  const currentJourney = watch('journey');

  async function getJourneyNodesByJourneyId(journeyId) {
    const { data } = await api.get(`/journey/${journeyId}/nodes`);

    const response = (data || []).map((node) => ({
      ...node,
      value: node.id,
      label: node.title,
    }));

    setJourneyNodes(response);

    return response;
  }

  useEffect(() => {
    getJourneyNodesByJourneyId(currentJourney?.value);
  }, [currentJourney]);

  return (
    <div className="flex flex-col w-full">
      <Alert
        title="Configurações do catálogo"
        description="Essas configurações são responsáveis por relacionar uma trilha do evento com jornadas que existem na plataforma, essas informações são exibidas na página de `mission-complete`, visível após o evento encerrar."
        className="mb-4"
      />

      <section className="mb-6">
        <Input
          name="catalog_link"
          label="Link do catálogo"
          description="Esse link é utilizado na página de eventos para redirecionar o usuário para o catálogo onde há todas as trilhas da jornada, geralmente é o link do catalogo com o filtro pela tag do evento."
          placeholder="https://app.rocketseat.com.br/catalog?tags=nlw-16-journey"
        />
      </section>

      <section className="flex flex-col gap-4">
        <div className="flex flex-col text-left items-start">
          <h3 className="text-xl font-bold">
            Relacionamento das trilhas com as jornadas
          </h3>

          <p className="text-zinc-500">
            Relacione aqui o módulo que representa o conteúdo de cada trilha.
          </p>
        </div>

        <Controller
          name="journey"
          control={control}
          render={({ field }) => (
            <JourneyDropdown
              {...field}
              label="Jornada do Evento"
              placeholder="Selecione a jornada deste evento para liberar os módulos da trilha."
              description="Ao selecionar essa jornada os módulos serão carregados para cada trilha."
              onChange={(journey) => {
                field.onChange(journey);
                getJourneyNodesByJourneyId(journey.value);
              }}
            />
          )}
        />

        <fieldset className="flex flex-col gap-4">
          <legend>Módulos das trilhas</legend>

          {eventTrailsFields.fields.length === 0 ? (
            <p className="text-zinc-400 flex items-center gap-2 italic">
              <Route size={16} />
              Você ainda não tem nenhuma trilha cadastrada.
            </p>
          ) : (
            eventTrailsFields.fields.map((eventTrailField, fieldIndex) => {
              return (
                <div className="grid grid-cols-[200px_auto] items-center gap-4">
                  <span className="flex items-center gap-2">
                    <Route size={16} />
                    {eventTrailField.trail?.label || 'Não identificado'}
                  </span>

                  <Controller
                    name={`eventTrails[${fieldIndex}].journeyNode`}
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        label="Journey Node do evento no catálogo"
                        description={`Esse campo carrega os dados do módulo na página de mission complete para que o usuário possa acessar o conteúdo caso tenha o rocketseat one.
                          <img src="${JourneyNodeImage}" className="w-[500px]" />  
                        `}
                        options={journeyNodes}
                        isClearable
                        placeholder="Selecione a journey node do evento."
                      />
                    )}
                  />
                </div>
              );
            })
          )}
        </fieldset>
      </section>
    </div>
  );
}
