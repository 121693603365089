import { gql } from '@apollo/client';

export const UPSERT_PRODUCT = gql`
  mutation UpsertProduct($data: UpsertProductInput!) {
    upsertProduct(data: $data) {
      id
    }
  }
`;

export const UPSERT_OFFER = gql`
  mutation UpsertOffer($data: UpsertOfferInput!) {
    upsertOffer(data: $data) {
      id
    }
  }
`;

export const UPSERT_COUPON = gql`
  mutation UpsertCoupon($data: UpsertCouponInput!) {
    upsertCoupon(data: $data) {
      id
    }
  }
`;
