import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Dropdown, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { useCan } from '~/utils';

import platforms from '../platforms.json';

const schema = Yup.object().shape({
  title: Yup.string().required('O título é obrigatório.'),
  description: Yup.string().required('A descrição é obrigatória.'),
  platform: Yup.string().required('A plataforma é obrigatória.'),
});

function ChangelogsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [changelog, setChangelog] = useState({});

  const options = useMemo(
    () => platforms.map((item) => ({ id: item.value, title: item.label })),
    [],
  );
  const isUpdating = useMemo(() => !!match.params.id, [match.params]);
  const can = useCan();
  const viewOnly = !can('edit_changelogs');

  useEffect(() => {
    async function loadChangelog(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/changelogs/${id}`);
        setChangelog(response);
      } catch (error) {
        toast.error(
          'Não foi possível carregar as informações desse changelog.',
        );
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadChangelog(id);
    }
  }, []);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);
      const { id } = match.params;
      await api.postOrPut('/changelogs', id, data);

      toast.success('Changelog salvo com sucesso!');
      history.push('/changelogs');
    } catch (err) {
      toast.error(`Erro ao ${isUpdating ? 'editar' : 'criar'} o changelog.`);
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar Changelog</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} changelog`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" to="/changelogs">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={changelog} onSubmit={handleSubmit}>
        <section>
          <Dropdown
            label="Plataforma"
            name="platform"
            options={options}
            disabled={viewOnly}
          />
        </section>

        <section>
          <Input label="Título" name="title" disabled={viewOnly} />
        </section>

        <section>
          <Input
            label="Descrição"
            multiline
            name="description"
            disabled={viewOnly}
          />
        </section>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

ChangelogsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ChangelogsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default ChangelogsForm;
