/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import React from 'react';

import { MdDelete, MdRestore } from 'react-icons/md';

import { ActionContainer, Fieldset } from '../styles';

import AvailableImage from '~/assets/images/tooltips/events/checkpoint-available-field.png';
import AwardImage from '~/assets/images/tooltips/events/checkpoint-award-field.png';
import DescriptionComplementImage from '~/assets/images/tooltips/events/checkpoint-description-complement-field.png';
import DescriptionImage from '~/assets/images/tooltips/events/checkpoint-description-field.png';
import TagImage from '~/assets/images/tooltips/events/checkpoint-tag-field.png';
import TitleImage from '~/assets/images/tooltips/events/checkpoint-title-field.png';
import UrlImage from '~/assets/images/tooltips/events/checkpoint-url-field.png';
import Button from '~/components/Button';
import { Datepicker, Input } from '~/components/HookForm';
import { cn } from '~/lib/utils';

export const CheckpointItem = ({ item, index, restore, remove, ...rest }) => {
  const isMarkedToDelete = item?._destroy;

  return (
    <div className={cn('flex flex-col gap-4', rest?.className)}>
      <Fieldset>
        <legend>{`Checkpoint ${index + 1}`}</legend>

        <div className="flex flex-col gap-2 relative">
          {isMarkedToDelete && (
            <Button
              icon={MdRestore}
              color="warning"
              onClick={() => restore(index)}
              style={{ height: '50px' }}
              className="z-10 absolute top-[20%] left-[50%] -translate-x-[50%]"
            >
              Desfazer remoção
            </Button>
          )}

          <div
            className={cn(
              isMarkedToDelete ? 'opacity-30 pointer-events-none' : '',
              'flex flex-col gap-2',
            )}
          >
            <Input name={`checkpoints[${index}].checkpointId`} type="hidden" />

            <section>
              <Input
                name={`checkpoints[${index}].title`}
                label="Título"
                placeholder="Desafio 01"
                description={`Título do checkpoint.
                        <img src="${TitleImage}" className="w-[500px]" />
                      `}
              />

              <ActionContainer>
                <Button
                  color="danger"
                  icon={MdDelete}
                  onClick={() => remove(index)}
                  style={{ height: '50px' }}
                >
                  Remover
                </Button>
              </ActionContainer>
            </section>

            <section>
              <Input
                name={`checkpoints[${index}].description`}
                label="Descrição"
                placeholder="Descrição do checkpoint"
                description={`Descrição breve do checkpoint.
                        <img src="${DescriptionImage}" className="w-[500px]" />
                      `}
                multiline
              />

              <Input
                name={`checkpoints[${index}].metadata.description_complement`}
                label="Descrição Complementar"
                placeholder="Descrição complementar"
                description={`Descrição extra adicionado após o texto da descrição.
                        <img src="${DescriptionComplementImage}" className="w-[500px]" />
                      `}
                multiline
              />
            </section>

            <section>
              <Datepicker
                label="Disponível em"
                name={`checkpoints[${index}].available_at`}
                description={`Data e hora que ficará disponível o checkpoint para os alunos.
                        <img src="${AvailableImage}" className="w-[500px]" />  
                      `}
                placeholder="Data de exibição que a aula será liberada."
                showTimeSelect
                required
                timeIntervals={5}
                style={{ height: '50px' }}
              />

              <Datepicker
                label="Indisponível em"
                name={`checkpoints[${index}].unavailable_at`}
                description={`Data e hora que ficará indisponível o checkpoint para os alunos.
                        <img src="${AvailableImage}" className="w-[500px]" />  
                      `}
                placeholder="Data de exibição que a aula será liberada."
                showTimeSelect
                required
                timeIntervals={5}
                style={{ height: '50px' }}
              />
            </section>

            <section>
              <Input
                name={`checkpoints[${index}].url`}
                label="URL"
                placeholder="Link do forms do desafio"
                description={`Link do formulário de entrega, visível apenas quando o checkpoint estiver disponível.
                        <img src="${UrlImage}" className="w-[500px]" />  
                      `}
              />

              <Input
                name={`checkpoints[${index}].award`}
                label="Prêmio"
                placeholder="1 camiseta"
                description={`Prêmio por concluir o checkpoint.
                        <img src="${AwardImage}" className="w-[500px]" />  
                      `}
              />
            </section>

            <section>
              <Input
                name={`checkpoints[${index}].metadata.tag_text`}
                label="Texto da tag"
                placeholder="Em breve"
                description={`Texto da label que fica abaixo do título do checkpoint.
                        <img src="${TagImage}" className="w-[500px]" />  
                      `}
              />

              <Input
                name={`checkpoints[${index}].priority`}
                label="Prioridade"
                placeholder="1"
                description={`Esse campo define a ordem que será exibido os checkpoints, quanto menor mais em cima.
                        Exemplo:
                          1
                          2
                          3  
                      `}
              />
            </section>
          </div>
        </div>
      </Fieldset>
    </div>
  );
};
