import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 60px;
  }

  button {
    margin-top: 30px;
  }

  label {
    text-align: start;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
