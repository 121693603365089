import * as Yup from 'yup';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório.'),
  points: Yup.number()
    .required('Pontos é um campo obrigatório.')
    .positive()
    .integer(),
  discountInPoints: Yup.number()
    .min(0, 'Deve ser igual ou maior que 0')
    .integer(),
  imageUrl: Yup.string().url(),
  availableAt: Yup.date().required('Disponível em é um campo obrigatório.'),
  unavailableAt: Yup.date().nullable(),
  typeId: Yup.string().required('Tipo é um campo obrigatório.'),
});

export default schema;
