import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  background: '#FFF';
  border-color: ${(props) => (props.error ? '#DD5554' : '#CCC')};
  padding: 0 12px;
  height: 36px;
  font-size: 14px;
  color: '#302B3E';
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    background: ${transparentize(0.9, '#4FCDA5')};
  }

  & > input[type='file'] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }
`;

const loadingAnimation = keyframes`
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
`;

export const Loading = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: '#302B3E',
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${loadingAnimation} 2s infinite ease-in-out;
  }
  &::after {
    animation-delay: -1s;
  }
`;
