/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { MdClose, MdSave } from 'react-icons/md';
import { Scope } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Container, Footer, Title } from './styles';

import { Check, Form, Input } from '~/components/Form';
import Button from '~/components/Button';

const schema = Yup.object().shape({
  option: Yup.object().shape({
    title: Yup.string().required('Título é um campo obrigatório.'),
    is_correct: Yup.boolean().required('Tipo é um campo obrigatório.'),
  }),
});

export function OptionForm({ onSubmit, onCancel, initialData }) {
  const isUpdatingOption = !!initialData;
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  function handleSubmit(formData) {
    formData.option.tempId = String(Date.now());

    onSubmit({ ...formData.option });
    // Fix: Clear value by manipulating DOM element because unform ref doesn't work
    const optionTitle = document.getElementById('option.title');
    if (optionTitle) optionTitle.value = '';

    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function handleCancel() {
    const textAreaRef = containerRef.current.querySelector(
      "textarea[id='option.title']",
    );

    const hasValue = !!textAreaRef.value;

    if (hasValue) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Deseja realmente cancelar?')) {
        onCancel();
      }

      return;
    }

    onCancel();
  }

  return (
    <Container ref={containerRef}>
      <Form
        schema={schema}
        initialData={{ option: { is_correct: false, ...initialData } }}
        onSubmit={handleSubmit}
      >
        <Title>
          {`${isUpdatingOption ? 'Atualizar' : 'Adicionar'} resposta`}
        </Title>

        <Scope path="option">
          <section>
            <Input
              label="Texto da resposta"
              name="title"
              placeholder="Texto da resposta"
              multiline
            />

            <Check
              label="É correta?"
              name="is_correct"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />
          </section>
        </Scope>

        <Footer>
          <Button icon={MdClose} color="danger" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button icon={MdSave} color="success" type="submit">
            Salvar
          </Button>
        </Footer>
      </Form>
    </Container>
  );
}

OptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    is_correct: PropTypes.bool.isRequired,
  }),
};

OptionForm.defaultProps = {
  initialData: null,
};
