import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;

  .error {
    color: #dd5554;
    font-size: 12px;
    margin-top: 2px;
  }

  &::before {
    display: table;
    content: ' ';
  }

  input {
    padding-right: 24px;
  }
`;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}`;

export const Loading = styled.span`
  position: absolute;
  margin-top: 1px;
  animation: ${loadingAnimation} 2s infinite ease-in-out;
  color: #ccc;
`;

export const FeedBack = styled.div`
  position: absolute;
  top: 50%;
  right: 3px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: 3px;
`;
