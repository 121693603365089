import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import ClipboardButton from '~/components/ClipboardButton';
import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    getValue: (element) => {
      return <td key={`title-${element.id}`}>{element.tagged_title}</td>;
    },
  },
  {
    label: 'Id',
    getValue: (element) => {
      const { jupiter_id: jupiterId, id } = element;

      if (!jupiterId) {
        return (
          <td key={`jupiter-${id}`}>
            <em>Processando</em>
          </td>
        );
      }

      return (
        <td key={`jupiter-${id}`}>
          {id}
          <ClipboardButton value={id} />
        </td>
      );
    },
  },
];

function VideosList({ match }) {
  const [category, setCategory] = useState(null);

  useEffect(() => {
    async function loadCategory(id) {
      try {
        const { data: response } = await api.get(`/video-categories/${id}`);
        setCategory(response);
      } catch (err) {
        toast.error('Não foi possível carregar os dados da categoria');
      }
    }

    const { categoryId } = match.params;

    if (categoryId) {
      loadCategory(categoryId);
    }
  }, []);

  return (
    <PagedList
      resource={`video-categories/${match.params.categoryId}/videos`}
      backLink={`/video-categories/edit/${match.params.categoryId}`}
      createPermission="edit_videos"
      header={category ? `Vídeos (${category.title})` : 'Vídeos'}
      columns={columns}
      actions={() => <td />}
    />
  );
}

VideosList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

VideosList.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default VideosList;
