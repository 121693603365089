import React, { useState } from 'react';

import { FaRegTrashAlt } from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    getValue: (element) => {
      return <td key={`title-${element.id}`}>{element.title}</td>;
    },
  },
  {
    label: 'Slug',
    value: 'slug',
  },
];

function MicroCertificatesList() {
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function handleRemoveCertificate() {
    try {
      await api.delete(`/micro-certificates/${selectedCertificate?.id}`);

      toast.success('Micro-certificado excluído com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir o Micro-certificado');
    }

    setShouldRefetch(false);
    setSelectedCertificate(null);
  }

  return (
    <>
      <PagedList
        resource="micro-certificates"
        header="Micro Certificados"
        createPermission="edit_journeys"
        columns={columns}
        refetchData={shouldRefetch}
        customActions={(contract) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedCertificate(contract)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedCertificate}
        title="Excluir Micro-certificado?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveCertificate()}
        onResolve={() => setSelectedCertificate(null)}
      />
    </>
  );
}

export default MicroCertificatesList;
