import styled from 'styled-components';

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:nth-child(2) {
      gap: 0;
    }
  }
`;

export const PurchasesContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
