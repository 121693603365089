/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import debounce from 'debounce-promise';

import schema from './schema';

import Button from '~/components/Button';
import {
  Datepicker,
  Dropdown,
  FileInput,
  Form,
  Input,
} from '~/components/Form';
import Page from '~/components/Page';
import mgmApi from '~/services/mgm.api';
import { DEFAULT_DEBOUNCE_VALUE, getLabel, useCan } from '~/utils';

function RewardsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reward, setReward] = useState({
    title: '',
    points: 0,
    discountInPoints: 0,
    availableAt: new Date(),
    unavailableAt: null,
    typeId: '',
  });

  const can = useCan();
  const viewOnly = !can('edit_rewards');
  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      if (isUpdating) {
        const { id } = match.params;

        await mgmApi.put(`/admin/rewards/${id}`, data);

        toast.success('Prêmio atualizado com sucesso!');
      } else {
        await mgmApi.post('/admin/rewards/', data);

        toast.success('Prêmio criado com sucesso!');
      }

      history.push('/rewards');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadRewards(id) {
      try {
        setLoading(true);

        const { data: response } = await mgmApi.get(`/admin/rewards/${id}`);

        setReward(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadRewards(id);
    }
  }, [match.params]);

  async function loadRewardTypes() {
    try {
      const { data } = await mgmApi.get(`/admin/reward-types`);

      const rewardTypes = data.map((rewardType) => ({
        id: rewardType.id,
        title: rewardType.type,
      }));

      return rewardTypes;
    } catch (err) {
      return [];
    }
  }

  const loadAsyncRewardTypes = useCallback(
    debounce(loadRewardTypes, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar prêmio</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} prêmio`}</h1>
        )}
      </header>

      <Form
        schema={schema}
        initialData={{
          ...reward,
        }}
        onSubmit={handleSubmit}
      >
        <section>
          <Input
            label={getLabel('Título', 'O título do prêmio', true)}
            name="title"
            readOnly={viewOnly}
            maxLength="60"
          />
        </section>

        <section>
          <Input
            type="number"
            name="points"
            label={getLabel('Pontos', 'Custo em pontos total.', true)}
            readOnly={viewOnly}
          />
          <Input
            type="number"
            name="discountInPoints"
            label={getLabel(
              'Desconto (Em pontos)',
              'Número de pontos para desconto',
              false,
            )}
            readOnly={viewOnly}
          />
        </section>

        <section>
          <FileInput
            label="Imagem do prêmio"
            name="imageUrl"
            placeholder="Imagem do prêmio"
            path="platform"
            responseKey="url"
          />
        </section>

        <section>
          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Data de disponibilidade',
              'A data em que o prêmio estará disponível. Coloque uma data no passado para disponibilizar imediatamente.',
              true,
            )}
            name="availableAt"
            showTimeSelect
            disabled={viewOnly}
          />
          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Data de fim da disponibilidade',
              'Data de fim da disponibilidade para itens exclusivos.',
            )}
            name="unavailableAt"
            showTimeSelect
            disabled={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            name="typeId"
            load={loadAsyncRewardTypes}
            label={getLabel(
              'Tipo de prêmio',
              'O tipo de prêmio que será disponibilizado.',
              true,
            )}
            disabled={viewOnly}
          />
        </section>

        <section style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button color="cancel" to="/rewards">
            Cancelar
          </Button>

          {!viewOnly && (
            <Button type="submit" loading={submitting} disabled={submitting}>
              Salvar prêmio
            </Button>
          )}
        </section>
      </Form>
    </Page>
  );
}

RewardsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RewardsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default RewardsForm;
