import React, { useEffect } from 'react';

import { MdAdd } from 'react-icons/md';

import Button from '~/components/Button';
import ClipboardButton from '~/components/ClipboardButton';
import Page from '~/components/Page';
import { Thumbnail } from '~/components/PagedList/styles';
import { Actions, Table } from '~/components/Table';
import mgmApi from '~/services/mgm.api';
import { useCan } from '~/utils';

const columns = [
  {
    label: 'Imagem',
    value: 'imageUrl',
    getValue: (element) => {
      return (
        element.imageUrl && (
          <Thumbnail>
            <img src={element.imageUrl} alt="" />
          </Thumbnail>
        )
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Pontos',
    value: 'points',
  },
  {
    label: 'Desconto (em pontos)',
    value: 'discountInPoints',
  },
];

export function RewardsList() {
  const [rewards, setRewards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const can = useCan();

  useEffect(() => {
    async function loadRewards() {
      const { data } = await mgmApi.get('/admin/rewards');

      setRewards(data);
      setLoading(false);
    }

    loadRewards();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  return (
    <Page>
      <header>
        <h1>Prêmios</h1>

        <div>
          {can('edit_rewards') && (
            <Button icon={MdAdd} color="success" to="/rewards/new">
              Novo
            </Button>
          )}
        </div>
      </header>

      {!loading && !rewards.length ? (
        <div style={{ marginTop: '1rem' }}>
          <p>Nenhum registro encontrado</p>
        </div>
      ) : (
        <Table loading={loading}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th>Editar</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {rewards.map((reward) => {
              return (
                <tr key={reward.id}>
                  {columns.map((column) => (
                    <td key={`${reward.id}-${column.value}`}>
                      {column.getValue?.(reward) || reward[column.value]}
                    </td>
                  ))}

                  <td width={60}>
                    <Actions href={`/rewards/edit/${reward.id}`} />
                  </td>
                  <td width={60}>
                    <ClipboardButton title="Copiar ID" value={reward.id} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Page>
  );
}
