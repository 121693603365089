import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Versão',
    value: 'version',
  },
];

export default function TermsList() {
  return (
    <PagedList
      resource="terms"
      header="Termos de uso"
      columns={columns}
      createPermission="edit_terms"
    />
  );
}
