/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { MdAdd } from 'react-icons/md';

import { useProducts } from '@shared/skylab/graphql/pluto';
import { contentTypes, types } from '../Form/options';

import { Page } from '~/pages/styles';
import Button from '~/components/Button';
import { useCan } from '~/utils';
import { Actions, Table } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Identificador',
    value: 'identifier',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <div className="flex items-center gap-2">
            {element.identifier}
            <ClipboardButton title="Copiar" value={element.identifier} />
          </div>
        </td>
      );
    },
  },
  {
    label: 'Tipo',
    value: 'type',
    getValue: (element) => {
      const option = types.find((item) => item.id === element.type);

      return <td key={element.id}>{option?.title || '-'}</td>;
    },
  },
  {
    label: 'Tipo de conteúdo',
    value: 'contentType',
    getValue: (element) => {
      const option = contentTypes.find(
        (item) => item.id === element.contentType,
      );

      return <td key={element.id}>{option?.title || '-'}</td>;
    },
  },
];

function ProductsList() {
  const { loading, data } = useProducts();
  const can = useCan();

  const products = data?.products || [];

  return (
    <Page>
      <header>
        <h1>Produtos</h1>

        <div>
          {can('edit_products') && (
            <Button icon={MdAdd} color="success" to="/products/new">
              Novo
            </Button>
          )}
        </div>
      </header>

      {!loading && !products.length ? (
        <div style={{ marginTop: '1rem' }}>
          <p>Nenhum registro encontrado</p>
        </div>
      ) : (
        <Table loading={loading}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                {columns.map((column) => {
                  if (column.getValue) {
                    return column.getValue(product);
                  }

                  return (
                    <td key={`${product.id}-${column.value}`}>
                      {product[column.value]}
                    </td>
                  );
                })}

                <td width={120}>
                  <Actions href={`/products/edit/${product.identifier}`} />
                  <ClipboardButton title="Copiar ID" value={product.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Page>
  );
}

export default ProductsList;
