import styled from 'styled-components';

export const MarkdownWrapper = styled.div`
  background: #f9f9f9;
  padding: 16px;
  border-radius: 5px;

  p + p {
    margin-top: 0.35em;
  }

  a {
    color: #8234e9;
    text-decoration: underline;
  }

  > span:first-child {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #888;
    margin-bottom: 10px;
  }
`;
