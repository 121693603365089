import styled, { css } from 'styled-components';

export const RuleContainer = styled.div`
  position: relative;

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      > main {
        background: rgba(255, 255, 255, 0.8);

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    `}

  > section {
    button {
      margin-top: 25px;
      height: 50px;
    }
  }
`;
