import React, { useEffect, useState } from 'react';

import { FaCheckCircle, FaRegTrashAlt } from 'react-icons/fa';
import { RiCloseCircleFill } from 'react-icons/ri';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import { quantityInputs, validationTypes } from '../Form';

import Button from '~/components/Button';
import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Tarefa Final',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element?.is_final ? (
            <FaCheckCircle size={15} color="#4FCDA5" />
          ) : (
            <RiCloseCircleFill size={17} color="#DD5554" />
          )}
        </td>
      );
    },
  },
  {
    label: 'Requisito',
    getValue: (element) => {
      const { label } = validationTypes.find(
        (type) => type.value === element.validation_type,
      );
      return <td key={element.id}>{label}</td>;
    },
  },
  {
    label: 'Quantidade',
    getValue: (element) => {
      const { value } = validationTypes.find(
        (type) => type.value === element.validation_type,
      );
      const shouldReturn = quantityInputs.includes(value);
      return (
        <td key={element.id}>
          {shouldReturn
            ? `${value === 'JOURNEY_PROGRESS'
              ? `${element?.validation_params.count}%`
              : element?.validation_params.count
            }`
            : 'Não quantitativo'}
        </td>
      );
    },
  },
];

export default function TasksList() {
  const [selectedTask, setSelectedTask] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(false);

  const filters = [
    {
      label: 'Fase',
      name: 'level_id',
      data: levels.map((level) => ({
        value: level.id,
        label: level.title,
      })),
    },
  ];

  useEffect(() => {
    async function loadLevels() {
      const { data } = await api.get('/guide/levels', {
        params: {
          per_page: 999,
        },
      });

      setLevels(data.data);
      setLoading(false);
    }

    loadLevels();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  async function handleRemoveTask() {
    try {
      await api.delete(`/guide/tasks/${selectedTask?.id}`);

      toast.success('Tarefa excluída com sucesso');
      setShouldRefetch(true);
    } catch {
      toast.error('Não foi possível excluir a tarefa');
    }

    setShouldRefetch(false);
    setSelectedTask(null);
  }

  return (
    <>
      <PagedList
        resource="guide/tasks"
        header="Tarefas"
        columns={columns}
        refetchData={shouldRefetch}
        filters={filters}
        createPermission="create_tasks"
        customActions={(level) => {
          return (
            <Button
              type="button"
              color="danger"
              style={{ height: 30 }}
              onClick={() => setSelectedTask(level)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }}
      />

      <SweetAlert2
        show={selectedTask}
        title="Excluir tarefa?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveTask()}
        onResolve={() => setSelectedTask(null)}
      />
    </>
  );
}
