import * as Yup from 'yup';

const offersPercentage = Yup.number()
  .transform((c, o) => (o === '' ? null : c))
  .max(100, 'Porcentagem não pode ser maior que 100.')
  .nullable()
  .when('ids', {
    is: (ids) => ids?.length,
    then: Yup.number().required(
      'Porcentagem é um campo obrigatório quando houverem ofertas selecionadas.',
    ),
  });

const customersPercentage = Yup.number()
  .transform((c, o) => (o === '' ? null : c))
  .max(100, 'Porcentagem não pode ser maior que 100.')
  .nullable()
  .when('atlasUserIds', {
    is: (atlasUserIds) => atlasUserIds?.length,
    then: Yup.number().required(
      'Porcentagem é um campo obrigatório quando houverem alunos selecionados.',
    ),
  });

const schema = Yup.object().shape(
  {
    createdBy: Yup.string(),
    title: Yup.string().required('Título é um campo obrigatório'),
    description: Yup.string().optional(),
    code: Yup.string().required('Código é um campo obrigatório'),
    active: Yup.boolean().required('Status é um campo obrigatório.'),
    isPartnership: Yup.boolean().required('Parceria é um campo obrigatório.'),
    isUnique: Yup.boolean().required('Uso único é um campo obrigatório.'),
    isRestricted: Yup.boolean().required(
      'Cupom restrito é um campo obrigatório.',
    ),
    partner: Yup.string().when('isPartnership', {
      is: true,
      then: Yup.string().required('Nome do parceiro é um campo obrigatório.'),
    }),
    partnerCnpj: Yup.string().when('isPartnership', {
      is: true,
      then: Yup.string().required('CNPJ do parceiro é um campo obrigatório.'),
    }),
    startsAt: Yup.string()
      .nullable()
      .required('Data inicial é um campo obrigatório'),
    endsAt: Yup.string().nullable(),
    offers: Yup.object().when('customers', {
      is: (customers) => !customers?.atlasUserIds?.length,
      then: Yup.object().shape({
        ids: Yup.array()
          .of(Yup.string())
          .required('Ofertas é um campo obrigatório se não houverem alunos.'),
        percentage: offersPercentage,
      }),
      otherwise: Yup.object().shape({
        ids: Yup.array().of(Yup.string()),
        percentage: offersPercentage,
      }),
    }),
    customers: Yup.object().when('offers', {
      is: (offers) => !offers?.ids?.length,
      then: Yup.object().shape({
        atlasUserIds: Yup.array()
          .of(Yup.string())
          .required('Alunos é um campo obrigatório se não houverem ofertas.'),
        percentage: customersPercentage,
      }),
      otherwise: Yup.object().shape({
        atlasUserIds: Yup.array().of(Yup.string()),
        percentage: customersPercentage,
      }),
    }),
    type: Yup.string()
      .oneOf([
        'DEFAULT',
        'INFLUENCER',
        'INSIDE_SALES',
        'CUSTOMER_SUCCESS',
        'MEMBER_GET_MEMBER',
        'B2B',
      ])
      .required('Tipo é um campo obrigatório'),
  },
  ['offers', 'customers'],
);

export default schema;
