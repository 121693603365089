/* eslint-disable react/prop-types */
import React from 'react';

import ReactMarkdown from 'react-markdown';

import { MarkdownContainer } from './styles';

export const Markdown = ({ children }) => {
  return (
    <MarkdownContainer>
      <ReactMarkdown>{children}</ReactMarkdown>
    </MarkdownContainer>
  );
};
