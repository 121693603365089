import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 2;

  height: 72px;
  background: #fff;
`;

export const Stats = styled.ul`
  display: flex;

  li {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #000;
    border-color: ${transparentize(0.9, '#302B3E')};

    &:first-child {
      margin: 0;
      padding: 0;
      border: 0;
    }

    small {
      opacity: 0.4;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }

    strong {
      font-size: 22px;
      color: #a795d9;
      line-height: 18px;
    }
  }
`;

export const User = styled.div`
  display: flex;
  margin-left: auto;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    line-height: 16px;

    strong {
      font-size: 14px;
    }

    button {
      background: transparent;
      border: 0;
      color: #dd5554;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;
