import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-size: 1rem;
  line-height: 160%;

  padding: 0.5rem;
  max-width: 500px;

  ul {
    list-style: circle;
    padding-left: 15px;
  }

  img {
    border-radius: 10px;
    opacity: 1;
  }
`;
