import { createGlobalStyle } from 'styled-components';

import './lib/react-toastify.css';
import './lib/react-day-picker.css';
import './lib/react-tabtab.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'filepond/dist/filepond.min.css';
import 'react-tooltip/dist/react-tooltip.css';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: 'Source Sans Pro', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    font-family: 'Source Sans Pro', sans-serif;
  }

  input {
    outline: 0;
  }
`;

export default GlobalStyle;
