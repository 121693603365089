/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import schema from './schema';
import { client } from '@shared/skylab/graphql/self-evaluation/client';
import {
  CRITERIA,
  SELF_EVALUATIONS,
  STEP,
} from '@shared/skylab/graphql/self-evaluation/queries';
import { UPSERT_STEP } from '@shared/skylab/graphql/self-evaluation/mutations';

import Button from '~/components/Button';
import { Dropdown, Form, Input, SortableList } from '~/components/Form';
import Page from '~/components/Page';
import { useCan } from '~/utils';

function NavigationsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [navigation, setNavigation] = useState({});
  const can = useCan();
  const isUpdating = !!match.params.id;

  const viewOnly = !can('edit_navigations');
  const token = `Bearer ${localStorage.getItem('@Skylab:token')}`;

  async function loadSelfEvaluation() {
    const {
      data: { selfEvaluations },
    } = await client.query({
      query: SELF_EVALUATIONS,
      context: {
        headers: {
          authorization: token,
          AUTH_ACCESS_TOKEN:
            process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
        },
      },
    });

    return selfEvaluations;
  }

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await client.mutate({
        mutation: UPSERT_STEP,
        variables: {
          data: {
            id,
            ...data,
          },
        },
        context: {
          headers: {
            authorization: token,
            AUTH_ACCESS_TOKEN:
              process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
          },
        },
      });

      toast.success('Autoavaliação salva com sucesso!');
      history.push('/navigations');
    } catch (err) {
      toast.error('Ocorreu um erro ao salvar a autoavaliação');
    } finally {
      setSubmitting(false);
    }
  }

  async function loadCriteria() {
    const {
      data: { criteria },
    } = await client.query({
      query: CRITERIA,
      variables: { limit: 9999 },
      context: {
        headers: {
          authorization: token,
          AUTH_ACCESS_TOKEN:
            process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
        },
      },
    });

    return criteria?.nodes || [];
  }

  useEffect(() => {
    async function loadNavigation(id) {
      try {
        setLoading(true);

        const {
          data: { step },
        } = await client.query({
          fetchPolicy: 'no-cache',
          query: STEP,
          variables: {
            id,
          },
          context: {
            headers: {
              authorization: token,
              AUTH_ACCESS_TOKEN:
                process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
            },
          },
        });

        setNavigation({
          title: step?.title || '',
          slug: step?.slug || '',
          selfEvaluationId: step?.selfEvaluation?.id || '',
          criteriaIds: step?.criteria || [],
        });
      } catch (err) {
        toast.error('Ocorreu um erro ao carregar a autoavaliação');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadNavigation(id);
    }
  }, [match.params, token]);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar autoavaliação</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} autoavaliação`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" to="/navigations">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={navigation} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" readOnly={viewOnly} />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            name="selfEvaluationId"
            load={loadSelfEvaluation}
            placeholder="Selecione uma jornada"
            getOptionLabel={(el) => el.relatedJourney}
            label="Jornada"
          />
        </section>

        <section>
          <SortableList
            label="Critérios"
            name="criteriaIds"
            load={loadCriteria}
          />
        </section>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

NavigationsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

NavigationsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default NavigationsForm;
