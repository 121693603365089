import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';

const progress = keyframes`
  0% {
      background-position: -200px 0;
  }
  100% {
      background-position: calc(200px + 100%) 0;
  }
`;

const Shimmer = styled.span`
  animation: ${progress} 1.2s ease-in-out infinite;
  height: 20px;

  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    ${lighten(0.02, '#fff')},
    rgba(0, 0, 0, 0)
  );

  background-color: ${darken(0.03, '#fff')};

  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
`;

export default Shimmer;
