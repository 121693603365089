/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '../Form';

import api from '~/services/api';

export const Tag = ({ name, label, placeholder, multiple, type }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);

  const loadTagByType = async () => {
    setIsLoading(true);

    const { data: response } = await api.get('/content-tags', {
      params: {
        type,
        perPage: 999,
      },
    });

    setIsLoading(false);
    setTags(response?.data);
  };

  useEffect(() => {
    loadTagByType(type);
  }, [type]);

  return (
    <Dropdown
      name={name}
      label={label}
      placeholder={isLoading ? 'Carregando tags' : placeholder}
      options={tags}
      multiple={multiple}
      disabled={isLoading}
    />
  );
};

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
};
