import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  ${(props) =>
    props.readOnly &&
    `
      button {
        cursor: default;
      }
  `}
`;

export const Option = styled.button`
  flex: 1;
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.selected ? '#FFF' : '#302B3E')};
  border: 1px solid #ccc;
  background: ${(props) => (props.selected ? '#7159c1' : '#FFF')};
  border-color: ${(props) => (props.withError ? '#DD5554' : '#CCC')};
  &:not(:first-child) {
    border-left: 0;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        background: ${() => transparentize(0.95, '#302B3E')};
      }
    `}
`;
