export const signInRequest = (email, password) => ({
  type: '@auth/SIGN_IN_REQUEST',
  payload: { email, password },
});

export const checkoutSignInRequest = (email, password) => ({
  type: '@auth/CHECKOUT_SIGN_IN_REQUEST',
  payload: { email, password },
});

export const githubRequest = (code, page = '') => ({
  type: '@auth/GITHUB_REQUEST',
  payload: { code, page },
});

export const signInSuccess = (token) => ({
  type: '@auth/SIGN_IN_SUCCESS',
  payload: { token },
});

export const checkoutSignInSuccess = (token) => ({
  type: '@auth/CHECKOUT_SIGN_IN_SUCCESS',
  payload: { token },
});

export const setCheckoutConfirmed = (status) => ({
  type: '@auth/SET_CHECKOUT_CONFIRMED',
  payload: { status },
});

export const authFailure = () => ({
  type: '@auth/AUTH_FAILURE',
});

export const signUpRequest = (token, user) => ({
  type: '@auth/SIGN_UP_REQUEST',
  payload: { token, user },
});

export const checkoutSignUpRequest = (data) => ({
  type: '@auth/CHECKOUT_SIGN_UP_REQUEST',
  payload: { data },
});

export const signUpSuccess = () => ({
  type: '@auth/SIGN_UP_SUCCESS',
});

export const signUpFailure = () => ({
  type: '@auth/SIGN_UP_FAILURE',
});

export const verifyEmailRequest = (token) => ({
  type: '@auth/VERIFY_EMAIL_REQUEST',
  payload: { token },
});

export const verifyEmailFailure = () => ({
  type: '@auth/VERIFY_EMAIL_FAILURE',
});

export const signOutRequest = (redirect = true, data) => ({
  type: '@auth/SIGN_OUT_REQUEST',
  payload: { redirect, data },
});

export const signOutSuccess = () => ({
  type: '@auth/SIGN_OUT_SUCCESS',
});

export const setMixpanelIdentifyRequest = () => ({
  type: '@auth/SET_MIXPANEL_IDENTIFY',
});
