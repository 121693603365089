import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FaRegTrashAlt } from 'react-icons/fa';
import { MdRestore } from 'react-icons/md';

import { Button, ButtonsContainer, FieldContainer } from './styles';

import ButtonComponent from '~/components/Button';
import { Dropdown, FileInput, Input } from '~/components/Form';
import { getLabel } from '~/utils';
import api from '~/services/api';

const attachmentTypes = [
  { id: 'link', title: 'Link' },
  { id: 'video', title: 'Vídeo' },
  { id: 'file', title: 'Arquivo' },
];

export default function Field({ field, setFields, isFirst }) {
  const currentType = attachmentTypes?.find(
    (attType) => attType.id === field?.type,
  );
  const [selectedType, setSelectedType] = useState(currentType ?? null);

  function handleRemove(id) {
    setFields((prev) =>
      prev.map((prevField) => ({
        ...prevField,
        deleted: prevField.id === id ? true : prevField.deleted,
      })),
    );
  }

  function handleRestore(id) {
    setFields((prev) =>
      prev.map((prevField) => ({
        ...prevField,
        deleted: prevField.id === id ? false : prevField.deleted ?? false,
      })),
    );
  }

  async function loadJourneys() {
    const { data } = await api.get('/journeys', {
      params: {
        per_page: 999,
      },
    });
    return data.data;
  }

  return (
    <FieldContainer isDeleted={field.deleted}>
      {field.deleted && (
        <main>
          <ButtonComponent
            icon={MdRestore}
            onClick={() => handleRestore(field.id)}
          >
            Restaurar
          </ButtonComponent>
        </main>
      )}
      <Input type="hidden" name="id" id="dsadasdas" />
      <Input type="hidden" name="deleted" id="dsdas" value={field.deleted} />

      <section>
        <Dropdown
          name="type"
          options={attachmentTypes}
          disabled={false}
          placeholder="Selecione..."
          label={isFirst ? getLabel('Tipo do anexo', '', true) : null}
          onChange={(value) => setSelectedType(value)}
        />

        <Input
          name="title"
          placeholder="Título"
          style={{ height: 50 }}
          label={isFirst ? getLabel('Título', '', true) : null}
        />

        {selectedType?.id === 'link' && (
          <Input
            name="value.link"
            placeholder="https://github.com/example"
            style={{ height: 50 }}
            label={isFirst ? getLabel('URL', '', true) : null}
          />
        )}

        {selectedType?.id === 'file' && (
          <FileInput
            name="value.file"
            label={isFirst ? getLabel('Arquivo', '', true) : null}
          />
        )}

        {selectedType?.id === 'video' && (
          <>
            <Dropdown
              name="value.journey"
              load={loadJourneys}
              disabled={false}
              placeholder="Selecione..."
              label={isFirst ? getLabel('Jornada da aula', '', true) : null}
            />

            <Input
              name="value.lesson"
              placeholder="ID da aula"
              style={{ height: 50 }}
              label={isFirst ? getLabel('ID da aula', '', true) : null}
            />
          </>
        )}

        <ButtonsContainer isFirst={isFirst}>
          <Button type="button" onClick={() => handleRemove(field.id)}>
            <FaRegTrashAlt className="icon" />
          </Button>
        </ButtonsContainer>
      </section>
    </FieldContainer>
  );
}

Field.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    deleted: PropTypes.bool,
  }).isRequired,
  isFirst: PropTypes.bool.isRequired,
  setFields: PropTypes.func.isRequired,
};
