/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MdAdd, MdSearch } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Select from 'react-select';

import { useOffers } from '@shared/skylab/graphql/pluto';

import { Input } from '~/components/Form';
import { Page } from '~/pages/styles';
import Button from '~/components/Button';
import { getSearchParam, useCan } from '~/utils';
import { Actions, Table } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';
import { Filters, SearchForm, Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: '',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        element.icon_url && (
          <Thumbnail>
            <img src={element.icon_url} alt="" />
          </Thumbnail>
        )
      );
    },
  },
  {
    label: 'Título',
    getValue: (element) => {
      return (
        <section style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <strong
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            {element.title}
          </strong>

          <span style={{ fontSize: 12 }}>{element.slug}</span>
        </section>
      );
    },
  },
  {
    label: 'Preço',
    getValue: (element) => {
      const priceInDecimal = element.priceInCents / 100;
      const discountInDecimal = element.discountPriceInCents / 100;

      const formattedPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(priceInDecimal);

      const formattedDiscount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(priceInDecimal - discountInDecimal);

      if (element.discountPriceInCents) {
        return (
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <small
              style={{ fontWeight: 'normal', textDecoration: 'line-through' }}
            >
              {`de ${formattedPrice}`}
            </small>

            <strong>{`por: ${formattedDiscount}`}</strong>
          </section>
        );
      }

      return <strong>{formattedPrice}</strong>;
    },
  },
  {
    label: 'Pode usar cupom?',
    getValue: (element) => (
      <input type="checkbox" checked={element.canUseCoupon} />
    ),
  },
  {
    label: 'Válido para o MGM?',
    getValue: (element) => (
      <input type="checkbox" checked={element.isValidForMGM} />
    ),
  },
  {
    label: 'Habilitado',
    getValue: (element) => (
      <input type="checkbox" checked={element.isPurchaseable} />
    ),
  },
  {
    label: '',
    value: 'slug',
    getValue: (element) => {
      return (
        <section style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <a
            href={`https://app.rocketseat.com.br/cart/${element.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              style={{
                height: 26,
                fontSize: 12,
              }}
            >
              <div
                style={{
                  gap: 5,
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                Abrir
                <FaExternalLinkAlt />
              </div>
            </Button>
          </a>

          <ClipboardButton
            title="Copiar Link"
            value={`https://app.rocketseat.com.br/cart/${element.slug}`}
            style={{
              background: 'white',
              fontSize: 12,
              width: 100,
            }}
          >
            Copiar URL
          </ClipboardButton>
        </section>
      );
    },
  },
];

const filters = [
  {
    label: 'Status',
    name: 'isPurchaseable',
    data: [
      { value: true, label: 'Habilitado' },
      { value: false, label: 'Desabilitado' },
    ],
  },
];

function OffersList({ location, history, match }) {
  const can = useCan();

  const searchParam = getSearchParam(location);
  const [isPurchaseable, setIsPurchaseable] = useState(undefined);
  const [search, setSearch] = useState(searchParam || '');

  const { loading, data, refetch } = useOffers({
    variables: {
      search: searchParam,
      isPurchaseable,
    },
  });

  const offers = data?.offers || [];

  useEffect(() => {
    refetch({
      search: searchParam,
      isPurchaseable,
    });
  }, [match.params.page, location.search]); // eslint-disable-line

  function handleSubmitSearch(e) {
    e.preventDefault();

    history.push(`/offers/?s=${escape(search)}`);
  }

  return (
    <Page>
      <header>
        <h1>Ofertas</h1>

        <div>
          <SearchForm onSubmit={handleSubmitSearch}>
            <Filters>
              <Select
                name="isPurchaseable"
                placeholder="Status"
                options={[
                  { value: true, label: 'Habilitado' },
                  { value: false, label: 'Desabilitado' },
                ]}
                isClearable
                onChange={(data) => {
                  setIsPurchaseable(data?.value);
                }}
              />
            </Filters>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />

            <Button type="submit" icon={MdSearch} color="success" />
          </SearchForm>

          {can('edit_offers') && (
            <Button icon={MdAdd} color="success" to="/offers/new">
              Novo
            </Button>
          )}
        </div>
      </header>

      {!loading && !offers.length ? (
        <div style={{ marginTop: '1rem' }}>
          <p>Nenhum registro encontrado</p>
        </div>
      ) : (
        <Table loading={loading}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {offers.map((offer) => (
              <tr key={offer.id}>
                {columns.map((column) => (
                  <td key={`${offer.id}-${column.value}`}>
                    {column.getValue?.(offer) || offer[column.value]}
                  </td>
                ))}

                <td width={120}>
                  <Actions href={`/offers/edit/${offer.slug}`} />
                  <ClipboardButton title="Copiar ID" value={offer.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Page>
  );
}

OffersList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      page: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default OffersList;
