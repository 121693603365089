/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { languages, themes } from '../types';

import Button from '~/components/Button';
import { Form, Input, FileInput, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/certificates.api';
import MarkdownInput from '~/components/Form/MarkdownInput';
import HtmlInput from '~/components/Form/HtmlInput';

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  courseId: Yup.string().required('Course is required'),
  language: Yup.string()
    .oneOf(languages.map((language) => language.value))
    .required('Language is required'),
  theme: Yup.string()
    .oneOf(themes.map((theme) => theme.value))
    .required('Theme is required'),
  thumbnail: Yup.string(),
  info: Yup.string(),
  content: Yup.string(),
  template: Yup.string().required('Template is required'),
  email: Yup.string().required('Email is required'),
});

const templateButtons = [
  { language: 'PT_BR', type: 'EVENT', title: 'Evento (PT/BR)' },
  { language: 'EN', type: 'EVENT', title: 'Evento (EN)' },
  { language: 'PT_BR', type: 'SPECIALIZATION', title: 'Formação (PT_BR)' },
  { language: 'EN', type: 'SPECIALIZATION', title: 'Formação (EN)' },
  { language: 'PT_BR', type: 'COURSE', title: 'Curso (PT_BR)' },
  { language: 'EN', type: 'COURSE', title: 'Curso (EN)' },
  { language: 'PT_BR', type: 'MODULE', title: 'Micro Certificação (PT_BR)' },
  { language: 'EN', type: 'MODULE', title: 'Micro Certificação (EN)' },
];

function CertificatesForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [certificate, setCertificates] = useState({
    title: '',
    language: 'PT_BR',
    courseId: '',
    theme: 'LIGHT',
    thumbnail: '',
    info: '',
    content: '',
    template: '',
    email: `<html>
<body>
  <div>
    <p>Faala, <strong>{{name}}</strong>!</p>
    <p>Sua jornada no <strong>{{title}}</strong> foi concluída com sucesso 🚀</p>
    <p>Aqui está o seu certificado de conclusão.</p>
    <p>Compartilhe nas redes sociais (principalmente no LinkedIn) pra mostrar pro mundo que você está comprometido(a)
      com a sua evolução como dev.</p>
    <p>#NeverStopLearning</p>
    <br />
    <a
      href="https://www.linkedin.com/profile/add/?startTask=CERTIFICATION_NAME&name={{title}}&organizationId=11290873&certId={{id}}&certUrl=https://app.rocketseat.com.br/certificates/{{id}}"><strong>Adicionar
        ao perfil do LinkdedIn</strong></a>
    <p>Abraaaço, </p>
    <p>Equipe da Rocketseat 💜</p>
  </div>
</body> 
</html>
    `,
  });
  const [courses, setCourses] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    certificate.language,
  );

  const currentTemplatePreview = useMemo(() => {
    return certificate.template
      .replace(/{{#if[^}]*}}/g, '')
      .replace(/{{\/if}}/g, '')
      .replace(
        /{{companyCertificateLogo}}/g,
        'https://xesque.rocketseat.dev/platform/1720813164135.png',
      );
  }, [certificate]);

  const isUpdating = !!match.params.id;

  const loadTemplate = useCallback(
    async ({ language, type }) => {
      const response = await fetch(`/templates/${language}/${type}.html`);
      const htmlString = await response.text();

      let info = null;

      if (language === 'PT_BR') {
        info = `
**Data de emissão:** {{emittedAt}}

{{id}}

Acesse em: [app.rocketseat.com.br/certificates](https://app.rocketseat.com.br/certificates/{{id}})
        `;
      }

      if (language === 'EN') {
        info = `
**Issued date**: {{emittedAt}}

{{id}}

Verify in: [app.rocketseat.com.br/certificates](https://app.rocketseat.com.br/certificates/{{id}})
        `;
      }

      setCertificates({
        ...certificate,
        template: htmlString,
        info,
      });
    },
    [certificate],
  );

  useEffect(() => {
    async function loadCertificates(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/certificates/${id}`);

        setCertificates(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do certificado.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadCertificates(id);
    }
  }, [match.params]);

  useEffect(() => {
    async function loadCourses() {
      try {
        setLoading(true);

        const { data: response } = await api.get('/courses?limit=0');

        setCourses(response.courses);
      } catch (err) {
        toast.error('Erro ao buscar cursos.');
      } finally {
        setLoading(false);
      }
    }

    loadCourses();
  }, [match.params]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      if (!data.thumbnail) {
        const chosenCourse = courses.find(
          (course) => course.id === data.courseId,
        );

        if (chosenCourse && chosenCourse.type === 'MODULE') {
          data.thumbnail = 'micro-certificate.svg';
        }

        if (chosenCourse && chosenCourse.type === 'SPECIALIZATION') {
          data.thumbnail = 'certificate.svg';
        }
      }

      if (isUpdating) {
        const { id } = match.params;

        api.put(`/certificates/${id}`, data);

        toast.success('Certificado atualizado com sucesso!');
      } else {
        await api.post('/certificates', data);

        toast.success('Certificado criado com sucesso!');
      }

      history.push('/certificate-service/certificates');
    } catch (err) {
      toast.error('Algo deu errado.');
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} certificado`}</h1>

        <div>
          <Button
            icon={MdClose}
            color="cancel"
            to="/certificate-service/certificates"
          >
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={certificate} onSubmit={handleSubmit}>
        <section>
          <Input name="title" label="Título" placeholder="título" autoFocus />

          <FileInput
            label="Thumbnail"
            name="thumbnail"
            placeholder="Thumbnail"
            path="certificates/thumbnails/generic"
          />
        </section>

        <section>
          <Dropdown
            name="courseId"
            label="Curso"
            options={courses.map((course) => ({
              title: `${course.title} (${course.slug})`,
              id: course.id,
            }))}
            placeholder="Selecione o curso"
          />
        </section>

        <section>
          <Dropdown
            name="language"
            label="Idioma"
            options={languages.map((language) => ({
              title: language.label,
              id: language.value,
            }))}
            placeholder="Selecione o idioma"
          />

          <Dropdown
            name="theme"
            label="tema"
            options={themes.map((theme) => ({
              title: theme.label,
              id: theme.value,
            }))}
            placeholder="Selecione o tema"
          />
        </section>

        <section>
          <MarkdownInput
            name="info"
            label="info (markdown)"
            placeholder="Informações"
          />
        </section>

        <section>
          <MarkdownInput
            name="content"
            label="content (markdown)"
            placeholder="Conteúdo"
          />
        </section>

        <section>
          <HtmlInput
            name="email"
            label="email (html)"
            placeholder="Template HTML do E-mail que será enviado na hora de baixar o certificado."
            value={certificate.email}
          />
        </section>

        <section>
          <HtmlInput
            name="template"
            label="template (html)"
            placeholder="Template HTML do certificado."
            value={currentTemplatePreview}
          >
            <div style={{ display: 'flex', gap: '5px' }}>
              {templateButtons.map((templateButton, index) => (
                <button
                  type="button"
                  key={`template-button-${index}`}
                  onClick={() =>
                    loadTemplate({
                      language: templateButton.language,
                      type: templateButton.type,
                    })
                  }
                >
                  {templateButton.title}
                </button>
              ))}
            </div>
          </HtmlInput>
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

CertificatesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CertificatesForm;
