import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Form, Input, Datepicker, Checkbox, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { useCan, showError } from '~/utils';

const statusOptions = [
  {
    id: 'eligible',
    title: 'Elegível',
  },
  {
    id: 'accepted',
    title: 'Aceito',
  },
  {
    id: 'declined',
    title: 'Recusado',
  },
];

const conditionOptions = [
  {
    id: 'ignite',
    title: 'Ignite',
  },
  {
    id: 'gostack-launchbase-scolarship',
    title: 'Bolsa de estudo GoStack & Launchbase ',
  },
  {
    id: 'unknown',
    title: 'Desconhecido',
  },
];

function UsersPioneerStatusForm({ match, history, location }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pioneerStatus, setPioneerStatus] = useState({});
  const isUpdating = !!match.params.id && !location.pathname.includes('/new');
  const can = useCan();

  const viewOnly = !can('edit_users');

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      let { id } = match.params;
      let dataAsInput = data;

      if (!isUpdating) {
        dataAsInput = {
          ...data,
          user_id: id,
        };

        id = null;
      }

      await api.postOrPut('pioneer-status', id, dataAsInput);

      toast.success('Compra salva com sucesso!');
      history.goBack();
    } catch (err) {
      showError(err);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadPioneerStatus(id) {
      try {
        setLoading(true);

        const { data } = await api.get(`/pioneer-status/${id}`);

        setPioneerStatus(data);
      } catch (err) {
        showError(err);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id && isUpdating) {
      loadPioneerStatus(id);
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar compra</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} compra`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" onClick={history.goBack}>
            Cancelar
          </Button>
        </div>
      </header>

      <Form initialData={pioneerStatus} onSubmit={handleSubmit}>
        <section>
          <Input label="Purchase id" name="purchase_id" readOnly={viewOnly} />
          <Dropdown
            label="Status"
            name="status"
            options={statusOptions}
            disabled={viewOnly}
          />
          <Dropdown
            label="Condição"
            name="condition"
            options={conditionOptions}
            disabled={viewOnly}
          />
        </section>

        <section>
          <Input
            label="Convertido para (purchase_id)"
            name="converted_to"
            readOnly={viewOnly}
          />
          <Datepicker
            label="Expiração sem pioneer"
            name="expiration_without_pioneer"
            readOnly={viewOnly}
          />
        </section>
        <section>
          <Checkbox
            name="pending_upgrade"
            label="Aguardando atualização?"
            disabled={viewOnly}
          />
        </section>
        <br />

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

UsersPioneerStatusForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

UsersPioneerStatusForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default UsersPioneerStatusForm;
