/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';

import { Container, ButtonContainer } from './styles';

import Form, { Input } from '~/components/Form';
import { getLabel } from '~/utils';
import Button from '~/components/Button';
import mgmApi from '~/services/mgm.api';

export function ApproveRedeemModal({
  redeemId,
  typeMetadata,
  show,
  setModalState,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      await mgmApi.patch(`/admin/redeems/${redeemId}/approve`, data);

      toast.success('Resgate concluído com sucesso');

      setModalState(null);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  const hasRequiredFields = typeMetadata?.required?.length > 0;

  return (
    <SweetAlert2 show={show} showConfirmButton={false}>
      <Container>
        <h2 className="font-bold">Aprovar resgate</h2>
        <p className="text-base">
          {hasRequiredFields
            ? 'Insira os valores abaixo para confirmar o resgate. Essa ação não poderá ser desfeita.'
            : 'Essa ação não poderá ser desfeita.'}
        </p>

        <Form
          initialData={
            typeMetadata.properties &&
            Object.keys(typeMetadata.properties).reduce((acc, key) => {
              acc[key] = '';
              return acc;
            }, {})
          }
          onSubmit={handleSubmit}
          className="flex flex-col"
        >
          {typeMetadata.properties &&
            Object.keys(typeMetadata.properties).map((key) => {
              return (
                <section>
                  <Input
                    className="!m-0"
                    key={key}
                    name={key}
                    type={typeMetadata.properties[key].type}
                    placeholder={typeMetadata.properties[key]?.description}
                    label={getLabel(
                      typeMetadata.properties[key]?.title,
                      typeMetadata.properties[key]?.description,
                      typeMetadata.required.includes(key),
                    )}
                  />
                </section>
              );
            })}

          <ButtonContainer>
            <Button
              onClick={() => setModalState(null)}
              type="button"
              color="cancel"
            >
              Cancelar
            </Button>

            <Button loading={isSubmitting} type="submit">
              Salvar
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </SweetAlert2>
  );
}

ApproveRedeemModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  typeMetadata: PropTypes.shape({}).isRequired,
  redeemId: PropTypes.string.isRequired,
};
