import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  max-width: 130px;
`;

export const Fieldset = styled.fieldset`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
