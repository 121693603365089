import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';

import schema from './schema';

import api from '~/services/api';
import Button from '~/components/Button';
import { Form, Input } from '~/components/Form';
import Page from '~/components/Page';

function EducatorsForm({ match, history }) {
  const isUpdating = !!match.params.id;
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [educator, setEducator] = useState({});

  function showError(err) {
    let error = err.message;
    if (err.response) {
      const { data, status } = err.response;

      if (Array.isArray(data) && data.length) {
        data.forEach((item) => {
          toast.error(item.message);
        });

        return;
      }

      switch (status) {
        case 400:
          error = data.error.message;
          break;
        case 500:
          error = data;
          break;
        default:
          error = 'Server connection error';
      }
    }
    toast.error(error);
  }

  async function handleSubmit(data) {
    try {
      setSubmitting(true);
      const { id } = match.params;
      await api.postOrPut('/educators', id, data);

      toast.success('Educator salvo com sucesso!');
      history.push('/educators');
    } catch (err) {
      showError(err);
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadEducator(id) {
      try {
        setLoading(true);

        const response = await api.get(`/educators/${id}`);

        setEducator(response.data);
      } catch (err) {
        toast.error('Não foi possível carregar os dados do educador');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadEducator(id);
    }
  }, [match.params]);

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} educador`}</h1>

        <Button icon={MdClose} color="cancel" to="/educators">
          Cancelar
        </Button>
      </header>

      <Form schema={schema} initialData={educator} onSubmit={handleSubmit}>
        <section>
          <Input
            label="Cargo"
            name="role"
            description="Descreve os cargos, funções e atribuições do educador"
          />
          <Input
            label="Usuário"
            name="user_id"
            description="Id do usuário relacionado a este educador"
          />
        </section>

        <section>
          <Input
            label="Sobre"
            name="about"
            description="Descreva quem é o educador"
            multiline
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

EducatorsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

EducatorsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default EducatorsForm;
