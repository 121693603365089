import styled from 'styled-components';

import { Container as HeaderBar } from '~/components/HeaderBar/styles';
import { Container as Sidebar } from '~/components/Nav/styles';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;

  ${Sidebar} {
    position: fixed;
    left: 0;
    top: 0;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  margin-left: 280px;
  min-height: 100%;

  background: #f7f7f8;
  color: #302b3e;

  ${HeaderBar} {
    position: fixed;
    width: calc(100% - 280px);
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 102px;
  padding: 20px 30px;
  background: #fff;

  h1 {
    font-size: 32px;
  }
`;
