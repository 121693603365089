/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';

import {
  useProduct,
  useUpsertProductMutation,
} from '@shared/skylab/graphql/pluto';
import schema from './schema';
import { contentTypes, types } from './options';

import Button from '~/components/Button';
import { Form, Input, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import { getLabel, useCan } from '~/utils';

function ProductsForm({ match, history }) {
  const { loading, data, error } = useProduct({
    skip: !match.params.identifier,
    variables: { identifier: match.params.identifier },
  });
  const [upsertProduct, { loading: submitting }] = useUpsertProductMutation({
    onError: () => toast.error('Ocorreu um erro ao salvar o produto.'),
    onCompleted: () => {
      toast.success('Produto salvo com sucesso!');
      history.push('/products');
    },
  });

  const can = useCan();

  const isUpdating = !!match.params.identifier;
  const viewOnly = !can('edit_products');

  const product = data?.product
    ? data.product
    : { mgmPoints: 0, type: 'DEFAULT' };

  async function handleSubmit(values) {
    upsertProduct({
      variables: {
        data: {
          id: product?.id,
          ...values,
        },
      },
    });
  }

  if (error) {
    history.push('/products');
    return null;
  }

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar produto</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} produto`}</h1>
        )}
      </header>

      <Form schema={schema} initialData={product} onSubmit={handleSubmit}>
        <section>
          <Input
            label={getLabel(
              'Título',
              'Dê um título que descreva bem o produto e suas particularidades.',
              true,
            )}
            name="title"
            readOnly={viewOnly}
          />

          <Input
            label={getLabel(
              'Identificador',
              'Identifica este produto internamente. Não é possível alterar manualmente. O identificador deve ser igual ao identificador do time ou ao ID do contrato.',
              true,
            )}
            name="identifier"
            readOnly={viewOnly || isUpdating}
            {...(isUpdating && { style: { background: '#EFEFEF' } })}
          />
        </section>

        <section>
          <Input
            label={getLabel(
              'Pontos para o MGM',
              'Quantos pontos o usuário que indicou ganha no MGM.',
              true,
            )}
            name="mgmPoints"
            type="number"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label="Tipo"
            options={types}
            placeholder="Selecione o tipo"
          />

          <Dropdown
            name="contentType"
            label="Tipo de Conteúdo"
            options={contentTypes}
            placeholder="Selecionar o tipo de conteúdo"
          />
        </section>

        <section style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button color="cancel" to="/products">
            Cancelar
          </Button>

          {!viewOnly && (
            <Button type="submit" loading={submitting} disabled={submitting}>
              Salvar produto
            </Button>
          )}
        </section>
      </Form>
    </Page>
  );
}

ProductsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      identifier: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ProductsForm.defaultProps = {
  match: {
    params: {
      identifier: '',
    },
  },
};

export default ProductsForm;
