import { useState } from 'react';

import { toast } from 'react-toastify';

import api from '~/services/api';
import { showError } from '~/utils';

export const useClearCache = () => {
  const [loadingClearCache, setLoadingClearCache] = useState(false);

  async function handleClearCache(userId) {
    if (loadingClearCache) {
      return;
    }

    try {
      setLoadingClearCache(true);
      await api.delete(`/users/${userId}/clear-cache`);
      toast.success('Os caches do usuário foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCache(false);
    }
  }

  return {
    handle: handleClearCache,
    isLoading: loadingClearCache,
  };
};
