import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';

const InputDefault = forwardRef(
  ({ name, label, description, required, multiline, mask, ...rest }, ref) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    const renderInput = ({ field }) => {
      const inputProps = {
        ...rest,
        ...field,
        id: field.name,
        'aria-label': field.name,
        ref: ref || field.ref || null,
      };

      if (mask) {
        return <ReactInputMask {...inputProps} mask={mask} maskChar=" " />;
      }

      return multiline ? (
        <textarea {...inputProps} />
      ) : (
        <input {...inputProps} />
      );
    };

    return (
      <>
        {label && (
          <label
            htmlFor={name}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}

            {required && <span style={{ color: 'red' }}>*</span>}

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}

        <Controller
          control={control}
          name={name}
          render={({ field }) => renderInput({ field })}
        />

        {errors[name] && <span className="error">{errors[name].message}</span>}
      </>
    );
  },
);

InputDefault.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  multiline: PropTypes.bool,
  mask: PropTypes.string,
};

InputDefault.defaultProps = {
  label: '',
  description: null,
  multiline: false,
  required: false,
  mask: null,
};

export default InputDefault;
