import styled from 'styled-components';

export const TeamSelect = styled.div`
  margin-top: 30px;
`;

export const Node = styled.div`
  hr {
    margin-bottom: 20px;
  }

  &:first-child hr {
    display: none;
  }

  + button {
    margin-top: 30px;
  }
`;
