/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';

import { get } from 'dot-prop-immutable';
import isFunction from 'lodash/isFunction';
import { unescape } from 'querystring-browser';
import { MdAdd, MdSearch, MdFileDownload, MdArrowBack } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { SearchForm, Filters, CheckboxContainer, Tabs } from './styles';
import ClipboardButton from '../ClipboardButton';

import Button from '~/components/Button';
import { Checkbox, Input } from '~/components/Form';
import Pagination from '~/components/Pagination';
import { Table, Actions } from '~/components/Table';
import { Page } from '~/pages/styles';
import api from '~/services/api';
import { useCan } from '~/utils';

function PagedList({
  history,
  match,
  location,
  resource,
  header,
  tabs,
  columns,
  filters,
  filtersText,
  filtersCheck,
  rowProps,
  initialFilters,
  exportRoute,
  hideCreate,
  createPermission,
  actions,
  backLink,
  hideSearch,
  hideSearchInput,
  hideCopyButton,
  hideEditButton,
  customActions,
  refetchData,
}) {
  const [searchFormState, setSearchFormState] = useState({});

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilters || {});
  const [apiData, setApiData] = useState({
    data: [],
    page: Number(match.params.page || 1),
    pageCount: 0,
    total: 0,
    perPage: 0,
  });
  const [exporting, setExporting] = useState(false);
  const can = useCan();

  const resourceSlug =
    typeof resource === 'function' ? resource.resourceSlug : resource;

  function getSearchParams() {
    const url = window.location.search;

    const vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
    });
    return vars;
  }

  function getSearchParamsAsParams() {
    const searchParams = getSearchParams();
    const params = new URLSearchParams(searchParams).toString();

    return params;
  }

  function renderActions(element) {
    if (isFunction(actions)) {
      return actions(element);
    }

    return (
      actions && (
        <td width={150}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
            }}
          >
            {!hideEditButton && (
              <Actions href={`/${resourceSlug}/edit/${element.id}`} />
            )}
            {!hideCopyButton && (
              <ClipboardButton title="Copiar ID" value={element.id} />
            )}
            {customActions(element)}
          </div>
        </td>
      )
    );
  }

  async function loadData(page = 1, searchTerm = '') {
    setLoading(true);

    let data;
    let pageCount;
    let total;
    let perPage;

    if (typeof resource === 'function') {
      const response = await resource(page);

      data = response.data;
      pageCount = response.pageCount;
      total = response.total;
      perPage = response.perPage;
    } else {
      const searchParams = decodeURIComponent(getSearchParamsAsParams());

      const { data: response } = await api.get(
        `/${resource}?page=${page}&${searchParams}`,
      );

      data = response.data;
      pageCount = response.lastPage;
      total = response.total;
      perPage = response.perPage;
    }

    setApiData({
      data,
      page: Number(page),
      pageCount,
      total: Number(total),
      perPage,
    });

    setLoading(false);
  }

  function handleSubmitSearch(e) {
    e.preventDefault();

    const params = new URLSearchParams(searchFormState).toString();

    history.push(`/${resourceSlug}/1/?${params}`);
  }

  const handleTabClick = (name, value) => {
    const newSearchFormState = {
      ...searchFormState,
      [name]: value,
    };

    setSearchFormState(newSearchFormState);

    const params = new URLSearchParams(newSearchFormState).toString();

    history.push(`/${resourceSlug}/1/?${params}`);
  };

  const handleInputChange = ({ fieldName, fieldValue }) => {
    setSearchFormState({
      ...searchFormState,
      [fieldName]: fieldValue,
    });
  };

  const handleCheckboxChange = ({ fieldName, fieldValue, hasDefaultValue }) => {
    setSearchFormState({
      ...searchFormState,
      [fieldName]:
        hasDefaultValue && !fieldValue ? !hasDefaultValue : fieldValue,
    });
  };

  function handlePaginate(newPage) {
    let searchQuery = getSearchParamsAsParams();

    searchQuery = searchQuery ? `/?${searchQuery}` : '';

    history.push(`/${resourceSlug}/${newPage}${searchQuery}`);
  }

  function handleFilter(name, data) {
    setFilter({
      ...filter,
      [name]: data ? data.value : '',
    });

    handlePaginate(1);
  }

  async function handleExport(exportRoute) {
    if (exporting) {
      return;
    }

    try {
      setExporting(true);

      const response = await api.get(exportRoute, {
        params: {
          ...filter,
          ...getSearchParams(),
        },
      });

      const contentDisposition = response.headers['content-disposition'];
      const matchFilename = /filename="(.*?)"/.exec(contentDisposition);
      const fileName = matchFilename ? matchFilename[1] : 'export';

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch ({ response }) {
      const message =
        response.data && response.data.error
          ? response.data.error.message
          : 'Error while exporting data.';

      toast.error(message);
    } finally {
      setExporting(false);
    }
  }

  function emptyData() {
    const searchTerm = !!getSearchParams();
    if (searchTerm) {
      return (
        <p>
          {`Não existe nenhum resultado para a busca: `}
          <b>{getSearchParamsAsParams()}</b>
        </p>
      );
    }

    return (
      <div>
        <p>Nenhum registro encontrado</p>
      </div>
    );
  }

  function renderData() {
    const hasRowPropsInjector = isFunction(rowProps);

    return (
      <Table loading={loading}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.label}>{column.label}</th>
            ))}
            {actions && <th aria-label="Actions" />}
          </tr>
        </thead>
        <tbody>
          {apiData.data.map((element, rowIndex) => {
            const elementKey = element.id || rowIndex;

            return (
              <tr
                key={`api-data-element-${elementKey}`}
                {...(hasRowPropsInjector ? rowProps(element) : {})}
              >
                {columns.map((column, columnIndex) => {
                  const hasDataDecorator = isFunction(column.decorate);

                  return column.getValue ? (
                    <Fragment
                      key={`api-data-element-${elementKey}-column-${columnIndex}`}
                    >
                      {column.getValue(element)}
                    </Fragment>
                  ) : (
                    <td key={column.value}>
                      {hasDataDecorator
                        ? column.decorate(get(element, column.value))
                        : get(element, column.value, 'Não preenchido')}
                    </td>
                  );
                })}
                {renderActions(element, customActions)}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function renderHeader() {
    if (isFunction(header)) {
      return header();
    }

    return (
      <header>
        <h1>{header}</h1>

        <div>
          {!loading && (
            <Filters>
              {filters.map((options) => (
                <Select
                  key={options.name}
                  name={options.name}
                  placeholder={options.label}
                  options={options.data}
                  defaultValue={options.data.find((data) => {
                    return data.value === searchFormState[options.name];
                  })}
                  isClearable
                  onChange={(data) => {
                    handleInputChange({
                      fieldName: options.name,
                      fieldValue: data?.value ?? '',
                    });
                  }}
                />
              ))}
            </Filters>
          )}

          {!hideSearch && (
            <SearchForm onSubmit={handleSubmitSearch}>
              {filtersText?.map((options) => {
                return (
                  <Input
                    name={options.name}
                    placeholder={options.label}
                    key={options.name}
                    mask={options.mask}
                    value={searchFormState[options.name]}
                    onChange={(e) => {
                      let { value } = e.target;
                      const isMask = options.mask;

                      if (isMask) {
                        value = value.replace(/[^\w\s]/gi, '').trim();
                      }

                      handleInputChange({
                        fieldName: options.name,
                        fieldValue: value,
                      });
                    }}
                  />
                );
              })}
              {filtersCheck?.map((options) => {
                const params = getSearchParams();

                const value = params[options.name];
                const defaultValue = value === 'true';

                return (
                  <CheckboxContainer>
                    <Checkbox
                      name={options.name}
                      label={options.label}
                      key={options.name}
                      defaultChecked={defaultValue ?? false}
                      value={searchFormState[options.name]}
                      onChange={() => {
                        handleCheckboxChange({
                          fieldName: options.name,
                          fieldValue: !searchFormState[options.name],
                          hasDefaultValue: defaultValue,
                        });
                      }}
                    />
                  </CheckboxContainer>
                );
              })}

              {!hideSearchInput && (
                <Input
                  type="text"
                  placeholder="Digite sua busca..."
                  name="search"
                  value={searchFormState.s}
                  onChange={(e) =>
                    handleInputChange({
                      fieldName: 'search',
                      fieldValue: e.target.value,
                    })
                  }
                  unform={false}
                />
              )}

              <Button type="submit" icon={MdSearch} color="success" />
            </SearchForm>
          )}

          {backLink && (
            <Button icon={MdArrowBack} color="success" to={backLink}>
              Voltar
            </Button>
          )}

          {!hideCreate && can(createPermission || `edit_${resourceSlug}`) && (
            <Button icon={MdAdd} color="success" to={`/${resourceSlug}/new`}>
              Novo
            </Button>
          )}

          {exportRoute && can(`export_${resourceSlug}`) && (
            <Button
              icon={MdFileDownload}
              color="default"
              onClick={() => handleExport(exportRoute)}
              disabled={!apiData.data.length}
              loading={exporting}
            >
              Exportar
            </Button>
          )}
        </div>
      </header>
    );
  }

  function renderTabs() {
    if (!tabs.length) {
      return null;
    }

    return (
      <Tabs>
        {tabs.map(({ label, name, value }) => (
          <button
            type="button"
            key={`${name}-${label}`}
            onClick={() => handleTabClick(name, value)}
            data-active={
              searchFormState?.[name] === value ||
              (value === '' && !searchFormState?.[name])
            }
          >
            {label}
          </button>
        ))}
      </Tabs>
    );
  }

  useEffect(() => {
    loadData(
      Number(match.params.page || 1),
      unescape(getSearchParams() || search),
    );
  }, [match.params.page, location.search, filter, refetchData]); // eslint-disable-line

  useEffect(() => {
    const searchParams = getSearchParams();
    setSearchFormState(searchParams);
  }, []); // eslint-disable-line

  return (
    <Page>
      {renderHeader()}
      {renderTabs()}

      {(loading && renderData()) || apiData.data.length
        ? renderData()
        : emptyData()}
      <Pagination
        activePage={apiData.page}
        pageCount={apiData.pageCount}
        total={apiData.total}
        perPage={apiData.perPage}
        onChange={handlePaginate}
      />
    </Page>
  );
}

PagedList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      page: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  rowProps: PropTypes.func,
  resource: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  filtersText: PropTypes.arrayOf(PropTypes.object),
  filtersCheck: PropTypes.arrayOf(PropTypes.object),
  initialFilters: PropTypes.objectOf(PropTypes.any),
  exportRoute: PropTypes.string,
  hideCreate: PropTypes.bool,
  createPermission: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  backLink: PropTypes.string,
  hideSearch: PropTypes.bool,
  hideSearchInput: PropTypes.bool,
  hideCopyButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  customActions: PropTypes.func,
  refetchData: PropTypes.bool,
};

PagedList.defaultProps = {
  tabs: [],
  filters: [],
  filtersText: [],
  filtersCheck: [],
  initialFilters: {},
  exportRoute: null,
  hideCreate: false,
  hideSearchInput: false,
  createPermission: null,
  actions: true,
  backLink: '',
  hideCopyButton: false,
  hideEditButton: false,
  hideSearch: false,
  rowProps: null,
  customActions: () => {},
  refetchData: false,
};

export default withRouter(PagedList);
