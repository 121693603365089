/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { FaExternalLinkAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import { isBefore } from 'date-fns';

import { contentTypes } from '../Form/JourneyForm';
import { Badges } from './styles';

import api from '~/services/api';
import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';
import Button from '~/components/Button';

const CONTENT_TYPES = {
  SPECIALIZATION: 'Formação',
  COURSE: 'Curso',
  EVENT: 'Eventos',
  EXTRA: 'Conteúdo Suplementar',
};

const columns = [
  {
    label: '',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.icon_url && (
            <Thumbnail>
              <img src={element.icon_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Visível',
    value: 'is_enabled_on_catalog',
    getValue: (element) => {
      const isEnabledOnCatalog = element.is_enabled_on_catalog;
      const isReleased = isBefore(new Date(element.released_at), new Date());
      const isVisible = isEnabledOnCatalog && isReleased;
      const isVisibleForUsersWithAccess =
        !isVisible && element.is_visible_for_users_with_access;

      let Icon = <FaEyeSlash color="red" />;

      if (isVisible) {
        Icon = <FaEye />;
      }

      if (isVisibleForUsersWithAccess) {
        Icon = <FaEye color="yellow" />;
      }

      return (
        <td key={element.id} style={{ textAlign: 'center' }}>
          {Icon}
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <strong
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              {element.title}
            </strong>
            <span>{element.slug}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Tipo de conteúdo',
    value: 'content_type',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.content_type
            ? CONTENT_TYPES[element.content_type] || 'Outro'
            : '-'}
        </td>
      );
    },
  },
  {
    label: 'Jornada',
    value: 'title',
    getValue: (element) => {
      const {
        is_recording,
        is_certificable,
        has_cashback,
        has_after_access,
      } = element;

      const badges = [
        { id: uuid(), title: `Gravando`, value: is_recording },
        { id: uuid(), title: `Certificável`, value: is_certificable },
        { id: uuid(), title: `Cashback`, value: has_cashback },
        { id: uuid(), title: `Acesso estendido`, value: has_after_access },
      ];

      return (
        <td key={element.id}>
          <Badges>
            {badges.map((badge) => (
              <small key={badge.id}>
                <b>{badge.title}</b>: {badge.value ? 'Sim' : 'Não'}
              </small>
            ))}
          </Badges>
        </td>
      );
    },
  },
  {
    label: 'Catálogo',
    value: 'title',
    getValue: (element) => {
      const { is_enabled_on_catalog, is_old, is_free, is_offer } = element;

      const badges = [
        { id: uuid(), title: `Catálogo`, value: is_enabled_on_catalog },
        { id: uuid(), title: `Antigo`, value: is_old },
        { id: uuid(), title: `Gratuito`, value: is_free },
        { id: uuid(), title: `Oferta`, value: is_offer },
      ];

      return (
        <td key={element.id}>
          <Badges>
            {badges.map((badge) => (
              <small key={badge.id}>
                <b>{badge.title}</b>: {badge.value ? 'Sim' : 'Não'}
              </small>
            ))}
          </Badges>
        </td>
      );
    },
  },
  {
    label: 'Busca',
    value: 'title',
    getValue: (element) => {
      const { is_searchable, is_filterable } = element;

      const badges = [
        { id: uuid(), title: `Pesquisável`, value: is_searchable },
        { id: uuid(), title: `Filtrável`, value: is_filterable },
      ];

      return (
        <td key={element.id}>
          <Badges>
            {badges.map((badge) => (
              <small key={badge.id}>
                <b>{badge.title}</b>: {badge.value ? 'Sim' : 'Não'}
              </small>
            ))}
          </Badges>
        </td>
      );
    },
  },
];

export default function JourneysList() {
  const [types, setType] = useState([]);
  const [loading, setLoading] = useState(false);

  const filters = [
    {
      label: 'Tipo de conteúdo',
      name: 'content_type',
      data: contentTypes.map((type) => ({
        value: type.id,
        label: type.title,
      })),
    },
    {
      label: 'Tipo',
      name: 'type',
      data: types.map((type) => ({
        value: type.value,
        label: type.value,
      })),
    },
    {
      label: 'Conteúdo',
      name: 'is_old',
      data: [
        {
          value: true,
          label: 'Antigo',
        },
        {
          value: false,
          label: 'Novos',
        },
      ],
    },
  ];

  useEffect(() => {
    async function loadTeams() {
      const { data } = await api.get('/journey-types');

      setType(data);
      setLoading(false);
    }

    loadTeams();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  return (
    <PagedList
      resource="journeys"
      header="Jornadas"
      columns={columns}
      filters={filters}
      customActions={(element) => {
        const isValidContentType = element.content_type;

        let url = '';

        if (['SPECIALIZATION'].includes(isValidContentType)) {
          url = `${process.env.REACT_APP_FRONT_URL}/journey/${element.slug}`;
        }

        if (['COURSE', 'EVENT'].includes(isValidContentType)) {
          const [firstNode] = element.nodes;

          url = `${process.env.REACT_APP_FRONT_URL}/classroom/${firstNode.slug}`;
        }

        return (
          <>
            {url && (
              <a href={url} target="_blank" rel="noreferrer">
                <Button iconSize={12} icon={FaExternalLinkAlt} size="small" />
              </a>
            )}
          </>
        );
      }}
    />
  );
}
