/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Page from '~/components/Page';
import api from '~/services/api';
import { FileInput, Form, Input } from '~/components/Form';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório.'),
  description: Yup.string().required('Descrição é um campo obrigatório.'),
  filename: Yup.string().required('Imagem é um campo obrigatório.'),
});

function BadgesForm({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id: badgeId } = match.params;
  const isUpdatingPage = !!match.params.id;

  useEffect(() => {
    async function loadBadge(id) {
      try {
        setIsLoading(true);

        const { data } = await api.get(`/badges/${id}`);

        setForm(data);
      } catch (error) {
        toast.error('Não foi possível carregar o formulário.');
      } finally {
        setIsLoading(false);
      }
    }

    if (badgeId) {
      loadBadge(badgeId);
    }
  }, [badgeId]);

  async function handleSubmit(formData) {
    try {
      setIsSubmitting(true);

      await api.postOrPut('/badges', badgeId, formData);

      toast.success('Insígnia salva com sucesso!');

      history.push('/badges');
    } catch (error) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdatingPage ? 'Editar' : 'Nova'} insígnia`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/badges">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={form} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" placeholder="Título da insígnia" />
          <FileInput
            label="Imagem"
            name="filename"
            path="platform/badges"
            placeholder="Adicionar imagem"
          />
        </section>

        <section>
          <Input
            label="Descrição"
            name="description"
            placeholder="Descrição"
            multiline
          />
        </section>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

BadgesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

BadgesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default BadgesForm;
