import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: fit-content;
  gap: 3px;

  &::before {
    display: table;
    content: ' ';
  }

  input {
    background-color: transparent;
    width: 100%;
    min-height: 35px;
    padding: 2px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  label {
    font-weight: 600;
    width: fit-content;
  }

  .error {
    color: #dd5554;
    font-size: 12px;
  }
`;
