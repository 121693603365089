import { ApolloClient, InMemoryCache } from '@apollo/client';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const token =
  cookies.get('skylab_next_access_token_v4') ||
  localStorage.getItem('@Skylab:token');

export const client = new ApolloClient({
  uri: process.env.REACT_APP_PLUTO_URL,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${token}`,
    AUTH_ACCESS_TOKEN: process.env.REACT_APP_PLUTO_AUTH_ACCESS_TOKEN,
  },
});
