import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
];

function ForumList() {
  return <PagedList resource="forums" header="Fóruns" columns={columns} />;
}

export default ForumList;
