import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, forwardRef } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { FaInfoCircle } from 'react-icons/fa';

import Field from '../Field';

const Dropdown = forwardRef(
  (
    {
      name,
      label,
      required,
      multiple,
      load,
      options,
      onChange,
      disabled,
      async,
      description,
      loadDefaultValue,
      ...rest
    },
    ref,
  ) => {
    const {
      control,
      setValue,
      watch,
      formState: { errors },
    } = useFormContext();

    const [selected, setSelected] = useState(multiple ? [] : null);
    const [optionsFromAPI, setOptions] = useState([]);

    const [isLoadingOptions, setIsLoadingOptions] = useState(false);

    const hasAnyOptionsToDisplay =
      options.length > 0 || optionsFromAPI.length > 0;

    const optionsToDisplay = options.length ? options : optionsFromAPI;

    const handleChange = (value) => {
      setSelected(value);
      setValue(name, value, { shouldValidate: true });
      onChange(value);
    };

    const checkIfIsAnArrayOrObject = (value) => {
      const isObject =
        typeof value === 'object' && value !== null && value?.value;

      return Array.isArray(value) || isObject;
    };

    const getDefaultValue = useCallback(() => {
      const defaultValue = watch(name);

      if (!defaultValue) return null;

      const defaultValueIsObjectOrArray =
        checkIfIsAnArrayOrObject(defaultValue);

      if (defaultValueIsObjectOrArray) {
        return defaultValue;
      }

      const values = options.length ? options : optionsFromAPI;

      if (multiple) {
        return defaultValue.map((item) =>
          !item?.value ? values.find((option) => option.value === item) : item,
        );
      }

      return values.find(
        (option) =>
          JSON.stringify(option.value) === JSON.stringify(defaultValue),
      );
    }, [watch, name, options, optionsFromAPI, multiple]);

    useEffect(() => {
      async function fetchData() {
        if (load && async) {
          setIsLoadingOptions(() => true);

          const data = await load();

          setIsLoadingOptions(() => false);
          setOptions(data);
        }
      }
      fetchData();
    }, [load, async]);

    useEffect(() => {
      (async () => {
        if (loadDefaultValue) {
          setIsLoadingOptions(() => true);
          try {
            const defaultValue = watch(name);

            if (!defaultValue) {
              setSelected(null);
              return;
            }

            const data = await loadDefaultValue(defaultValue, optionsToDisplay);

            setSelected(Array.isArray(data) ? data : [data]);
          } catch {
            toast.error(`Erro ao carregar valor inicial de: ${label}`);
          } finally {
            setIsLoadingOptions(() => false);
          }
        } else if (!selected?.length) {
          setSelected(getDefaultValue());
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      options,
      multiple,
      optionsFromAPI,
      loadDefaultValue,
      getDefaultValue,
      watch,
      name,
      label,
    ]);

    useEffect(() => {
      setSelected(getDefaultValue());
    }, [getDefaultValue]);

    const Component = async ? AsyncSelect : Select;

    return (
      <Field>
        <>
          {label && (
            <label
              htmlFor={name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {label}
              {required && <span style={{ color: 'red' }}>*</span>}

              {description && (
                <FaInfoCircle
                  size={12}
                  data-tooltip-id="tooltip"
                  data-tooltip-content={description}
                />
              )}
            </label>
          )}
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Component
                {...rest}
                {...field}
                ref={ref || field.ref || null}
                instanceId={`${name}-select`}
                options={optionsToDisplay}
                isMulti={multiple}
                {...(async && {
                  loadOptions: load,
                  cacheOptions: true,
                  defaultOptions: true,
                })}
                value={hasAnyOptionsToDisplay ? selected : null}
                onChange={handleChange}
                isDisabled={disabled || isLoadingOptions}
              />
            )}
          />

          {errors[name] && (
            <span className="error">{errors[name].message ?? errors[name]?.value?.message}</span>
          )}
        </>
      </Field>
    );
  },
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  load: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  async: PropTypes.bool,
  loadDefaultValue: PropTypes.func,
  description: PropTypes.string,
};

Dropdown.defaultProps = {
  multiple: false,
  options: [],
  load: null,
  required: false,
  onChange: () => { },
  disabled: false,
  async: false,
  description: null,
  loadDefaultValue: null,
};

export default Dropdown;
