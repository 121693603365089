import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import TeamsForm from './TeamForm';

import Button from '~/components/Button';
import { Form, Input, Datepicker } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

const schema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  days: Yup.string().required(),
  end_date: Yup.string().nullable(),
  context: Yup.string().required(),
  teams: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      deleted: Yup.string(),
    }),
  ),
});

export default function TrialsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [trial, setTrial] = useState({
    title: '',
    description: '',
    days: 7,
    end_date: null,
    context: '',
    teams: [],
  });

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/trials', id, {
        ...data,
        teams: data.teams.map((team) => {
          if (team.deleted === 'false') {
            delete team.deleted;
          }

          return team;
        }),
      });

      toast.success('Trial salvo com sucesso!');
      history.push('/trials');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadTrial(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/trials/${id}`);

        setTrial(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do trial.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadTrial(id);
    }
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Trials`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/trials">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={trial} onSubmit={handleSubmit}>
        <section>
          <Input
            name="title"
            placeholder="Título do Trial"
            label="Título do trial"
          />
        </section>

        <section>
          <Input name="description" placeholder="Descrição" label="Descrição" />

          <Input
            name="days"
            placeholder="Quantos dias de acesso o usuário vai receber."
            label="Dias de acesso"
          />
        </section>

        <section>
          <Datepicker
            description="Data final para resgate do trial. <b>Caso não seja informado, o trial não terá data de expiração.</br>"
            label="Data de termino"
            name="end_date"
          />

          <Input
            name="context"
            placeholder="Explique o contexto deste trial"
            label="Contexto"
          />
        </section>

        <fieldset>
          <legend>Times que dará acesso.</legend>

          <TeamsForm trial={trial} />
        </fieldset>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

TrialsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
