/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { BadgesListHeader } from './Header';

import PagedList from '~/components/PagedList';
import { Actions } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Descrição',
    value: 'description',
  },
];

function BadgesList({ history }) {
  return (
    <PagedList
      resource="badges"
      header={BadgesListHeader}
      columns={columns}
      actions={(element) => {
        return (
          <td width={120}>
            <Actions
              mainAction={() => history.push(`/badges/edit/${element.id}`)}
            />
            <ClipboardButton title="Copiar ID" value={element.id} />
          </td>
        );
      }}
    />
  );
}

export default BadgesList;

BadgesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
