import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '~/lib/utils';

function Error({ hasError, message, className }) {
  return hasError ? (
    <span className={cn('error', className)}>{message}</span>
  ) : null;
}

Error.propTypes = {
  hasError: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
};

Error.defaultProps = {
  hasError: false,
  message: '',
  className: '',
};

export default Error;
