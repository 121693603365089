import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  padding: 10px;
  cursor: ${({ deleted }) => (deleted ? 'default' : 'move')};
  position: relative;

  > svg {
    margin-right: 10px;
  }

  span {
    color: black;
  }

  div {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    > a {
      color: black;
      margin-right: 8px;
    }

    svg {
      cursor: pointer;
    }
  }
`;

export const Deleted = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(204, 204, 204, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
