import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 24px !important;
  font-weight: 700;

  color: #302b3e;
`;

export const Button = styled.button`
  overflow: hidden;
  width: 38px;
  max-height: 38px;
  background: ${({ disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.025)' : 'rgba(0, 0, 0, 0.1)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;
  margin-top: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: ${({ disabled }) => (disabled ? '#ccc' : '#302b3ecc')};
  }
`;
