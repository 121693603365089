import styled from 'styled-components';

export const HtmlWrapper = styled.div`
  background: #f0f0f0;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
`;

export const HtmlLabel = styled.label`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #888;
  margin-bottom: 10px;
`;

export const HtmlTextArea = styled.textarea`
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 16px;
  resize: vertical;
  min-height: 100%;
`;

export const PreviewWrapper = styled.div`
  background: #f9f9f9;
  padding: 16px;
  border-radius: 5px;
  overflow: auto;
  max-height: 500px;
  max-width: 1000px;
`;

export const PreviewLabel = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #888;
`;
