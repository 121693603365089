import styled from 'styled-components';

export const ListArea = styled.div`
  margin-top: 15px;
`;

export const Error = styled.span`
  color: #dd5554;
  font-size: 12px;
  margin-top: 2px;
`;
