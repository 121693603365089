import { detectContentBodyFormat } from '../utils';
import { transformSegmentParamValues } from './segment.utils';

function rebuildSegmentParams(segmentParams) {
  if (!segmentParams) {
    return;
  }

  if (segmentParams?.journey_slug) {
    segmentParams.journey_slug = {
      value: segmentParams.journey_slug,
    };
  }

  if (segmentParams?.team_identifier) {
    segmentParams.team_identifier = {
      value: segmentParams.team_identifier,
    };
  }

  return segmentParams;
}

export const notificationFormMapper = {
  mapToHttp: (data) => {
    const payload = {
      is_drafting: Boolean(data?.is_drafting),
      title: data?.title,
      category_id: data?.category?.value,
      scheduled_at: data?.scheduledAt || null,
      contents: [
        {
          channel_type: 'PLATFORM',
          subject: data?.subject,
          type: detectContentBodyFormat(data?.body || ''),
          body: data?.body,
          links_to: data?.link,
          metadata: {
            banner_url: data?.coverImage,
            buttons: data?.hasCtaButton
              ? [{ text: data.ctaLabel, link: data.ctaLink }]
              : null,
          },
        },
      ],
      shipping_type: 'COMMON',
      segments: data?.segment?.id
        ? [
            {
              id: data?.segment?.id,
              parameters: data?.params
                ? transformSegmentParamValues(data?.segment, data?.params)
                : {},
              file: data?.params?.emails,
              _destroy: data.is_drafting,
            },
          ]
        : [],
    };

    // Filter out PLATFORM content and append to payload contents
    const filteredContents =
      data?.contents?.filter(
        ({ channel_type }) => channel_type !== 'PLATFORM',
      ) || [];

    filteredContents.forEach((content) => {
      payload.contents.push({
        channel_type: content.channel_type,
        subject: content.is_customized ? content.subject : data?.subject,
        type: detectContentBodyFormat(data?.body || ''),
        links_to: data?.link,
      });
    });

    return payload;
  },

  mapToDefaultValues: (data) => {
    const defaultContent =
      data?.contents?.find(({ channel }) => channel?.type === 'PLATFORM') || {};
    const segment = data?.segmentConfig?.segment || {};

    const defaultValues = {
      id: data?.id,
      is_published: data?.is_published,
      is_drafting: data?.is_drafting,

      category: {
        value: data?.category?.id,
        label: data?.category?.title,
        channels: data?.category?.channels,
      },
      title: data?.title || '',
      link: defaultContent?.links_to || '',
      subject: defaultContent?.subject || '',

      publishPage: !!defaultContent?.body,
      coverImage: defaultContent?.metadata?.banner_url,
      body: defaultContent?.body || '',
      hasCtaButton: !!defaultContent?.metadata?.buttons?.[0],
      ctaLabel: defaultContent?.metadata?.buttons?.[0]?.text || '',
      ctaLink: defaultContent?.metadata?.buttons?.[0]?.link || '',

      scheduledAt: data?.scheduled_at && new Date(data.scheduled_at),

      segment: segment.title
        ? {
            ...segment,
            value: data?.segmentConfig?.segment_id || undefined,
            label: data?.segmentConfig?.segment?.title || undefined,
          }
        : undefined,
      params: {
        ...rebuildSegmentParams(data?.segmentConfig?.parameters),
        ...(data?.segmentConfig?.file && { emails: data.segmentConfig.file }),
      },
      contents: data?.contents?.map((content) => ({
        id: content?.id || '',
        title: content?.title || '',
        subject: content?.subject || '',
        channel_type: content?.channel?.type || '',
        is_customized: content?.subject !== defaultContent?.subject,
      })),
    };

    return defaultValues;
  },
};
