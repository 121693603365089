import React from 'react';

import { Container } from './styles';

function Dashboard() {
  return (
    <Container>
      <h1>Dashboard</h1>
    </Container>
  );
}

export default Dashboard;
