import React, { useEffect, useState } from 'react';

import { FaFlag } from 'react-icons/fa';

import { Badges } from './styles';

import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Contexto',
    value: 'context',
    getValue: (element) => {
      return <td key={element.context.id}>{element.context.title}</td>;
    },
  },
  {
    label: 'Canais',
    value: 'channels',
    getValue: (element) => {
      const { channels } = element;
      return (
        <td key={element.id}>
          <Badges>
            {channels.map((channel) => (
              <small key={channel.id}>
                <b>{channel.title}</b>
              </small>
            ))}
          </Badges>
        </td>
      );
    },
  },
  {
    label: 'Criticidade',
    getValue: (element) => {
      const categoryType = {
        MANDATORY: {
          color: '#DD5554',
          label: 'Obrigatório',
        },
        OPTIONAL: {
          color: '#4FCDA5',
          label: 'Opcional',
        },
      };

      const { color, label } = categoryType[element.type] || {};
      return (
        <td key={element.id}>
          <FaFlag size={15} color={color} aria-label={label} />
        </td>
      );
    },
  },
];

export default function NotificationCategoriesList() {
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [channels, setChannels] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadCategoryGroups() {
      const { data: response } = await api.get(
        '/notifications/categories/groups',
        {
          params: { per_page: 999 },
        },
      );

      setCategoryGroups(response.data);
      setLoading(false);
    }

    async function loadChannels() {
      const { data: response } = await api.get('/notifications/channels', {
        params: { per_page: 999 },
      });

      setChannels(response.data);
      setLoading(false);
    }

    loadCategoryGroups();
    loadChannels();
  }, []);

  const filters = [
    {
      label: 'Contexto',
      name: 'group_id',
      data: categoryGroups.map((group) => ({
        value: group.id,
        label: group.title,
      })),
    },

    {
      label: 'Canais',
      name: 'channel_id',
      data: channels.map((channel) => ({
        value: channel.id,
        label: channel.title,
      })),
    },
  ];

  if (loading) {
    return 'Carregando...';
  }

  return (
    <PagedList
      resource="notifications/categories"
      header="Categorias"
      hideSearchInput
      columns={columns}
      createPermission="create_categories"
      filtersText={[
        {
          name: 'title',
          label: 'Título',
        },
      ]}
      filtersCheck={[
        {
          name: 'is_mandatory_category',
          label: 'Categoria crítica',
        },
      ]}
      filters={filters}
    />
  );
}
