import styled from 'styled-components';

export const Link = styled.a`
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 28px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: #333;
  }
`;

export const DisabledLink = styled.div`
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 28px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;

  opacity: 0.3;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: #333;
  }
`;
