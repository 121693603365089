import * as Yup from 'yup';

const schema = Yup.object().shape({
  status: Yup.string().required('Status é um campo obrigatório'),
  description: Yup.string().when('status', {
    is: 'changes-requested',
    then: Yup.string().required('Review é um campo obrigatório.'),
  }),
});

export default schema;
