import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdAssignment, MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Checkbox, Form, Input, SortableList } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  tasks: Yup.array().of(Yup.string()).required('Campo obrigatório'),
  is_final: Yup.boolean().required('Campo obrigatório'),
  paid_user_only: Yup.boolean().required('Campo obrigatório'),
});

export default function LevelsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState({
    title: '',
    description: '',
    tasks: [],
    is_final: false,
    paid_user_only: false,
  });
  const { id } = match.params;

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);
      await api.postOrPut('/guide/levels', id, {
        ...data,
        tasks: data.tasks.map((taskId, order) => ({
          id: taskId,
          order,
        })),
      });

      toast.success('Fase salva com sucesso!');
      history.push('/guide/levels');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function loadTasks() {
    const { data: response } = await api.get('/guide/tasks', {
      params: {
        per_page: 999,
      },
    });
    const { data } = response;

    return data;
  }

  useEffect(() => {
    async function loadLevel(levelId) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/guide/levels/${levelId}`);

        setLevel(response);
      } catch (err) {
        toast.error('Erro ao buscar dados da fase.');
      } finally {
        setIsLoading(false);
      }
    }

    if (id) {
      loadLevel(id);
    }
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} Fase`}</h1>

        <div>
          {isUpdating && (
            <Button
              icon={MdAssignment}
              color="success"
              to={`/guide/tasks/1/?level_id=${id}`}
            >
              Tarefas
            </Button>
          )}
          <Button icon={MdClose} color="cancel" to="/guide/levels">
            Cancelar
          </Button>
          <Button form="level-form" type="submit" loading={isSubmitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="level-form"
        schema={schema}
        initialData={level}
        onSubmit={handleSubmit}
      >
        <section>
          <Input name="title" label={getLabel('Título', '', true)} />
        </section>

        <section style={{ marginBottom: '.75rem' }}>
          <Checkbox
            name="paid_user_only"
            label={getLabel(
              'Somente usuários pagantes',
              'Selecione caso o objetivo será mostrado somente para usuários B2C',
              false,
            )}
          />

          <Checkbox
            name="is_final"
            label={getLabel(
              'Nível Final',
              'Selecione se o nível for o último para concluir o objetivo',
              false,
            )}
          />
        </section>

        <section>
          <Input
            multiline
            name="description"
            label={getLabel(
              'Descrição',
              'Descreva o contexto do objetivo',
              false,
            )}
          />
        </section>

        <section style={{ marginTop: 15 }}>
          <SortableList
            label={getLabel('Tarefas', '', true)}
            name="tasks"
            resource="tasks"
            load={loadTasks}
          />
        </section>
      </Form>
    </Page>
  );
}

LevelsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
