import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose, MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import LessonHistoryForm from './LessonHistoryForm';

import Button from '~/components/Button';
import { Form, Check } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { tagFormsSchema } from '~/components/TagForms';

const schema = Yup.object().shape({
  type: Yup.string(),
  history: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string().when('deleted', {
          is: false,
          then: Yup.string().required('Título é um campo obrigatório.'),
        }),
        icon: Yup.string(),
        tags: Yup.string(),
        resource: Yup.string().when(['deleted', 'type'], {
          is: (deleted, type) => deleted === 'false' && type === 'video',
          then: Yup.string().required('Recurso é um campo obrigatório.'),
        }),
        signal_language_video_id: Yup.string(),
        downloads: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().when('deleted', {
              is: (deleted) => deleted !== 'true',
              then: Yup.string().required('Título é um campo obrigatório.'),
            }),
            file: Yup.string().when('deleted', {
              is: (deleted) => deleted !== 'true',
              then: Yup.string().required('Título é um campo obrigatório.'),
            }),
            deleted: Yup.string(),
          }),
        ),
        link: Yup.string().when(['deleted', 'type'], {
          is: (deleted, type) => deleted === 'false' && type === 'link',
          then: Yup.string().required('Link é um campo obrigatório.'),
        }),
        duration: Yup.string(),
        description: Yup.string(),
        platform: Yup.string(),
        release_at: Yup.string(),
        deleted: Yup.string(),
        challenge_id: Yup.string(),
        self_evaluation_step_id: Yup.string(),
        author_id: Yup.string(),
        is_free: Yup.boolean(),
        ...tagFormsSchema,
      }),
    )
    .test('not-contains-at-least', null, (arr) => {
      const validItems =
        (arr &&
          arr.filter((item) => !item.delete || item.deleted === 'false')
            .length) ||
        0;

      if (validItems >= 1) {
        return true;
      }

      return new Yup.ValidationError(
        'A aula precisa ter ao menos uma versão.',
        null,
        'type',
      );
    }),
});

function LessonsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [lesson, setLesson] = useState({
    type: 'video',
    history: [
      {
        platform: 'panda',
        downloads: [],
        release_at: new Date(),
        author_id: null,
        is_free: false,
      },
    ],
  });
  const isUpdating = !!match.params.id;

  useEffect(() => {
    async function loadLesson(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/lessons/${id}`, {
          params: { tags: false },
        });

        const { type: lessonType } = response;

        setLesson({
          ...response,
          type: lessonType,
        });
      } catch (err) {
        toast.error('Erro ao buscar dados da aula.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadLesson(id);
    }
  }, [match.params]);

  function addNewHistory() {
    setLesson({
      ...lesson,
      history: [
        ...lesson.history,
        {
          platform: { value: 'vimeo', label: 'Vimeo' },
          downloads: [],
          release_at: new Date(),
          deleted: null,
        },
      ],
    });
  }

  function handleDeleteHistory(index) {
    setLesson({
      ...lesson,
      history: lesson.history.map((item, itemIndex) =>
        itemIndex === index
          ? { ...item, deleted: item.deleted === 'true' ? 'false' : 'true' }
          : item,
      ),
    });
  }

  function addDownload(index) {
    return () =>
      setLesson({
        ...lesson,
        history: lesson.history.map((item, itemIndex) =>
          itemIndex === index
            ? { ...item, downloads: [...item.downloads, {}] }
            : item,
        ),
      });
  }

  function removeDownload(index) {
    return (downloadIndex) =>
      setLesson({
        ...lesson,
        history: lesson.history.map((item, itemIndex) =>
          itemIndex === index
            ? {
                ...item,
                downloads: item.downloads.map((download, i) =>
                  downloadIndex === i
                    ? { ...download, deleted: true }
                    : download,
                ),
              }
            : item,
        ),
      });
  }

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      const formData = {
        ...data,
        history: data.history.map((item) => ({
          ...item,
          author_id: item?.author_id?.length ? item.author_id : null,
          duration: item.duration || 0,
        })),
      };

      await api.postOrPut('/lessons', id, formData);

      toast.success('Aula salva com sucesso!');
      history.push('/lessons');
    } catch (err) {
      toast.error('Algo deu errado.');
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} aula`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/lessons">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={lesson} onSubmit={handleSubmit}>
        <section>
          <Check
            label="Tipo"
            name="type"
            options={[
              { value: 'link', label: 'Link' },
              { value: 'material', label: 'Material' },
              { value: 'video', label: 'Vídeo' },
              { value: 'challenge', label: 'Desafio' },
              { value: 'navigation', label: 'Navigation' },
              {
                value: 'international-career',
                label: 'Carreira Internacional',
              },
            ]}
            onClick={(option) => {
              setLesson({
                type: option.value,
                history: lesson.history,
              });
            }}
          />
        </section>

        <fieldset>
          <legend>Histórico da aula</legend>

          {lesson.history.length ? (
            <ul>
              {lesson.history.map((lessonHistory, index) => {
                return (
                  <LessonHistoryForm
                    key={lessonHistory.id ? lessonHistory.id : index}
                    index={index}
                    type={lesson.type}
                    lessonHistory={lessonHistory}
                    handleDelete={handleDeleteHistory}
                    addDownload={addDownload(index)}
                    removeDownload={removeDownload(index)}
                  />
                );
              })}
            </ul>
          ) : (
            <small>Nenhum versão da aula</small>
          )}

          <footer>
            <Button icon={MdAdd} onClick={addNewHistory} type="button">
              Nova versão
            </Button>
          </footer>
        </fieldset>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

LessonsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default LessonsForm;
