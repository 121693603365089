/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { MdCheckCircle, MdDelete, MdEdit } from 'react-icons/md';

import { Actions, Container } from './styles';

import Item from '~/components/Form/SortableList/Item';
import Field from '~/components/Form/Field';

export function List({ title, data, disabled, onChange, onItemClick }) {
  const [, drop] = useDrop({ accept: 'card' });

  const findItem = (id) => {
    const item = data.find((d) => d.id === id || d.tempId === id);

    return {
      item,
      index: data.indexOf(item),
    };
  };

  const moveItem = (id, atIndex) => {
    const { item, index } = findItem(id);

    const newData = update(data, {
      $splice: [
        [index, 1],
        [atIndex, 0, item],
      ],
    });

    onChange(newData);
  };

  const removeItem = (id) => {
    const isNewItem = !findItem(id).item.id;

    if (isNewItem) {
      onChange(data.filter((d) => d.tempId !== id));
      return;
    }

    const newData = data.map((item) => {
      return {
        ...item,
        deleted: item.id === id ? !item.deleted : item.deleted,
      };
    });

    onChange(newData);
  };

  function renderActions(item) {
    return (
      <Actions>
        {item.is_correct && (
          <MdCheckCircle
            id="correct-svg"
            size={17}
            color="#4FCDA5"
            title="Resposta correta"
          />
        )}
        <MdEdit
          title="Editar"
          onClick={() => onItemClick && onItemClick(item)}
          size={17}
        />
        <MdDelete
          title="Excluir (se for um novo item, não será possível restaurar)"
          onClick={() => removeItem(item.id || item.tempId)}
          size={17}
        />
      </Actions>
    );
  }

  return (
    <Field disabled={disabled}>
      <Container ref={drop} disabled={disabled}>
        <label>{title}</label>

        {data.map((item) => (
          <Item
            id={item.id || item.tempId}
            text={item.title.split('\n')[0]}
            moveItem={moveItem}
            findItem={findItem}
            removeItem={removeItem}
            deleted={!!item.deleted}
            renderActions={() => renderActions(item)}
          />
        ))}
      </Container>
    </Field>
  );
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func,
};

List.defaultProps = {
  disabled: false,
  onItemClick: null,
};
