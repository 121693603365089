/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { format } from 'date-fns';
import { Calendar, Edit } from 'lucide-react';
import { FaInfoCircle } from 'react-icons/fa';

import { useNotificationForm } from '../notifications-form.hook';
import { SegmentSection } from '../components/SegmentSection';

import { Alert } from '~/components/ui/alert';
import { Container } from '~/components/HookForm/Form/styles';
import { Datepicker } from '~/components/HookForm';
import Field from '~/components/HookForm/Field';

const buildFormSchema = (schema) =>
  Yup.object().shape({
    scheduledAt: Yup.string().nullable(),
    ...schema,
  });

export default function ShippingStep() {
  const formRef = useRef(null);

  const [segmentSchema, setSegmentSchema] = useState({});

  const { setFormRef, notification, setNotification, onStepSubmit } =
    useNotificationForm();

  const form = useForm({
    resolver: yupResolver(buildFormSchema(segmentSchema)),
    defaultValues: notification,
  });

  const scheduledAt = form.watch('scheduledAt');

  useEffect(() => {
    if (formRef.current) {
      setFormRef(formRef);
    }
  }, [formRef, setFormRef]);

  useEffect(() => {
    const subscription = form.watch(
      debounce((formValues) => {
        if (!isEqual(formValues, notification)) {
          setNotification(formValues);
        }
      }, 100),
    );

    return () => subscription.unsubscribe();
  }, [form, notification, setNotification]);

  return (
    <>
      <Alert
        icon={() => <p className="text-[#7159C1] font-bold -mr-1">3.</p>}
        title="Configuração de Envio"
        description="Defina como e para quem a mensagem será enviada."
      />

      <Container className="m-0">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onStepSubmit)}
            className="flex flex-col gap-5"
            ref={formRef}
          >
            <fieldset>
              <legend className="uppercase bg-[#7159c1]">
                Configurações do disparo
              </legend>

              <Field>
                <label className="gap-1">
                  Data de envio
                  <FaInfoCircle
                    size={12}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Agende o disparo da notificação"
                  />
                </label>

                <div className="flex items-center gap-3 border border-gray-200 bg-gray-100 p-3 rounded text-gray-500">
                  <Calendar size={16} />

                  <span className="inline-flex gap-1 items-center text-sm font-bold flex-1">
                    {!scheduledAt ? (
                      <>
                        Envio imediato
                        <FaInfoCircle
                          size={12}
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Envia a notificação imediatamente"
                        />
                      </>
                    ) : (
                      format(scheduledAt, 'DD/MM/YYYY HH:mm')
                    )}
                  </span>

                  <div
                    className={`${
                      scheduledAt ? 'w-24' : 'w-16'
                    } ${String.raw`[&_.react-datepicker\_\_close-icon]:after:bg-[#7159c1] [&_.react-datepicker\_\_close-icon]:after:content-["\00d7"]`}`}
                  >
                    <Datepicker
                      showTimeSelect
                      name="scheduledAt"
                      timeIntervals={10}
                      isClearable
                      minDate={new Date()}
                      customInput={
                        <button
                          type="button"
                          className="inline-flex gap-2 text-sm font-bold items-center transition-color hover:text-gray-600"
                        >
                          <Edit size={16} />
                          Editar
                        </button>
                      }
                      popperPlacement="bottom-end"
                    />
                  </div>
                </div>
              </Field>
            </fieldset>

            <SegmentSection setSchema={setSegmentSchema} />
          </form>
        </FormProvider>
      </Container>
    </>
  );
}
