import React, { useMemo } from 'react';

import { useEnrollmentDocumentsForm } from '../enrollment-documents-form.hook';
import { isDocumentReviewed } from '../document.utils';
import { DocumentStatusValues } from '../../../constants';

import Button from '~/components/Button';

export function StepNavigation() {
  const {
    form,
    isSubmitting,
    totalDocuments,
    currentDocument,
    currentDocumentIndex,
    goToPreviousDocument,
    goToNextDocument,
    onConfirm,
  } = useEnrollmentDocumentsForm();

  const isUnderReview = !isDocumentReviewed(currentDocument);
  const isLastDocument = currentDocumentIndex + 1 === totalDocuments;

  const watchedStatus = form.watch(`${currentDocumentIndex}.status`);
  const watchedComment = form.watch(`${currentDocumentIndex}.comment`);

  const isConfirmDisabled = useMemo(() => {
    return watchedStatus === DocumentStatusValues.REJECTED
      ? !watchedComment
      : !watchedStatus;
  }, [watchedComment, watchedStatus]);

  const progressPercentage = useMemo(() => {
    return ((currentDocumentIndex + 1) / totalDocuments) * 100;
  }, [currentDocumentIndex, totalDocuments]);

  return (
    <div className="flex items-center justify-between mt-10 py-8 border-t border-t-gray-200">
      <div className="flex items-center gap-3">
        <div className="relative w-28 h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute left-0 top-0 h-full bg-[#7159C1] transition-all ease-out"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>

        <span className="text-sm text-gray-500">
          <strong className="text-gray-700">{currentDocumentIndex + 1}</strong>
          {` / ${totalDocuments}`}
        </span>
      </div>

      <nav className="flex items-center gap-2">
        {isUnderReview && (
          <>
            <Button
              type="submit"
              onClick={onConfirm}
              disabled={isConfirmDisabled}
              outline
            >
              Confirmar e sair
            </Button>

            <div className="w-px h-9 bg-[#B4B4B4]" />
          </>
        )}

        <Button
          disabled={currentDocumentIndex === 0}
          onClick={goToPreviousDocument}
          color="cancel"
        >
          Anterior
        </Button>

        <Button
          type="submit"
          onClick={goToNextDocument}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {isLastDocument ? 'Finalizar' : 'Próximo'}
        </Button>
      </nav>
    </div>
  );
}
