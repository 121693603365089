import format from 'date-fns/format';
import capitalize from 'lodash/capitalize';
import React from 'react';

import PagedList from '~/components/PagedList';

import platforms from '../platforms.json';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Plataforma',
    value: 'platform',
    getValue: (element) => (
      <td key={`platform-key-${element.id}`}>{capitalize(element.platform)}</td>
    ),
  },
  {
    label: 'Data de criação',
    value: 'created_at',
    getValue: (element) => (
      <td key={`created_at-key-${element.id}`}>
        {format(element.created_at, 'DD/MM/YYYY')}
      </td>
    ),
  },
];

const filters = [
  {
    label: 'Plataforma',
    name: 'platform',
    data: platforms,
  },
];

function ChangelogsList() {
  return (
    <PagedList
      resource="changelogs"
      header="Changelogs"
      columns={columns}
      filters={filters}
    />
  );
}

export default ChangelogsList;
