import api from '~/services/api';

export const revalidateJourneyCache = () => {
  return api.post(
    `${process.env.REACT_APP_FRONT_URL}/api/cache/revalidate`,
    {
      tags: ['GetJourneyDetailsBySlug', 'GetJourneyContentBySlug'],
    },
    {
      headers: {
        'X-Secret': process.env.REACT_APP_FRONT_REVALIDATE_SECRET,
      },
    },
  );
};
