import styled from 'styled-components';

export const Container = styled.div`
  > div,
  > div > div {
    display: flex;
    flex: 1;
  }

  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: '#FFF';
    border-color: ${({ withError }) => (withError ? '#DD5554' : '#CCC')};
    padding: 0 12px;
    height: 36px;
    font-size: 14px;
    color: '#302B3E';
  }
`;
