/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import { useField } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { FaInfoCircle } from 'react-icons/fa';

import Field from '../Field';
import { Container, Loading } from './styles';

import api from '~/services/api';

function FileInput({
  name,
  label,
  placeholder,
  type,
  path,
  responseKey,
  description,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [file, setFile] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'dataset.file',
        clearValue: (fileRef) => {
          fileRef.value = '';
          setFile('');
        },
      });
    }
  }, [ref.current, fieldName]);

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
  };

  function handleProgress(progressEvent) {
    const progressEventPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setProgressPercent(progressEventPercent);
  }

  async function handleChange(event) {
    try {
      const data = new FormData();

      setLoading(true);
      setProgressPercent(0);

      data.append('type', type);
      data.append('file', event.target.files[0]);

      if (path) data.append('path', path);

      const response = await api.post('files/upload', data, {
        onUploadProgress: handleProgress,
      });

      setFile(response.data[responseKey || 'name']);
    } catch (err) {
      toast.error('Erro ao enviar arquivo para upload.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Field>
      <>
        {label && (
          <label
            htmlFor={fieldName}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}
        <Container progress={progressPercent} error={error}>
          {loading ? <Loading /> : <span>{file || placeholder}</span>}

          <input
            {...props}
            type="file"
            onChange={handleChange}
            data-file={file}
          />
        </Container>
        {error && <span>{error}</span>}
      </>
    </Field>
  );
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  responseKey: PropTypes.string,
  description: PropTypes.string,
};

FileInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'download',
  path: '',
  responseKey: '',
  description: null,
};

export default FileInput;
