/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import SweetAlert2 from 'react-sweetalert2';

import { ApproveRedeemModal } from './ApproveRedeemModal';

import Button from '~/components/Button';
import { Datepicker, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import mgmApi from '~/services/mgm.api';
import { getLabel } from '~/utils';

function RedeemsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalState, setModalState] = useState(null);
  const [redeem, setRedeem] = useState({
    metadata: {},
    points: 0,
    status: '',
    statusUpdatedAt: '',
  });

  const [user, setUser] = useState({
    name: '',
    atlasUserId: '',
  });

  const [rewardType, setRewardType] = useState({
    type: '',
    typeMetadata: {},
  });

  const [reward, setReward] = useState({
    title: '',
  });

  useEffect(() => {
    async function loadRedeems(id) {
      try {
        setLoading(true);

        const { data: response } = await mgmApi.get(`/admin/redeems/${id}`);

        setRedeem({
          metadata: response.redeem.metadata,
          points: response.redeem.points,
          status: response.redeem.status,
          statusUpdatedAt: response.redeem.statusUpdatedAt,
          createdAt: response.redeem.createdAt,
        });

        setUser({
          name: response.user.name,
          atlasUserId: response.user.atlasUserId,
        });

        setReward({
          title: response.reward.title,
        });

        setRewardType({
          type: response.rewardType.type,
          typeMetadata: response.rewardType.metadata,
        });
      } catch (err) {
        toast.error('Erro ao buscar dados do.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadRedeems(id);
    }
  }, [match.params]);

  const redeemHasMetadata = Object.keys(redeem.metadata).length > 0;

  async function handleCancelRedeem() {
    try {
      setSubmitting(true);

      await mgmApi.patch(`/admin/redeems/${match.params.id}/cancel`);

      toast.success('Resgate cancelado com sucesso');

      history.push('/redeems');
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  }

  async function handleCompleteRedeem() {
    try {
      setSubmitting(true);

      await mgmApi.patch(`/admin/redeems/${match.params.id}/complete`);

      toast.success('Resgate completado com sucesso');

      history.push('/redeems');
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>Resgate</h1>
      </header>

      <Form
        initialData={{
          ...user,
          ...reward,
          ...rewardType,
          ...redeem,
        }}
      >
        <section>
          <Input
            label={getLabel('Título', 'O título do prêmio resgatado')}
            name="title"
            viewOnly
            maxLength="60"
          />

          <Input
            type="number"
            name="points"
            label={getLabel('Pontos', 'Custo em pontos total.')}
            readOnly
          />
        </section>

        <section>
          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Data de resgate',
              'A data que o usuário solicitou o resgate',
            )}
            name="createdAt"
            showTimeSelect
            disabled
          />
        </section>

        <section>
          <Input
            label={getLabel('Status', 'Status do pedido')}
            name="status"
            viewOnly
            maxLength="60"
          />
          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Última mudança de status',
              'Data da última mudança de status do resgate',
            )}
            name="statusUpdatedAt"
            showTimeSelect
            disabled
          />
        </section>
        <section className="flex flex-row items-end">
          <Input
            label={getLabel('Nome do usuário', 'Nome do usuário')}
            name="name"
            viewOnly
            maxLength="60"
          />

          <div className="mb-4">
            <Button type="button" to={`/users/edit/${user.atlasUserId}`}>
              Ver usuário
            </Button>
          </div>
        </section>
        {redeemHasMetadata && (
          <h2 className="font-bold my-2 text-lg">Campos adicionais</h2>
        )}
        <section>
          {redeemHasMetadata && (
            <>
              {Object.entries(redeem.metadata).map(([key, value]) => (
                <Input
                  key={key}
                  label={key}
                  name={key}
                  viewOnly
                  value={value}
                />
              ))}
            </>
          )}
        </section>
      </Form>
      <section className="flex justify-end items-center flex-row gap-2.5">
        <Button color="cancel" to="/redeems">
          Cancelar
        </Button>

        {redeem.status === 'PENDING' && (
          <Button
            type="button"
            color="success"
            onClick={() => setModalState('approve')}
            loading={submitting}
            disabled={submitting}
          >
            Aprovar resgate
          </Button>
        )}
        {redeem.status === 'PENDING' && (
          <Button
            color="danger"
            type="button"
            loading={submitting}
            disabled={submitting}
            onClick={() => setModalState('cancel')}
          >
            Cancelar resgate
          </Button>
        )}
        {redeem.status === 'APPROVED' && (
          <Button
            onClick={() => setModalState('complete')}
            type="button"
            loading={submitting}
            disabled={submitting}
          >
            Completar resgate
          </Button>
        )}
      </section>

      <SweetAlert2
        show={modalState === 'cancel'}
        title="Tem certeza que deseja cancelar este resgate?"
        html="Ao cancelar o resgate o usuário será notificado e você não poderá desfazer essa ação. Deseja continuar?"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={() => {
          handleCancelRedeem();
        }}
        onResolve={() => {
          setModalState(null);
        }}
      />

      <SweetAlert2
        show={modalState === 'complete'}
        title="Tem certeza que deseja completar este resgate?"
        html="Ao completar o resgate você confirma que o usuário recebeu o prêmio. Essa ação não poderá ser desfeita. Deseja continuar?"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={() => {
          handleCompleteRedeem();
        }}
        onResolve={() => {
          setModalState(null);
        }}
      />

      <ApproveRedeemModal
        show={modalState === 'approve'}
        setModalState={setModalState}
        redeemId={match.params.id}
        metadata={redeem.metadata}
        typeMetadata={rewardType?.typeMetadata}
      />
    </Page>
  );
}

RedeemsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RedeemsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default RedeemsForm;
