import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Scope } from '@rocketseat/unform';
import { MdDelete, MdUndo } from 'react-icons/md';

import { Container, Deleted, Downloads, DownloadItem } from './styles';
import { client } from '@shared/skylab/graphql/self-evaluation/client';
import { STEPS } from '@shared/skylab/graphql/self-evaluation/queries';

import api from '~/services/api';
import Button from '~/components/Button';
import {
  Input,
  Datepicker,
  Check,
  FileInput,
  Dropdown,
} from '~/components/Form';
import { TagForms } from '~/components/TagForms';

function LessonHistoryForm({
  index,
  type,
  lessonHistory,
  handleDelete,
  addDownload,
  removeDownload,
}) {
  const [title, setTitle] = useState(lessonHistory?.title);

  const isVideo = type === 'video';
  const isMaterial = type === 'material';
  const isLink = type === 'link';
  const isChallenge = type === 'challenge';
  const isNavigation = type === 'navigation';
  // const isInternationalCareer = type === 'international-career';

  const token = `Bearer ${localStorage.getItem('@Skylab:token')}`;

  async function loadChallenges() {
    const { data } = await api.get('/challenges', {
      params: { showAll: true },
    });

    return data.data;
  }

  async function loadNavigation() {
    const {
      data: { steps },
    } = await client.query({
      query: STEPS,
      variables: { limit: 9999 },
      context: {
        headers: {
          authorization: token,
          AUTH_ACCESS_TOKEN:
            process.env.REACT_APP_SELF_EVALUATION_AUTH_ACCESS_TOKEN,
        },
      },
    });

    return steps.nodes;
  }

  function handleSelectChallenge(value) {
    setTitle(value.title);
  }

  function handleSelectNavigation(value) {
    setTitle(value.title);
  }

  return (
    <Container disabled={lessonHistory.deleted}>
      {lessonHistory.deleted && (
        <Deleted>
          <Button
            color="danger"
            icon={MdUndo}
            onClick={() => handleDelete(index)}
          >
            Restaurar
          </Button>
        </Deleted>
      )}
      <Scope path={`history[${index}]`}>
        <Input type="hidden" name="id" />
        <Input type="hidden" name="deleted" />

        <section>
          <FileInput
            label="Icone"
            description={`
              Este é o Ícone principal da aula, será utilizado em todo lugar que for necessário listar.

              <b>Como:</b>
              <ul>
                <li>Catálogo</li>
                <li>Página da Jornada</li>
                <li>Fórums</li>
                <li>Continue assistindo</li>
                <li>Recomendações</li>
              </ul>
            `}
            name="icon"
            placeholder="Icone da aula"
            path="platform"
          />

          <div />
        </section>

        <section>
          <Input
            name="title"
            label="Título da aula"
            placeholder="Preencha o título da aula"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />

          <Input
            name="slug"
            label="Slug"
            placeholder="Slug da aula (gerado automaticamente)"
          />

          <button type="button" onClick={() => handleDelete(index)}>
            <MdDelete size={20} />
          </button>
        </section>

        <section>
          <Input
            multiline
            name="description"
            label="Descrição da aula (markdown)"
          />
        </section>

        <section>
          <Datepicker
            label="Data de liberação"
            name="release_at"
            showTimeSelect
          />

          <Input
            name="tags"
            label="Tags internas"
            placeholder="Insira tags para identificar a aula internamente."
          />
        </section>

        {isChallenge && (
          <fieldset>
            <legend>Configurações do desafio</legend>

            <section>
              <Dropdown
                name="challenge_id"
                load={loadChallenges}
                defaultValue={lessonHistory.challenge}
                placeholder="Selecione um desafio"
                label="Desafio"
                onChange={handleSelectChallenge}
              />
            </section>
          </fieldset>
        )}

        {isNavigation && (
          <fieldset>
            <legend>Configurações do navigation</legend>

            <section>
              <Dropdown
                name="self_evaluation_step_id"
                load={loadNavigation}
                placeholder="Selecione uma autoavaliação"
                label="Autoavaliação"
                onChange={handleSelectNavigation}
              />
            </section>
          </fieldset>
        )}

        {isVideo && (
          <>
            <fieldset>
              <legend>Configurações do vídeo</legend>

              <section>
                <Check
                  label="Plataforma do vídeo"
                  name="platform"
                  options={[
                    { value: 'panda', label: 'Panda' },
                    { value: 'nivo', label: 'Bunny (nivo)' },
                    { value: 'jupiter-next', label: 'Jupiter (Next)' },
                    { value: 'youtube', label: 'YouTube' },
                  ]}
                />

                <Input
                  name="resource"
                  label="ID do Vídeo"
                  placeholder="Preencha o ID do vídeo da plataforma selecionada"
                />
              </section>

              <section>
                <Input
                  label="Duração (em segundos)"
                  name="duration"
                  type="number"
                  placeholder="Duração da aula"
                />

                <Input
                  name="author_id"
                  label="ID do Autor (ID do usuário)"
                  placeholder="Adicione o ID do autor"
                />

                <Input
                  name="link"
                  label="Link"
                  placeholder="Insira um link adicional"
                />
              </section>

              <section>
                <Input
                  description="Caso essa aula tenha linguagem de sinais, basta adicionar o ID do vídeo do Panda para que a funcionalidade seja habilitada na sala de aula."
                  name="signal_language_video_id"
                  label="ID do Vídeo (Linguagem de sinais)"
                  placeholder="Preencha o ID do Vídeo (Linguagem de sinais)"
                />
              </section>
            </fieldset>

            <fieldset>
              <legend>Material Complementar da Aula</legend>

              <section>
                <Downloads>
                  {!!lessonHistory.downloads &&
                    lessonHistory.downloads.map((download, i) => (
                      <DownloadItem visible={!download.deleted}>
                        <Scope path={`downloads[${i}]`}>
                          <Input type="hidden" name="deleted" />
                          <Input placeholder="Título" name="title" />
                          <FileInput
                            name="file"
                            placeholder="Enviar arquivo"
                            type="attachment"
                            path="platform"
                          />
                          <button
                            type="button"
                            onClick={() => removeDownload(i)}
                          >
                            <MdDelete size={20} />
                          </button>
                        </Scope>
                      </DownloadItem>
                    ))}

                  <Button
                    onClick={addDownload}
                    style={{ width: 'fit-content' }}
                  >
                    Adicionar Download
                  </Button>
                </Downloads>

                <div />
              </section>
            </fieldset>
          </>
        )}

        {isMaterial && (
          <fieldset>
            <legend>Configurações do material</legend>

            <section>
              <FileInput
                name="resource"
                placeholder="Adicionar material"
                type="attachment"
              />
            </section>
          </fieldset>
        )}

        {isLink && (
          <fieldset>
            <legend>Configurações do link</legend>

            <section>
              <Input name="link" placeholder="Link" />
            </section>
          </fieldset>
        )}

        <fieldset>
          <legend>Configurações do Catálogo</legend>

          <section>
            <Check
              name="is_free"
              label="Conteúdo gratuito"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
            />

            <div />
          </section>
        </fieldset>

        <TagForms tag={[]} />
      </Scope>
    </Container>
  );
}

LessonHistoryForm.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number.isRequired,
  lessonHistory: PropTypes.shape({
    title: PropTypes.string,
    resource: PropTypes.string,
    platform: PropTypes.string,
    deleted: PropTypes.string,
    downloads: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.string,
      }),
    ),
    challenge: PropTypes.shape({}),
    self_evaluation_step_id: PropTypes.string,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  addDownload: PropTypes.func.isRequired,
  removeDownload: PropTypes.func.isRequired,
};

LessonHistoryForm.defaultProps = {
  type: '',
};

export default LessonHistoryForm;
