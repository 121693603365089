import styled, { css } from 'styled-components';

export const ManagerContainer = styled.div`
  position: relative;

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      main {
        background: rgba(255, 255, 255, 0.8);

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    `}

  > section {
    > div {
      > div {
        > div {
          &:nth-child(3) {
            z-index: 200;
          }
        }
      }
    }
    button {
      margin-top: 25px;
      height: 50px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 20;
  font-weight: 700;

  color: #302b3e;
`;

export const Button = styled.button`
  overflow: hidden;
  width: 38px;
  max-height: 38px;
  background: ${({ disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.025)' : 'rgba(0, 0, 0, 0.1)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;
  margin-top: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: ${({ disabled }) => (disabled ? '#ccc' : '#302b3ecc')};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: ${({ isFirst }) => (isFirst ? 'center' : 'flex-start')};
  margin-top: ${({ isFirst }) => (isFirst ? '25px' : 0)};

  justify-content: center;
  gap: 0.5rem;

  flex: 0 !important;
  flex-direction: row !important;
`;
