import styled from 'styled-components';

export const Button = styled.button`
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 28px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: #dd5554;
  }
`;

export const Badges = styled.small`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  > small {
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 100px;
    color: #666;

    font-size: 11px !important;
    font-weight: 300 !important;

    padding: 3px 6px;
  }
`;
