import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.table`
  width: 100%;
  margin-top: 30px;

  thead th {
    color: #302b3e;
    text-align: left;
    text-transform: uppercase;
    padding: 0 10px 15px;
    border-bottom: 1px solid #000;
    border-color: ${transparentize(0.9, '#302B3E')};
  }

  tbody td {
    text-align: left;
    padding: 10px 10px;

    small {
      font-weight: 700;
      font-size: 13px;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    &:last-child a {
      color: #fff;
      text-decoration: none;
      justify-content: center;
    }

    label {
      display: inline-block;
      background: #7159c1;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      border-radius: 4px;
      padding: 4px 8px;
      text-transform: uppercase;
      line-height: 16px;
      margin-left: 10px;

      &:first-child {
        margin: 0;
      }
    }
  }

  tbody tr:nth-child(2n-1) td {
    background: ${transparentize(0.98, '#302B3E')};
  }

  tbody tr[data-blocked='true'] td {
    background: #ffe8e8;
  }

  tbody tr:nth-child(2n-1)[data-blocked='true'] td {
    background: #ffdada;
  }

  tbody tr:hover td,
  tbody tr[data-blocked='true']:hover td {
    background: ${transparentize(0.87, '#302B3E')};
  }
`;
