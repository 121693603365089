import PropTypes from 'prop-types';
import React from 'react';

import { get } from 'lodash';
import { escape } from 'querystring-browser';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loading from '~/components/Loading';
import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import { useCan } from '~/utils';

function RouteWrapper({
  component: Component,
  isPrivate = false,
  permission,
  ...rest
}) {
  const authenticated = localStorage.getItem('@Skylab:token');
  const loadedUserInfo = useSelector((state) =>
    get(state, 'user.loaded', false),
  );
  const can = useCan();
  const Layout = authenticated ? DefaultLayout : AuthLayout;

  if (!authenticated && isPrivate) {
    const { pathname, search } = window.location;
    const redirect = pathname ? `?to=${escape(pathname + search)}` : ``;

    return <Redirect to={`/${redirect}`} />;
  }

  if (authenticated && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  if (authenticated && isPrivate && !loadedUserInfo) {
    return <Route {...rest} render={() => <Loading />} />;
  }

  if (authenticated && isPrivate && permission && !can(permission)) {
    toast.error('Acesso negado');
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  permission: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  permission: null,
};

export default RouteWrapper;
