import React from 'react';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: '',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.icon_url && (
            <Thumbnail>
              <img src={element.icon_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'slug',
    value: 'slug',
  },
];

export default function TrailsList() {
  return (
    <PagedList
      resource="trails"
      header="Trilhas"
      columns={columns}
      createPermission="edit_lessons"
    />
  );
}
