import { toast } from 'react-toastify';
import { all, call, put, fork, takeLatest } from 'redux-saga/effects';

import DefaultAvatar from '~/assets/images/user-placeholder.png';
import history from '~/routes/history';
import api from '~/services/api';

import { Creators as User } from '../user/actions';
import { Creators as Websocket } from '../websocket/actions';
import { Types, Creators as Auth } from './actions';

export function* loadToken() {
  const token = localStorage.getItem('@Skylab:token');
  const refreshToken = localStorage.getItem('@Skylab:refreshToken');

  if (!token) {
    return;
  }

  yield put(Auth.signInSuccess({ token, refreshToken }));
}

export function* signIn({ email, password }) {
  try {
    const { data } = yield call(api.post, 'sessions', { email, password });

    yield put(Auth.signInSuccess(data));
  } catch ({ response }) {
    if (response) {
      toast.error(response.data.error.message);
    }

    yield put(Auth.signFailure());
  }
}

export function* signInSuccess({ token: { token, refreshToken } }) {
  try {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    const { data } = yield call(api.get, 'account');

    if (!data.avatar) {
      data.avatar = DefaultAvatar;
    }

    yield put(User.saveUserData(data));
    yield put(Websocket.subscribeUserRequest(data.id));

    const tokenDoesExist = !!localStorage.getItem('@Skylab:token');

    if (!tokenDoesExist) {
      localStorage.setItem('@Skylab:token', token);
      localStorage.setItem('@Skylab:refreshToken', refreshToken);

      const { search } = window.location;
      const params = new URLSearchParams(search);
      let redirectTo = '/dashboard';

      if (params.has('to')) {
        redirectTo = params.get('to');
      }

      history.push(redirectTo);
    }
  } catch (err) {
    yield yield put(Auth.signOutRequest());
  }
}

export function* signOut() {
  const refreshToken = localStorage.getItem('@Skylab:refreshToken');

  yield call(api.delete, '/sessions/logout', { refresh_token: refreshToken });

  localStorage.removeItem('@Skylab:token');
  localStorage.removeItem('@Skylab:refreshToken');

  history.push('/');
}

export default all([
  fork(loadToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_IN_SUCCESS, signInSuccess),
  takeLatest(Types.SIGN_OUT_REQUEST, signOut),
]);
