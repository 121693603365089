import PropTypes from 'prop-types';
import React from 'react';

import { DocumentStatusDetails } from '../../../constants';

export function Status({ document }) {
  const { icon: Icon, color, label } = DocumentStatusDetails[document?.status];

  return (
    <div
      className="flex w-full px-5 py-4 flex-col justify-center items-center gap-4 self-stretch rounded-md opacity-[9]g"
      style={{ backgroundColor: `${color}17` }}
    >
      <div className="flex items-center gap-1">
        <Icon color={color} size={20} />
        <strong className="uppercase">{label}</strong>
      </div>

      {document?.comment && (
        <>
          <div
            className="w-full h-px"
            style={{ backgroundColor: `${color}1A` }}
          />

          <p className="text-black text-sm">
            <strong>Motivo: </strong>
            {document.comment}
          </p>
        </>
      )}
    </div>
  );
}

Status.propTypes = {
  document: PropTypes.shape({
    status: PropTypes.oneOf(['APPROVED', 'REJECTED']),
    comment: PropTypes.string,
  }).isRequired,
};
