/* eslint-disable no-restricted-syntax */
import * as Yup from 'yup';

export const schemaObjectCases = ['journey_slug', 'team_identifier'];

export function buildSegmentParamsFormSchema({ segment, segmentKeyParams }) {
  const schema = {};

  if (segment) {
    for (const paramKey of segmentKeyParams) {
      const param = segment.parameters?.[paramKey];
      const isRequired = param.required;
      const isObjectCase = schemaObjectCases.includes(paramKey);

      const requiredMessage = `O campo ${
        param.title || paramKey
      }  é obrigatório`;

      if (isObjectCase) {
        schema[paramKey] = isRequired
          ? Yup.object()
              .shape({
                value: isRequired
                  ? Yup[param.type]().required(requiredMessage)
                  : Yup[param.type]().nullable().typeError(false),
              })
              .required()
              .typeError(requiredMessage)
          : Yup.object()
              .shape({
                value: isRequired
                  ? Yup[param.type]().required(requiredMessage)
                  : Yup[param.type]().nullable().typeError(false),
              })
              .nullable()
              .typeError(false);
      }

      if (!isObjectCase && param) {
        schema[paramKey] = isRequired
          ? Yup[param.type]()
              .required(requiredMessage)
              .typeError(requiredMessage)
          : Yup[param.type]().nullable().typeError(false);
      }
    }
  }

  return {
    params: Yup.object().shape(schema),
  };
}
