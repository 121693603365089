import PropTypes from 'prop-types';
import React from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { MdDelete, MdDragHandle, MdUndo, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, Deleted } from './styles';

import Button from '~/components/Button';

function Item({
  id,
  text,
  moveItem,
  findItem,
  removeItem,
  deleted,
  editUrl,
  renderActions,
}) {
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'card',
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        moveItem(draggedId, overIndex);
      }
    },
  });

  const getRef = (node) => {
    if (deleted) {
      return null;
    }

    return drag(drop(node));
  };

  return (
    <div>
      <Container ref={getRef} isDragging={isDragging} deleted={deleted}>
        {deleted && (
          <Deleted>
            <Button
              color="danger"
              icon={MdUndo}
              size="small"
              onClick={() => removeItem(id)}
            >
              Restaurar
            </Button>
          </Deleted>
        )}

        <MdDragHandle size={17} />

        <span>{text}</span>

        <div>
          {renderActions ? (
            renderActions()
          ) : (
            <>
              {editUrl && (
                <Link to={editUrl} target="_blank" rel="noopener noreferrer">
                  <MdOpenInNew size={17} />
                </Link>
              )}

              <MdDelete onClick={() => removeItem(id)} size={17} />
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

Item.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  moveItem: PropTypes.func.isRequired,
  findItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  deleted: PropTypes.bool.isRequired,
  editUrl: PropTypes.string,
  renderActions: PropTypes.func,
};

Item.defaultProps = {
  editUrl: null,
  renderActions: null,
};

export default Item;
