import styled from 'styled-components';

export const Container = styled.li`
  background: ${({ selected }) => (selected ? '#f0f0f0' : '#fff')};
  padding: 10px;

  cursor: pointer;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(48, 43, 62, 0.15);
  border-top: none;

  button {
    padding: 0 5px;
    border-radius: 12px;
    svg {
      margin: 0;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    flex: 1;

    header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;

      svg {
        margin-right: 5px;
      }

      small {
        margin-left: 5px;
        color: #717171;
      }
    }

    > small {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 300px;
      display: inline-block;
      color: rgba(48, 43, 62, 0.5);
      border: 1px solid red;
    }
  }

  small {
    margin-right: 5px;
  }

  small.tag {
    display: inline-block;
    margin-top: 8px;
    margin-right: 5px;
    padding: 0px 7px;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    color: #1890ff;
    background: #e6f7ff;
    font-size: 12px;
  }

  img {
    width: 30px;
    margin-left: 10px;
  }
`;
