/* eslint-disable no-shadow */

export function normalizeProductsToForm(products = []) {
  return products.map((product) => ({
    ...product,
    value: product.id,
    label: `[${product.identifier}] ${product.title}`,
  }));
}

export function normalizeOfferToForm(offers = [], bonus = {}) {
  const offer = offers.find((offer) => offer.slug === bonus?.metadata?.offer);

  if (!offer) {
    return;
  }

  return {
    value: offer.slug,
    label: `[${offer.slug}] ${offer.title}`,
  };
}
