/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';

import { MdAdd } from 'react-icons/md';

import { useBonuses } from '@shared/skylab/graphql/pluto';
import { bonusTypesMapper } from '../bonus-types.mapper';

import { Page } from '~/pages/styles';
import Button from '~/components/Button';
import { Actions, Table } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Identificador',
    value: 'identifier',
  },
  {
    label: 'Tipo',
    value: 'type',
  },
];

function getBonusType(bonusType) {
  return bonusTypesMapper?.[bonusType] || bonusType || '';
}

function normalizeProducts(products = []) {
  return (
    products?.map((product) => ({
      ...product,
      ...(product?.metadata || {}),
    })) || []
  );
}

function BonusesList() {
  const { loading, data } = useBonuses();

  const products = normalizeProducts(data?.products);

  return (
    <Page>
      <header>
        <h1>Bônus</h1>

        <div>
          <Button icon={MdAdd} color="success" to="/bonuses/new">
            Novo
          </Button>
        </div>
      </header>

      {!loading && !products.length ? (
        <div style={{ marginTop: '1rem' }}>
          <p>Nenhum registro encontrado</p>
        </div>
      ) : (
        <Table loading={loading}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                {columns.map((column) => {
                  if (column.value === 'type') {
                    return (
                      <td key={`${product.id}-${column.value}`}>
                        {getBonusType(product[column.value])}
                      </td>
                    );
                  }

                  return (
                    <td key={`${product.id}-${column.value}`}>
                      <div className="flex gap-2 items-center">
                        {product[column.value]}

                        {column.value === 'identifier' && (
                          <ClipboardButton
                            title="Copiar"
                            value={product.identifier}
                          />
                        )}
                      </div>
                    </td>
                  );
                })}

                <td width={120}>
                  <div className="flex flex-wrap gap-1">
                    <Actions href={`/bonuses/edit/${product.identifier}`} />
                    <ClipboardButton title="Copiar ID" value={product.id} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Page>
  );
}

export default BonusesList;
