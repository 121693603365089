import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Placeholder from '../../assets/images/user-placeholder.png';
import { Container, Stats, User } from './styles';

import { Creators as Auth } from '~/store/modules/auth/actions';

function HeaderBar({ signOutRequest, user }) {
  return (
    <Container>
      <User>
        <div>
          <strong>{user.name}</strong>
          <button type="button" onClick={() => signOutRequest()}>
            Sair
          </button>
        </div>
        <img src={user.avatar_url || Placeholder} alt={user.name} />
      </User>
    </Container>
  );
}

HeaderBar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    avatar_url: PropTypes.string,
  }).isRequired,
  signOutRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(Auth, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);
