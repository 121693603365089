import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Badge } from './styles';

import Button from '~/components/Button';
import { Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  description: Yup.string().required('Descrição é um campo obrigatório'),
  slug: Yup.string(),
  journey_id: Yup.string().required('Jornada é um campo obrigatório'),
  requiredJourneyNodes: Yup.array(Yup.string()).required(
    'É necessário selecionar pelo menos um node',
  ),
});

function MicroCertificatesForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [microCertificate, setMicroCertificate] = useState({});
  const [journey, setJourney] = useState({});
  const [journeys, setJourneys] = useState([]);
  const [journeyNodes, setJourneyNodes] = useState([]);
  const isUpdating = !!match.params.id;

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setSubmitting(true);

        const { id } = match.params;

        await api.postOrPut('/micro-certificates', id, data);

        toast.success('Micro certificado salvo com sucesso!');
        history.push('/micro-certificates');
      } catch (err) {
        toast.error('Algo deu errado');
      } finally {
        setSubmitting(false);
      }
    },
    [history, match.params],
  );

  const loadJourneys = useCallback(async () => {
    try {
      const { data: response } = await api.get('/journeys', {
        params: { per_page: 9999, content_type: 'SPECIALIZATION' },
      });

      const { data } = response;

      setJourneys(data);
    } catch (err) {
      toast.error('Não foi possível carregar as jornadas');
    }
  }, []);

  const loadJourneyNodes = useCallback(async (journeyId) => {
    try {
      const { data } = await api.get(`/journey/${journeyId}/nodes`);

      setJourneyNodes(data);
    } catch (err) {
      toast.error('Não foi possível carregar as jornadas');
    }
  }, []);

  const handleSelectJourney = useCallback(
    async (selectedJourney) => {
      setJourney(selectedJourney);
      await loadJourneyNodes(selectedJourney.id);
    },
    [loadJourneyNodes],
  );

  useEffect(() => {
    async function loadMicroCertificate(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/micro-certificates/${id}`);

        setMicroCertificate(response);
        setJourney(response?.journey);
        loadJourneyNodes(response?.journey?.id);
      } catch (err) {
        toast.error('Não foi possível carregar o micro certificado');
      } finally {
        setLoading(false);
      }
    }

    loadJourneys();

    const { id } = match.params;
    if (id) {
      loadMicroCertificate(id);
    }
  }, [loadJourneyNodes, loadJourneys, match.params]);

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} micro certificado`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/micro-certificates">
            Cancelar
          </Button>
        </div>
      </header>

      <Form
        schema={schema}
        initialData={microCertificate}
        onSubmit={handleSubmit}
      >
        <section>
          <Input label="Título" name="title" />

          <Input
            label="Slug"
            name="slug"
            placeholder="Slug do micro certificado (gerado automaticamente)"
          />
        </section>

        <section>
          <Input label="Descrição" name="description" />
        </section>

        <section>
          <Dropdown
            label="Jornada"
            name="journey_id"
            disabled={!journeys.length}
            placeholder="Selecione uma jornada"
            options={journeys}
            getOptionLabel={(option) => `[${option?.slug}] ${option.title}`}
            onChange={handleSelectJourney}
          />
        </section>

        <section>
          <Dropdown
            label="Módulos necessários para obter o micro certificado"
            name="requiredJourneyNodes"
            disabled={
              !journey.id &&
              !journeyNodes.length &&
              !microCertificate.requiredJourneyNodes
            }
            options={journeyNodes}
            placeholder={
              journey.id
                ? 'Selecione os módulos necessários para obter o micro certificado'
                : 'Selecione uma jornada para carregar os módulos'
            }
            getOptionValue={(node) => node.id}
            getOptionLabel={(node) => {
              const contentTitle =
                node?.challenge?.title ||
                node?.cluster?.title ||
                node?.group?.title ||
                node?.lesson?.last?.title;

              return `[${node.title}] ${contentTitle}`;
            }}
            multiple
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

MicroCertificatesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

MicroCertificatesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default MicroCertificatesForm;
