import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Page } from '~/pages/styles';
import api from '~/services/api';

import { Container } from './styles';

export default function Provi() {
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  function handleProgress(progressEvent) {
    const progressEventPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setProgressPercent(progressEventPercent);
  }

  async function handleChange(event) {
    const data = new FormData();

    data.append('file', event.target.files[0]);

    try {
      await api.post('provi/upload', data, {
        onUploadProgress: handleProgress,
      });
    } catch (err) {
      toast.error('Erro ao enviar arquivo para upload.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page>
      <header>
        <h1>Provi</h1>
      </header>
      <div>
        Importar lista CSV da Provi&nbsp;
        <input type="file" onChange={handleChange} />
        {loading && (
          <Container>
            Carregando &nbsp;
            {progressPercent}
            &nbsp;%
          </Container>
        )}
      </div>
    </Page>
  );
}
