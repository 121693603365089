/* eslint-disable react/prop-types */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { MdDelete, MdRestore } from 'react-icons/md';

import { LessonSection } from '../LessonSection';
import { useEventForm } from '../../../use-event-form.hook';
import { Fieldset } from '../styles';

import {
  Check,
  Dropdown,
  Error,
  FileInput,
  Input,
} from '~/components/HookForm';
import { RequiredFieldWrapper } from '~/components/HookForm/Error/styles';
import Button from '~/components/Button';
import { cn } from '~/lib/utils';

export const TrailItem = ({
  item,
  index,
  restoreTrail,
  removeTrail,
  journeyNodes,
  ...rest
}) => {
  const { trails, educators, certificateCourses } = useEventForm();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const isMarkedToDelete = item?._destroy;

  const hasVslOnCertificate = watch(
    `eventTrails[${index}].metadata.has_vsl_on_certificate`,
  );

  return (
    <div className={cn('flex flex-col', rest?.className)}>
      <Controller
        name={`eventTrails[${index}].eventTrailId`}
        control={control}
        defaultValue={item?.eventTrailId}
        render={({ field }) => <Input {...field} type="hidden" />}
      />

      <Fieldset>
        <legend>Dados da trilha</legend>

        <div className="flex flex-col gap-2 relative">
          {isMarkedToDelete && (
            <Button
              icon={MdRestore}
              color="warning"
              onClick={restoreTrail}
              style={{ height: '50px' }}
              className="z-10 absolute top-[20%] left-[50%] -translate-x-[50%]"
            >
              Desfazer remoção
            </Button>
          )}

          <div
            className={isMarkedToDelete ? 'opacity-30 pointer-events-none' : ''}
          >
            <section>
              <Controller
                name={`eventTrails[${index}].trail`}
                control={control}
                defaultValue={item.trail}
                render={({ field }) => {
                  return (
                    <RequiredFieldWrapper>
                      <Dropdown
                        {...field}
                        label="Trilha"
                        options={trails}
                        required
                        isClearable
                        placeholder="Selecione a trilha"
                      />

                      <Error
                        hasError={errors?.trails?.[index]?.trail}
                        message={errors?.trails?.[index]?.trail?.id?.message}
                      />
                    </RequiredFieldWrapper>
                  );
                }}
              />

              <Button
                icon={MdDelete}
                color="danger"
                onClick={removeTrail}
                className="mt-[30px] h-[48px]"
              />
            </section>

            <section>
              <Controller
                name={`eventTrails[${index}].description`}
                control={control}
                defaultValue={item?.description}
                render={({ field }) => (
                  <Input {...field} multiline label="Descrição da trilha" />
                )}
              />
            </section>

            <section>
              <Controller
                name={`eventTrails[${index}].educators`}
                control={control}
                defaultValue={item.educators}
                placeholder="Selecione quem são os educadores desta trilha"
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    multiple
                    label="Educadores"
                    placeholder="Selecione os educadores que ensinam essa trilha."
                    options={educators}
                    isClearable
                  />
                )}
              />

              <Controller
                name={`eventTrails[${index}].certificate`}
                control={control}
                placeholder="Selecione o curso do certificado"
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    label="Certificado"
                    placeholder="Selecione o curso do certificado"
                    options={certificateCourses}
                    isClearable
                  />
                )}
              />
            </section>

            <section>
              <Controller
                name={`eventTrails[${index}].community_url`}
                control={control}
                defaultValue={item?.community_url}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="URL da Comunidade"
                    style={{ height: '50px' }}
                  />
                )}
              />
            </section>

            <section>
              <FileInput
                label="Imagem do projeto"
                name={`eventTrails[${index}].project_image_url`}
                placeholder="Selecione a imagem do projeto"
                path="platform"
              />
            </section>

            <Input
              name={`eventTrails[${index}].project_description`}
              label="Descrição do projeto"
              placeholder="Descrição sobre o que é o projeto dessa trilha (caso exista)."
              description="Descrição sobre o que é o projeto dessa trilha (caso exista)."
              multiline
            />
          </div>
        </div>

        <section>
          <Check
            label="Exibir VSL na certificação"
            name={`eventTrails[${index}].metadata.has_vsl_on_certificate`}
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />

          <Input
            name={`eventTrails[${index}].metadata.vsl_on_certificate_video`}
            label="Código do Video do Panda"
            description="Código HSL da VSL no panda"
            placeholder="f30ffe04-53f7-4b7c-8b14-8086709a2f01"
            disabled={!hasVslOnCertificate}
          />

          <Input
            name={`eventTrails[${index}].metadata.vsl_on_certificate_video_duration_in_seconds`}
            label="Duração do vídeo em segundos"
            description="Essa informação é importante para gerar o progresso da certificação."
            placeholder="120"
            disabled={!hasVslOnCertificate}
          />
        </section>
      </Fieldset>

      <Fieldset>
        <legend>Aulas da trilha</legend>

        <LessonSection
          trailIndex={index}
          isParentMarkedToDelete={isMarkedToDelete}
          style={{
            opacity: isMarkedToDelete ? 0.3 : 1,
            pointerEvents: isMarkedToDelete ? 'none' : 'auto',
          }}
        />
      </Fieldset>
    </div>
  );
};
