export const types = [
  { value: 'SPECIALIZATION', label: 'Especialização' },
  { value: 'COURSE', label: 'Curso' },
  { value: 'MODULE', label: 'Micro certificado' },
  { value: 'EVENT', label: 'Evento' },
  { value: 'COLLECTION', label: 'Coleção' },
];

export const products = [
  { value: 'DISCOVER', label: 'Discover' },
  { value: 'EXPLORER', label: 'Explorer' },
  { value: 'IGNITE', label: 'Ignite' },
  { value: 'EVENT', label: 'Evento' },
  { value: 'GOSTACK', label: 'GoStack' },
  { value: 'LAUNCHBASE', label: 'LaunchBase' },
  { value: 'REACTJS', label: 'ReactJS' },
  { value: 'NODEJS', label: 'Node.js' },
  { value: 'REACTNATIVE', label: 'React Native' },
  { value: 'PYTHON', label: 'Python' },
  { value: 'JAVA', label: 'Java' },
  { value: 'CSHARP', label: 'C#' },
  { value: 'IA', label: 'IA' },
];
