import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { useReminderForm } from '../../use-reminder-form.hook';

import { Dropdown } from '~/components/HookForm';

export function OfferSection() {
  const { offers } = useReminderForm();
  const { control } = useFormContext();

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="text-center uppercase !text-gray-500 !bg-white">
        Oferta
      </legend>

      <section>
        <Controller
          name="offer"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              isClearable
              options={offers}
              label="Selecione a oferta"
              placeholder="Selecione a oferta deste lembrete."
            />
          )}
        />
      </section>
    </fieldset>
  );
}
