import React, { useCallback, useEffect } from 'react';

import debounce from 'debounce-promise';

import { useReminderForm } from '../../use-reminder-form.hook';

import { Check, Dropdown, Input } from '~/components/HookForm';
import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';
import api from '~/services/api';

export function EventSection() {
  const { educators, tagLevels, tagSkills } = useReminderForm();

  async function loadEvents(search) {
    const { data: response } = await api.get('/events', {
      params: {
        search,
        per_page: 50,
      },
    });

    const events = response.data.map((event) => ({
      value: event.id,
      label: `${event.title} (${event.slug})`,
    }));

    return events;
  }

  useEffect(() => {
    loadEvents();
  }, []);

  const loadAsyncEvents = useCallback(
    debounce(loadEvents, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="text-center uppercase !text-gray-500 !bg-white">
        Evento
      </legend>

      <Dropdown
        async
        isClearable
        name="event"
        load={loadAsyncEvents}
        placeholder="Selecione um evento"
        label="Selecionar evento existente"
        loadDefaultValue={async (value) => value}
      />

      <section>
        <Check
          name="restrict_subscriptions"
          label="Limitar participantes?"
          options={[
            { value: true, label: 'Sim' },
            { value: false, label: 'Não' },
          ]}
        />

        <Input name="subscribers_limit" label="Quantidade de vagas" />
      </section>

      <section>
        <Dropdown
          multiple
          isClearable
          name="educators"
          label="Educadores"
          options={educators}
          placeholder="Selecione os educadores deste evento."
        />

        <Dropdown
          isClearable
          name="tag_level"
          multiple={false}
          label="Dificuldade"
          options={tagLevels}
          placeholder="Selecione o nível de dificuldade"
        />

        <Dropdown
          multiple
          isClearable
          label="Temas"
          name="tag_skills"
          options={tagSkills}
          placeholder="Selecione os temas do evento"
        />
      </section>
    </fieldset>
  );
}
