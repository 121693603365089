/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';

import debounce from 'debounce-promise';

import Dropdown from '.';

import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';
import api from '~/services/api';

export const JourneyDropdown = ({ label, description, content_type = 'EVENT', getSlug = false, ...rest }) => {
  async function loadJourneys(search) {
    const { data: journeysList } = await api.get('/journeys', {
      params: {
        per_page: 999,
        search,
        fields: ['id', 'title', 'slug'],
        content_type,
      },
    });

    const journeys = journeysList.data.map((journey) => ({
      value: getSlug ? journey.slug : journey.id,
      label: journey.title,
    }));

    return journeys;
  }

  const loadAsyncJourneys = useCallback(
    debounce(loadJourneys, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  return (
    <Dropdown
      {...rest}
      async
      label={label}
      description={description}
      load={loadAsyncJourneys}
      isClearable
    />
  );
};
