import PropTypes from 'prop-types';
import React from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

export const AccordionItem = React.forwardRef((props, forwardedRef) => {
  const { title, children, ...rest } = props;

  return (
    <Accordion.Item
      className="overflow-hidde rounded-lg border border-[#E5E7EB] [&+&]:mt-2"
      {...rest}
      ref={forwardedRef}
    >
      <Accordion.Header className="flex">
        <Accordion.Trigger className="flex p-4 justify-between items-center flex-1 border-none cursor-pointer transition-colors group">
          <h2 className="text-[#18181B] text-base font-semibold leading-normal">
            {title}
          </h2>

          <ChevronDown className="size-5 text-[#B5B5B5] transition-transform group-data-[state=open]:rotate-180" />
        </Accordion.Trigger>
      </Accordion.Header>

      {children}
    </Accordion.Item>
  );
});

AccordionItem.displayName = 'AccordionItem';

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
