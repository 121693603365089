import React from 'react';

import { types } from '../types';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: 'Icone',
    value: 'icon',
    getValue: (element) => {
      return (
        <td key={element.id} width="60">
          <Thumbnail>
            {element.icon_url && <img src={`${element.icon_url}`} alt="" />}
          </Thumbnail>
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>{element.title}</strong>
            <span>{element.slug}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Tipo',
    value: 'type',
    getValue: (element) => {
      const typeLabel =
        types.find((type) => type.value === element.type)?.label || '-';

      return <td key={element.id}>{typeLabel}</td>;
    },
  },
  {
    label: 'Destacado',
    value: 'is_displayable',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <input type="checkbox" checked={element.is_displayable} />
        </td>
      );
    },
  },
  {
    label: 'Buscável',
    value: 'is_searchable',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <input type="checkbox" checked={element.is_searchable} />
        </td>
      );
    },
  },
];

function TagsList() {
  const filters = [
    {
      label: 'Tipo',
      name: 'type',
      data: types,
    },
    {
      label: 'Destacado',
      name: 'is_displayable',
      data: [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
      ],
    },
    {
      label: 'Buscável',
      name: 'is_searchable',
      data: [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
      ],
    },
  ];

  return (
    <PagedList
      resource="content-tags"
      header="Tags"
      columns={columns}
      filters={filters}
      createPermission="edit_lessons"
    />
  );
}

export default TagsList;
