import styled from 'styled-components';

export const Badges = styled.small`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  > small {
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 100px;
    color: #666;

    font-size: 11px !important;
    font-weight: 300 !important;

    padding: 3px 6px;
  }
`;
