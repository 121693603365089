/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdAdd, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { QuestionForm } from './QuestionForm';
import { List } from './List';

import Button from '~/components/Button';
import Page from '~/components/Page';
import { Checkbox, Form, Input } from '~/components/Form';
import api from '~/services/api';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório.'),
  description: Yup.string(),
  has_random_questions: Yup.boolean(),
  time_spent_in_minutes: Yup.number()
    .typeError('Tempo máximo é um campo obrigatório.')
    .required('Tempo máximo é um campo obrigatório.'),
  questions_limit: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value,
    )
    .nullable()
    .optional(),
});

export function FormsForm({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState();
  const [questions, setQuestions] = useState([]);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const { id: formId } = match.params;
  const isUpdatingPage = !!match.params.id;

  useEffect(() => {
    async function loadForm(id) {
      try {
        const { data } = await api.get(`/forms/${id}`);

        setForm(data);
        setQuestions(data.questions);
      } catch (error) {
        toast.error('Não foi possível carregar o formulário.');
      } finally {
        setIsLoading(false);
      }
    }

    if (formId) {
      loadForm(formId);
    }
  }, [formId]);

  function handleQuestionSubmit(question) {
    if (!selectedQuestion) {
      setQuestions((prev) => [...prev, question]);
    } else {
      setQuestions((prev) => {
        return prev.map((item) =>
          item.id === selectedQuestion.id &&
          item.tempId === selectedQuestion.tempId
            ? { ...item, ...question }
            : item,
        );
      });
    }

    setShowQuestionForm(false);
    setSelectedQuestion(null);
  }

  function handleQuestionListChange(questionList) {
    setQuestions(questionList);
  }

  function handleQuestionSelect(question) {
    if (!showQuestionForm) {
      setSelectedQuestion(question);
      setShowQuestionForm(true);
    }
  }

  function handleCreateQuestionButton() {
    setShowQuestionForm((prev) => !prev);
    setSelectedQuestion(null);
  }

  async function handleFormSubmit(formData) {
    try {
      setIsSubmitting(true);
      setShowQuestionForm(false);

      const response = await api.postOrPut('/forms', formId, {
        ...formData,
        questions,
      });

      toast.success('Formulário salvo com sucesso!');

      if (isUpdatingPage) {
        setForm(response.data);
        setQuestions(response.data.questions);
      } else {
        toast.success('Redirecionado para página de edição!');
        history.replace(`/forms/edit/${response.data.id}`);
      }
    } catch (error) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancelQuestion() {
    setShowQuestionForm(false);
    setSelectedQuestion(null);
  }

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdatingPage ? 'Editar' : 'Novo'} formulário`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/forms">
            Cancelar
          </Button>
        </div>
      </header>

      <Form
        id="form"
        schema={schema}
        initialData={form}
        onSubmit={handleFormSubmit}
      >
        <section>
          <Input
            disabled={isSubmitting}
            label="Título"
            name="title"
            placeholder="Título do formulário"
          />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug do formulário (gerado automaticamente)"
            readOnly
          />
        </section>

        <section>
          <Input
            label="Tempo máximo para responder (em minutos)"
            name="time_spent_in_minutes"
            placeholder="Tempo máximo para responder"
            type="number"
          />
        </section>

        <section>
          <Checkbox
            label={getLabel(
              'Deve randomizar questões',
              'Marque essa opção, caso o formulário deve ordenar de forma randômica as questões e suas alternativas',
              false,
            )}
            description=""
            name="has_random_questions"
          />

          <Input
            label={getLabel(
              'Limite de Questões',
              'Insira a quantidade máxima de questoões a ser exibida para o usuário',
              false,
            )}
            name="questions_limit"
            placeholder="Limite de questões"
            type="number"
          />
        </section>

        <section>
          <Input
            label="Descrição"
            name="description"
            placeholder="Descrição do formulário"
            multiline
          />
        </section>

        {questions.length > 0 && (
          <section>
            <List
              disabled={showQuestionForm}
              title="Perguntas"
              data={questions}
              onChange={handleQuestionListChange}
              onItemClick={handleQuestionSelect}
            />
          </section>
        )}
      </Form>

      {!showQuestionForm && (
        <Button icon={MdAdd} onClick={handleCreateQuestionButton}>
          Adicionar pergunta
        </Button>
      )}

      {showQuestionForm && (
        <QuestionForm
          onCancel={handleCancelQuestion}
          onSubmit={handleQuestionSubmit}
          initialData={selectedQuestion}
        />
      )}

      <Button
        style={{ marginTop: 16 }}
        type="submit"
        size="block"
        loading={isSubmitting}
        disabled={showQuestionForm}
        form="form"
      >
        Salvar
      </Button>
    </Page>
  );
}

FormsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

FormsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};
