import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdArrowBack } from 'react-icons/md';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { addHours } from 'date-fns';

import { TeamSelect, Node } from './styles';

import Button from '~/components/Button';
import { Form, Input, Check, Datepicker } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

function JourneyTeamsExhibition({ match }) {
  const [loading, setLoading] = useState(true);
  const [changing, setChanging] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [journey, setJourney] = useState('');
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState(null);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      const { data: response } = await api.get(
        `/journeys/${match.params.id}/teams-exhibition`,
      );

      setLoading(false);
      setJourney(response.title);
      setTeams(response.teams);
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadNodes(id) {
      setChanging(true);

      const { data: response } = await api.get(
        `/journeys/${match.params.id}/teams-exhibition/${id}`,
      );

      setNodes(
        response.map((item) => {
          if (item.release_at) {
            item.release_at = addHours(new Date(item.release_at), 3);
          }

          return item;
        }),
      );
      setChanging(false);
    }

    if (teamId) {
      loadNodes(teamId);
    }
  }, [teamId]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      data.active = data.active.map((active) =>
        typeof active !== 'boolean' ? null : String(active),
      );

      await api.put(`/journeys/${id}/teams-exhibition/${teamId}`, data);

      toast.success('Configurações de exibição salvas com sucesso!');
      setSubmitting(false);
    } catch (err) {
      toast.error('Ocorreu um erro ao salvar as configurações de exibição.');
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`Exibição por times (${journey})`}</h1>

        <div>
          <Button
            icon={MdArrowBack}
            color="success"
            to={`/journeys/edit/${match.params.id}`}
          >
            Voltar
          </Button>
        </div>
      </header>

      <TeamSelect>
        <Select
          name="team_id"
          placeholder="Selecionar time"
          options={teams.map((team) => ({
            value: team.id,
            label: team.title,
          }))}
          onChange={({ value }) => setTeamId(value)}
          isDisabled={changing}
        />
      </TeamSelect>

      {!!nodes.length && !changing && (
        <Form
          onSubmit={handleSubmit}
          initialData={{
            journey_node_id: nodes.map((node) => node.id),
            active: nodes.map((node) => node.active),
            release_at: nodes.map((node) => node.release_at),
            ends_at: nodes.map((node) => node.ends_at),
          }}
        >
          {nodes.map((node, idx) => (
            <Node key={node.id}>
              <hr />

              <h3>{node.title}</h3>

              <Input type="hidden" name={`journey_node_id[${idx}]`} />
              <section>
                <Check
                  label="Habilitado"
                  name={`active[${idx}]`}
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />

                <Datepicker
                  label="Data de liberação"
                  name={`release_at[${idx}]`}
                  showTimeSelect
                />

                <Datepicker
                  label="Data final de acesso"
                  name={`ends_at[${idx}]`}
                  showTimeSelect
                  description="Defina a data e hora em que o acesso ao node será encerrado."
                />
              </section>
            </Node>
          ))}

          <Button
            type="submit"
            size="block"
            loading={submitting}
            disabled={submitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </Page>
  );
}

JourneyTeamsExhibition.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default JourneyTeamsExhibition;
