import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import PagedList from '~/components/PagedList';
import api from '~/services/api';
import { showError } from '~/utils';

const columns = [
  {
    label: 'Login',
    value: 'time',
  },
  {
    label: 'IP',
    value: 'ip',
  },
  {
    label: 'Local',
    value: 'where',
  },
  {
    label: 'Dispositivo',
    value: 'device',
  },
];

function UsersLoginLog({ match }) {
  const [loading, setLoading] = useState(false);
  const [loadingClearCache, setLoadingClearCache] = useState(false);
  const [logged, setLogged] = useState(false);

  async function handleRevokeUser() {
    try {
      setLoading(true);

      const { data: response } = await api.delete(
        `/sessions/force-logout/${match.params.id || 1}`,
      );

      if (response.error) {
        toast.error(response.error.message);
      } else {
        setLogged(false);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleClearCache() {
    if (loadingClearCache) {
      return;
    }

    try {
      setLoadingClearCache(true);
      await api.delete(`/users/${match.params.id}/clear-cache`);
      toast.success('Os caches do usuário foram apagados com sucesso!');
    } catch (response) {
      showError(response);
    } finally {
      setLoadingClearCache(false);
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const { data } = await api.get(`/tokens/${match.params.id}`);
        setLogged(!!data);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  function renderHeader() {
    return (
      <header>
        <h1>Histórico de acesso</h1>
        <div>
          <Button onClick={handleClearCache} loading={loadingClearCache}>
            Limpar cache
          </Button>
          <Button
            onClick={handleRevokeUser}
            disabled={!logged}
            loading={loading}
          >
            Revogar acesso
          </Button>
          <Button
            icon={MdArrowBack}
            color="success"
            to={`/users/edit/${match.params.id}`}
          >
            Voltar
          </Button>
        </div>
      </header>
    );
  }

  return (
    <PagedList
      resource={`users/login-logs/${match.params.id}`}
      header={renderHeader}
      columns={columns}
      actions={false}
    />
  );
}

UsersLoginLog.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

UsersLoginLog.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default UsersLoginLog;
