import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Form, Choice, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError, useCan } from '~/utils';

import schema from './schema';
import { ChoiceContainer } from './styles';

function UsersAcl({ match, history }) {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({});
  const can = useCan();

  const viewOnly = !can('edit_users') || !can('edit_users-roles');

  const renderPermissions = useMemo(() => {
    return (
      <Choice
        multiple
        name="permissions"
        options={permissions}
        disabled={viewOnly}
      />
    );
  }, [permissions]);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      let rolesPermissions = [];

      const rolePermission = roles.find((r) => r.id === data.roles);

      if (rolePermission) {
        rolesPermissions = rolePermission.permissions.map(({ id }) => id);
      }

      const permissionsData = data.permissions.filter((id) => {
        return !rolesPermissions.includes(id);
      });

      const { id } = match.params;

      await api.put(`/users/${id}/permissions`, {
        roles: data.roles && [data.roles],
        permissions: permissionsData,
      });

      toast.success('Permissões salvas com sucesso!');
      history.push('/users');
    } catch ({ response }) {
      showError(response);
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadData() {
      const { id: userId } = match.params;

      try {
        const [
          { data: userResponse },
          { data: permissionsResponse },
          {
            data: { data: rolesResponse },
          },
        ] = await Promise.all([
          api.get(`/users/${userId}`),
          api.get('/permissions'),
          api.get('/roles'),
        ]);

        let rolesPermissions = [];

        userResponse.roles.forEach(({ id }) => {
          const rolePermission = rolesResponse.find((r) => r.id === id);

          if (rolePermission) {
            rolesPermissions = rolesPermissions.concat(
              rolePermission.permissions,
            );
          }
        });

        rolesPermissions = uniq(rolesPermissions.map(({ id }) => id));

        const data = permissionsResponse.map((permission) => {
          const status = rolesPermissions.includes(permission.id);

          return {
            label: permission.name,
            value: permission.id,
            disabled: status,
            checked: status,
          };
        });

        userResponse.permissions = userResponse.permissions.map(({ id }) => id);
        const [role] = userResponse.roles;
        if (role) {
          userResponse.roles = role.id;
        }

        setPermissions(data);
        setRoles(rolesResponse);
        setUser(userResponse);
      } catch ({ response }) {
        showError(response);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  function handleChangeRoles(role) {
    let rolesPermissions = [];

    const rolePermission = roles.find((r) => r.id === role.id);

    if (rolePermission) {
      rolesPermissions = rolePermission.permissions.map(({ id }) => id);
    }

    const data = permissions.map((permission) => {
      const status = rolesPermissions.includes(permission.value);
      return {
        label: permission.label,
        value: permission.value,
        disabled: status,
        checked: status,
      };
    });

    setPermissions(data);
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>Grupos e permissões do usuário</h1>

        <div>
          <Button
            icon={MdArrowBack}
            color="success"
            to={`/users/edit/${match.params.id}`}
          >
            Voltar
          </Button>
        </div>
      </header>
      <Form schema={schema} initialData={user} onSubmit={handleSubmit}>
        <Dropdown
          disabled={viewOnly}
          label="Grupo de permissões"
          name="roles"
          options={roles}
          getOptionLabel={(option) => option.name}
          onChange={handleChangeRoles}
        />
        <ChoiceContainer>{renderPermissions}</ChoiceContainer>
        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

UsersAcl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default UsersAcl;
