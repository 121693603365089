import { transparentize } from 'polished';
import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const BillingAddress = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const RegistrationToken = styled.div`
  flex: 1;

  a {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
      display: none;
      ${({ loading }) =>
        loading &&
        css`
          display: block;
          animation: ${rotate} 2s linear infinite;
        `}
    }
  }
`;

export const UpdatePassword = styled.section`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Divider = styled.div`
  position: relative;
  text-align: center;
  margin: 5px 0 15px;
  color: ${transparentize(0.3, '#302B3E')};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #302b3e;
  }

  &::before,
  &::after {
    content: '';
    width: calc(50% - 60px);
    height: 1px;
    position: absolute;
    border-top: 1px dashed #000;
    border-color: ${transparentize(0.9, '#302B3E')};
  }

  &::before {
    left: 0;
    top: 10px;
  }

  &::after {
    right: 0;
    top: 10px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  > * {
    margin-left: 10px;

    &:first-child {
      margin: 0;
    }
  }
`;
