import axios from 'axios';
import { toast } from 'react-toastify';

import { Creators as Auth } from '~/store/modules/auth/actions';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
});

api.postOrPut = (url, id, data, config = {}) => {
  const method = id ? 'put' : 'post';
  const apiUrl = id ? `${url}/${id}` : url;

  return api[method](apiUrl, data, config);
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.registerInterceptWithStore = (store) => {
  api.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (!err.response) {
        toast.error('Não foi possível comunicar com o servidor');
      }

      const refreshToken = localStorage.getItem('@Skylab:refreshToken');
      const originalRequest = err.config;

      const needsToRefresh =
        err.response.status === 401 &&
        typeof err.response.data === 'string' &&
        err.response.data.startsWith('E_JWT_TOKEN_EXPIRED');

      if (needsToRefresh) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return api(originalRequest);
            })
            .catch((error) => error);
        }

        isRefreshing = true;

        return new Promise(async (resolve, reject) => {
          try {
            const { data } = await api.post('/sessions/refresh', {
              refresh_token: refreshToken,
            });

            localStorage.setItem('@Skylab:token', data.token);
            localStorage.setItem('@Skylab:refreshToken', data.refreshToken);

            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization =
              api.defaults.headers.Authorization;

            processQueue(null, data.token);
            resolve(api(originalRequest));
          } catch (error) {
            processQueue(error, null);
            store.dispatch(Auth.signOutRequest());
            reject(error);
          } finally {
            isRefreshing = false;
          }
        });
      }

      return Promise.reject(err);
    },
  );
};

export default api;
