import * as Yup from 'yup';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  slug: Yup.string().required('Slug é um campo obrigatório'),
  selfEvaluationId: Yup.string().required('Jornada é um campo obrigatório'),
  criteriaIds: Yup.array()
    .of(Yup.string())
    .required('Selecione ao menos um critério'),
});

export default schema;
