import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Diretório',
    value: 'path',
  },
];

function VideosList() {
  return (
    <PagedList
      resource="video-categories"
      header="Categorias de Vídeos"
      columns={columns}
    />
  );
}

export default VideosList;
