import React from 'react';

import { RiArticleLine } from 'react-icons/ri';

import { DisabledLink, Link } from './styles';

import PagedList from '~/components/PagedList';
import { formatCnpj } from '~/utils';

const columns = [
  {
    label: 'Nome',
    value: 'name',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <b>{element.name}</b>
            <span>{element.business_name}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'CNPJ',
    value: 'cnpj',
    getValue: (element) => {
      const formatedCnpj = element.cnpj ? formatCnpj(element.cnpj) : null;

      return <td key={element.id}>{formatedCnpj}</td>;
    },
  },
  {
    label: 'Contratos',
    value: '__meta__.num_contracts',
    getValue: (element) => {
      const numContract = Number(element.__meta__.num_contracts);

      const label = numContract > 1 ? 'contratos' : 'contrato';

      if (numContract === 0) {
        return <td>-</td>;
      }

      return (
        <td key={element.id}>
          {numContract} {label}
        </td>
      );
    },
  },
];

export default function CompaniesList() {
  return (
    <PagedList
      resource="b2b/companies"
      header="Empresas"
      columns={columns}
      hideSearchInput
      createPermission="btb:contracts:licenses_create"
      filtersText={[
        {
          name: 'name',
          label: 'Nome',
        },
        {
          name: 'cnpj',
          label: 'CNPJ',
          mask: '99.999.999/9999-99',
        },
      ]}
      filtersCheck={[
        {
          name: 'has_contracts',
          label: 'Tem contrato',
        },
      ]}
      customActions={(company) => {
        const linkIsDisable = Number(company.__meta__.num_contracts) === 0;

        return (
          <>
            {!linkIsDisable ? (
              <Link href={`/admin/b2b/contracts?company_id=${company.id}`}>
                <RiArticleLine className="icon" />
              </Link>
            ) : (
              <DisabledLink>
                <RiArticleLine className="icon" />
              </DisabledLink>
            )}
          </>
        );
      }}
    />
  );
}
