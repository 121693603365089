/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import debounce from 'debounce-promise';

import {
  useCoupon,
  useLazyOffers,
  useUpsertCouponMutation,
} from '@shared/skylab/graphql/pluto';
import schema from './schema';
import { types } from './options';

import Button from '~/components/Button';
import { Check, Datepicker, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import { getLabel, useCan } from '~/utils';
import api from '~/services/api';

function parseDate(rawDate) {
  const date = new Date(rawDate);

  const newDate = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000,
  );

  return newDate;
}

function CouponsForm({ match, history }) {
  const { userData } = useSelector((state) => state.user);
  const { loading, data, error } = useCoupon({
    skip: !match.params.code,
    variables: { code: match.params.code },
  });
  const [fetchOffers] = useLazyOffers();
  const [upsertCoupon, { loading: submitting }] = useUpsertCouponMutation({
    onError: (err) =>
      toast.error(err?.message || 'Ocorreu um erro ao salvar o cupom.'),
    onCompleted: () => {
      toast.success('Cupom salvo com sucesso!');
      history.goBack();
    },
  });

  const can = useCan();

  const isUpdating = !!match.params.code;
  const viewOnly = !can('edit_coupons');

  const coupon = data?.coupon
    ? {
        ...data.coupon,
        startsAt: parseDate(data.coupon.startsAt),
        endsAt: data.coupon?.endsAt && parseDate(data.coupon.endsAt),
        offers: data.coupon?.availableOffers && {
          ids: data.coupon?.availableOffers?.[0]?.offers,
          percentage: data.coupon?.availableOffers?.[0]?.discountPercentage,
        },
        customers: data.coupon?.availableCustomers && {
          atlasUserIds: data.coupon?.availableCustomers?.[0]?.customers?.map(
            (customer) => ({
              ...customer,
              id: customer.atlasUserId,
            }),
          ),
          percentage: data.coupon?.availableCustomers?.[0]?.discountPercentage,
        },
      }
    : {
        type: 'DEFAULT',
        active: true,
        isPartnership: false,
        isUnique: false,
        isRestricted: false,
        startsAt: new Date(),
      };

  const loadCustomers = debounce(
    async (search) => {
      const { data: users } = await api.get('/users', {
        params: {
          page: 1,
          search,
        },
      });

      return users?.data || [];
    },
    500,
    { leading: true },
  );

  async function loadOffers() {
    const response = await fetchOffers();
    return (
      response?.data?.offers?.filter((offer) => !!offer.canUseCoupon) || []
    );
  }

  async function handleSubmit({
    partner,
    partnerCnpj,
    offers,
    customers,
    ...values
  }) {
    upsertCoupon({
      variables: {
        data: {
          id: coupon?.id,
          ...(values.isPartnership
            ? { partner, partnerCnpj }
            : { partner: null, partnerCnpj: null }),
          ...(offers.ids?.length && { offers }),
          ...(customers.atlasUserIds?.length && { customers }),
          ...values,
        },
      },
    });
  }

  if (error) {
    history.goBack();
    return null;
  }

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar cupom</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} cupom`}</h1>
        )}
      </header>

      <Form schema={schema} initialData={coupon} onSubmit={handleSubmit}>
        <Input
          type="hidden"
          name="createdBy"
          value={coupon?.createdBy ?? userData.id}
        />

        <section>
          <Input
            label={getLabel(
              'Título',
              'Como identificaremos internamente este cupom.',
              true,
            )}
            name="title"
            readOnly={viewOnly}
          />

          <Input
            label={getLabel(
              'Código',
              'É o código que vai identificar e ser digitado pelo cliente no momento da compra.',
              true,
            )}
            name="code"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label="Tipo"
            options={types}
            placeholder="Selecione o tipo"
          />

          <Input
            label={getLabel(
              'Descrição',
              'Descreva o cupom: a quem ele é destinado, quais são as condições etc. O cliente não vê essa descrição.',
              false,
            )}
            name="description"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Check
            label={getLabel('Status', 'Marque qual o status.', true)}
            name="active"
            options={[
              { value: true, label: 'Habilitado' },
              { value: false, label: 'Desabilitado' },
            ]}
            readOnly={viewOnly}
          />

          <Check
            label={getLabel(
              'O cupom é oferecido por parceiros?',
              'Marque se é oferecido por parceria.',
              true,
            )}
            name="isPartnership"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />
        </section>
        <section>
          <Check
            label={getLabel(
              'O cupom é de uso único?',
              'Marque se o cupom só puder ser utilizado uma vez.',
              true,
            )}
            name="isUnique"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />

          <Check
            label={getLabel(
              'O cupom é restringido por usuários?',
              'Marque se o cupom deverá ser apenas utilizável por email de usuários específicos.',
              true,
            )}
            name="isRestricted"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Input
            label={getLabel(
              'Nome do parceiro',
              'Se este cupom for oferecido em conjunto com parceria.',
            )}
            name="partner"
            readOnly={viewOnly}
          />

          <Input
            label={getLabel(
              'CNPJ do parceiro',
              'Se este cupom for oferecido em conjunto com parceria.',
            )}
            name="partnerCnpj"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            label={getLabel(
              'Ofertas elegíveis',
              'Em quais ofertas este cupom pode ser utilizado.',
            )}
            name="offers.ids"
            multiple
            load={loadOffers}
            disabled={viewOnly}
            getOptionLabel={(option) => `[${option?.slug}] ${option.title}`}
          />

          <Input
            label={getLabel(
              'Porcentagem',
              'Qual é a porcentagem de desconto aplicada às ofertas?',
            )}
            name="offers.percentage"
            // step="0.1"
            // type="number"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            label={getLabel(
              'Alunos elegíveis',
              'Deixe em branco se puder ser usado por todos.',
            )}
            placeholder="Digite para buscar..."
            name="customers.atlasUserIds"
            async
            multiple
            loadOptions={loadCustomers}
            getOptionLabel={(customer) =>
              `${customer.name} - ${customer.email}`
            }
            disabled={viewOnly}
          />

          <Input
            label={getLabel(
              'Porcentagem',
              'Qual é a porcentagem de desconto aplicada aos alunos?',
            )}
            name="customers.percentage"
            // step="0.1"
            // type="number"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Data inicial',
              'Qual a data inicial para este cupom ser usado?',
              true,
            )}
            name="startsAt"
            showTimeSelect
            disabled={viewOnly}
          />

          <Datepicker
            timeIntervals={1}
            label={getLabel(
              'Data final',
              'Qual a data final para este cupom ser usado?',
            )}
            name="endsAt"
            showTimeSelect
            disabled={viewOnly}
          />
        </section>

        <section style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button color="cancel" to="/coupons">
            Cancelar
          </Button>

          {!viewOnly && (
            <Button type="submit" loading={submitting} disabled={submitting}>
              Salvar cupom
            </Button>
          )}
        </section>
      </Form>
    </Page>
  );
}

CouponsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

CouponsForm.defaultProps = {
  match: {
    params: {
      code: '',
    },
  },
};

export default CouponsForm;
