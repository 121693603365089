import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -24px;

  h2 {
    margin-bottom: 8px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }
`;

export const SideInfo = styled.div`
  margin: 0 !important;
`;
