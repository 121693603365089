import React from 'react';

import {
  FaRegCheckCircle,
  FaRegQuestionCircle,
  FaRegTimesCircle,
} from 'react-icons/fa';

import PagedList from '~/components/PagedList';

export const UserAuditsMotivations = {
  WRONG_GOAL: 'Objetivo errado',
  COMPLETED_GOAL: 'Objetivo atingido',
  UNDESIRABLE_GOAL: 'Troca de objetivo pessoal',
};

const columns = [
  {
    label: 'Aluno',
    value: 'user.name',
  },
  {
    label: 'Objetivo Anterior',
    value: 'beforeGoal.title',
  },
  {
    label: 'Objetivo Posterior',
    value: 'afterGoal.title',
  },
  {
    label: 'Motivação',
    getValue: (element) => {
      const label = UserAuditsMotivations[element.motivation];

      const getIcon = () => {
        const iconStyles = {
          fontSize: 24,
        };

        switch (element.motivation) {
          case 'WRONG_GOAL':
            return (
              <FaRegTimesCircle style={{ color: '#DD5554', ...iconStyles }} />
            );
          case 'COMPLETED_GOAL':
            return (
              <FaRegCheckCircle style={{ color: '#4FCDA5', ...iconStyles }} />
            );
          default:
            return (
              <FaRegQuestionCircle
                style={{ color: '#DDCC54', ...iconStyles }}
              />
            );
        }
      };
      return (
        <td key={element.id}>
          <section
            style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
          >
            {getIcon()}
            {label}
          </section>
        </td>
      );
    },
  },
];

export default function UsersAuditsList() {
  const motivationKeys = Object.keys(UserAuditsMotivations);

  const filters = [
    {
      label: 'Motivacão',
      name: 'motivation',
      data: motivationKeys.map((key) => ({
        value: key,
        label: UserAuditsMotivations[key],
      })),
    },
  ];

  return (
    <>
      <PagedList
        resource="guide/users/audits"
        header="Auditorias"
        columns={columns}
        hideCreate
        actions={false}
        filters={filters}
        hideSearchInput
        filtersText={[
          {
            name: 'username',
            label: 'Aluno',
          },
        ]}
      />
    </>
  );
}
