import React, { useCallback, useEffect } from 'react';

import debounce from 'debounce-promise';

import { useReminderForm } from '../../use-reminder-form.hook';

import { Dropdown } from '~/components/HookForm';
import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';
import api from '~/services/api';

export function ContentSection() {
  const { educators, tagLevels } = useReminderForm();

  async function loadTechnologies(search) {
    const { data: response } = await api.get('/content-tags', {
      params: {
        search,
        per_page: 50,
        type: 'TECHNOLOGY',
      },
    });

    const events = response.data.map((event) => ({
      value: event.id,
      label: `${event.title} (${event.slug})`,
    }));

    return events;
  }

  useEffect(() => {
    loadTechnologies();
  }, []);

  const loadAsyncTechnologies = useCallback(
    debounce(loadTechnologies, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="text-center uppercase !text-gray-500 !bg-white">
        Conteúdo
      </legend>

      <section>
        <Dropdown
          multiple
          isClearable
          name="educators"
          label="Educadores"
          options={educators}
          placeholder="Selecione os educadores deste evento."
        />

        <Dropdown
          async
          multiple
          isClearable
          name="technologies"
          label="Tecnologias"
          load={loadAsyncTechnologies}
          placeholder="Selecione uma tecnologia"
          loadDefaultValue={async (value) => value}
        />

        <Dropdown
          isClearable
          name="tag_level"
          multiple={false}
          label="Dificuldade"
          options={tagLevels}
          placeholder="Selecione o nível de dificuldade"
        />
      </section>
    </fieldset>
  );
}
