export function handleBonusFormErrors(message) {
  let errorMessage = 'Ocorreu um erro ao salvar o bônus.';

  if (
    message?.includes('Unique constraint') &&
    message?.includes('identifier')
  ) {
    errorMessage = 'O identificador já está em uso.';
  }

  return errorMessage;
}
