import * as Yup from 'yup';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  slug: Yup.string(),
  start_at: Yup.string().nullable(),
  end_at: Yup.string().nullable(),
  required: Yup.boolean().nullable(),
  instruction: Yup.string(),
  form_id: Yup.string(),
  type: Yup.string(),
  description: Yup.string(),
  resolution_url: Yup.string(),
  blocked_challenge_id: Yup.string(),
  has_attempts: Yup.boolean(),
  show_response: Yup.boolean(),
  is_evaluative: Yup.boolean(),
  attempts: Yup.string().when('has_attempts', {
    is: true,
    then: Yup.string().required('campo obrigatório.'),
  }),
});

export default schema;
