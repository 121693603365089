import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';

import { useField } from '@rocketseat/unform';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

import Field from '../Field';
import Item from './Item';
import { ListArea, Error } from './styles';

function SortableList({
  name,
  label,
  load,
  getOptionLabel,
  resource,
  isAsync,
  includeDeletedEntries = false,
}) {
  const ref = useRef(null);
  const [options, setOptions] = useState([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [data, setData] = useState(defaultValue || []);
  const [, drop] = useDrop({ accept: 'card' });

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const parseValue = (raw) => {
    const value = JSON.parse(raw.value);

    if (includeDeletedEntries) {
      const filtered = value.filter(
        (option) => !(option.deleted && option.created),
      );

      return filtered || [];
    }

    return value
      ? value.filter((option) => !option.deleted).map((option) => option.id)
      : [];
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      parseValue,
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      if (load) {
        setIsLoadingOptions(true);

        const response = await load();

        if (!data.length) {
          setOptions(response);
          setIsLoadingOptions(false);
          return;
        }

        const filtered = response.filter(
          (option) => !(data.findIndex((item) => item.id === option.id) >= 0),
        );

        setOptions(filtered);
        setIsLoadingOptions(false);
      }
    }

    fetchData();
  }, []); // eslint-disable-line

  const findItem = (id) => {
    const item = data.filter((d) => d.id === id)[0];

    return {
      item,
      index: data.indexOf(item),
    };
  };

  const addItem = (item) => {
    const optionsIndex = options.findIndex((option) => option.id === item.id);

    const newItem = {
      ...item,
      created: true,
    };

    setData(
      update(data, {
        $push: [newItem],
      }),
    );

    setOptions(
      update(options, {
        $splice: [[optionsIndex, 1]],
      }),
    );
  };

  const moveItem = (id, atIndex) => {
    const { item, index } = findItem(id);

    setData(() => {
      const newState = update(data, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      });

      ref.current.value = newState;

      return newState;
    });
  };

  const removeItem = (id) => {
    const newData = data.map((item) => {
      return {
        ...item,
        deleted: item.id === id ? !item.deleted : item.deleted,
      };
    });

    setData(newData);
  };

  const asyncSelectLoadOptions = debounce((inputText, callback) => {
    load(inputText).then((response) => callback(response));
  }, 500);

  return (
    <Field>
      <div ref={drop}>
        {label && <label htmlFor={fieldName}>{label}</label>}

        {isAsync ? (
          <AsyncSelect
            loadOptions={asyncSelectLoadOptions}
            isLoading={isLoadingOptions}
            defaultOptions
            value={null}
            onChange={addItem}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              (getOptionLabel && getOptionLabel(option)) || option.title
            }
          />
        ) : (
          <Select
            options={options}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              (getOptionLabel && getOptionLabel(option)) || option.title
            }
            onChange={addItem}
            value={null}
            isDisabled={isLoadingOptions}
          />
        )}

        {error && <Error>{error}</Error>}

        <ListArea>
          {data.map((item) => (
            <Item
              key={item.id}
              id={item.id}
              text={(getOptionLabel && getOptionLabel(item)) || item.title}
              moveItem={moveItem}
              findItem={findItem}
              removeItem={removeItem}
              deleted={!!item.deleted}
              editUrl={resource ? `/${resource}/edit/${item.id}` : ''}
            />
          ))}
        </ListArea>

        <input type="hidden" ref={ref} value={JSON.stringify(data)} />
      </div>
    </Field>
  );
}

SortableList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  resource: PropTypes.string,
  isAsync: PropTypes.bool,
  includeDeletedEntries: PropTypes.bool,
};

SortableList.defaultProps = {
  getOptionLabel: null,
  resource: null,
  isAsync: false,
  includeDeletedEntries: false,
};

export default SortableList;
