import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 28px;
  color: #333;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  svg {
    &:nth-child(2) {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -200%);
      transition: none;
    }
  }

  ${({ copied }) =>
    copied &&
    `
      background: #7159c1;
      color: #fff;

      svg {
        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(2) {
          opacity: 1;
          transform: translate(-50%, -50%);
          transition: opacity 0.2s, transform 0.3s;
        }
      }
  `}
`;
