import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { MdArrowBack, MdAdd, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';

import { PurchasesContainer, Header } from './styles';
import { UserProducts } from './UserProducts';
import { PurchasesList } from './PurchasesList';

import Button from '~/components/Button';
import { Page } from '~/pages/styles';
import api from '~/services/api';
import { useCan } from '~/utils';

function UsersPurchasesList({ match }) {
  const accessRef = useRef();
  const activeRef = useRef();
  const trialRef = useRef();
  const inactiveRef = useRef();

  const [upgrading, setUpgrading] = useState(false);

  const can = useCan();

  async function handleUpgrade() {
    try {
      setUpgrading(true);
      const { data: user } = await api.get(`/users/${match.params.id}`);

      await api.put('one/upgrade', {
        emails: [user.email],
      });

      toast.success(
        'Enviado solicitação de upgrade do usuário, isso pode levar alguns segundos.',
      );
    } finally {
      setUpgrading(false);
    }
  }

  function reloadData() {
    accessRef.current.reload();
    activeRef.current.reload();
    trialRef.current.reload();
    inactiveRef.current.reload();
  }

  return (
    <Page>
      <Header>
        <div>
          <h1>Compras</h1>
          <Button icon={MdRefresh} onClick={reloadData}>
            Atualizar
          </Button>
        </div>

        <div>
          {can('edit_purchases') && (
            <Button icon={MdAdd} loading={upgrading} onClick={handleUpgrade}>
              Upgrade
            </Button>
          )}

          <Button
            icon={MdArrowBack}
            color="success"
            to={`/users/edit/${match.params.id}`}
          >
            Voltar
          </Button>

          {can('edit_purchases') && (
            <Button
              icon={MdAdd}
              color="success"
              to={`/users/purchases/${match.params.id}/new`}
            >
              Novo
            </Button>
          )}
        </div>
      </Header>

      <UserProducts userId={match.params.id} ref={accessRef} />

      <PurchasesContainer>
        <PurchasesList
          ref={activeRef}
          userId={match.params.id}
          type="active"
          title="Compras ativas"
          showEmpty
        />

        <PurchasesList
          ref={trialRef}
          userId={match.params.id}
          type="trial"
          title="Trial"
          showEmpty={false}
        />

        <PurchasesList
          ref={inactiveRef}
          userId={match.params.id}
          type="inactive"
          title="Compras inativas"
          showEmpty={false}
        />
      </PurchasesContainer>
    </Page>
  );
}

UsersPurchasesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default UsersPurchasesList;
