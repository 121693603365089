import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 15px;

  aside {
    ul {
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }
`;
