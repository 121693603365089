/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { types } from '../types';

import Button from '~/components/Button';
import { Form, Check, Input, Dropdown, FileInput } from '~/components/Form';
import MarkdownInput from '~/components/Form/MarkdownInput';
import Page from '~/components/Page';
import api from '~/services/api';

const schema = Yup.object().shape({
  icon: Yup.string(),
  title: Yup.string().required(),
  slug: Yup.string(),
  description: Yup.string(),
  type: Yup.string().required(),
  order: Yup.number(),
  is_displayable: Yup.bool(),
  is_searchable: Yup.bool(),
});

function TagsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [contentTag, setContentTag] = useState({
    title: '',
    slug: '',
    type: 'DEFAULT',
    order: 0,
    is_displayable: false,
    is_searchable: true,
  });
  const isUpdating = !!match.params.id;

  useEffect(() => {
    async function loadContentTag(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/content-tags/${id}`);

        setContentTag(response);
      } catch (err) {
        toast.error('Erro ao buscar dados da aula.');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadContentTag(id);
    }
  }, [match.params]);

  async function handleDelete() {
    if (
      window.confirm(
        'Deseja realmente remover essa tag? Todos os relacionamentos serão apagados.',
      )
    ) {
      await api.delete(`/content-tags/${contentTag.id}`);
      toast.success('Tag removida com sucesso!');
      history.push('/content-tags');
    }
  }

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/content-tags', id, data);

      toast.success('Tag salva com sucesso!');
      history.push('/content-tags');
    } catch (err) {
      toast.error('Algo deu errado.');
      setSubmitting(false);
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Nova'} tag`}</h1>

        <div>
          {isUpdating && (
            <Button icon={MdDelete} color="danger" onClick={handleDelete}>
              Remover
            </Button>
          )}

          <Button icon={MdClose} color="cancel" to="/content-tags">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={contentTag} onSubmit={handleSubmit}>
        <section>
          <FileInput name="icon" placeholder="Ícone da tag" path="platform" />
        </section>

        <section>
          <Input
            name="title"
            label="Nome da Tag"
            placeholder="Nome da Tag"
            autoFocus
          />

          {isUpdating && <Input name="slug" label="Slug" placeholder="Slug" />}
        </section>

        <section>
          <MarkdownInput
            name="description"
            label="Descrição (markdown)"
            placeholder="Descreva a tag"
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label="Tipo"
            options={types.map((type) => ({
              title: type.label,
              id: type.value,
            }))}
            placeholder="Selecione o tipo de conteúdo desta jornada"
          />

          <Input
            name="order"
            type="number"
            label="Ordem"
            placeholder="Ordem de listagem (Recomendo colocar de 10 em 10)"
          />
        </section>

        <section>
          <Check
            label="Destacado"
            name="is_displayable"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />

          <Check
            label="Buscável"
            name="is_searchable"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

TagsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default TagsForm;
