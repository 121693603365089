import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuid } from 'uuid';
import { Scope } from '@rocketseat/unform';
import { MdAdd, MdDelete, MdRestore } from 'react-icons/md';

import { useLazyProducts } from '@shared/skylab/graphql/pluto';
import { Container } from './styles';

import { getLabel } from '~/utils';
import { Check, Dropdown, Input } from '~/components/Form';
import Button from '~/components/Button';

const periodTypes = [
  { id: 'MONTHLY', title: 'Mensal' },
  { id: 'SEMIANNUAL', title: 'Semestral' },
  { id: 'YEARLY', title: 'Anual' },
];

export default function ProductsForm({ products, viewOnly }) {
  const [fetchProducts] = useLazyProducts();
  const [rows, setRows] = useState([{ id: uuid() }]);

  async function loadProducts() {
    const response = await fetchProducts();
    return response?.data?.products || [];
  }

  const handleAdd = () => {
    setRows((prevState) => [...prevState, { id: uuid() }]);
  };

  const handleRemove = (id) => {
    setRows((prevState) =>
      prevState.map((row) => (row.id === id ? { ...row, deleted: true } : row)),
    );
  };

  const handleRestore = (id) => {
    setRows((prevState) =>
      prevState.map((row) =>
        row.id === id ? { ...row, deleted: false } : row,
      ),
    );
  };

  useEffect(() => {
    if (products.length) {
      setRows(products);
    }
  }, [products]);

  return (
    <>
      {rows.map((row, index) => (
        <Scope
          path={`offerProducts[${index}]`}
          key={`offer-product-[${row.id}]`}
        >
          <Container isDeleted={row.deleted}>
            <Input type="hidden" name="deleted" value={row.deleted} />

            {row.deleted && (
              <main>
                <Button icon={MdRestore} onClick={() => handleRestore(row.id)}>
                  Restaurar
                </Button>
              </main>
            )}

            <fieldset>
              <section>
                <Dropdown
                  label={getLabel('Produto', 'Selecione.')}
                  name="product.id"
                  load={loadProducts}
                  getOptionLabel={(product) =>
                    `[${product.identifier}]  ${product.title}`
                  }
                  disabled={viewOnly}
                />
              </section>

              <section>
                <Dropdown
                  label={getLabel(
                    'Período de acesso',
                    'O período de acesso que o aluno terá.',
                    true,
                  )}
                  name="periodType"
                  options={periodTypes}
                  disabled={viewOnly}
                />

                <Input
                  label={getLabel(
                    'Intervalo da recorrência',
                    'O intervalo da recorrência do acesso.',
                    true,
                  )}
                  name="periodInterval"
                  type="number"
                  readOnly={viewOnly}
                />

                <Check
                  label={getLabel('Renovável?', 'Marque se é renovável.', true)}
                  name="isRenewable"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                  readOnly={viewOnly}
                />
              </section>

              <Button
                icon={MdDelete}
                onClick={() => handleRemove(row.id)}
                disabled={rows.filter((r) => !r.deleted).length === 1}
              >
                Remover
              </Button>
            </fieldset>
          </Container>
        </Scope>
      ))}

      <Button icon={MdAdd} onClick={handleAdd}>
        Novo produto
      </Button>
    </>
  );
}

ProductsForm.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isRenewable: PropTypes.bool,
      periodType: PropTypes.string,
      periodInterval: PropTypes.number,
      product: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  ).isRequired,
  viewOnly: PropTypes.bool.isRequired,
};
