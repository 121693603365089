import { darken, transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  border-radius: 4px;
  position: relative;

  button,
  a {
    height: 30px;
    border: 1px solid #000;
    width: 45px;
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.1);
    color: ${transparentize(0.2, '#302B3E')} !important;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-bottom-right-radius: ${({ hasChildren }) =>
        hasChildren ? 0 : '4px'};
      border-top-right-radius: ${({ hasChildren }) =>
        hasChildren ? 0 : '4px'};
      ${({ hasChildren }) => hasChildren && 'border-right: 0;'}
    }

    &:nth-child(2) {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const Actions = styled.ul`
  position: absolute;
  top: 36px;
  right: 0;
  margin-right: -1px;
  width: 160px;
  border-radius: 3px;
  border: 1px solid #000;
  border-color: ${darken(0.05, '#FFF')};
  background: ${darken(0.01, '#FFF')};
  z-index: 10;

  display: ${(props) => (props.isMenuOpen ? 'block' : 'none')};

  li {
    padding: 4px 10px;
    font-size: 14px;

    &:hover {
      background: ${darken(0.03, '#FFF')};
    }
  }
`;
