import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input } from '~/components/Form';
import { Creators as Auth } from '~/store/modules/auth/actions';

import { Container } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório'),
  password: Yup.string()
    .min(4, 'Senha deve ter mais que 4 caracteres')
    .required('Senha é obrigatório'),
});

function SignIn({ signInRequest, loading }) {
  function handleSubmit({ email, password }) {
    signInRequest(email, password);
  }

  return (
    <Container>
      <h1>Acessar Backoffice</h1>
      <Form onSubmit={handleSubmit} schema={schema}>
        <section>
          <Input name="email" placeholder="Digite seu e-mail" />
        </section>

        <section>
          <Input
            name="password"
            type="password"
            placeholder="Sua senha secreta"
          />
        </section>

        <Button type="submit" size="block" loading={loading}>
          Entrar!
        </Button>
      </Form>

      <footer>
        <Link to="/forgot">Esqueci a senha</Link>
      </footer>
    </Container>
  );
}

SignIn.propTypes = {
  signInRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(Auth, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(SignIn);
