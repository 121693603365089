import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import {
  FaCalendar,
  FaCheckCircle,
  FaEdit,
  // FaRegTrashAlt,
} from 'react-icons/fa';
import SweetAlert2 from 'react-sweetalert2';
import { format, isBefore } from 'date-fns';

import {
  Badges,
  // Button
} from './styles';
import { frequencyOptions } from '../utils';

import PagedList from '~/components/PagedList';
import api from '~/services/api';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Canais',
    value: 'channels',
    getValue: (element) => {
      const channels = element?.category?.channels || [];

      return (
        <td key={element.id}>
          <Badges>
            {channels.map((channel) => (
              <small key={channel.id}>
                <b>{channel.title}</b>
              </small>
            ))}
          </Badges>
        </td>
      );
    },
  },
  {
    label: 'Envio',
    value: 'scheduled_at',
    getValue: ({ scheduled_at, frequency }) => {
      if (scheduled_at) {
        return (
          <td>
            {format(scheduled_at, 'DD/MM/YYYY - HH:mm')}
            <br />
            {frequencyOptions.find((option) => option.value === frequency)
              ?.label ?? null}
          </td>
        );
      }

      return <td />;
    },
  },
  {
    label: 'Status',
    getValue: (element) => {
      if (element.is_drafting) {
        return (
          <td key={element.id}>
            <div className="inline-flex gap-2 items-center">
              <FaEdit size={17} color="#bbb" />
              Rascunho
            </div>
          </td>
        );
      }

      if (
        element.scheduled_at &&
        isBefore(new Date(), new Date(element.scheduled_at))
      ) {
        return (
          <td key={element.id}>
            <div className="inline-flex gap-2 items-center">
              <FaCalendar size={15} color="#bbb" />
              Agendada
            </div>
          </td>
        );
      }

      return (
        <td key={element.id}>
          <div className="inline-flex gap-2 items-center">
            <FaCheckCircle size={15} color="#4FCDA5" />
            Enviada
          </div>
        </td>
      );
    },
  },
];

const tabs = [
  { label: 'Todas', name: 'status', value: '' },
  { label: 'Enviadas', name: 'status', value: 'sent' },
  { label: 'Em rascunho', name: 'status', value: 'draft' },
  { label: 'Agendadas', name: 'status', value: 'scheduled' },
];

export default function NotificationsList() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const filters = [
    {
      label: 'Categoria',
      name: 'category_id',
      data: categories.map((category) => ({
        value: category.id,
        label: category.title,
      })),
    },
  ];

  useEffect(() => {
    async function loadCategories() {
      const { data } = await api.get('/notifications/categories', {
        params: { per_page: 999 },
      });

      setCategories(data.data);
      setLoading(false);
    }

    loadCategories();
  }, []);

  async function handleRemoveNotification() {
    try {
      toast.success('Notificação excluída com sucesso');
    } catch {
      toast.error('Não foi possível excluir a notificação');
    }

    setSelectedNotification(null);
  }

  if (loading) {
    return 'Carregando...';
  }

  return (
    <>
      <PagedList
        resource="notifications"
        header="Notificações"
        tabs={tabs}
        hideSearchInput
        hideCopyButton
        createPermission="create_notifications"
        columns={columns}
        filtersText={[
          {
            name: 'title',
            label: 'Título',
          },
        ]}
        filtersCheck={[
          {
            name: 'is_recurrent',
            label: 'Recorrente',
          },
        ]}
        filters={filters}
        /* customActions={(notification) => {
          return (
            <Button
              type="button"
              onClick={() => setSelectedNotification(notification)}
            >
              <FaRegTrashAlt className="icon" />
            </Button>
          );
        }} */
      />

      <SweetAlert2
        show={selectedNotification}
        title="Excluir notificação?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveNotification()}
        onResolve={() => setSelectedNotification(null)}
      />
    </>
  );
}
