import PropTypes from 'prop-types';
import React from 'react';

import { format } from 'date-fns';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Usuário',
    value: 'user.name',
  },
  {
    label: 'Link',
    getValue: (element) => {
      return (
        <td key={`link-${element.id}`}>
          <a href={element.link} target="_blank" rel="noopener noreferrer">
            {element.link}
          </a>
        </td>
      );
    },
  },
  {
    label: 'Segundo link',
    getValue: (element) => {
      return (
        <td key={`link-${element.id}`}>
          <a href={element.second_link} target="_blank" rel="noopener noreferrer">
            {element.second_link}
          </a>
        </td>
      );
    },
  },
  {
    label: 'Enviado em',
    getValue: (element) => (
      <td key={`created-${element.id}`}>
        {format(element.created_at, 'DD/MM/YYYY - HH:mm')}
      </td>
    ),
  },
];

function ChallengeAnswersList({ match }) {
  const filters = [
    {
      label: 'Filtro',
      name: 'replied',
      data: [
        {
          value: false,
          label: 'Em aberto',
        },
        {
          value: true,
          label: 'Respondido',
        },
      ],
    },
  ];

  return (
    <PagedList
      resource={`challenges/${match.params.challengeId}/answers`}
      backLink={`/challenges/edit/${match.params.challengeId}`}
      header="Respostas do desafio"
      columns={columns}
      filters={filters}
      initialFilters={{
        replied: false,
      }}
      hideCreate
    />
  );
}

ChallengeAnswersList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

ChallengeAnswersList.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default ChallengeAnswersList;
