import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { isDocumentReviewed } from './document.utils';
import { DocumentStatusValues } from '../../constants';

import api from '~/services/api';

const EnrollmentDocumentsFormContext = createContext({});

export const EnrollmentDocumentsFormProvider = ({
  params,
  history,
  children,
}) => {
  const { userId, contractId, documentId } = params || {};

  const form = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [enrollment, setEnrollment] = useState();

  const documents = useMemo(() => {
    return enrollment?.documents || [];
  }, [enrollment?.documents]);

  const currentDocument = documents[currentDocumentIndex];
  const companyId = enrollment?.contract?.company?.id;

  const totalDocuments = documents.length;

  const enrollmentDetailsPath = `/documents/users/${userId}/${contractId}`;

  const onSubmit = useCallback(
    async (data) => {
      const { status, comment } = data?.[currentDocumentIndex] || {};

      if (!status || isDocumentReviewed(currentDocument)) {
        return;
      }

      setIsSubmitting(true);

      try {
        api.put(`/documents/users/${userId}/companies/${companyId}`, {
          documentId: currentDocument?.id,
          status,
          comment,
        });

        setEnrollment((prev) => ({
          ...prev,
          documents: prev.documents.map((doc) =>
            doc.id === currentDocument?.id ? { ...doc, status, comment } : doc,
          ),
        }));
      } catch (error) {
        toast.error(error?.response?.data || 'Erro ao salvar documento');

        return;
      } finally {
        setIsSubmitting(false);
      }
    },
    [companyId, currentDocument, currentDocumentIndex, userId],
  );

  const handleSubmit = useCallback(async () => {
    await form.handleSubmit(onSubmit)();
  }, [form, onSubmit]);

  const onConfirm = useCallback(async () => {
    await handleSubmit();

    history.push(enrollmentDetailsPath);
  }, [enrollmentDetailsPath, handleSubmit, history]);

  const goBackToDetails = useCallback(() => {
    history.push(enrollmentDetailsPath);
  }, [enrollmentDetailsPath, history]);

  const goToPreviousDocument = useCallback(() => {
    if (currentDocumentIndex >= 0) {
      setCurrentDocumentIndex((prev) => prev - 1);
    }
  }, [currentDocumentIndex]);

  const goToNextDocument = useCallback(async () => {
    await handleSubmit();

    if (currentDocumentIndex + 1 < totalDocuments) {
      setCurrentDocumentIndex((prev) => prev + 1);
    } else {
      goBackToDetails();
    }
  }, [currentDocumentIndex, goBackToDetails, handleSubmit, totalDocuments]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const { data } = await api.get(
          `/documents/users/${userId}/contracts/${contractId}`,
        );

        const filteredDocuments =
          data?.documents?.filter(
            (doc) => doc.status !== DocumentStatusValues.PENDING,
          ) || [];

        const foundDocIndex =
          filteredDocuments.findIndex((doc) => doc.id === documentId) || 0;

        setEnrollment({ ...data, documents: filteredDocuments });
        setCurrentDocumentIndex(foundDocIndex >= 0 ? foundDocIndex : 0);
      } catch (error) {
        toast.error(error?.response?.data || 'Erro ao carregar dados');
      } finally {
        setIsLoading(false);
      }
    };

    if (userId && contractId) {
      loadData();
    }
  }, [contractId, documentId, userId]);

  return (
    <EnrollmentDocumentsFormContext.Provider
      value={{
        form,
        isLoading,
        isSubmitting,
        totalDocuments,
        currentDocument,
        currentDocumentIndex,
        goToPreviousDocument,
        goToNextDocument,
        goBackToDetails,
        onConfirm,
      }}
    >
      {children}
    </EnrollmentDocumentsFormContext.Provider>
  );
};

EnrollmentDocumentsFormProvider.propTypes = {
  children: PropTypes.element.isRequired,
  params: PropTypes.shape({
    userId: PropTypes.string,
    contractId: PropTypes.string,
    documentId: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

/**
 * @typedef {Object} EnrollmentDocumentsFormContext
 * @property {boolean} isLoading - Indicates if data is currently loading.
 * @property {boolean} isSubmitting - Indicates if the form is being submitted.
 * @property {Object|null} currentDocument - The current document object being processed.
 * @property {number} totalDocuments - Total number of documents available.
 * @property {number} currentDocumentIndex - Index of the currently displayed document.
 * @property {ReturnType<typeof useForm>} form - The form object from react-hook-form.
 * @property {function(): void} goToPreviousDocument - Function to navigate to the previous document.
 * @property {function(): void} goToNextDocument - Function to navigate to the next document.
 * @property {function(): void} goBackToDetails - Function to navigate back to enrollment details.
 * @property {function(): void} onConfirm - Function to handle confirm submission.
 */

/**
 * Custom hook to access the enrollment documents form context.
 * @returns {EnrollmentDocumentsFormContext} The enrollment documents form context.
 * @throws {Error} If used outside of EnrollmentDocumentsFormProvider.
 */
export const useEnrollmentDocumentsForm = () => {
  const context = useContext(EnrollmentDocumentsFormContext);

  if (!context) {
    throw new Error(
      'useEnrollmentDocumentsForm must be used within EnrollmentDocumentsFormProvider',
    );
  }

  return context;
};
