import * as Yup from 'yup';

const schema = Yup.object().shape({
  slug: Yup.string().required('Slug é um campo obrigatório'),
  title: Yup.string().required('Título é um campo obrigatório'),
  description: Yup.string().required('Descrição é um campo obrigatório'),
  iconUrl: Yup.string().url('Ícone deve ser uma URL válida'),
  priceInCents: Yup.number()
    .transform((c, o) => (o === '' ? undefined : c))
    .required('Preço em centavos é um campo obrigatório')
    .positive('Utilize apenas números inteiros'),
  discountPriceInCents: Yup.number()
    .transform((c, o) => (o === '' ? null : c))
    .positive('Utilize apenas números inteiros')
    .nullable(),
  refundDays: Yup.number()
    .transform((c, o) => (o === '' ? undefined : c))
    .required('Dias para reembolso é um campo obrigatório')
    .positive('Utilize apenas números inteiros'),
  renewInMonths: Yup.number()
    .transform((c, o) => (o === '' ? null : c))
    .positive('Utilize apenas números inteiros')
    .nullable(),
  isRecurrent: Yup.boolean().required('Recorrência é um campo obrigatório.'),
  isPurchaseable: Yup.boolean().required('Status é um campo obrigatório.'),
  isValidForMGM: Yup.boolean().required(
    'É válido para o MGM é um campo obrigatório.',
  ),
  canUseCoupon: Yup.boolean().required(
    'Pode ser usado com cupom de desconto é um campo obrigatório.',
  ),
  acceptedPaymentTypes: Yup.array()
    .of(Yup.string())
    .required('Formas de pagamento é um campo obrigatório.'),
  relatedOffers: Yup.array().of(Yup.string()),
  offerProducts: Yup.array().of(
    Yup.object().shape({
      isRenewable: Yup.boolean(),
      periodType: Yup.string(),
      periodInterval: Yup.number()
        .transform((c, o) => (o === '' ? null : c))
        .positive('Utilize apenas números inteiros')
        .nullable(),
      product: Yup.object().shape({ id: Yup.string() }),
      deleted: Yup.bool().transform((_, o) => o === 'true'),
    }),
  ),
});

export default schema;
