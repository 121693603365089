/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React from 'react';

import { FormProvider } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import AccessExtensionSection from './Sections/AccessExtension';
import { bonusTypesMapper } from '../bonus-types.mapper';
import ExtraContentSection from './Sections/ExtraContent';
import ReservaSection from './Sections/Reserva';
import { useBonusForm } from '../use-bonus-form.hook';

import { Container } from '~/components/HookForm/Form/styles';
import Button from '~/components/Button';
import { slugify } from '~/utils';
import { Input, Dropdown } from '~/components/HookForm';
import Page from '~/components/Page';

function BonusesForm({ match, history }) {
  const {
    form,
    bonus,
    error,
    offers,
    loadOffer,
    submitting,
    upsertBonus,
    offerProducts,
    currentBonusType,
    shouldDisplayOfferProductsForm,
  } = useBonusForm({
    history,
    identifier: match.params.identifier,
  });

  const isUpdating = !!match.params.identifier;

  async function handleSubmit(values) {
    const {
      type,
      offer,
      __typename,
      description,
      reserva_item_type,
      extra_content_products,
      access_extension_products,
      ...data
    } = values;

    const products = [];

    if (type.value === 'ACCESS_EXTENSION') {
      products.push(
        ...access_extension_products.map((product) => ({
          ...product,
          numberOfMonths: Number(product.numberOfMonths),
        })),
      );
    }

    if (type.value === 'EXTRA_CONTENT') {
      products.push(
        ...extra_content_products.map(({ value, identifier, title }) => ({
          id: value,
          title,
          identifier,
        })),
      );
    }

    upsertBonus({
      variables: {
        data: {
          id: bonus?.id,
          ...data,
          type: 'BONUS',
          metadata: {
            ...(data?.metadata || {}),
            type: type.value,
            offer: offer.value,
            reserva_item_type: reserva_item_type || null,
            description,
            products,
          },
        },
      },
    });
  }

  if (error) {
    history.push('/bonuses');
    return null;
  }

  return (
    <Page>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} bônus`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/bonuses">
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <section>
              <Input
                required
                name="identifier"
                label="Identificador"
                onInput={(e) => {
                  e.target.value = slugify(e.target.value);
                }}
                placeholder="Identificador"
                description="Identifica este bônus internamente. Não é possível alterar manualmente."
                disabled={isUpdating}
                {...(isUpdating && { style: { background: '#EFEFEF' } })}
              />
            </section>

            <section>
              <Input
                required
                name="title"
                label="Título"
                placeholder="Título"
                description="Dê um título que descreva bem o bônus e suas particularidades."
              />
            </section>

            <section>
              <Input
                required
                name="description"
                label="Descrição"
                multiline
                placeholder="Descrição"
              />
            </section>

            <section>
              <Dropdown
                required
                name="offer"
                label="Oferta"
                placeholder="Selecione a oferta"
                onChange={loadOffer}
                options={offers.map((offer) => ({
                  value: offer.slug,
                  label: `[${offer.slug}]  ${offer.title}`,
                }))}
              />
            </section>

            <section>
              <Dropdown
                name="type"
                label="Tipo"
                required
                placeholder="Selecione o tipo de bônus"
                description="Selecione o tipo de bônus que está sendo criado."
                options={Object.entries(bonusTypesMapper).map(
                  ([value, label]) => ({
                    value,
                    label,
                  }),
                )}
              />
            </section>

            <section>
              {currentBonusType.value === 'RESERVA' && <ReservaSection />}
              {currentBonusType.value === 'EXTRA_CONTENT' && (
                <ExtraContentSection />
              )}

              {currentBonusType.value === 'ACCESS_EXTENSION' &&
                (shouldDisplayOfferProductsForm ? (
                  <AccessExtensionSection offerProducts={offerProducts} />
                ) : (
                  <fieldset className="flex flex-col w-full gap-4 mt-2">
                    Nenhum produto encontrado (é necessário selecionar uma
                    oferta válida)
                  </fieldset>
                ))}
            </section>

            <section className="mt-2">
              <Button
                type="submit"
                size="block"
                loading={submitting}
                disabled={submitting}
              >
                Salvar bônus
              </Button>
            </section>
          </form>
        </FormProvider>
      </Container>
    </Page>
  );
}

BonusesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      identifier: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

BonusesForm.defaultProps = {
  match: {
    params: {
      identifier: '',
    },
  },
};

export default BonusesForm;
