import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { FaSpinner } from 'react-icons/fa';

import Field from '../Field';
import Input from './Input';
import { Container, FeedBack, Loading } from './styles';

const InputField = forwardRef(
  ({ label, name, disabled, type, loading, ...rest }, ref) => {
    return (
      <Field name={name} disabled={disabled} type={type}>
        <Container>
          <Input
            label={label}
            name={name}
            disabled={disabled}
            type={type}
            ref={ref}
            {...rest}
          />

          {loading && (
            <FeedBack>
              <Loading>
                <FaSpinner size={16} color="#ccc" />
              </Loading>
            </FeedBack>
          )}
        </Container>
      </Field>
    );
  },
);

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

InputField.defaultProps = {
  label: '',
  disabled: false,
  placeholder: '',
  type: 'text',
  loading: false,
};

export default InputField;
