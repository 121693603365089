import * as Yup from 'yup';

export const experimentFormSchema = Yup.object().shape({
  title: Yup.string().required('O nome é obrigatório.'),
  slug: Yup.string().required('O slug é obrigatório.'),
  description: Yup.string().nullable(),
  limit: Yup.number().nullable(),

  is_active: Yup.boolean()
    .required('O status é obrigatório.')
    .typeError('O status é obrigatório.'),
});
