import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@rocketseat/unform';

import Field from '../Field';
import {
  HtmlLabel,
  HtmlTextArea,
  HtmlWrapper,
  PreviewLabel,
  PreviewWrapper,
} from './style';

function HtmlPreview({ html }) {
  return (
    <PreviewWrapper>
      <PreviewLabel>Preview</PreviewLabel>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </PreviewWrapper>
  );
}

HtmlPreview.propTypes = {
  html: PropTypes.string.isRequired,
};

export default function HtmlInput({
  name,
  label,
  placeholder,
  value,
  children,
}) {
  const ref = useRef();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [html, setHtml] = useState(defaultValue || '');

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, ref.current]);

  useEffect(() => {
    setHtml(value);
  }, [value]);

  return (
    <Field>
      <HtmlWrapper>
        {label && <HtmlLabel>{label}</HtmlLabel>}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '36px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <HtmlTextArea
              ref={ref}
              id={fieldName}
              name={fieldName}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onChange={(e) => setHtml(e.target.value)}
              style={{ height: '300px' }}
            />

            {children}
          </div>

          <HtmlPreview html={html} />
        </div>
      </HtmlWrapper>

      {error && <span className="error">{error}</span>}
    </Field>
  );
}

HtmlInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

HtmlInput.defaultProps = {
  label: null,
  placeholder: null,
  value: '',
};
