import { transformSegmentParamValues } from './segment.utils';

import { transformDateFromDatePicker } from '~/utils';

export const bannerFormMapper = {
  mapToHttp: (data) => {
    const payload = {
      title: data.title,
      banner: data.banner,
      cta_url: data.cta_url,
      event_slug: data.event_slug,
      start_date: transformDateFromDatePicker(data.start_date),
      end_date: transformDateFromDatePicker(data.end_date),

      // FLAGS
      is_enabled: !!data.is_enabled,
      is_external_cta_url: !!data.is_external_cta_url,
      display_to_logged_out_users: !!data.display_to_logged_out_users,

      // SEGMENT
      segment_id: data?.segment?.id,
      segment_params: data?.params
        ? transformSegmentParamValues(data?.segment, data?.params)
        : {},
    };

    return payload;
  },
};
