import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { Types as AuthTypes } from './actions';

export const INITIAL_STATE = Immutable({
  token: undefined,
  signed: false,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [AuthTypes.SIGN_IN_REQUEST]: (state) => state.set('loading', true),
  [AuthTypes.SIGN_IN_SUCCESS]: (state, { token }) =>
    state.merge({ loading: false, signed: true, token }),
  [AuthTypes.SIGN_FAILURE]: (state) => state.set('loading', false),
  [AuthTypes.SIGN_OUT_REQUEST]: () => INITIAL_STATE,
});
