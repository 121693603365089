import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import { Container } from './styles';

function Checkbox({ name, label, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({ name: fieldName, ref: ref.current, path: 'checked' });
  }, [ref.current, fieldName]);

  return (
    <Container>
      <label htmlFor={fieldName}>
        <input
          type="checkbox"
          id={fieldName}
          name={fieldName}
          aria-label={fieldName}
          defaultChecked={defaultValue}
          ref={ref}
          {...rest}
        />
        <span>{label}</span>
      </label>

      {error && <span>{error}</span>}
    </Container>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Checkbox;
