import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Scope } from '@rocketseat/unform';
import { addHours, differenceInDays, isAfter } from 'date-fns';
import { toast } from 'react-toastify';

import { Container } from './styles';

import { Table } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';
import { Datepicker, Input } from '~/components/Form';
import Button from '~/components/Button';
import api from '~/services/api';

export const TeamsUsersForm = ({ purchaseId, userId, teams, viewOnly }) => {
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const updatedData = {
        ...data,
        start_date: addHours(data.start_date, 3),
        end_date: addHours(data.end_date, 3),
      };

      await api.put(`purchases/teams/${purchaseId}`, updatedData);

      toast.success('Acessos da compra atualizado');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Container>
      <h1>Acessos</h1>

      <Form initialData={{ teams }} onSubmit={handleSubmit}>
        <Table>
          <thead>
            <tr>
              <th>Time</th>
              <th>Usuário</th>
              <th>Início do acesso</th>
              <th>Fim do Acesso</th>
              <th>Tempo de acesso</th>
              <th>Tempo</th>
            </tr>
          </thead>

          <tbody>
            {teams?.map((team, index) => (
              <Scope path={`teams[${index}]`}>
                <tr
                  style={
                    userId !== team.user_id ? { background: '#f7c0bb' } : {}
                  }
                >
                  <td>
                    <section>
                      <Input type="hidden" name="id" value={team.id} />
                      <strong>{team.team.title}</strong>
                      <span>{team.team.identifier}</span>
                    </section>
                  </td>
                  <td>
                    <Input
                      type="text"
                      name="user_id"
                      label="ID do Usuário"
                      value={team.user_id}
                    />
                  </td>
                  <td>
                    <Datepicker
                      label="Data início"
                      name="start_date"
                      readOnly={viewOnly}
                    />
                  </td>
                  <td>
                    <Datepicker
                      label="Data fim"
                      name="end_date"
                      readOnly={viewOnly}
                    />
                  </td>
                  <td>
                    <Datepicker
                      label="Renova em"
                      name="renewable_at"
                      readOnly={viewOnly}
                    />
                  </td>
                  <td>
                    <section
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {`${differenceInDays(
                        new Date(team.end_date),
                        new Date(team.start_date),
                      )} dias de acesso`}

                      <small>
                        {isAfter(team.start_date, new Date())
                          ? `Começa em ${differenceInDays(
                              new Date(team.start_date),
                              new Date(),
                            )} dias`
                          : `Restam ${differenceInDays(
                              new Date(team.end_date),
                              new Date(),
                            )} dias`}
                      </small>
                    </section>
                  </td>
                  <td />
                  <td>
                    <ClipboardButton title="Copiar ID" value={team.id} />
                  </td>
                </tr>
              </Scope>
            ))}
          </tbody>
        </Table>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar Acessos
          </Button>
        )}
      </Form>
    </Container>
  );
};

TeamsUsersForm.propTypes = {
  purchaseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      team_id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      purchase_id: PropTypes.string.isRequired,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        hotmart_id: PropTypes.string,
        starts_at: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        auto_enrollment: PropTypes.bool.isRequired,
        discord_roles: PropTypes.string,
        tag_id: PropTypes.string,
        chat_id: PropTypes.string,
        identifier: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        off_price: PropTypes.number,
        checkout: PropTypes.bool.isRequired,
        identifier_prefix: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  viewOnly: PropTypes.bool.isRequired,
};
