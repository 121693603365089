import React, { useMemo } from 'react';

import { Scope } from '@rocketseat/unform';
import { MdAdd } from 'react-icons/md';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Check, Input } from '~/components/Form';

export const testimonialSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
  avatar_url: Yup.string(),
  content: Yup.string(),
  deleted: Yup.boolean(),
});

export const testimonialsSchema = Yup.array().of(testimonialSchema);

export default function TestimonialForm({
  testimonials = [],
  onSubmit = () => {},
}) {
  function clearTestimonialForm() {
    const name = document.getElementById('testimonial_name');
    const avatarUrl = document.getElementById('testimonial_avatar_url');
    const content = document.getElementById('testimonial_content');

    name && (name.value = '');
    avatarUrl && (avatarUrl.value = '');
    content && (content.value = '');
  }

  const render = useMemo(() => {
    function handleSubmit() {
      const name = document.getElementById('testimonial_name');
      const avatarUrl = document.getElementById('testimonial_avatar_url');
      const content = document.getElementById('testimonial_content');

      if (!name || !avatarUrl || !content) return;

      if (!name.value || !avatarUrl.value || !content.value) return;

      onSubmit({
        name: name.value,
        avatar_url: avatarUrl.value,
        content: content.value,
        deleted: false,
      });

      clearTestimonialForm();
    }

    return (
      <fieldset style={{ marginTop: 20 }}>
        <legend>Depoimentos de alunos</legend>

        <section
          style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}
        >
          <Input label="Nome do aluno" name="testimonial_name" />
          <Input label="URL do avatar" name="testimonial_avatar_url" />
          <Input label="Depoimento" name="testimonial_content" />
          <Button
            icon={MdAdd}
            iconSize={16}
            iconColor="#fff"
            color="success"
            size="small"
            onClick={handleSubmit}
          />
        </section>

        {testimonials.length > 0 &&
          testimonials.map((t, index) => (
            <section
              style={{
                display: t.deleted ? 'flex' : 'flex',
                alignItems: 'center',
              }}
            >
              <Scope path={`testimonials[${index}]`}>
                <Input label="Nome do aluno" name="name" />
                <Input label="URL do avatar" name="avatar_url" />
                <Input label="Depoimento" name="content" />
                <Check
                  name="deleted"
                  label="Deletar"
                  options={[
                    {
                      label: 'Sim',
                      value: true,
                    },
                    {
                      label: 'Não',
                      value: false,
                    },
                  ]}
                />

                {/* this field is necessary to keep the already saved testimonial's id when updating the journey */}
                <span style={{ display: 'none' }}>
                  <Input name="id" />
                </span>
              </Scope>
            </section>
          ))}
        <section />
      </fieldset>
    );
  }, [testimonials, onSubmit]);

  return render;
}
