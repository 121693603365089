import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Badge } from '../../coupons/Form/styles';

import Button from '~/components/Button';
import { Check, Dropdown, FileInput, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import MarkdownInput from '~/components/Form/MarkdownInput';
import api from '~/services/api';

export const types = [
  { value: 'JOURNEY', label: 'Jornada' },
  { value: 'GENERAL', label: 'Plataforma' },
  { value: 'COLLEGE', label: 'Faculdade' },
];

const schema = Yup.object().shape({
  title: Yup.string().required(),
  slug: Yup.string().nullable(),
  version: Yup.string().nullable(),
  content: Yup.string().required(),
  preceding_term_id: Yup.string().nullable(),
  icon: Yup.string().nullable(),
  type: Yup.string().required(),
  is_active: Yup.boolean(),
});

export default function TermsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [term, setTerm] = useState({
    title: '',
    slug: '',
    version: '',
    content: '',
    preceding_term_id: '',
    icon: '',
    type: '',
    is_active: true,
  });

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/terms', id, data);

      toast.success('Termo de uso salvo com sucesso!');
      history.push('/terms');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadData(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/terms/${id}`);

        setTerm(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do termo de uso.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;

    if (id) {
      loadData(id);
    }
  }, [match.params]);

  async function loadTerms() {
    const { data } = await api.get('/terms', {
      params: {
        per_page: 999,
      },
    });

    return data.data;
  }

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Termo de uso`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/terms">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={term} onSubmit={handleSubmit}>
        <section>
          <Input
            name="title"
            placeholder="Título do termo de uso"
            label="Título"
          />

          <Input
            name="slug"
            readOnly
            disabled
            placeholder="Será criado automaticamente"
            label="Slug"
          />

          <Check
            description="Para que o aluno possa ver isso, precisa estar como ativo."
            label="Está ativo?"
            name="is_active"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />
        </section>

        <section>
          <Dropdown
            description={`
            Este campo é utilizado para associar o termo de uso atual a um termo de uso anterior, caso se trate de uma atualização ou correção. Siga as orientações abaixo ao preencher:

            <ul>
              <li>
                Para um novo termo de uso: Deixe este campo em branco. Use esta opção se estiver criando um termo de uso que não se baseia ou atualiza um existente.
              </li>
              <li>
                Para atualizar um termo de uso existente: Selecione o termo de uso que está sendo atualizado ou corrigido. Ao fazer isso, o termo selecionado será considerado a versão anterior, e o termo de uso que você está criando será tratado como a versão mais recente. Isso ajuda a manter um histórico claro de versões e alterações.
              </li>
            </ul>
            `}
            label="Termo de uso relacionado"
            name="preceding_term_id"
            isClearable
            load={loadTerms}
            getOptionLabel={(option) => `[${option?.version}] ${option.title}`}
          />

          <Dropdown
            name="type"
            label="Tipo"
            options={types.map((type) => ({
              title: type.label,
              id: type.value,
            }))}
            placeholder="Selecione o tipo do termo de uso"
          />

          <FileInput
            label="Ìcone do termo"
            name="icon"
            placeholder="Ícone do termo de uso"
            path="platform"
          />

          <Input
            name="version"
            readOnly
            disabled
            placeholder="Será criado automaticamente"
            label="Versão"
          />
        </section>

        <section>
          <MarkdownInput
            name="content"
            label="Conteúdo (markdown)"
            placeholder="Conteúdo do termo de uso"
          />
        </section>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

TermsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
