/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import * as Yup from 'yup';

import { Tag } from './tag';

export const tagFormsSchema = {
  tag_technologies: Yup.array().of(Yup.string()),
  tag_level: Yup.string(),
  tag_skills: Yup.array().of(Yup.string()),
  tag_default: Yup.array().of(Yup.string()),
};

export const TagForms = () => {
  return (
    <fieldset style={{ marginTop: 20 }}>
      <legend>Área de tags</legend>

      <section>
        <Tag
          name="tag_technologies"
          label="Tecnologia"
          placeholder="Selecione uma ou mais tecnologias"
          type="TECHNOLOGY"
          multiple
        />

        <Tag
          name="tag_level"
          label="Dificuldade"
          placeholder="Selecione o nível de dificuldade"
          type="LEVEL"
          isClearable
          multiple={false}
        />
      </section>

      <section>
        <Tag
          name="tag_skills"
          label="Competência"
          placeholder="Selecione uma ou mais competências"
          type="SKILL"
          multiple
        />

        <Tag
          name="tag_default"
          label="Tags Padrões"
          placeholder="Selecione uma ou mais tags"
          type="DEFAULT"
          multiple
        />
      </section>
    </fieldset>
  );
};
