import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { ChartColumnIcon } from 'lucide-react';

import { buildSegmentField, formatNumber } from '../../segment.utils';
import { useBannerFormSegment } from '../../banner-form-segment.hook';

import { Alert } from '~/components/ui/alert';
import { Dropdown, Error } from '~/components/HookForm';
import { cn } from '~/lib/utils';

export function SegmentSection() {
  const form = useFormContext();

  const {
    segment,
    loaders,
    segmentParams,
    numberOfParams,
    segmentKeyParams,
    isEmailProcedure,
    segmentCountValue,
    isFetchingSegmentCount,
  } = useBannerFormSegment();

  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="uppercase bg-[#7159c1]">Base de usuários</legend>

      <Dropdown
        async
        isClearable
        name="segment"
        label="Perfil de alunos"
        load={loaders.segments}
        placeholder="Selecione um perfil"
        description={segment?.description}
        loadDefaultValue={async (value) => value}
      />

      {segment && (
        <section className="flex flex-col w-full">
          <section
            className={cn(
              '!grid grid-cols-2 w-full',
              numberOfParams <= 1 ? '!grid-cols-1' : '',
            )}
          >
            {segmentKeyParams.map((paramKey) => {
              const key = `params.${paramKey}`;
              const param = segment.parameters?.[paramKey];

              if (!param) return null;

              const loaderMapper = {
                team_identifier: loaders.teams,
                journey_slug: loaders.journeys,
              };

              const loader = loaderMapper?.[paramKey];

              return (
                <div className="flex flex-col" key={key}>
                  <Controller
                    name={key}
                    control={form.control}
                    defaultValue={segmentParams?.[paramKey] || null}
                    render={({ field }) => (
                      <>
                        {buildSegmentField({
                          key,
                          param,
                          field,
                          loader,
                        })}

                        <Error
                          className="text-[#dd5554] text-xs"
                          hasError={
                            !!form?.formState?.errors?.params?.[paramKey]
                          }
                          message={
                            form?.formState?.errors?.params?.[paramKey]?.message
                          }
                        />
                      </>
                    )}
                  />
                </div>
              );
            })}
          </section>

          {!isEmailProcedure && (
            <Alert
              className="!flex-row"
              icon={() => <ChartColumnIcon size={24} color="#4FCDA5" />}
              description={
                isFetchingSegmentCount
                  ? 'Carregando...'
                  : `Usuários que visualizarão o banner: **${formatNumber(
                      segmentCountValue,
                    )}**`
              }
            />
          )}
        </section>
      )}
    </fieldset>
  );
}
