import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import websocket from './websocket/reducer';

export default combineReducers({
  websocket,
  auth,
  user,
});
