import styled from 'styled-components';

export const Container = styled.div`
  label {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ddd;
    background: #eee;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;

    input {
      cursor: pointer;
      margin-right: 5px;
    }

    span {
      font-size: 13px;
      font-weight: bold;
      color: #777;
      letter-spacing: 0.2px;
    }

    input:checked + span {
      color: #302b3e;
    }
  }
`;
