/* eslint-disable react/prop-types */

import React from 'react';

import { MdDelete, MdRestore } from 'react-icons/md';
import { useFormContext, useWatch } from 'react-hook-form';

import { ActionContainer, Fieldset } from '../styles';

import { Input, FileInput } from '~/components/HookForm';
import Button from '~/components/Button';
import { cn } from '~/lib/utils';

export const PrizeItem = ({ item, index, restore, remove, ...rest }) => {
  const isMarkedToDelete = item?._destroy;
  const { control } = useFormContext();

  const placement = useWatch({
    control,
    name: `mgm[${index}].placement`,
  });

  return (
    <div className={cn('flex flex-col gap-4', rest?.className)}>
      <Fieldset>
        <legend>
          {placement ? `Premiação do ${placement}º colocado` : 'Prêmio'}
        </legend>

        <div className="flex flex-col gap-2 relative">
          {isMarkedToDelete && (
            <Button
              icon={MdRestore}
              color="warning"
              onClick={() => restore(index)}
              style={{ height: '50px' }}
              className="z-10 absolute top-[20%] left-[50%] -translate-x-[50%]"
            >
              Desfazer remoção
            </Button>
          )}

          <div
            className={cn(
              isMarkedToDelete ? 'opacity-30 pointer-events-none' : '',
              'flex flex-col gap-2',
            )}
          >
            <Input name={`mgm[${index}].prizeId`} type="hidden" />

            <section className="">
              <Input
                name={`mgm[${index}].title`}
                label="Título"
                placeholder="Título do prêmio"
              />

              <ActionContainer>
                <Button
                  color="danger"
                  icon={MdDelete}
                  onClick={() => remove(index)}
                  className="flex justify-center"
                >
                  Remover
                </Button>
              </ActionContainer>
            </section>

            <section>
              <Input
                name={`mgm[${index}].placement`}
                label="Colocação"
                description="O número da colocação do prêmio"
                min={1}
                type="number"
              />

              <FileInput
                label="Imagem do prêmio"
                name={`mgm[${index}].imageUrl`}
                placeholder="Selecione a imagem do prêmio"
                path="platform"
              />
            </section>
          </div>
        </div>
      </Fieldset>
    </div>
  );
};
