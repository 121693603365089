import React, { useState, useEffect } from 'react';

import { get } from 'dot-prop-immutable';
import { RiLoginBoxLine } from 'react-icons/ri';

import { useLoginAs } from '../hooks/useLoginAs';

import { emojifyAccountStatus, hasAccountBlockedStatus, useCan } from '~/utils';
import PagedList from '~/components/PagedList';
import api from '~/services/api';
import { Thumbnail } from '~/components/PagedList/styles';
import Button from '~/components/Button';

const columns = [
  {
    label: 'Thumb',
    value: 'thumbnail',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {element.avatar_url && (
            <Thumbnail>
              <img src={element.avatar_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Nome',
    value: 'name',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{element.name}</b>
            <span>{element.email}</span>
          </section>
        </td>
      );
    },
  },
  {
    label: 'Número Carteirinha',
    value: 'card_number',
    getValue: (element) => {
      return <td key={element.id}>{`#${element.card_number}`}</td>;
    },
  },
  {
    label: 'CPF',
    value: 'document',
  },
  {
    label: 'Pioneer',
    value: 'pioneer',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <input type="checkbox" checked={element.pionerr} />
        </td>
      );
    },
  },
  {
    label: 'Status',
    value: 'blocked_status',
    decorate: emojifyAccountStatus,
  },
];

function UsersList() {
  const can = useCan();

  const { handle: handleLoginAs } = useLoginAs();

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const filters = [
    {
      label: 'Time',
      name: 'team_id',
      data: teams.map((team) => ({
        value: team.id,
        label: team.title,
      })),
    },
  ];

  useEffect(() => {
    async function loadTeams() {
      const { data } = await api.get('/teams', {
        params: {
          all: true,
        },
      });

      setTeams(data);
      setLoading(false);
    }

    loadTeams();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  return (
    <PagedList
      resource="users"
      header="Usuários"
      columns={columns}
      filters={filters}
      rowProps={(element) => ({
        'data-blocked': hasAccountBlockedStatus(get(element, 'blocked_status')),
      })}
      customActions={(user) => (
        <>
          {can('login-as') && (
            <Button
              size="small"
              icon={RiLoginBoxLine}
              onClick={() => handleLoginAs(user.id)}
            />
          )}
        </>
      )}
    />
  );
}

export default UsersList;
