import styled, { keyframes } from 'styled-components';

import RocketImage from '~/assets/images/rocket.svg';

const pulse = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #7159c1;
`;

export const Rocket = styled.div`
  width: 50px;
  height: 61px;
  background: url(${RocketImage}) center no-repeat;
  animation: ${pulse} ease-in-out 3s infinite;
`;
