import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';

import { Container } from './styles';

const ColorPicker = forwardRef(
  ({ name, label, required, description, ...rest }, ref) => {
    const {
      control,
      setValue,
      formState: { errors },
    } = useFormContext();

    const selectedColor = useWatch({
      control,
      name,
    });

    const props = {
      ...rest,
      id: name,
      name,
      'aria-label': name,
      type: 'color',
      value: selectedColor || '#8234e8',
      onChange: (e) => setValue(name, e.target.value),
    };

    return (
      <Container>
        {label && (
          <label
            htmlFor={name}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}

            {required && <span style={{ color: 'red' }}>*</span>}

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}

        <input {...props} ref={ref} />

        {errors[name] && <span className="error">{errors[name].message}</span>}
      </Container>
    );
  },
);

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.string,
};

ColorPicker.defaultProps = {
  label: '',
  required: false,
  description: null,
};

export default ColorPicker;
