import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Nome',
    value: 'name',
  },
];

function RolesList() {
  return (
    <PagedList
      resource="roles"
      header="Grupo de permissões"
      columns={columns}
    />
  );
}

export default RolesList;
