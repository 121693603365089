/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { BannerFormProvider, useBannerForm } from '../use-banner-form.hook';
import { bannerFormMapper } from '../banner-form-payload.mapper';
import { SegmentSection } from './Sections/SegmentSection';

import { Alert } from '~/components/ui/alert';
import { Container } from '~/components/HookForm/Form/styles';
import { Input, Check, FileInput, Datepicker } from '~/components/HookForm';
import Button from '~/components/Button';
import api from '~/services/api';
import 'react-day-picker/lib/style.css';
import { slugify } from '~/utils';

export function BannersFormComponent({ match, history }) {
  const { banner, defaultValues, schema, isInDisplayPeriod } = useBannerForm();

  const isUpdating = !!match?.params?.id;

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  async function handleSubmit(data) {
    const payload = bannerFormMapper.mapToHttp(data);

    try {
      const { id } = match?.params || {};

      await api.postOrPut('/banners', id, payload);

      toast.success('Banner salvo com sucesso!');
      history.push('/banners');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  return (
    <>
      {isUpdating && !isInDisplayPeriod && (
        <Alert
          className="bg-yellow-100/50 text-yellow-900 mb-4"
          description="Este banner não está no período de exibição."
        />
      )}

      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Banner`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/banners">
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <section className="flex flex-col">
              <section>
                <FileInput
                  name="banner"
                  path="platform"
                  label="Banner"
                  placeholder="Banner"
                  description={
                    banner.banner_url
                      ? `<img src="${banner.banner_url}" className="w-[500px]" />`
                      : 'Dimensões recomendadas: <span><strong>720</strong>x<strong>720</strong></span>'
                  }
                />

                <Input
                  required
                  name="title"
                  label="Título do banner"
                  placeholder="Título do banner"
                />

                <Datepicker
                  required
                  showTimeSelect
                  name="start_date"
                  timeIntervals={5}
                  label="Data inicial de exibição"
                  placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
                />

                <Datepicker
                  required
                  showTimeSelect
                  name="end_date"
                  timeIntervals={5}
                  label="Data final de exibição"
                  placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
                />
              </section>

              <section>
                <Input
                  name="event_slug"
                  label="Slug do evento"
                  onInput={(e) => {
                    e.target.value = slugify(e.target.value);
                  }}
                  placeholder="slug-do-evento"
                  description={
                    'Slug do evento relacionado ao banner <strong class="text-red-500">(opcional, somente para eventos)</strong>'
                  }
                />

                <Check
                  name="display_to_logged_out_users"
                  label="Exibir para usuários deslogados?"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />

                <Check
                  name="is_enabled"
                  label="Habilitado?"
                  description="Habilita ou desabilita a exibição do banner"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              <section>
                <Input
                  name="cta_url"
                  label="Link do CTA"
                  placeholder="https://www.rocketseat.com.br/formacao/devops"
                />

                <Check
                  name="is_external_cta_url"
                  description="Define se o link é externo ou interno"
                  label="É um link externo?"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              <SegmentSection />

              <Button
                size="block"
                type="submit"
                loading={form.formState.isSubmitting}
              >
                Salvar
              </Button>
            </section>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

export default function BannersForm({ match, history }) {
  return (
    <BannerFormProvider bannerId={match?.params?.id}>
      <BannersFormComponent match={match} history={history} />
    </BannerFormProvider>
  );
}

BannersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
