import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationFormProvider,
  useNotificationForm,
} from './notifications-form.hook';
import rawSteps from './Steps';
import StepNavigation from './components/StepNavigation';

import Button from '~/components/Button';

function NotificationsFormComponent({ match }) {
  const isUpdating = !!match.params?.id;

  const { currentStep, setStepsCount, notification, onSubmit, isSubmitting } =
    useNotificationForm();

  const steps = notification?.is_published ? rawSteps.slice(0, -1) : rawSteps;

  const Step = steps[currentStep];

  useEffect(() => {
    setStepsCount(steps.length);
  }, [setStepsCount, steps.length]);

  return (
    <div className="flex flex-col gap-8 max-w-[800px] mx-auto">
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Criar'} Notificação`}</h1>

        <div>
          <Button color="cancel" to="/notifications">
            Cancelar
          </Button>

          {(!isUpdating || notification?.is_drafting) && (
            <Button
              outline
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={() => onSubmit(true)}
            >
              Salvar Rascunho
            </Button>
          )}
        </div>
      </header>

      <Step />

      <StepNavigation />
    </div>
  );
}

export default function NotificationsForm({ match, history }) {
  return (
    <NotificationFormProvider notificationId={match?.params?.id}>
      <NotificationsFormComponent match={match} history={history} />
    </NotificationFormProvider>
  );
}

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

NotificationsFormComponent.propTypes = propTypes;
NotificationsForm.propTypes = propTypes;
