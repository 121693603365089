import Ws from '@adonisjs/websocket-client';
import { toast } from 'react-toastify';
import { eventChannel } from 'redux-saga';
import {
  all,
  spawn,
  takeLatest,
  call,
  take,
  put,
  select,
} from 'redux-saga/effects';

import { Types as AuthTypes } from '../auth/actions';
import { Types } from './actions';

export function connect(ws) {
  return eventChannel(() => {
    ws.connect();

    return () => {
      ws.close();
    };
  });
}

export function* closeUserChannel(ws) {
  const id = yield select((state) => state.user.userData.id);

  const channel = ws.getSubscription(`user:${id}`);
  channel && channel.close();
}

export function subscribeUser({ ws, id }) {
  return eventChannel((emitter) => {
    const channel =
      ws.getSubscription(`user:${id}`) || ws.subscribe(`user:${id}`);

    channel.on('logout', () => {
      toast.error('Login realizado em outro navegador');
      return emitter({
        type: AuthTypes.SIGN_OUT_REQUEST,
        refreshToken: localStorage.getItem('@Skylab:refreshToken'),
      });
    });

    return () => {
      channel.close();
    };
  });
}

export function* watchUserSubscribtion(ws, { id }) {
  const channel = yield call(subscribeUser, {
    ws,
    id,
  });

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* initWebsocket() {
  const protocol = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';
  const ws = Ws(`${protocol}://${process.env.REACT_APP_WS_URL}`);

  connect(ws);

  yield all([
    takeLatest(Types.SUBSCRIBE_USER_REQUEST, watchUserSubscribtion, ws),
    takeLatest(AuthTypes.SIGN_OUT_REQUEST, closeUserChannel, ws),
  ]);
}

export default all([spawn(initWebsocket)]);
