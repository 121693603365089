/* eslint-disable no-restricted-syntax */
import React from 'react';

import * as FormComponents from '~/components/HookForm';

const ComponentsMapper = {
  string: FormComponents.Input,
  number: FormComponents.Input,
  boolean: FormComponents.Check,
};

const typeMapper = {
  string: 'text',
  number: 'number',
  boolean: 'checkbox',
};

export function transformSegmentParamValues(segment, paramValues) {
  if (!segment) {
    return {};
  }

  const { journey_slug, team_identifier, emails, ...rest } = paramValues || {};
  const parameters = rest;

  for (const [key, value] of Object.entries(rest)) {
    if (segment.parameters[key]?.type === 'number') {
      parameters[key] = value ? Number(value) : undefined;
    }
  }

  if (journey_slug) {
    parameters.journey_slug = journey_slug.slug;
  }

  if (team_identifier) {
    parameters.team_identifier = team_identifier.identifier;
  }

  if (emails) {
    parameters.emails = emails.split(',').map((email) => email.trim());
  }

  return parameters;
}

export function buildSegmentField({ param, key, field, loader }) {
  const Component = ComponentsMapper?.[param.type] || FormComponents.Input;

  if (loader) {
    return (
      <FormComponents.Dropdown
        {...field}
        async
        key={key}
        isClearable
        name={key}
        label={param.title}
        load={loader}
        placeholder={param.title}
        required={param.required}
        description={param.description}
        loadDefaultValue={async (value) => value}
      />
    );
  }

  return param.type === 'boolean' ? (
    <Component
      {...field}
      key={key}
      name={key}
      label={param.title}
      description={param.description}
      required={param.required}
      options={[
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
      ]}
    />
  ) : (
    <Component
      {...field}
      key={key}
      name={key}
      label={param.title}
      type={typeMapper[param.type]}
      description={param.description}
      required={param.required}
      multiline={param.is_array}
      {...(param.type === 'number' && {
        min: 0,
        step: 1,
      })}
    />
  );
}

/**
 *  During an update, dynamic fields such as journeys and teams may only contain slugs or identifiers.
 *  We need to restore these fields to their full state by retrieving the correct labels, ids and etc.
 *  This also ensures that the form is pre-filled with the correct initial values for these fields,
 *  preventing empty fields when values already exist.
 * */
export function restoreFieldState({ form, key, dataSet }) {
  const formParams = form.getValues(key);

  if (formParams) {
    if (!formParams.label) {
      const data = dataSet?.find((d) => d.value === formParams.value) || {};

      form.setValue(key, {
        ...data,
        ...formParams,
        label: data?.label,
      });
    }
  }
}

export function formatNumber(value) {
  return parseFloat(value).toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
  });
}
