import { DocumentStatusReviewed } from '../../constants';

/**
 * Checks if the provided document has been reviewed.
 * @param {Object} document - The document object to check.
 * @param {string} document.status - The status of the document.
 * @returns {boolean} - Returns true if the document is reviewed, false otherwise.
 */
export const isDocumentReviewed = (document) => {
  return DocumentStatusReviewed.includes(document?.status);
};
