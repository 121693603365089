/* eslint-disable import/no-extraneous-dependencies */

import React, { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { Fieldset } from './TrailTab/styles';
import { useEventForm } from '../use-event-form.hook';

import { Alert } from '~/components/ui/alert';

export function IntegrationTab() {
  const { watch } = useFormContext();
  const { trails } = useEventForm();

  const [slug, eventTrails] = watch(['slug', 'eventTrails']);

  const trailsSlug = useMemo(() => {
    return eventTrails.map((eventTrail) => {
      const trailItem = trails.find(
        (trail) => trail.value === eventTrail.trail.value,
      );

      return trailItem?.slug;
    });
  }, [eventTrails, trails]);

  return (
    <div className="flex flex-col gap-2">
      <Alert
        title="Configurações dos Detalhes"
        description="Essas configurações ajustam detalhes visuais na platafroma e alguns redirecionamentos."
        className="mb-4"
      />

      <Fieldset>
        <legend>Endpoint: Registrar usuário</legend>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
          <code className="bg-gray-100 p-2 rounded">
            POST
            {` /public/event/${slug}/register`}
          </code>

          <h3 className="text-xl font-semibold mt-4 mb-2">Request Body</h3>
          <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
            {`{
  "name": "Gabriel Buzzi Venturi"
  "email": "gabriel.buzzi@rocketseat.team",
  "phone": "47999999999",
  "trail": "react" // ${trailsSlug.join(', ')},
  "pageUri": "http://www.rocketseat.com.br/nlw/inscricao" // URL da página onde foi feito a inscrição,
  "referralId": ""
}`}
          </pre>
        </div>
      </Fieldset>

      <Fieldset>
        <legend>Endpoint: Listar indicações</legend>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
          <code className="bg-gray-100 p-2 rounded">
            POST
            {` /event/${slug}/referral/:referralId`}
          </code>

          <h3 className="text-xl font-semibold mt-4 mb-2">Response</h3>
          <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
            {`{
	"totalCount": 4168
}`}
          </pre>
        </div>
      </Fieldset>
    </div>
  );
}
