import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdArrowBack, MdLink } from 'react-icons/md';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { Form, Input, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError } from '~/utils';

import schema from './schema';
import { StatusBox, Entries, Entry, Awaiting, Divider } from './styles';

function LessonSubmissionsEntries({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submission, setSubmission] = useState({});

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.put(`/lesson-submissions/${id}`, data);

      toast.success('Review enviado com sucesso!');
      setSubmitting(false);
      history.push('/lesson-submissions');
    } catch (err) {
      showError(err);
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadSubmission(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/lesson-submissions/${id}`);

        const lessonEntries = response.entries.filter(
          (entry) => entry.type === 'lesson',
        );

        const lastLessonEntry = lessonEntries.length
          ? lessonEntries.pop()
          : null;

        await setSubmission({
          ...response,
          lastLessonEntry,
        });
      } catch (err) {
        showError(err);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    loadSubmission(id);
  }, []);

  function formatDate(date) {
    return format(date, 'DD/MM/YYYY - HH:mm');
  }

  function renderEntry(entry, idx) {
    const lessonType =
      idx === 0 ? 'uma proposta de aula' : 'uma versão da aula';

    return (
      <Entry key={entry.id} type={entry.type}>
        <h5>
          {`${entry.user.name} enviou ${
            entry.type === 'review' ? 'um review' : lessonType
          } em ${formatDate(entry.created_at)}`}
        </h5>

        {entry.title && <h3>{entry.title}</h3>}

        {entry.link && (
          <a href={entry.link} target="_blank" rel="noopener noreferrer">
            <MdLink size="16" />
            {entry.link}
          </a>
        )}

        <pre>{entry.description}</pre>
      </Entry>
    );
  }

  const reviewOptions = [
    {
      id: 'changes-requested',
      title: 'Solicitar submissão',
    },
    { id: 'rejected', title: 'Rejeitado' },
  ];

  if (submission.entries && submission.entries.length > 1) {
    reviewOptions.unshift({ id: 'approved', title: 'Aprovado' });
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>
          {submission.lastLessonEntry && submission.lastLessonEntry.title}
        </h1>

        <div>
          <Button icon={MdArrowBack} color="success" to="/lesson-submissions">
            Voltar
          </Button>
        </div>
      </header>

      {submission.status === 'approved' && (
        <StatusBox approved>
          <p>
            {`Aula aprovada por ${submission.admin.name} em ${formatDate(
              submission.updated_at,
            )}`}
          </p>

          {renderEntry(submission.lastLessonEntry)}
        </StatusBox>
      )}

      {submission.status === 'rejected' && (
        <StatusBox rejected>
          <p>
            {`Aula rejeitada por ${submission.admin.name} em ${formatDate(
              submission.updated_at,
            )}`}
          </p>
        </StatusBox>
      )}

      {submission.entries && (
        <Entries>
          {submission.entries.map((entry, idx) => renderEntry(entry, idx))}
        </Entries>
      )}

      {submission.status === 'changes-requested' && (
        <Awaiting>
          <span>...</span>
          Aguardando nova versão da aula
        </Awaiting>
      )}

      {submission.status === 'awaiting-review' && (
        <>
          <Divider>Enviar review da aula</Divider>

          <Form schema={schema} onSubmit={handleSubmit}>
            <section>
              <Dropdown label="Status" name="status" options={reviewOptions} />
            </section>
            <section>
              <Input multiline name="description" placeholder="Review" />
            </section>

            <Button type="submit" size="block" loading={submitting}>
              Enviar
            </Button>
          </Form>
        </>
      )}
    </Page>
  );
}

LessonSubmissionsEntries.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default LessonSubmissionsEntries;
