import styled from 'styled-components';

export const LicenseContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  > section {
    display: flex;
    align-items: center;

    > div {
      margin: 0;
    }

    > input {
      margin: 0;
    }

    button {
      margin-top: 25px;
      height: 50px;
    }
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  > p {
    margin: 0 !important;
  }
`;

export const Title = styled.h1`
  font-size: 20px !important;
  font-weight: 700;

  color: #302b3e;
`;

export const Button = styled.button`
  overflow: hidden;
  width: 38px;
  max-height: 38px;
  background: ${({ disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.025)' : 'rgba(0, 0, 0, 0.1)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;
  margin-top: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .icon {
    color: ${({ disabled }) => (disabled ? '#ccc' : '#302b3ecc')};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: ${({ isFirst }) => (isFirst ? 'center' : 'flex-start')};

  justify-content: center;
  gap: 0.5rem;

  flex: 0 !important;
  flex-direction: row !important;
`;

export const HeadButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
`;

export const StatusContainer = styled.div`
  max-width: 120px;

  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const StatusLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  > h1 {
    font-size: 16px;
    font-weight: 600;
  }

  > p {
    color: rgb(117, 117, 117);
    font-size: 12px;
  }
`;

export const Status = styled.div`
  width: 100%;
  height: 37px;

  border: 1px solid #ccc;
  border-radius: 4px;

  background-color: #efefef;
  color: #757575;
  padding: 0 10px;

  display: flex;
  align-items: center;
`;

export const ListContainer = styled.div`
  > div {
    width: 100%;

    > section {
      width: 100%;
      display: flex;
      gap: 1rem;
    }
  }
`;
