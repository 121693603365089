import * as Yup from 'yup';

const schema = Yup.object().shape({
  identifier: Yup.string().required('Identificador é um campo obrigatório'),
  title: Yup.string().required('Título é um campo obrigatório'),
  mgmPoints: Yup.number().min(0, 'Informe um número positivo').default(0),
  type: Yup.string()
    .oneOf(['DEFAULT', 'CONTRACT'])
    .required('Tipo é um campo obrigatório'),
  contentType: Yup.string()
    .oneOf([
      'ONE',
      'SPECIALIZATION',
      'COURSE',
      'MENTORING',
      'IMMERSION',
      'POST_GRADUATION',
      'GRADUATION',
      'EXTRA',
    ])
    .required('Tipo de contéudo é um campo obrigatório'),
});

export default schema;
