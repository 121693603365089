import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 0.5rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const SectionHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > h1 {
    font-size: 16px;
    font-weight: 600;
  }
`;
