import React, { useContext, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';

import { notificationFormMapper } from './notifications-form-payload.mapper';

import api from '~/services/api';
import Page from '~/components/Page';
import Button from '~/components/Button';

export const NotificationFormContext = createContext({});

async function getNotification(id) {
  if (id) {
    const { data } = await api.get(`/notifications/${id}`);

    return data;
  }

  return {};
}

async function getCategoryGroups() {
  const { data: response } = await api.get('/notifications/categories/groups', {
    params: { per_page: 999 },
  });

  const categoryGroups = response.data.map((categoryGroup) => ({
    label: categoryGroup.title,
    options: categoryGroup.categories.map((category) => ({
      value: category.id,
      label: category.title,
      channels: category.channels,
    })),
  }));

  return categoryGroups;
}

async function getChannels() {
  const { data: response } = await api.get('/notifications/channels', {
    params: { per_page: 999 },
  });

  return response?.data;
}

export const NotificationFormProvider = ({ notificationId, children }) => {
  const history = useHistory();
  const location = useLocation();

  const [formRef, setFormRef] = useState(null);
  const [stepsCount, setStepsCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [notification, setNotification] = useState({});
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const goToPreviousStep = () => {
    setCurrentStep((step) => step - 1);
  };

  const onSubmit = async (is_drafting = false) => {
    setIsSubmitting(true);

    try {
      const { data: response } = await api.postOrPut(
        '/notifications',
        notification?.id,
        notificationFormMapper.mapToHttp({ ...notification, is_drafting }),
      );

      if (response?.errors?.length) {
        return response.errors.forEach((error) => {
          toast.error(error);
        });
      }

      if (is_drafting) {
        toast.success('Rascunho salvo com sucesso!');
        history.push(`/notifications/edit/${response.id}?step=${currentStep}`);
      } else {
        toast.success('Notificação salva com sucesso!', {
          delay: 2,
          closeButton: (
            <div style={{ marginLeft: 16 }}>
              <Button
                onClick={() => {
                  history.push(`/notifications/edit/${response.id}`);
                }}
                style={{ background: 'rgba(0,0,0,0.6)' }}
              >
                Voltar
              </Button>
            </div>
          ),
        });

        history.push('/notifications');
      }
    } catch (err) {
      toast.error(
        `Ocorreu um erro ao salvar ${
          is_drafting ? 'o rascunho' : 'a notificação'
        }`,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onStepSubmit = () => {
    if (currentStep + 1 < stepsCount) {
      return setCurrentStep((step) => step + 1);
    }

    onSubmit();
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const [notificationData, categoryGroupsList, channelsList] =
          await Promise.all([
            getNotification(notificationId),
            getCategoryGroups(),
            getChannels(),
          ]);

        setNotification(
          notificationFormMapper.mapToDefaultValues(notificationData),
        );
        setCategoryGroups(categoryGroupsList);
        setChannels(channelsList);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [notificationId]);

  useEffect(() => {
    if (notification?.id && location.search) {
      const params = new URLSearchParams(location.search);
      const step = params.get('step');

      if (Number(step) && Number(step) < stepsCount) {
        setCurrentStep(Number(step));
      }
    }
  }, [location.search, notification?.id, stepsCount]);

  if (isLoading) {
    return <Page loading>Carregando</Page>;
  }

  return (
    <NotificationFormContext.Provider
      value={{
        formRef,
        setFormRef,
        notification,
        setNotification,
        categoryGroups,
        channels,
        isLoading,
        stepsCount,
        setStepsCount,
        currentStep,
        onStepSubmit,
        goToPreviousStep,
        onSubmit,
        isSubmitting,
      }}
    >
      <Page loading={false}>{children}</Page>
    </NotificationFormContext.Provider>
  );
};

NotificationFormProvider.propTypes = {
  notificationId: PropTypes.string,
  children: PropTypes.element.isRequired,
};

NotificationFormProvider.defaultProps = {
  notificationId: null,
};

export const useNotificationForm = () => {
  const context = useContext(NotificationFormContext);

  if (!context) {
    throw new Error(
      'useNotificationForm must be used within a NotificationFormProvider',
    );
  }

  return context;
};
