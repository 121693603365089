import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { deburr } from 'lodash';

import { Container, Menu, Link } from './styles';
import { navLinks } from './links';
import { Search } from './Search';

import logoImage from '~/assets/images/logo.svg';
import { useCan } from '~/utils';

function getHighlightedText(text, search) {
  let start = 0;
  let end = 0;

  deburr(text).replace(
    new RegExp(`(${deburr(search)})`, 'gmi'),
    (match, _, offset) => {
      start = offset;
      end = offset + match.length;

      return match;
    },
  );

  const matchedPartOfText = text.slice(start, end);

  return text.replace(
    new RegExp(`(${matchedPartOfText})`, 'gmi'),
    (match) => `<mark>${match}</mark>`,
  );
}

const Nav = () => {
  const can = useCan();
  const location = useLocation();

  const [search, setSearch] = useState('');

  const filteredNavLinks = navLinks
    .map((section) => ({
      ...section,
      links: section.links
        .filter(
          (link) =>
            !search ||
            deburr(link.name.toLowerCase()).includes(
              deburr(search.toLowerCase()),
            ),
        )
        .map((link) =>
          !search
            ? link
            : {
                ...link,
                name: (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: getHighlightedText(link.name, search),
                    }}
                  />
                ),
              },
        ),
    }))
    .filter((section) => section.links.length);

  useEffect(() => {
    const activeElement = document.querySelector('.active');

    if (!activeElement) {
      return;
    }

    activeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [location.pathname]);

  return (
    <Container>
      <header>
        <div>
          <img src={logoImage} alt="Rocketseat" />
          <strong>Backoffice</strong>
        </div>
      </header>

      <Menu>
        <Search value={search} onChange={setSearch} />

        {filteredNavLinks.map((section) => {
          const sectionPermissions = section.links.reduce((acc, link) => {
            if (link.permissions) {
              acc.push(...link.permissions);
            }

            return acc;
          }, []);

          const showSection = can(sectionPermissions);

          if (!showSection) return null;

          return (
            <nav key={section.name}>
              <strong>{section.name.toUpperCase()}</strong>

              <ul>
                {section.links.map((link) => {
                  const showLink = can(link.permissions);

                  if (!showLink) return null;

                  return (
                    <li key={link.url}>
                      <Link
                        to={link.url}
                        {...(link.isActive && { isActive: link.isActive })}
                      >
                        {link.icon}
                        {link.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          );
        })}
      </Menu>
    </Container>
  );
};

export default Nav;
