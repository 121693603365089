import { transparentize } from 'polished';
import styled from 'styled-components';

export const StatusBox = styled.div`
  margin: 20px 0 50px;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
  border-radius: 4px;
  padding: 20px;

  > p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }

  > div {
    width: 100%;
    float: none;
    border: 0;
    padding: 0;
    margin-top: 20px;

    h5 {
      display: none;
    }
  }

  ${({ approved }) =>
    approved &&
    `
    border-color: #0b9481;

    > p {
      color: #0b9481;
    }
  `}

  ${({ rejected }) =>
    rejected &&
    `
    border-color: #940b0b;

    > p {
      color: #940b0b;
    }
  `};
`;

export const Entries = styled.div`
  width: 100%;
  margin: 30px 0;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const Entry = styled.div`
  width: 50%;
  float: left;
  clear: both;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;

  ${({ type }) =>
    type === 'review' &&
    `
    float: right;
    border: 1px solid #7159c1
  `}

  h5 {
    color: #666;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    font-weight: normal;
  }

  h3 {
    font-size: 18px;

    + pre {
      margin-top: 10px;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    color: #7159c1;
    margin: 5px 0 15px;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  pre {
    white-space: pre-wrap;
  }

  + div {
    margin-top: 15px;
  }
`;

export const Awaiting = styled.div`
  text-align: center;
  color: #666;
  text-transform: uppercase;
  font-size: 13px;
  padding-bottom: 20px;

  span {
    display: block;
    color: #7159c1;
    font-size: 2em;
  }
`;

export const Divider = styled.div`
  position: relative;
  text-align: center;
  margin: 50px 0 -5px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #302b3e;

  &::before,
  &::after {
    content: '';
    width: calc(50% - 100px);
    height: 1px;
    position: absolute;
    border-top: 1px dashed ${transparentize(0.9, '#302B3E')};
  }

  &::before {
    left: 0;
    top: 10px;
  }

  &::after {
    right: 0;
    top: 10px;
  }
`;
