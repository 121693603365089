import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import { useField } from '@rocketseat/unform';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { get } from 'dot-prop-immutable';
import { FaInfoCircle } from 'react-icons/fa';

import Field from '../Field';

function Dropdown({
  name,
  label,
  multiple,
  load,
  getOptionLabel,
  getOptionValue,
  options,
  onChange,
  disabled,
  async = false,
  description = null,
  loadDefaultValue,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [selected, setSelected] = useState(multiple ? [] : null);
  const [optionsFromAPI, setOptions] = useState([]);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  function handleChange(value) {
    setSelected(value);
    onChange(value);
  }

  const getDefaultValue = useCallback(() => {
    if (!defaultValue) return null;

    const values = options.length ? options : optionsFromAPI;

    if (!multiple) {
      return values.find((option) => option.id === defaultValue);
    }

    return defaultValue.map((item) =>
      !item?.id ? values.find((option) => option.id === item) : item,
    );
  }, [defaultValue, multiple, options, optionsFromAPI]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      parseValue: () => {
        const selectValue = selected;

        if (!multiple) {
          return selectValue ? selectValue?.id : '';
        }

        return selectValue ? selectValue.map((option) => option?.id) : [];
      },
      clearValue: (selectRef) => {
        selectRef.select.clearValue();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, options, selected, multiple, ref.current, fieldName]);

  useEffect(() => {
    async function fetchData() {
      if (load) {
        setIsLoadingOptions(true);

        const data = await load();

        setIsLoadingOptions(false);
        setOptions(data);
      }
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (loadDefaultValue) {
        if (!defaultValue) {
          setSelected(null);
          return;
        }

        try {
          setIsLoadingOptions(true);
          const data = await loadDefaultValue(defaultValue);

          setSelected({
            ...data,
            title: get(data, 'history.$end.tagged_title'),
          });
        } catch {
          toast.error(`Erro ao carregar valor inicial de: ${label}`);
        } finally {
          setIsLoadingOptions(false);
        }
      } else if (!selected?.length) {
        setSelected(getDefaultValue());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, defaultValue, multiple, optionsFromAPI, loadDefaultValue]);

  const Component = async ? AsyncSelect : Select;

  return (
    <Field>
      <>
        {label && (
          <label
            htmlFor={fieldName}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {label}

            {description && (
              <FaInfoCircle
                size={12}
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
              />
            )}
          </label>
        )}

        <Component
          name={fieldName}
          aria-label={fieldName}
          options={options.length ? options : optionsFromAPI}
          isMulti={multiple}
          value={selected}
          ref={ref}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          onChange={handleChange}
          isDisabled={disabled || isLoadingOptions}
          {...rest}
        />
        {error && <span>{error}</span>}
      </>
    </Field>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  load: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  async: PropTypes.bool,
  loadDefaultValue: PropTypes.func,
  description: PropTypes.string,
};

Dropdown.defaultProps = {
  multiple: false,
  options: [],
  load: null,
  getOptionValue: (option) => option.id,
  getOptionLabel: (option) => option.title,
  onChange: () => {},
  disabled: false,
  async: false,
  description: null,
  loadDefaultValue: null,
};

export default Dropdown;
