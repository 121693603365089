/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';

import { buildFormSchema } from './banner-form.schema';

import api from '~/services/api';
import Page from '~/components/Page';
import { isDateInPeriod } from '~/utils';

async function getBanner(id) {
  const { data } = await api.get(`/banners/${id}`);

  return data;
}

function makeDefaultValues({ banner }) {
  const isUpdating = !!banner?.id;

  function rebuildSegmentParams(segmentParams) {
    if (!segmentParams) {
      return;
    }

    if (segmentParams?.journey_slug) {
      segmentParams.journey_slug = {
        value: segmentParams.journey_slug,
      };
    }

    if (segmentParams?.team_identifier) {
      segmentParams.team_identifier = {
        value: segmentParams.team_identifier,
      };
    }

    return segmentParams;
  }

  const segment = banner?.segmentRelationship?.segment
    ? {
        ...(banner?.segmentRelationship?.segment || {}),
        value: banner?.segmentRelationship?.segment_id || undefined,
        label: banner?.segmentRelationship?.segment?.title || undefined,
      }
    : undefined;

  return {
    title: banner.title,
    banner: banner.banner,
    cta_url: banner?.cta_url,
    event_slug: banner?.event_slug,

    start_date:
      isUpdating && banner?.start_date
        ? new Date(banner.start_date)
        : undefined,

    end_date:
      isUpdating && banner?.end_date ? new Date(banner.end_date) : undefined,

    // FLAGS
    is_enabled: isUpdating ? banner?.is_enabled : null,
    is_external_cta_url: isUpdating ? banner.is_external_cta_url : null,

    display_to_logged_out_users: isUpdating
      ? banner.display_to_logged_out_users
      : null,

    // SEGMENT
    segment: isUpdating ? segment : undefined,
    params: rebuildSegmentParams(banner.segmentRelationship?.parameters),
  };
}

export const BannerFormProvider = ({ bannerId, children }) => {
  const [banner, setBanner] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState(buildFormSchema());

  const defaultValues = useMemo(
    () =>
      makeDefaultValues({
        banner,
      }),
    [banner],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true);

        const [bannerData] = await Promise.all([
          bannerId ? getBanner(bannerId) : {},
        ]);

        setBanner(bannerData);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [bannerId]);

  const isInDisplayPeriod = useMemo(
    () => isDateInPeriod(banner.start_date, banner.end_date),
    [banner],
  );

  if (isLoading) {
    return <Page loading>Carregando</Page>;
  }

  return (
    <BannerFormContext.Provider
      value={{
        banner,
        schema,
        setSchema,
        isLoading,
        defaultValues,
        isInDisplayPeriod,
      }}
    >
      <Page loading={false}>{children}</Page>
    </BannerFormContext.Provider>
  );
};

export const BannerFormContext = createContext({});

export const useBannerForm = () => {
  const context = useContext(BannerFormContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};
