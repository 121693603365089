import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { Types as UserTypes } from './actions';

export const INITIAL_STATE = Immutable({
  loaded: false,
  userData: {},
});

export default createReducer(INITIAL_STATE, {
  [UserTypes.SAVE_USER_DATA]: (state, { user }) =>
    state.merge({ userData: user, loaded: true }),
});
