import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Dropdown, Input } from '~/components/Form';

const typeOptions = [
  { id: 'journey', title: 'Jornada' },
  { id: 'team', title: 'Time' },
  { id: 'event', title: 'Evento' },
];

export default function RuleFields({ rule }) {
  const [fieldOptions, setFieldOptions] = useState([]);

  function updateFieldOptions(selectedType) {
    let options = [];

    switch (selectedType.id) {
      case 'journey':
        options = [
          { id: 'id', title: 'ID' },
          { id: 'title', title: 'Título' },
          { id: 'type', title: 'Tipo' },
          { id: 'slug', title: 'Slug' },
        ];
        break;
      case 'team':
        options = [
          { id: 'id', title: 'ID' },
          { id: 'title', title: 'Título' },
          { id: 'identifier', title: 'Identificador' },
        ];
        break;
      case 'event':
        options = [
          { id: 'event_id', title: 'ID' },
          { id: 'trail', title: 'Trilha' },
        ];
        break;
      default:
        setFieldOptions([]);
    }

    setFieldOptions(options);
  }

  useEffect(() => updateFieldOptions({ id: rule.type }), [rule.type]);

  return (
    <>
      <Dropdown
        name="type"
        label="Tipo"
        options={typeOptions}
        placeholder="Selecione o tipo da regra"
        onChange={updateFieldOptions}
      />

      <Dropdown
        name="resource_field"
        label="Campo"
        options={fieldOptions}
        placeholder="Selecione o campo"
      />

      <Input
        name="resource_value"
        label="Valor"
        placeholder="Valor do campo"
        style={{ height: 50 }}
      />
    </>
  );
}

RuleFields.propTypes = {
  rule: PropTypes.shape({
    type: PropTypes.string,
    resource_field: PropTypes.string,
    resource_value: PropTypes.string,
  }).isRequired,
};
