import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;

  section {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  hr {
    margin: 15px 0;
    border: 0;
    height: 1px;
    background: ${transparentize(0.9, '#302B3E')};
  }

  fieldset {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 15px;

    legend {
      font-size: 13px;
      font-weight: 600;
      padding: 2px 10px;
      background: #7159c1;
      border-radius: 4px;
      color: #fff;
      text-transform: uppercase;
    }

    footer {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }
`;
