import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { MdSearch, MdAdd } from 'react-icons/md';
import Select from 'react-select';

import { languages, themes } from '../types';

import api from '~/services/certificates.api';
import { Page } from '~/pages/styles';
import { Filters, SearchForm } from '~/components/PagedList/styles';
import { Input } from '~/components/Form';
import Button from '~/components/Button';
import { useCan } from '~/utils';
import { Table, Actions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import SpinnerLoading from '~/components/SpinnerLoading';

function CertificatesList() {
  const [loading, setLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [languageFilter, setLanguageFilter] = useState(null);
  const [themeFilter, setThemeFilter] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const itemsPerPage = 20;
  const can = useCan();

  useEffect(() => {
    async function loadCertificates() {
      try {
        setLoading(true);

        const { data: response } = await api.get('/certificates', {
          params: {
            page: currentPage,
            language: languageFilter?.value,
            theme: themeFilter?.value,
            search,
          },
        });

        setCertificates(response.certificates);
        setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
        setTotalCount(response.totalCount);
      } catch (err) {
        toast.error('Erro ao buscar certificados.');
      } finally {
        setLoading(false);
      }
    }

    loadCertificates();
  }, [currentPage, languageFilter, themeFilter, search]);

  function handlePaginate(newPage) {
    setCurrentPage(newPage);
  }

  function handleFilter(name, data) {
    if (name === 'language') {
      setSelectedLanguage(data);
      setLanguageFilter(data);
    }

    if (name === 'theme') {
      setSelectedTheme(data);
      setThemeFilter(data);
    }

    handlePaginate(1);
  }

  function handleSearch(e) {
    e.preventDefault();
    handlePaginate(1);
  }

  function renderHeader() {
    return (
      <header>
        <h1>Lista de Certificados</h1>
        <div>
          {!loading && (
            <Filters>
              <Select
                key="language"
                name="Idiomas"
                placeholder="Idioma"
                options={languages}
                isClearable
                value={selectedLanguage}
                onChange={(data) => handleFilter('language', data)}
              />

              <Select
                key="theme"
                name="Temas"
                placeholder="Tema "
                options={themes}
                isClearable
                value={selectedTheme}
                onChange={(data) => handleFilter('theme', data)}
              />
            </Filters>
          )}

          <SearchForm onSubmit={handleSearch}>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />
            <Button type="submit" icon={MdSearch} color="success" />
          </SearchForm>

          {can(`create_certificates`) && (
            <Button
              icon={MdAdd}
              color="success"
              to="/certificate-service/certificate/new"
            >
              Novo
            </Button>
          )}
        </div>
      </header>
    );
  }

  function renderBody() {
    return (
      <Table loading={loading}>
        <thead>
          <tr>
            <th>Título</th>
            <th>Language</th>
            <th>Theme </th>
          </tr>
        </thead>
        <tbody>
          {certificates.map((certificate) => (
            <tr key={certificate.id}>
              <td>{certificate.title}</td>
              <td>{certificate.language}</td>
              <td>{certificate.theme}</td>
              <td>
                <Actions
                  href={`/certificate-service/certificate/edit/${certificate.id}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Page>
      {renderHeader()}

      {loading && <SpinnerLoading />}

      {!loading && certificates.length === 0 && (
        <p>Nenhum certificado encontrado.</p>
      )}

      {!loading && certificates.length > 0 && renderBody()}

      <Pagination
        activePage={currentPage}
        pageCount={totalPages}
        total={totalCount}
        perPage={itemsPerPage}
        onChange={handlePaginate}
      />
    </Page>
  );
}

CertificatesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CertificatesList;
