import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import TwoArrow from '~/assets/images/icons/arrow-two.svg';
import Arrow from '~/assets/images/icons/arrow.svg';
import { min } from '~/utils';

import { Container, Navigation, Page } from './styles';

function Pagination({ activePage, pageCount, total, perPage, onChange }) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(activePage);
  }, [activePage]);

  function changePage(newPage) {
    setPage(newPage);
    onChange(newPage);
  }

  function handlePrev() {
    if (page === 1) return;

    const newPage = page - 1;

    changePage(newPage);
  }

  function handleNext() {
    if (page === pageCount) return;

    const newPage = page + 1;

    changePage(newPage);
  }

  function renderNavigation(isLeft, disabled) {
    const res = [];
    const icons = [isLeft ? TwoArrow : Arrow, isLeft ? Arrow : TwoArrow];
    const clicks = [
      isLeft ? () => changePage(1) : () => handleNext(),
      isLeft ? () => handlePrev() : () => changePage(pageCount),
    ];

    for (let i = 0; i < 2; i += 1) {
      const element = (
        <Navigation
          onClick={clicks[i]}
          disabled={disabled}
          left={isLeft}
          icon={icons[i]}
          key={i}
        >
          <div />
        </Navigation>
      );

      res.push(element);
    }

    return res;
  }

  function renderPagination() {
    const res = [];

    if (pageCount <= 1) return res;

    const left = page - 5;
    const right = page + 6;
    const pages = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
      (i) => i === pageCount || (i >= left && i < right),
    );

    const leftControls = page === 1;
    const rightControls = page === pageCount;

    const Previous = renderNavigation(true, leftControls);
    const Next = renderNavigation(false, rightControls);

    const TotalResult = (
      <li key="total-result">
        <p>{`Showing ${min(page * perPage, total)} of ${total} results.`}</p>
      </li>
    );

    res.push(Previous);
    res.push(
      pages.map((actualPage) => {
        const active = page === actualPage;
        const pageComponent = (
          <Page
            onClick={() => changePage(actualPage)}
            active={active}
            disabled={!!active}
            key={actualPage}
          >
            {actualPage}
          </Page>
        );

        return pageComponent;
      }),
    );
    res.push(Next);

    res.push(TotalResult);

    return res;
  }

  return (
    <Container>
      <ul>{renderPagination()}</ul>
    </Container>
  );
}

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,

  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange() {},
};

export default Pagination;
