import React from 'react';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Slug',
    value: 'slug',
  },
];

export function FormsList() {
  return <PagedList resource="forms" header="Formulários" columns={columns} />;
}
