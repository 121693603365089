/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';

import {
  useLazyOffers,
  useOffer,
  useUpsertOfferMutation,
} from '@shared/skylab/graphql/pluto';
import schema from './schema';
import ProductsForm from './ProductsForm';

import Button from '~/components/Button';
import { Check, Dropdown, FileInput, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import { getLabel, useCan } from '~/utils';
import MarkdownInput from '~/components/Form/MarkdownInput';

const offerPaymentTypesMapper = {
  CREDIT_CARD: 'Cartão de crédito',
  BILLET: 'Boleto',
  PIX: 'PIX',
  PAYPAL: 'PayPal',
};

function OffersForm({ match, history }) {
  const { loading, data, error } = useOffer({
    skip: !match.params.slug,
    variables: { slug: match.params.slug },
  });

  const [fetchOffers] = useLazyOffers();

  const [upsertOffer, { loading: submitting }] = useUpsertOfferMutation({
    onError: () => toast.error('Ocorreu um erro ao salvar a oferta.'),

    onCompleted: () => {
      toast.success('Oferta salva com sucesso!');
      history.push('/offers');
    },
  });

  const can = useCan();
  const offer = data?.offer;
  const viewOnly = !can('edit_offers');
  const isUpdating = !!match.params.slug;

  async function loadOffers() {
    const response = await fetchOffers();
    return response?.data?.offers?.filter((o) => o.id !== offer?.id) || [];
  }

  async function handleSubmit({ offerProducts, relatedOffers, ...values }) {
    upsertOffer({
      variables: {
        data: {
          ...values,
          id: offer?.id,
          products: offerProducts
            .filter(({ deleted }) => !deleted)
            .map(({ product, deleted, ...offerProduct }) => ({
              productId: product.id,
              ...offerProduct,
            })),
          relatedOffersIds: relatedOffers,
        },
      },
    });
  }

  if (error) {
    history.push('/offers');
    return null;
  }

  const acceptedPaymentTypes =
    offer?.acceptedPaymentTypes?.length > 0
      ? offer.acceptedPaymentTypes
      : Object.keys(offerPaymentTypesMapper);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar oferta</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Nova'} oferta`}</h1>
        )}
      </header>

      <Form
        schema={schema}
        initialData={{
          ...(offer || {
            acceptedPaymentTypes,
            renewInMonths: 12,
            refundDays: 15,
            isValidForMGM: false,
            canUseCoupon: true,
            isRecurrent: true,
            isPurchaseable: true,
          }),
        }}
        onSubmit={handleSubmit}
      >
        <section>
          {offer?.iconUrl && (
            <img
              src={offer.iconUrl}
              alt={offer.title}
              style={{
                padding: 10,
                border: '1px solid #ddd',
                marginBottom: 8,
                borderRadius: 5,
                width: 80,
                height: 80,
                objectFit: 'contain',
              }}
            />
          )}

          {!viewOnly && (
            <FileInput
              name="iconUrl"
              label={getLabel(
                'Ícone da oferta',
                'É a imagem que vai aparecer no checkout. Precisa ter largura e altura iguais.',
              )}
              placeholder="Enviar arquivo"
              path="checkout/offers"
              responseKey="url"
            />
          )}
        </section>

        <section>
          <Input
            label={getLabel(
              'Título',
              'Como esta oferta será chamada internamente.',
              true,
            )}
            name="title"
            readOnly={viewOnly}
            maxLength="60"
          />

          <Input
            label={getLabel(
              'Slug',
              'Identifica esta oferta internamente. Não é possível alterar manualmente.',
              true,
            )}
            name="slug"
            readOnly={viewOnly || isUpdating}
            {...(isUpdating && { style: { background: '#EFEFEF' } })}
          />
        </section>

        <section>
          <MarkdownInput
            name="description"
            label={getLabel('Descrição', 'Descreva esta oferta.', true)}
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Input
            label={getLabel(
              'Preço em centavos',
              'Valor do produto em centavos.',
              true,
            )}
            name="priceInCents"
            type="number"
            readOnly={viewOnly}
          />

          <Input
            label={getLabel(
              'Desconto em centavos',
              'Valor do desconto em centavos.',
            )}
            name="discountPriceInCents"
            type="number"
            readOnly={viewOnly}
          />
        </section>

        <fieldset>
          <legend>Configurações</legend>

          <section>
            <Check
              label={getLabel('Recorrência', 'Marque se é recorrente.', true)}
              name="isRecurrent"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              readOnly={viewOnly}
            />

            <Check
              label={getLabel('Status', 'Marque qual o status.', true)}
              name="isPurchaseable"
              options={[
                { value: true, label: 'Habilitado' },
                { value: false, label: 'Desabilitado' },
              ]}
              readOnly={viewOnly}
            />
          </section>

          <section>
            <Check
              label={getLabel(
                'É válido para o MGM',
                'Marque se puder ser usado no MGM (Member-get-Member).',
                true,
              )}
              name="isValidForMGM"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              readOnly={viewOnly}
            />

            <Check
              label={getLabel(
                'Pode ser usado com cupom de desconto',
                'Marque se puder ser usado com cupons.',
                true,
              )}
              name="canUseCoupon"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              readOnly={viewOnly}
            />
          </section>
        </fieldset>

        <fieldset>
          <legend>Configurações do checkout</legend>

          <section>
            <Dropdown
              label={getLabel(
                'Formas de pagamento',
                'As formas de pagamento que são aceitas para essa oferta.',
                true,
              )}
              name="acceptedPaymentTypes"
              multiple
              options={Object.entries(offerPaymentTypesMapper).map(
                ([key, value]) => ({
                  title: value,
                  id: key,
                }),
              )}
              disabled={viewOnly}
            />

            <Input
              label={getLabel(
                'Dias para reembolso',
                'Quanto tempo pode ser reembolsável.',
                true,
              )}
              name="refundDays"
              type="number"
              readOnly={viewOnly}
            />

            <Input
              label={getLabel(
                'Renova em meses',
                'Após quantos meses a oferta irá ser renovada.',
                false,
              )}
              name="renewInMonths"
              type="number"
              readOnly={viewOnly}
            />
          </section>

          <section>
            <Dropdown
              label={getLabel(
                'Ofertas da vitrine',
                'Quais ofertas podem ser utilizadas.',
              )}
              name="relatedOffers"
              multiple
              load={loadOffers}
              disabled={viewOnly}
            />
          </section>
        </fieldset>

        <fieldset>
          <legend>Produtos da oferta</legend>

          <ProductsForm
            products={offer?.offerProducts || []}
            viewOnly={viewOnly}
          />
        </fieldset>

        <section style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button color="cancel" to="/offers">
            Cancelar
          </Button>

          {!viewOnly && (
            <Button type="submit" loading={submitting} disabled={submitting}>
              Salvar oferta
            </Button>
          )}
        </section>
      </Form>
    </Page>
  );
}

OffersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

OffersForm.defaultProps = {
  match: {
    params: {
      slug: '',
    },
  },
};

export default OffersForm;
