import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.type === 'checkbox' ? 'row' : 'column')};
  margin: 0 10px 15px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  label {
    font-size: 16px;
    display: flex;
    font-weight: 600;
    align-items: center;
    margin-bottom: ${(props) => (props.type === 'checkbox' ? 0 : '5px')};

    order: ${(props) => (props.type === 'checkbox' ? 2 : 0)};
  }

  input,
  textarea {
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    border-color: ${(props) => (props.withError ? '#DD5554' : '#ccc')};
    padding: 12px;
    font-size: 14px;
    color: #302b3e;
    resize: vertical;
  }

  .description {
  }

  > span,
  > small {
    color: #dd5554;
    font-size: 12px;

    margin-top: 2px;
  }

  input {
    padding: 0 12px;
    height: 36px;
    width: 100%;
  }

  textarea {
    width: 100%;
    min-height: 120px;
  }

  > span {
  }
`;
