import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useMemo, useCallback } from 'react';

import { Container } from './styles';

export default function Choice({ name, options, multiple, ...rest }) {
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const ref = useRef({ values: [] });

  const nativeField = multiple ? 'checkbox' : 'radio';

  const checked = (value) => {
    if (!defaultValue) return false;
    if (typeof defaultValue === 'string') return [defaultValue].includes(value);

    return Array.from(defaultValue).includes(value);
  };

  const parseValue = useCallback(
    (choiceRef) => {
      const values = choiceRef.values
        .filter((i) => i.checked)
        .map((i) => i.value);
      return multiple ? values : values[0];
    },
    [ref.current.values],
  );

  const clearValue = useCallback(
    (choiceRef) => {
      choiceRef.values.forEach((i) => {
        i.checked = false;
      });
    },
    [ref.current.values],
  );

  useEffect(() => {
    if (ref.current.values.length) {
      registerField({
        name: fieldName,
        path: 'values',
        ref: ref.current,
        parseValue,
        clearValue,
      });
    }
  }, [ref.current, fieldName]);

  const render = useMemo(() => {
    return (
      <>
        {options.map(
          ({ value, label, disabled, checked: defaultChecked }, idx) => {
            const checkboxId = `${fieldName}-${value}`;

            return (
              <Container key={checkboxId} disabled={disabled}>
                <input
                  {...rest}
                  ref={(el) => {
                    ref.current.values[idx] = el;
                  }}
                  type={nativeField}
                  id={checkboxId}
                  name={fieldName}
                  aria-label={checkboxId}
                  value={value}
                  {...(defaultChecked && { checked: true })}
                  defaultChecked={checked(value) || defaultChecked}
                  disabled={disabled}
                />
                {label && <label htmlFor={checkboxId}>{label}</label>}
              </Container>
            );
          },
        )}
        {error && <span>{error}</span>}
      </>
    );
  }, [options, defaultValue]);

  return render;
}

Choice.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

Choice.defaultProps = {
  multiple: false,
};
