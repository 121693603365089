/* eslint-disable import/no-unresolved */
import React from 'react';

import { format } from 'date-fns';

import { Fieldset } from './TrailTab/styles';

import {
  Datepicker,
  Checkbox,
  Input,
  FileInput,
  ColorPicker,
} from '~/components/HookForm';
import { Alert } from '~/components/ui/alert';
import { slugify } from '~/utils';
import PriceImage from '~/assets/images/tooltips/events/price-field.png';
import PlaceImage from '~/assets/images/tooltips/events/place-field.png';
import TitleImage from '~/assets/images/tooltips/events/title-field.png';
import SlugImage from '~/assets/images/tooltips/events/slug-field.png';
import DescriptionImage from '~/assets/images/tooltips/events/description-field.png';
import BannerImage from '~/assets/images/tooltips/events/banner-field.png';
import VisibleImage from '~/assets/images/tooltips/events/visible-field.png';
import DateImage from '~/assets/images/tooltips/events/date-field.png';

export function EventTab() {
  return (
    <div className="flex flex-col gap-2">
      <Alert
        title="Configurações do Evento"
        description="Essas configurações são responsáveis por ajustar a exibição e liberação de acesso do evento. Todos os dados exibidos são utilizados para montar os cards e demais dados."
        className="mb-4"
      />

      <section>
        <Checkbox
          name="is_active"
          label="Ativar redirecionamento para o login"
          description="Ao ativar essa opção, durante o período que o evento estiver acontecendo, usuários cadastrados serão automaticamente redirecionados para a página do evento após o login."
        />

        <Checkbox
          name="is_visible"
          label="É visível na plataforma?"
          description={`Ao ativar essa opção, o evento passa a ficar visível na página de evento, tanto como evento acontecendo como evento que o usuário já participou
            <img src="${VisibleImage}" className="w-[500px]" />
          `}
        />
      </section>

      <br />

      <section>
        <FileInput
          description="Este icone é utilizado na exibição da retrospectiva e também na listagem do admin."
          label="Logo do evento"
          name="icon"
          placeholder="Logo do evento"
          path="platform"
        />

        <FileInput
          label="Banner do evento"
          name="banner"
          placeholder="Banner do evento"
          description={`Banner exibido na página de eventos para destacar o evento, como na imagem abaixo:
            <img src="${BannerImage}" className="w-[500px]" />
          `}
          path="platform"
        />
      </section>

      <section>
        <Input
          name="title"
          required
          label="Nome do evento"
          placeholder="Nome do evento"
          description={`Título do evento, é exibido em diversos locais da plataforma, inclusive na página de eventos como na imagem abaixo: 
          <img src="${TitleImage}" className="w-[500px]" />`}
        />

        <Input
          name="slug"
          label="Slug"
          placeholder="nome-do-evento"
          onInput={(e) => {
            e.target.value = slugify(e.target.value);
          }}
          required
          description={`
              Slug do evento, ele também será importante na geração automática da pré-criação
              dos certificados de cada trilha.

              <strong>Exemplo de slug:</strong>
              <code>nome-do-evento</code>

              <img src="${SlugImage}" className="w-[500px]" />
            `}
        />
      </section>

      <section>
        <Input
          name="description"
          label="Descrição do evento"
          placeholder="Descrição sobre o que é o evento."
          description={`Descrição do evento, que será exibida na página de eventos.
          <img src="${DescriptionImage}" className="w-[500px]" />`}
          multiline
        />
      </section>

      <Fieldset>
        <legend>Datas do evento</legend>

        <section>
          <Datepicker
            label="Data de inicio (Apenas exibição)"
            name="start_date"
            description={`Data e hora de início que será exibido publicamente na plataforma.
              <img src="${DateImage}" className="w-[500px]" />
            `}
            showTimeSelect
            required
            timeIntervals={5}
            placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
          />

          <Datepicker
            label="Data de fim (Apenas exibição)"
            name="end_date"
            description={`Data e hora de término que será exibido publicamente na plataforma.
              <img src="${DateImage}" className="w-[500px]" />
            `}
            showTimeSelect
            timeIntervals={5}
            placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
          />
        </section>

        <section>
          <Datepicker
            label="Disponível em (Liberação de acesso)"
            name="available_at"
            description="Data e hora em que o acesso ao evento estará disponível na plataforma."
            showTimeSelect
            required
            timeIntervals={5}
            placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
          />

          <Datepicker
            label="Indisponível em (Liberação de acesso)"
            name="unavailable_at"
            description="Data e hora em que o acesso ao evento não estará mais disponível na plataforma."
            showTimeSelect
            timeIntervals={5}
            placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
          />
        </section>
      </Fieldset>

      <Fieldset>
        <legend>Informações extras</legend>

        <section>
          <section className="w-60">
            <ColorPicker
              name="color"
              label="Cor de destaque para o evento"
              description="Essa cor é o que vai direcionar a visualização de alguns textos e o ticket do usuário."
            />
          </section>

          <Input
            name="place"
            label="Local"
            description={`Local do evento, por padrão é Online
            <img src="${PlaceImage}" className="w-[500px]" />
            `}
          />

          <Input
            name="price"
            label="Preço"
            type="number"
            step="0.00"
            description={`
              Usado na página de eventos, caso não seja gratuito.
              
              <img src="${PriceImage}" className="w-[500px]" />`}
            placeholder="R$ 100,00"
          />
        </section>
      </Fieldset>

      <Fieldset>
        <legend>Eventos Externos</legend>

        <Alert
          title="Eventos externos"
          description={`Quando o evento ocorrer fora da plataforma, esse link deverá ser preenchido com o link onde ocorerá o evento.
            
  - Quando evento ocorre no Youtube
  - Quando evento ocorreu no Zoom
`}
        />

        <section>
          <Input
            name="redirect_url"
            label="URL externo do Evento"
            description="Caso o evento seja realizado externalmente, este deverá ser o link onde acontecerá o evento."
            placeholder="http://www.youyube.com/watch/123456"
          />
        </section>
      </Fieldset>
    </div>
  );
}
