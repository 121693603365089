import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { RiBarChartFill } from 'react-icons/ri';

import { Field } from './styles';

import Button from '~/components/Button';
import {
  Dropdown,
  FileInput,
  Form,
  Input,
  SortableList,
} from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  slug: Yup.string(),
  description: Yup.string().required('Campo obrigatório'),
  deadline_in_months: Yup.number().required('Campo obrigatório'),
  badge_id: Yup.string().required('Campo obrigatório'),
  icon: Yup.string().required('Campo obrigatório'),
  hide_from: Yup.string(),
  levels: Yup.array().of(Yup.string()).required('Campo obrigatório'),
});

export default function GoalsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [goal, setGoal] = useState({
    title: '',
    description: '',
    slug: '',
    deadline_in_months: 0,
    badge_id: '',
    icon: '',
    levels: [],
    hide_from: '',
  });
  const { id } = match.params;

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);
      await api.postOrPut('/guide/goals', id, {
        ...data,
        levels: data.levels.map((levelId, order) => ({
          id: levelId,
          order,
        })),
      });

      toast.success('Objetivo salvo com sucesso!');
      history.push('/guide/goals');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function loadLevels() {
    const { data: response } = await api.get('/guide/levels', {
      params: {
        per_page: 999,
      },
    });
    const { data } = response;

    return data;
  }

  async function loadBadges() {
    setFetching(true);
    const { data: response } = await api.get('/badges', {
      params: {
        per_page: 999,
      },
    });
    const { data } = response;
    setFetching(false);
    return data;
  }

  useEffect(() => {
    async function loadGoal(goalId) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/guide/goals/${goalId}`);

        setGoal(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do objetivo.');
      } finally {
        setIsLoading(false);
      }
    }

    if (id) {
      loadGoal(id);
    }
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Objetivo`}</h1>

        <div>
          {isUpdating && (
            <Button
              icon={RiBarChartFill}
              color="success"
              to={`/guide/levels/1/?goal_id=${id}`}
            >
              Fases
            </Button>
          )}
          <Button icon={MdClose} color="cancel" to="/guide/goals">
            Cancelar
          </Button>
          <Button form="goal-form" type="submit" loading={isSubmitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="goal-form"
        schema={schema}
        initialData={goal}
        onSubmit={handleSubmit}
      >
        <section>
          <Input name="title" label={getLabel('Título', '', true)} />

          <Input name="slug" label={getLabel('Slug', '', false)} />
        </section>

        <section style={{ height: '15rem' }}>
          <Field>
            <Input
              name="deadline_in_months"
              type="number"
              label={getLabel(
                'Prazo (Em meses)',
                'Insira o prazo que o aluno tem para terminar em meses',
                true,
              )}
            />

            <Dropdown
              name="hide_from"
              options={[
                { id: 'b2b', title: 'B2B - Usuário de empresa' },
                { id: 'b2c', title: 'B2C - Usuário pagante' },
                { id: 'freemium', title: 'Freemium - Usuário não pagante' },
              ]}
              label={getLabel(
                'Tornar invisível',
                'Selecione o tipo do acesso do usuário que não deve ver esse objetivo',
                false,
              )}
            />
          </Field>

          <Input
            multiline
            name="description"
            label={getLabel(
              'Descrição',
              'Descreva o contexto do objetivo',
              true,
            )}
            style={{ height: '100%' }}
          />
        </section>

        <section>
          <Dropdown
            name="badge_id"
            load={loadBadges}
            label={getLabel('Insígnia', '', true)}
            disabled={fetching}
          />

          <FileInput
            label="Ícone"
            description="Este é o Ícone principal do objetivo."
            name="icon"
            placeholder="Icone do objetivo"
            path="platform/guide"
          />
        </section>

        <section>
          <SortableList
            label={getLabel('Fases', '', true)}
            name="levels"
            resource="levels"
            load={loadLevels}
          />
        </section>
      </Form>
    </Page>
  );
}

GoalsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
