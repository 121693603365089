import React, { useEffect, useRef } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { TrashIcon } from 'lucide-react';

import { useNotificationForm } from '../notifications-form.hook';

import { Alert } from '~/components/ui/alert';
import { Container } from '~/components/HookForm/Form/styles';
import { Check, Checkbox, FileInput, Input } from '~/components/HookForm';
import { getLabel } from '~/utils';
import MarkdownInput from '~/components/Form/HookForm/MarkdownInput';
import Button from '~/components/Button';
import BannerExample from '~/assets/images/tooltips/notifications/banner_example.png';
import BodyExample from '~/assets/images/tooltips/notifications/body_example.png';
import ButtonExample from '~/assets/images/tooltips/notifications/button_example.png';

const schema = Yup.object().shape({
  publishPage: Yup.bool(),
  coverImage: Yup.string().nullable(),
  body: Yup.string().when('publishPage', {
    is: true,
    then: Yup.string().required('Conteúdo é um campo obrigatório'),
    otherwise: Yup.string().nullable(),
  }),
  hasCtaButton: Yup.bool().nullable(),
  ctaLabel: Yup.string().when('hasCtaButton', {
    is: true,
    then: Yup.string().required('Texto do botão é um campo obrigatório'),
  }),
  ctaLink: Yup.string().when('hasCtaButton', {
    is: true,
    then: Yup.string()
      .url('Link do botão deve ser uma URL válida')
      .required('Link do botão é um campo obrigatório'),
  }),
});

export default function PageStep() {
  const formRef = useRef(null);
  const coverImageRef = useRef(null);

  const { setFormRef, notification, setNotification, onStepSubmit } =
    useNotificationForm();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...notification,
      ctaLink: notification.ctaLink || notification.link,
    },
  });

  const coverImage = form.watch('coverImage');
  const publishPage = form.watch('publishPage');
  const hasCtaButton = form.watch('hasCtaButton');

  useEffect(() => {
    if (formRef.current) {
      setFormRef(formRef);
    }
  }, [formRef, setFormRef]);

  useEffect(() => {
    const subscription = form.watch(
      debounce((formValues) => {
        if (!isEqual(formValues, notification)) {
          setNotification(formValues);
        }
      }, 100),
    );

    return () => subscription.unsubscribe();
  }, [form, notification, setNotification]);

  return (
    <>
      <Alert
        icon={() => <p className="text-[#7159C1] font-bold -mr-1">2.</p>}
        title="Publicar página da notificação"
        description="Caso essa notificação tenha uma página publicada, você deverá configurar ela aqui."
      />

      <Container className="m-0">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onStepSubmit)}
            className="flex flex-col gap-5"
            ref={formRef}
          >
            <div className="[&>div>label]:w-full [&>div>label]:gap-2">
              <Checkbox name="publishPage" label="Publicar página" />
            </div>

            {publishPage && (
              <>
                <section className="items-end">
                  <FileInput
                    name="coverImage"
                    path="platform/notifications/banners"
                    placeholder="Imagem"
                    label={getLabel(
                      'Capa',
                      '',
                      false,
                      `Imagem que será exibida na modal de texto
                      <img src="${BannerExample}" />
                      `,
                    )}
                    ref={coverImageRef}
                  />

                  {coverImage && (
                    <Button
                      icon={TrashIcon}
                      iconSize={16}
                      onClick={() => {
                        form.setValue('coverImage', null);
                        coverImageRef.current.value = null;
                      }}
                      className="px-4"
                    />
                  )}
                </section>

                <section>
                  <MarkdownInput
                    name="body"
                    label={getLabel(
                      'Conteúdo',
                      '',
                      true,
                      `Conteúdo textual da modal
                      <img src="${BodyExample}" />
                      `,
                    )}
                    control={form.control}
                  />
                </section>

                <section>
                  <Check
                    name="hasCtaButton"
                    label="Incluir botão"
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                    description={`
                    Exibir ação principal no conteúdo
                    <img src="${ButtonExample}" />
                    `}
                    defaultValue={false}
                  />

                  <Input
                    name="ctaLabel"
                    label="Texto do botão"
                    required
                    disabled={!hasCtaButton}
                    description="Texto do botão de ação"
                  />

                  <Input
                    name="ctaLink"
                    label="Link do botão"
                    required
                    disabled={!hasCtaButton}
                    description="Link que o usuário será direcionado ao clicar no botão"
                  />
                </section>
              </>
            )}

            <input type="submit" className="hidden" />
          </form>
        </FormProvider>
      </Container>
    </>
  );
}
