import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose, MdDelete, MdPerson } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { ChallengeLink, Divider } from './styles';

import Button from '~/components/Button';
import { Form, Input, Dropdown } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { useCan } from '~/utils';

const schema = Yup.object().shape({
  performance: Yup.string(),
  team_id: Yup.string(),
  class_period: Yup.string(),
  description: Yup.string(),
  metadata: Yup.string(),
  validity: Yup.string(),
});

function SensitiveInformationForm({ match, history }) {
  const [answer, setAnswer] = useState({
    replied: false,
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [teams, setTeams] = useState([]);
  const can = useCan();

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { challengeId, id } = match.params;

      await api.put(`/challenges/${challengeId}/answers/${id}`, data);

      toast.success('Desafio respondido com sucesso!');
      setSubmitting(false);

      history.push(`/challenges/${challengeId}/answers`);
    } catch (err) {
      toast.error('Ocorreu um erro ao responder o desafio.');
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadRequest(challengeId, id) {
      try {
        let { data: response } = await api.get(
          `/challenges/${challengeId}/answers/${id}`,
        );

        if (response.certificate) {
          response = { ...response.certificate, ...response };
        } else {
          response.performance = response.score;
        }

        if (!response.class_period) {
          response.class_period = new Date().getFullYear();
        }

        if (response.metadata) {
          try {
            response.metadata = JSON.stringify(response.metadata, null, 2);
          } catch (err) {
            response.metadata = '';
          }
        }

        if (!response.replied) {
          response.validity = 12;
        }

        setTeams(response.teams);

        setAnswer(response);
      } catch (err) {
        toast.error('Não foi possível carregar os dados da solicitação');
      } finally {
        setLoading(false);
      }
    }

    const { challengeId, id } = match.params;

    if (challengeId && id) {
      loadRequest(challengeId, id);
    }
  }, [match.params]);

  async function handleDeleteAnswer() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Deseja realmente apagar a resposta?')) {
      const { challengeId, id } = match.params;

      try {
        await api.delete(`/challenges/${challengeId}/answers/${id}`);

        history.push(`/challenges/${challengeId}/answers`);

        toast.success('Resposta apagada com sucesso!');
      } catch (error) {
        toast.error(error?.response?.data || 'Erro ao apagar resposta');
      }
    }
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>Resposta do desafio</h1>

        <div>
          {can('delete_challenge_answers') && (
            <Button icon={MdDelete} color="danger" onClick={handleDeleteAnswer}>
              Apagar resposta
            </Button>
          )}
          {answer.user && can('view_users') && (
            <Button
              icon={MdPerson}
              color="success"
              to={`/users/edit/${answer.user.id}`}
            >
              Ver usuário
            </Button>
          )}
          <Button
            icon={MdClose}
            color="cancel"
            to={`/challenges/${match.params.challengeId}/answers`}
          >
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={answer} onSubmit={handleSubmit}>
        <section>
          <Input
            label="Usuário"
            name="user"
            value={answer.user && answer.user.name}
            readOnly
          />
        </section>

        {answer?.final_revision && (
          <section style={{ margin: '20px 0 32px' }}>
            <h2>
              ⚠️ Atenção: Acesso do usuário expirado, esta é a última revisão do
              desafio final
            </h2>
          </section>
        )}

        <section>
          <ChallengeLink>
            <strong>Link</strong>
            <br />
            <a href={answer.link} target="_blank" rel="noopener noreferrer">
              {answer.link}
            </a>
          </ChallengeLink>
        </section>

        <section>
          <ChallengeLink>
            <strong>Segundo link</strong>
            <br />
            <a
              href={answer.second_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {answer.second_link}
            </a>
          </ChallengeLink>
        </section>

        {(can('reply_challenges') || answer.replied) && (
          <>
            <Divider>Avaliação</Divider>

            <section>
              <Input label="Nota" name="performance" />
              <Dropdown
                name="team_id"
                options={teams}
                placeholder="Selecione um time"
                label="Selecionar time"
              />
            </section>

            <section>
              <Input label="Período" name="class_period" />
              <Input label="Válidade em meses" name="validity" />
            </section>

            <section>
              <Input label="Observações" name="description" multiline />
            </section>

            <section>
              <Input label="Metadata" name="metadata" multiline />
            </section>
          </>
        )}

        {can('reply_challenges') && (
          <Button type="submit" size="block" loading={submitting}>
            {answer.replied ? 'Desafio já respondido' : 'Responder desafio'}
          </Button>
        )}
      </Form>
    </Page>
  );
}

SensitiveInformationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      challengeId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

SensitiveInformationForm.defaultProps = {
  match: {
    params: {
      challengeId: '',
      id: '',
    },
  },
};

export default SensitiveInformationForm;
