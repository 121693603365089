import PropTypes from 'prop-types';
import React from 'react';
import { useDrop } from 'react-dnd';

export default function Nodes({ children }) {
  const [, dropRef] = useDrop({
    accept: 'NODE',
  });

  return <ul ref={dropRef}>{children}</ul>;
}

Nodes.propTypes = {
  children: PropTypes.element,
};

Nodes.defaultProps = {
  children: null,
};
