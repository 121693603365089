import React, { useEffect } from 'react';

import { FaChevronRight } from 'react-icons/fa';

import ClipboardButton from '~/components/ClipboardButton';
import Page from '~/components/Page';
import { Thumbnail } from '~/components/PagedList/styles';
import { Actions, Table } from '~/components/Table';
import mgmApi from '~/services/mgm.api';
import { useCan } from '~/utils';

const STATUS_MAP = {
  APPROVED: {
    label: 'Aprovado',
    color: 'text-green-600',
  },
  PENDING: {
    label: 'Pendente',
    color: 'text-yellow-600',
  },
  CANCELLED: {
    label: 'Cancelado',
    color: 'text-red-600',
  },
  COMPLETED: {
    label: 'Completo',
    color: 'text-blue-700',
  },
};

const columns = [
  {
    label: 'Imagem',
    value: 'imageUrl',
    getValue: (element) => {
      return (
        element.reward.imageUrl && (
          <Thumbnail>
            <img src={element.reward.imageUrl} alt="" />
          </Thumbnail>
        )
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
    getValue: (element) => {
      return element.reward.title;
    },
  },
  {
    label: 'Usuário',
    value: 'user',
    getValue: (element) => {
      return element.user.email;
    },
  },
  {
    label: 'Pontos',
    value: 'points',
  },
  {
    label: 'Status',
    value: 'status',
    getValue: (element) => {
      return (
        <span
          className={`${STATUS_MAP[element.status].color} font-bold uppercase `}
        >
          {STATUS_MAP[element.status].label}
        </span>
      );
    },
  },
];

export function RedeemsList() {
  const [redeems, setRedeems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    async function loadRewards() {
      const { data } = await mgmApi.get('/admin/redeems');

      setRedeems(data);
      setLoading(false);
    }

    loadRewards();
  }, []);

  if (loading) {
    return 'Carregando...';
  }

  return (
    <Page>
      <header>
        <h1>Resgates</h1>
      </header>

      {!loading && !redeems.length ? (
        <div style={{ marginTop: '1rem' }}>
          <p>Nenhum registro encontrado</p>
        </div>
      ) : (
        <Table loading={loading}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th>Visualizar</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {redeems.map((redeem) => {
              return (
                <tr key={redeem.id}>
                  {columns.map((column) => (
                    <td key={`${redeem.id}-${column.value}`}>
                      {column.getValue?.(redeem) || redeem[column.value]}
                    </td>
                  ))}

                  <td width={60}>
                    <Actions
                      mainIcon={FaChevronRight}
                      href={`/redeems/${redeem.id}`}
                    />
                  </td>
                  <td width={60}>
                    <ClipboardButton title="Copiar ID" value={redeem.id} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Page>
  );
}
