import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 30px;

  aside {
    width: 400px;
    margin-right: 30px;
    float: left;
  }
`;

export const FileBox = styled.div`
  padding: 15px 15px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;

  p {
    display: block;
    font-size: 13px;
    font-weight: bold;
    margin: 0 0 12px;
    color: #7159c1;
  }
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 40px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  color: #999;
  font-size: 13px;
`;
