import PropTypes from 'prop-types';
import React, { useEffect, useRef, useMemo } from 'react';

import { useField } from '@rocketseat/unform';
import { FaInfoCircle } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';

function InputDefault({ name, label, description, multiline, ...rest }) {
  const ref = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, ref.current]);

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };

  const renderInput = useMemo(() => {
    if (rest.mask) {
      return <ReactInputMask {...props} mask={rest.mask} maskChar=" " />;
    }

    return multiline ? <textarea {...props} /> : <input {...props} />;
  }, [multiline, props, rest.mask]);

  return (
    <>
      {label && (
        <label
          htmlFor={fieldName}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {label}

          {description && (
            <FaInfoCircle
              size={12}
              data-tooltip-id="tooltip"
              data-tooltip-content={description}
            />
          )}
        </label>
      )}
      {renderInput}
      {error && <span className="error">{error}</span>}
    </>
  );
}

InputDefault.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  multiline: PropTypes.bool,
};

InputDefault.defaultProps = {
  label: '',
  description: null,
  multiline: false,
};

export default InputDefault;
