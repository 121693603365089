/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import debounce from 'debounce-promise';

import {
  restoreFieldState,
  transformSegmentParamValues,
} from './segment.utils';

import api from '~/services/api';
import { DEFAULT_DEBOUNCE_VALUE } from '~/utils';

export function useNotificationFormSegment() {
  const form = useFormContext();

  const [segmentCountValue, setSegmentCountValue] = useState(0);
  const [isFetchingSegmentCount, setIsFetchingSegmentCount] = useState(false);

  const segment = form.watch('segment');
  const segmentParams = form.watch('params');

  const paramsFieldState = JSON.stringify(segmentParams);

  const isEmailProcedure = useMemo(
    () => segment?.procedure_name === 'get_user_ids_by_emails',
    [segment?.procedure_name],
  );

  const segmentKeyParams = useMemo(() => {
    if (segment) {
      return Object.keys(segment?.parameters);
    }

    return [];
  }, [segment]);

  const numberOfParams = useMemo(
    () =>
      segmentKeyParams?.map((paramKey) =>
        Object.keys(segment?.parameters?.[paramKey] || {}),
      ).length,
    [segment?.parameters, segmentKeyParams],
  );

  async function loadSegments(search) {
    const { data: response } = await api.get('/segments', {
      params: {
        search,
        per_page: 50,
      },
    });

    const segments = response.data.map((segment) => ({
      ...segment,
      value: segment.id,
      label: segment.title,
    }));

    return segments;
  }

  async function loadJourneys(search) {
    const { data: response } = await api.get('/journeys', {
      params: {
        search,
        per_page: 50,
        fields: ['id', 'title', 'slug'],
      },
    });

    const journeys = response.data.map((journey) => ({
      ...journey,
      value: journey.slug,
      label: `${journey.title} (${journey.slug})`,
    }));

    restoreFieldState({ form, key: 'params.journey_slug', dataSet: journeys });

    return journeys;
  }

  async function loadTeams(search) {
    const { data: response } = await api.get('/teams', {
      params: {
        search,
        per_page: 50,
      },
    });

    const teams = response.data.map((team) => ({
      value: team.identifier,
      identifier: team.identifier,
      label: `${team.title} (${team.identifier})`,
    }));

    restoreFieldState({ form, key: 'params.team_identifier', dataSet: teams });

    return teams;
  }

  async function loadSegmentCount(segmentParams) {
    if (!segment?.id) {
      return;
    }

    const parameters = transformSegmentParamValues(segment, segmentParams);

    try {
      setIsFetchingSegmentCount(true);

      const { data: response } = await api.post(
        `/segments/${segment.id}/count`,
        {
          parameters,
        },
      );

      setSegmentCountValue(response?.count || 0);
    } catch {
    } finally {
      setIsFetchingSegmentCount(false);
    }
  }

  const loadAsyncSegments = useCallback(
    debounce(loadSegments, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  const loadAsyncJourneys = useCallback(
    debounce(loadJourneys, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  const loadAsyncTeams = useCallback(
    debounce(loadTeams, DEFAULT_DEBOUNCE_VALUE),
    [],
  );

  const loadAsyncSegmentCount = useCallback(
    debounce(loadSegmentCount, DEFAULT_DEBOUNCE_VALUE),
    [paramsFieldState, segment?.id],
  );

  useEffect(() => {
    loadSegments();
    loadTeams();
    loadJourneys();
  }, []);

  useEffect(() => {
    if (isEmailProcedure) {
      return;
    }

    loadAsyncSegmentCount(segmentParams);
  }, [paramsFieldState, segment?.id]);

  return {
    segment,
    segmentParams,
    numberOfParams,
    segmentKeyParams,
    isEmailProcedure,
    segmentCountValue,
    isFetchingSegmentCount,

    loaders: {
      teams: loadAsyncTeams,
      segments: loadAsyncSegments,
      journeys: loadAsyncJourneys,
    },
  };
}
