import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Container as FieldContainer } from '~/components/Form/Field/styles';

export const Container = styled.li`
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid ${transparentize(0.5, '#CCC')};
  display: flex;
  flex-direction: column;
  position: relative;
  &:first-child {
    padding: 0;
    margin: 0;
    border-top: 0;
  }
  > section {
    /* ${FieldContainer} input {
      margin: 0 0 10px;
      border-width: 0;
      border-bottom-width: 1px;
      padding: 0;
      border-radius: 0;
      font-weight: 600;
      background: transparent;
      font-size: 16px;
    } */
    > button {
      background: transparent;
      border: 0;
      margin-left: 5px;
      transition: all 0.2s;
      &:hover {
        opacity: 0.6;
      }
      svg {
        color: ${transparentize(0.3, '#302B3E')};
      }
      &:first-child {
        svg {
          color: #dd5554;
        }
      }
    }
  }
`;

export const Deleted = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

export const Downloads = styled.div`
  flex: 1;
  margin-bottom: 15px;
  padding-right: 15px;
`;

export const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > div {
    margin-bottom: 0;

    &[type='hidden'] {
      display: none;
    }
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 5px;
    transition: all 0.2s;

    &:hover {
      opacity: 0.6;
    }

    svg {
      color: ${transparentize(0.3, '#302B3E')};
    }
  }

  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;
