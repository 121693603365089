import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import RulesForm from './RulesForm';

import Button from '~/components/Button';
import { Form, Datepicker, Dropdown, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

const templateOptions = [
  { id: 'ignite', title: 'Ignite' },
  { id: 'explorer', title: 'Explorer' },
  { id: 'plus', title: 'Plus' },
  { id: 'nlw', title: 'NLW' },
  { id: 'assemble', title: 'Assemble' },
];

const schema = Yup.object().shape({
  template: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string().required(),
  url: Yup.string(),
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  rules: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      type: Yup.string(),
      resource_field: Yup.string(),
      resource_value: Yup.string(),
      deleted: Yup.string(),
    }),
  ),
});

export default function TopBannersForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [banner, setBanner] = useState({
    template: 'ignite',
    title: '',
    description: '',
    url: '',
    start_date: new Date(),
    end_date: new Date() + 1000 * 60 * 24 * 7, // 1 Week
    rules: [],
  });

  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/top-banners', id, {
        ...data,
        rules: data.rules.map((rule) => {
          if (rule.deleted === 'false') {
            delete rule.deleted;
          }

          return rule;
        }),
      });

      toast.success('Banner salvo com sucesso!');
      history.push('/top-banners');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadBanner(id) {
      try {
        setIsLoading(true);

        const { data: response } = await api.get(`/top-banners/${id}`);

        setBanner(response);
      } catch (err) {
        toast.error('Erro ao buscar dados do banner.');
      } finally {
        setIsLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadBanner(id);
    }
  }, [match.params]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Top Banner`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/top-banners">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={banner} onSubmit={handleSubmit}>
        <section>
          <Dropdown
            name="template"
            placeholder="Selecione o template do banner"
            label="Template"
            options={templateOptions}
          />
        </section>

        <section>
          <Input
            name="title"
            placeholder="Título do Banner (Apenas para organização do admin)"
            label="Título do banner"
          />
        </section>

        <section>
          <Input
            name="description"
            placeholder="Conteúdo que será exibido no banner"
            label="Conteúdo do banner"
          />

          <Input
            name="url"
            placeholder="URL para onde será direcionado ao clicar no banner"
            label="Link do banner"
          />
        </section>

        <section>
          <Datepicker
            name="start_date"
            label="Data de início"
            placeholder="Selecione à partir de que dia deverá ser exibido"
          />
          <Datepicker
            name="end_date"
            label="Data de fim"
            placeholder="Selecionar até que dia deverá ser exibido"
          />
        </section>

        <fieldset>
          <legend>Regra de exibição</legend>

          <RulesForm banner={banner} />
        </fieldset>

        <Button type="submit" size="block" loading={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

TopBannersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
