import { gql } from '@apollo/client';

export const PRODUCTS = gql`
  query Products {
    products {
      id
      title
      identifier
      type
      contentType
    }
  }
`;

export const BONUSES = gql`
  query Products($type: String!) {
    products(type: $type) {
      id
      title
      identifier
      metadata
    }
  }
`;

export const PRODUCT = gql`
  query Product($identifier: String!) {
    product(identifier: $identifier) {
      id
      title
      identifier
      mgmPoints
      type
      contentType
      metadata
    }
  }
`;

export const OFFERS = gql`
  query Offers($search: String, $isPurchaseable: Boolean) {
    offers(search: $search, isPurchaseable: $isPurchaseable) {
      id
      title
      description
      slug
      isPurchaseable
      isValidForMGM
      canUseCoupon
      priceInCents
      discountPriceInCents
      acceptedPaymentTypes
      refundDays
      iconUrl
    }
  }
`;

export const OFFER = gql`
  query Offer($slug: String!) {
    offer(slug: $slug) {
      id
      title
      slug
      description
      slug
      priceInCents
      discountPriceInCents
      acceptedPaymentTypes
      refundDays
      isRecurrent
      isPurchaseable
      isValidForMGM
      canUseCoupon
      iconUrl
      offerProducts {
        id
        isRenewable
        periodType
        periodInterval
        product {
          id
          title
          identifier
        }
      }
      relatedOffers {
        id
        title
      }
    }
  }
`;

export const COUPONS = gql`
  query Coupons($offset: Int, $limit: Int, $search: String) {
    coupons(offset: $offset, limit: $limit, search: $search) {
      totalCount
      nodes {
        id
        title
        description
        code
        active
        startsAt
        endsAt
      }
    }
  }
`;

export const COUPON = gql`
  query Coupon($code: String!) {
    coupon(code: $code) {
      id
      title
      description
      code
      active
      isUnique
      startsAt
      endsAt
      isPartnership
      isRestricted
      partner
      partnerCnpj
      createdBy
      availableOffers {
        discountPercentage
        offers {
          id
          title
          slug
        }
      }
      availableCustomers {
        discountPercentage
        customers {
          id
          atlasUserId
          name
          email
        }
      }
    }
  }
`;
