import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

import Shimmer from '~/components/Shimmer';

const tableItems = [...Array(10).keys()].map((i) => i + 1);

function Table({ loading, children }) {
  function renderSkeleton() {
    return (
      <Container cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th aria-label="Shimmer">
              <Shimmer />
            </th>
            <th aria-label="Shimmer">
              <Shimmer />
            </th>
            <th aria-label="Shimmer">
              <Shimmer />
            </th>
            <th aria-label="Shimmer">
              <Shimmer />
            </th>
            <th aria-label="Shimmer">
              <Shimmer />
            </th>
          </tr>
        </thead>

        <tbody>
          {tableItems.map((item) => (
            <tr key={item}>
              <td width="350">
                <Shimmer />
              </td>
              <td width="350">
                <Shimmer />
              </td>
              <td>
                <Shimmer />
              </td>
              <td>
                <Shimmer />
              </td>
              <td width="80">
                <Shimmer />
              </td>
            </tr>
          ))}
        </tbody>
      </Container>
    );
  }

  return loading ? (
    renderSkeleton()
  ) : (
    <Container cellPadding={0} cellSpacing={0}>
      {children}
    </Container>
  );
}

Table.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Table.defaultProps = {
  loading: false,
};

export default Table;
