import React from 'react';

import { IoDocumentTextOutline } from 'react-icons/io5';
import { format } from 'date-fns';

import {
  DocumentStatusDetails,
  DocumentStatusLabels,
  DocumentStatusValues,
} from '../constants';

import PagedList from '~/components/PagedList';
import { Actions } from '~/components/Table';

const columns = [
  {
    label: 'Nome',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <div className="flex flex-col">
            <strong>{element.name}</strong>
            <span>{element.email}</span>
          </div>
        </td>
      );
    },
  },
  {
    label: 'Curso',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <div className="flex items-center gap-1 self-stretch">
            {element.contract?.avatar_url && (
              <img
                width={32}
                height={32}
                alt={element.contract?.title || ''}
                src={element.contract.avatar_url}
              />
            )}

            <strong>{element.contract?.title}</strong>
          </div>
        </td>
      );
    },
  },
  {
    label: 'Status',
    getValue: (element) => {
      const {
        icon: Icon,
        color,
        label,
      } = DocumentStatusDetails[element?.status];

      return (
        <td key={element.id}>
          <div className="flex gap-1">
            <Icon size={20} color={color} aria-label={label} />
            <span className="flex flex-col">
              {label}{' '}
              {element?.status === 'PENDING' && !element.sent_at && (
                <small className="font-normal text-gray-600">
                  (Ainda não enviado)
                </small>
              )}
              {element?.status === 'PENDING' && element.sent_at && (
                <small className="font-normal text-gray-600">
                  (Parcialmente enviado)
                </small>
              )}
            </span>
          </div>
        </td>
      );
    },
  },
  {
    label: 'Data de criação',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <div className="flex gap-1">
            {element.sent_at
              ? format(new Date(element.sent_at), `DD/MM/YYYY hh:mm`)
              : 'Ainda não enviado'}
          </div>
        </td>
      );
    },
  },
];

export default function EnrollmentList() {
  const filters = [
    {
      label: 'Status',
      name: 'status',
      data: [
        {
          value: DocumentStatusValues.PENDING,
          label: DocumentStatusLabels.PENDING,
        },
        {
          value: DocumentStatusValues.UNDER_REVIEW,
          label: DocumentStatusLabels.UNDER_REVIEW,
        },
        {
          value: DocumentStatusValues.REJECTED,
          label: DocumentStatusLabels.REJECTED,
        },
        {
          value: DocumentStatusValues.APPROVED,
          label: DocumentStatusLabels.APPROVED,
        },
      ],
    },
  ];

  return (
    <PagedList
      resource="documents/users"
      header="Matrículas"
      columns={columns}
      hideSearchInput
      filtersText={[
        {
          name: 'search',
          label: 'Nome ou email',
        },
      ]}
      filters={filters}
      hideCopyButton
      hideEditButton
      customActions={(enrollment) => {
        return (
          <Actions
            mainIcon={IoDocumentTextOutline}
            href={`/documents/users/${enrollment?.user_id}/${enrollment?.contract?.id}`}
          />
        );
      }}
    />
  );
}
