import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

function Field({ disabled, type, description = null, children }) {
  return (
    <Container disabled={disabled} type={type}>
      {children}

      {description && <p className="description">{description}</p>}
    </Container>
  );
}

Field.propTypes = {
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  description: PropTypes.string,
};

Field.defaultProps = {
  description: null,
  disabled: false,
  type: 'text',
};

export default Field;
