import React from 'react';

import { types } from '../Form';

import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';

const columns = [
  {
    label: 'Ícone',
    value: 'icon',
    getValue: (element) => {
      return (
        <td key={element.id} width={100}>
          {element.icon_url && (
            <Thumbnail>
              <img src={element.icon_url} alt="" />
            </Thumbnail>
          )}
        </td>
      );
    },
  },
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Identificador',
    value: 'identifier',
  },
  {
    label: 'Tipo',
    value: 'type',
  },
];

function TeamsList() {
  return (
    <PagedList
      resource="teams"
      header="Times"
      filters={[
        {
          label: 'Tipo',
          name: 'type',
          data: types.map((type) => ({
            value: type.id,
            label: type.title,
          })),
        },
      ]}
      columns={columns}
    />
  );
}

export default TeamsList;
