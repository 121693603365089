/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useMemo, useState } from 'react';

import { EyeOff, Flag, Plus } from 'lucide-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { CheckpointItem } from './CheckpointItem';
import { Fieldset } from './styles';

import { Alert } from '~/components/ui/alert';
import { cn } from '~/lib/utils';
import Button from '~/components/Button';
import { FileInput, Input } from '~/components/HookForm';
import OverviewImage from '~/assets/images/tooltips/events/checkpoint-overview-field.png';
import AwardImage from '~/assets/images/tooltips/events/checkpoint-image-field.png';

const checkpointDefault = {
  title: null,
  description: null,
  url: null,
  award: null,
  metadata: {},
  available_at: null,
  unavailable_at: null,
};

export function CheckpointTab() {
  const { control, getValues } = useFormContext();

  const checkpointsFields = useFieldArray({
    control,
    name: 'checkpoints',
  });

  const [selected, setSelected] = useState(0);

  const selectedCheckpoint = useMemo(() => {
    return checkpointsFields.fields[selected];
  }, [checkpointsFields.fields, selected]);

  const handleAdd = useCallback(() => {
    checkpointsFields.append(checkpointDefault);
  }, [checkpointsFields]);

  const handleRemove = useCallback(
    (itemIndex) => {
      const isNew = false;

      if (isNew) {
        return checkpointsFields.remove(itemIndex);
      }

      const checkpointItem = getValues(`checkpoints[${itemIndex}]`);

      checkpointsFields.update(itemIndex, {
        ...checkpointItem,
        _destroy: true,
      });
    },
    [checkpointsFields, getValues],
  );

  const handleRestore = useCallback(
    (itemIndex) => {
      const checkpointItem = getValues(`checkpoints[${itemIndex}]`);

      delete checkpointItem._destroy;

      checkpointsFields.update(itemIndex, checkpointItem);
    },
    [checkpointsFields, getValues],
  );

  const renderTrailsListMenu = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        {checkpointsFields.fields.map((field, fieldIndex) => {
          const isDisabled = field?._destroy;
          const isSelected = field.id === selectedCheckpoint?.id;

          return (
            <button
              onClick={() => setSelected(fieldIndex)}
              key={`button-trail-${fieldIndex}`}
              type="button"
              className={cn(
                'w-full text-left p-4 hover:bg-zinc-200 rounded-sm flex items-center gap-2',
                isSelected ? `bg-zinc-200` : `bg-zinc-50`,
                isDisabled ? 'opacity-40 line-through' : '',
              )}
            >
              <Flag size={16} />
              <span className="flex-1">
                {field?.title || 'Novo checkpoint'}
              </span>

              {isDisabled && <EyeOff size={16} />}
            </button>
          );
        })}
      </div>
    );
  }, [checkpointsFields.fields, selectedCheckpoint?.id]);

  const renderCheckpoints = useMemo(() => {
    return checkpointsFields.fields.map((field, fieldIndex) => {
      return (
        <CheckpointItem
          item={field}
          index={fieldIndex}
          remove={() => handleRemove(fieldIndex)}
          restore={() => handleRestore(fieldIndex)}
          key={field?.id}
          className={fieldIndex === selected ? 'block' : 'hidden'}
        />
      );
    });
  }, [checkpointsFields.fields, handleRemove, handleRestore, selected]);

  return (
    <div className="flex flex-col w-full">
      <Alert
        title="Configurações dos Checkpoints"
        description="Essas configurações são responsáveis por gerenciar os checkpoints/desafios de cada trilha do evento."
        className="mb-4"
      />

      <Fieldset>
        <legend>Checkpoints</legend>

        <section>
          <Input
            required
            label="Descrição geral dos Checkpoints"
            name="checkpoints_description"
            placeholder="Descriçao dos checkpoints"
            description={`Descrição que fica visuaisivel
              <img src="${OverviewImage}" className="w-[500px]" />
            `}
          />

          <FileInput
            label="Imagem do prêmio"
            name="checkpoints_award_image"
            placeholder="Selecione a imagem do prêmio"
            description={`URL da imagem do prêmio
            <img src="${AwardImage}" className="w-[500px]" />
          `}
            path="platform"
          />
        </section>
      </Fieldset>

      <div className="grid grid-cols-[300px_auto] w-full gap-4">
        <div className="flex flex-col">
          <header className="w-full items-center flex gap-2 justify-between mb-2">
            <strong>Checkpoints</strong>

            <Button icon={Plus} color="success" onClick={handleAdd}>
              Adicionar
            </Button>
          </header>

          {renderTrailsListMenu}
        </div>

        <div className="flex flex-col w-full">{renderCheckpoints}</div>
      </div>
    </div>
  );
}
