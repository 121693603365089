/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content, Loading, Circle } from './styles';

function Page({ children, loading }) {
  return (
    <Container>
      {loading ? (
        <Loading>
          <Circle>
            <div />
            <div />
            <div />
            <div />
          </Circle>
        </Loading>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
}

Page.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

Page.defaultProps = {
  loading: false,
};

export default Page;
