import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input, SortableList } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';

const schema = Yup.object().shape({
  title: Yup.string().required('Título é um campo obrigatório'),
  slug: Yup.string(),
  tags: Yup.string(),
  lessons: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      deleted: Yup.boolean().default(false),
      created: Yup.boolean().default(false),
    }),
  ),
});

function LessonGroupsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [lessonGroup, setLessonGroup] = useState({});
  const isUpdating = !!match.params.id;

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      const lessons = data.lessons.map((lesson) => {
        const created = lesson.created || false;
        const deleted = lesson.deleted || false;

        return {
          id: lesson.id,
          created: created && !deleted,
          deleted,
        };
      });

      const newData = {
        ...data,
        lessons,
      };

      await api.postOrPut('/lesson-groups', id, newData);

      toast.success('Grupo de aula salvo com sucesso!');
      history.push('/lesson-groups');
    } catch (err) {
      toast.error('Algo deu errado');
    } finally {
      setSubmitting(false);
    }
  }

  async function loadLessons(search) {
    const { data: response } = await api.get('/lessons', {
      params: {
        search,
      },
    });

    const { data } = response;

    return data;
  }

  useEffect(() => {
    async function loadLessonGroup(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/lesson-groups/${id}`);

        setLessonGroup(response);
      } catch (err) {
        toast.error('Não foi possível');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadLessonGroup(id);
    }
  }, []);

  function getLabel(lesson) {
    if (lesson.history) {
      return lesson.history[0] && lesson.history[0].tagged_title;
    }

    return lesson.last && lesson.last.tagged_title;
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} grupo de aula`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/lesson-groups">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={lessonGroup} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug do grupo (gerado automaticamente)"
          />
        </section>

        <section>
          <Input name="tags" placeholder="Tags do grupo (ex: #Tag1 #Tag2)" />
        </section>

        <section>
          <SortableList
            label="Aulas"
            name="lessons"
            resource="lessons"
            load={loadLessons}
            getOptionLabel={getLabel}
            isAsync
            includeDeletedEntries
          />
        </section>

        <Button type="submit" size="block" loading={submitting}>
          Salvar
        </Button>
      </Form>
    </Page>
  );
}

LessonGroupsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

LessonGroupsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default LessonGroupsForm;
