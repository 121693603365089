import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';

import Button from '~/components/Button';
import { Table } from '~/components/Table';
import { Page } from '~/pages/styles';
import api from '~/services/api';

function UsersEmailHistoryList({ match }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function loadData() {
    setLoading(true);

    const { data: response } = await api.get(
      `/users/${match.params.id}/email-history`,
    );

    setLoading(false);
    setData(response);
  }

  useEffect(() => {
    loadData();
  }, []);

  function renderEmailHistory() {
    return (
      <Table loading={loading}>
        <thead>
          <tr>
            <th>E-mail</th>
            <th>Atualizado em</th>
          </tr>
        </thead>
        <tbody>
          {data.map((history) => (
            <tr key={history.id}>
              <td>
                {history.updated_at ? (
                  history.email
                ) : (
                  <strong>{history.email}</strong>
                )}
              </td>
              <td>
                {history.updated_at ? (
                  format(history.updated_at, 'DD/MM/YYYY - HH:mm')
                ) : (
                  <em>E-mail Atual</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Page>
      <header>
        <h1>E-mails usados</h1>

        <div>
          <Button
            icon={MdArrowBack}
            color="success"
            to={`/users/edit/${match.params.id}`}
          >
            Voltar
          </Button>
        </div>
      </header>

      {!loading && renderEmailHistory()}
    </Page>
  );
}

UsersEmailHistoryList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default UsersEmailHistoryList;
