import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ReactDatePicker from 'react-datepicker';
import { useField } from '@rocketseat/unform';
import ptBr from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { FaInfoCircle } from 'react-icons/fa';

import { Container } from './styles';
import Field from '../Field';

import { fromUTCToTimezonedBrazilianDateTime } from '~/utils';

function Datepicker({
  label,
  name,
  description = null,
  showTimeSelect,
  timeIntervals,
  disabled,
}) {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [selected, setSelected] = useState(
    defaultValue ? fromUTCToTimezonedBrazilianDateTime(defaultValue) : null,
  );

  const ref = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',

      parseValue: (pickerRef) =>
        pickerRef.props.selected
          ? format(
              pickerRef.props.selected,
              showTimeSelect ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD',
            )
          : null,

      clearValue: (pickerRef) => {
        pickerRef.clear();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, fieldName]);

  return (
    <Field label={label} name={name}>
      {label && (
        <label
          htmlFor={name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {label}
          {description && (
            <FaInfoCircle
              size={12}
              data-tooltip-id="tooltip"
              data-tooltip-content={description}
            />
          )}
        </label>
      )}

      <Container>
        <ReactDatePicker
          timeFormat="HH:mm"
          timeIntervals={timeIntervals}
          dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
          timeCaption="Horário"
          locale={ptBr}
          selected={selected}
          name={fieldName}
          onChange={(date) => setSelected(date)}
          ref={ref}
          showTimeSelect={showTimeSelect}
          disabled={disabled}
        />
      </Container>

      {error && <span>{error}</span>}
    </Field>
  );
}

Datepicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  timeIntervals: PropTypes.number,
  description: PropTypes.string,
};

Datepicker.defaultProps = {
  label: '',
  showTimeSelect: false,
  disabled: false,
  timeIntervals: 60,
  description: null,
};

export default Datepicker;
