import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0px 15px;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  label {
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
  }
`;
