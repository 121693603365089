/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useMemo, useState } from 'react';

import { EyeOff, Plus, Route } from 'lucide-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Container } from './styles';
import { TrailItem } from './TrailItem';

import { Alert } from '~/components/ui/alert';
import Button from '~/components/Button';
import { cn } from '~/lib/utils';

const eventTrailDefault = {
  eventTrailId: '',
  trail: null,
  educators: [],
  community_url: null,
  description: null,
  project_image_url: null,
  project_description: null,
};

export function TrailTab() {
  const { control, getValues } = useFormContext();

  const eventTrailsFields = useFieldArray({
    control,
    name: 'eventTrails',
  });

  const [selected, setSelected] = useState(0);

  const selectedTrail = useMemo(() => {
    return eventTrailsFields.fields[selected];
  }, [selected]);

  const handleAdd = useCallback(() => {
    eventTrailsFields.append(eventTrailDefault);
  }, []);

  const handleRemove = useCallback((item, itemIndex) => {
    const isNew = false;

    if (isNew) {
      return eventTrailsFields.remove(itemIndex);
    }

    const trailItem = getValues(`eventTrails[${itemIndex}]`);

    eventTrailsFields.update(itemIndex, {
      ...trailItem,
      _destroy: true,
    });
  }, []);

  const handleRestore = useCallback((itemIndex) => {
    const trailItem = getValues(`eventTrails[${itemIndex}]`);

    delete trailItem._destroy;

    eventTrailsFields.update(itemIndex, trailItem);
  }, []);

  const renderTrailsListMenu = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        {eventTrailsFields.fields.map((field, fieldIndex) => {
          const isDisabled = field?._destroy;
          const isSelected = field.id === selectedTrail?.id;

          return (
            <button
              onClick={() => setSelected(fieldIndex)}
              key={`button-trail-${fieldIndex}`}
              type="button"
              className={cn(
                'w-full text-left p-4 hover:bg-zinc-200 rounded-sm flex items-center gap-2',
                isSelected ? `bg-zinc-200` : `bg-zinc-50`,
                isDisabled ? 'opacity-40 line-through' : '',
              )}
            >
              <Route size={16} />
              <span className="flex-1">
                {field?.trail?.label || 'Nova trilha'}
              </span>

              {isDisabled && <EyeOff size={16} />}
            </button>
          );
        })}
      </div>
    );
  }, [eventTrailsFields.fields, selectedTrail]);

  const renderTrailListForm = useMemo(() => {
    return eventTrailsFields.fields.map((field, fieldIndex) => {
      return (
        <TrailItem
          item={field}
          index={fieldIndex}
          key={field?.id}
          removeTrail={() => handleRemove(field, fieldIndex)}
          restoreTrail={() => handleRestore(fieldIndex)}
          className={fieldIndex === selected ? 'block' : 'hidden'}
        />
      );
    });
  }, [eventTrailsFields.fields, selected]);

  return (
    <Container>
      <Alert
        title="Configurações das Trilhas"
        description="Essas configurações são responsáveis por definir as informações das trilhas do evento, quais são as trilhas, quais os dados delas e quais são as aulas e as datas que elas ficam disponíveis."
        className="mb-4"
      />

      <div className="grid grid-cols-[240px_auto] w-full gap-4">
        <div className="flex flex-col">
          <header className="w-full items-center flex gap-2 justify-between mb-2">
            <strong>Trilhas</strong>

            <Button icon={Plus} color="success" onClick={handleAdd}>
              Adicionar
            </Button>
          </header>

          {renderTrailsListMenu}
        </div>

        <div className="flex flex-col w-full">{renderTrailListForm}</div>
      </div>
    </Container>
  );
}
