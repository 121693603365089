/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Empty, Container } from './styles';

import api from '~/services/api';
import { Table, Actions } from '~/components/Table';
import ClipboardButton from '~/components/ClipboardButton';

export const PurchasesList = forwardRef(
  ({ userId, type, title, showEmpty }, ref) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    async function loadData() {
      setLoading(true);

      const { data: response } = await api.get(`/purchases`, {
        params: {
          userId,
          type,
        },
      });

      setLoading(false);
      setData(response);
    }

    useImperativeHandle(ref, () => ({
      reload() {
        loadData();
      },
    }));

    function formatDate(date) {
      return format(new Date(date), 'DD/MM/YYYY');
    }

    useEffect(() => {
      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function formatPaymentStatus(status) {
      switch (status) {
        case 'pending':
          return 'Pendente';
        case 'scheduled':
          return 'Agendado';
        case 'paid':
          return 'Sucesso';
        case 'canceled':
          return 'Cancelado';
        case 'refunded':
          return 'Reembolsado';
        default:
          return 'Não definido';
      }
    }

    function emptyData() {
      return (
        <Empty>
          <h1>Este usuário não possui nenhuma compra.</h1>
        </Empty>
      );
    }

    function validScholarship(paymentType, transaction) {
      if (paymentType === 'scholarship' || transaction.includes('BOLSA_')) {
        return true;
      }
      return false;
    }

    function validPurchaseStatus(purchase) {
      const hasRefundDate = purchase.refunded_at;
      const isCanceled = purchase.status === 'canceled';
      const periodEnded = new Date(purchase.end_date) < new Date();

      if (isCanceled) {
        return 'Cancelado';
      }

      if (hasRefundDate) {
        return 'Reembolsado';
      }

      if (periodEnded) {
        return 'Expirado';
      }

      return 'Ativo';
    }

    function renderPurchases() {
      return (
        <Container>
          <h3>{title}</h3>

          <Table loading={loading}>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Status</th>
                <th>Pagamento</th>
                <th>Bolsa</th>
                <th>Upgrade One</th>
                <th>Recorrente</th>
                <th>Inicia em</th>
                <th>Encerra em</th>
                <th>Valor</th>
                <th> </th>
              </tr>
            </thead>

            <tbody>
              {data.map((purchase) => {
                const isExpired = new Date(purchase.end_date) < new Date();

                const statusDateMapper = {
                  canceled: purchase?.canceled_at,
                  refunded: purchase?.refunded_at,
                  paid: isExpired ? new Date(purchase.end_date) : '',
                  scheduled: isExpired ? new Date(purchase.end_date) : '',
                };

                const statusDate = statusDateMapper[purchase.status] || '';

                return (
                  <tr key={purchase.id}>
                    <td>
                      {purchase.team
                        ? purchase.team.title
                        : purchase.product ?? '-'}
                    </td>

                    <td>
                      {validPurchaseStatus(purchase)}
                      {statusDate ? ` em ${formatDate(statusDate)}` : ''}
                    </td>

                    <td>{formatPaymentStatus(purchase.status)}</td>

                    <td>
                      <input
                        type="checkbox"
                        checked={validScholarship(
                          purchase.payment_type,
                          String(purchase.transaction),
                        )}
                      />
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        checked={purchase.is_one_upgraded}
                      />
                    </td>

                    <td>
                      <input type="checkbox" checked={purchase.is_recurrence} />
                    </td>

                    <td>
                      {purchase.start_date
                        ? formatDate(purchase.start_date)
                        : '-'}
                    </td>

                    <td>
                      {purchase.end_date ? formatDate(purchase.end_date) : '-'}
                    </td>

                    <td>{purchase.amount}</td>
                    <td />

                    <td width={120}>
                      <Actions
                        mainAction={() =>
                          history.push(`/users/purchases/${purchase.id}/edit`)
                        }
                      />

                      <ClipboardButton title="Copiar ID" value={purchase.id} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      );
    }

    return (loading && renderPurchases()) || data.length ? (
      renderPurchases()
    ) : showEmpty ? (
      emptyData()
    ) : (
      <div />
    );
  },
);

PurchasesList.propTypes = {
  userId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showEmpty: PropTypes.bool.isRequired,
};
