import { Scope } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FilePond } from 'react-filepond';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { showError } from '~/utils';

import { Wrapper, FileBox, Empty } from './styles';

const schema = Yup.object().shape({
  files: Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Título é um campo obrigatório'),
      tags: Yup.string(),
      file: Yup.string().required('Arquivo é um campo obrigatório'),
    }),
  ),
});

function VideosForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { categoryId } = match.params;

  const {
    token: { token: userToken },
  } = useSelector((state) => state.auth);

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      await api.post(`/video-categories/${categoryId}/videos`, data);

      toast.success('Vídeos salvos com sucesso!');
      history.push(`/video-categories/${categoryId}/videos`);
    } catch ({ response }) {
      showError(response);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    async function loadCategory(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/video-categories/${id}`);

        setCategory(response);
      } catch (err) {
        toast.error('Não foi possível carregar os dados da categoria');
      } finally {
        setLoading(false);
      }
    }

    const { categoryId } = match.params;

    if (categoryId) {
      loadCategory(categoryId);
    }
  }, []);

  function handleUpload(err, file) {
    if (err) return;
    setUploadedFiles([
      ...uploadedFiles,
      {
        file: file.serverId,
        title: file.filenameWithoutExtension,
        label: file.filename,
      },
    ]);
  }

  return (
    <Page loading={loading}>
      <header>
        <h1>{`Novo vídeo (${category.title})`}</h1>

        <Button
          icon={MdClose}
          color="cancel"
          to={`/video-categories/${categoryId}/videos`}
        >
          Cancelar
        </Button>
      </header>

      <h4>{`Diretório: ${category.path}`}</h4>

      <Wrapper>
        <aside>
          <FilePond
            name="file"
            allowMultiple
            allowRevert={false}
            labelIdle={`Arraste ou <span class="filepond--label-action">selecione</span> arquivos aqui`}
            onprocessfile={handleUpload}
            server={{
              url: `${process.env.REACT_APP_API_URL}/admin/`,
              process: {
                url: 'storage/upload',
                headers: {
                  authorization: `Bearer ${userToken}`,
                  'file-path': category.path,
                },
              },
            }}
          />
        </aside>

        <Form
          schema={schema}
          initialData={{
            files: uploadedFiles,
          }}
          onSubmit={handleSubmit}
        >
          {!uploadedFiles.length && <Empty>Nenhum arquivo enviado.</Empty>}

          {uploadedFiles.map((upload, index) => (
            <Scope key={upload.file} path={`files[${index}]`}>
              <FileBox>
                <p>{upload.label}</p>

                <div style={{ display: 'none' }}>
                  <Input name="file" type="hidden" />
                </div>

                <section>
                  <Input label="Título" name="title" />
                  <Input
                    label="Tags"
                    name="tags"
                    placeholder="ex: #Tag1 #Tag2"
                  />
                </section>
              </FileBox>
            </Scope>
          ))}

          <Button
            type="submit"
            size="block"
            loading={submitting}
            disabled={!uploadedFiles.length}
          >
            Salvar
          </Button>
        </Form>
      </Wrapper>
    </Page>
  );
}

VideosForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

VideosForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default VideosForm;
