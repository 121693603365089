import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Separator({ label, ...rest }) {
  return (
    <Container {...rest}>
      <div className="line" />
      {label && <div className="label">{label}</div>}
      <div className="line" />
    </Container>
  );
}

Separator.propTypes = {
  label: PropTypes.string,
};

Separator.defaultProps = {
  label: '',
};

export default Separator;
