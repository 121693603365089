import { useState } from 'react';

import api from '~/services/api';
import { showError } from '~/utils';

export const usePreviewLoginAs = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function loginAs(userId) {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const result = prompt('Insira a URL de preview:');

      const { data } = await api.post(`/users/login/${userId}`);

      const baseUrl = new URL(result);

      const params = new URLSearchParams({
        token: data.token,
        refreshToken: data.refreshToken,
      });

      const url = `${baseUrl.origin}/api/login-as?${params.toString()}`;

      window.open(url, '_blank');
    } catch (response) {
      showError(response);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    handle: loginAs,
    isLoading,
  };
};
