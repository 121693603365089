import React from 'react';

import { Check } from '~/components/HookForm';

export default function ReservaSection() {
  return (
    <fieldset className="flex flex-col w-full gap-4">
      <legend className="uppercase !text-gray-500 !bg-white">
        Tipo do item
      </legend>

      <Check
        name="reserva_item_type"
        options={[
          { value: 'camiseta', label: 'Camiseta' },
          { value: 'moletom', label: 'Moletom' },
        ]}
      />
    </fieldset>
  );
}
