import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy, MdCheck } from 'react-icons/md';

import { Button } from './styles';

function ClipboardButton({ value, children, ...rest }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => copied && setCopied(false), 1500);
    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
      <Button copied={copied} {...rest}>
        {children || (
          <>
            <MdContentCopy />
            <MdCheck />
          </>
        )}
      </Button>
    </CopyToClipboard>
  );
}

ClipboardButton.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ClipboardButton;
