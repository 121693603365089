import React from 'react';

import { useNotificationForm } from '../notifications-form.hook';

import Button from '~/components/Button';

export default function StepNavigation() {
  const {
    formRef,
    goToPreviousStep,
    currentStep,
    stepsCount,
    notification,
    isSubmitting,
  } = useNotificationForm();

  const isLastStep = currentStep + 1 === stepsCount;

  const submitLabel = notification?.is_published ? 'Salvar' : 'Enviar';

  return (
    <div className="flex items-center justify-between mt-10 py-8 border-t border-t-gray-200">
      <div className="flex items-center gap-3">
        <div className="relative w-28 h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute left-0 top-0 h-full bg-[#7159C1] transition-all ease-out"
            style={{ width: `${((currentStep + 1) / stepsCount) * 100}%` }}
          />
        </div>

        <span className="text-sm text-gray-500">
          <strong className="text-gray-700">{currentStep + 1}</strong>
          {` / ${stepsCount}`}
        </span>
      </div>

      <nav className="flex items-center gap-2">
        {currentStep > 0 && (
          <Button outline onClick={goToPreviousStep}>
            Voltar
          </Button>
        )}

        <Button
          type="button"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() =>
            formRef?.current?.dispatchEvent(
              new Event('submit', { cancelable: true }),
            )
          }
        >
          {isLastStep ? submitLabel : 'Próximo'}
        </Button>
      </nav>
    </div>
  );
}
