import React from 'react';

import { ExternalLink } from 'lucide-react';

import ClipboardButton from '~/components/ClipboardButton';
import PagedList from '~/components/PagedList';
import { Thumbnail } from '~/components/PagedList/styles';
import { Actions } from '~/components/Table';

const columns = [
  {
    label: 'Nome da Workspace',
    value: 'name',
  },
  {
    label: 'URL',
    value: 'URL',
    getValue: (element) => {
      return (
        <td key={element.id}>
          <div className="flex items-center gap-2">
            <a href={element.url} target="_blank" rel="noreferrer">
              {element.url}
            </a>
          </div>
        </td>
      );
    },
  },
  {
    label: 'Admin API Token',
    value: 'admin_api_token',
    getValue: (element) => {
      const prefix = element.admin_api_token.slice(0, 3);
      const suffix = element.admin_api_token.slice(-3);

      const hiddenToken = `${prefix}${Array.from({ length: 14 })
        .map((_) => '*')
        .join('')}${suffix}`;

      return (
        <td key={element.id}>
          <div className="flex items-center gap-2">
            {hiddenToken}

            <ClipboardButton
              title="Copiar Token"
              value={element.admin_api_token}
            />
          </div>
        </td>
      );
    },
  },
  {
    label: 'Headless API Token',
    value: 'headless_api_token',
    getValue: (element) => {
      const prefix = element.headless_api_token.slice(0, 3);
      const suffix = element.headless_api_token.slice(-3);

      const hiddenToken = `${prefix}${Array.from({ length: 14 })
        .map((_) => '*')
        .join('')}${suffix}`;

      return (
        <td key={element.id}>
          <div className="flex items-center gap-2">
            {hiddenToken}

            <ClipboardButton
              title="Copiar Token"
              value={element.headless_api_token}
            />
          </div>
        </td>
      );
    },
  },
  {
    label: 'Integrações',
    value: 'integrations',
    getValue: (element) => {
      return (
        <td key={element.id}>
          {`${
            element?.integrations?.length === 1
              ? `${element?.integrations?.length} integração`
              : `${element?.integrations?.length} integrações`
          } `}
        </td>
      );
    },
  },
];

export default function CircleWorkspacesList() {
  return (
    <PagedList
      resource="circle/workspaces"
      header="Circle Workspaces"
      columns={columns}
      createPermission="edit_lessons"
    />
  );
}
