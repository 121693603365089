/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';

import { Info } from 'lucide-react';

import { Markdown } from '../Markdown';

import { cn } from '~/lib/utils';

export const Alert = ({ icon: Icon, title, description, ...rest }) => {
  return (
    <div
      className={cn(
        'flex gap-4 border rounded-md p-4 w-full leading-[120%] text-foreground',
        rest?.className ?? '',
      )}
    >
      {Icon ? (
        <Icon className="h-6 w-6 mt-[1px]" />
      ) : (
        <Info className="h-6 w-6 mt-[1px]" />
      )}

      <div className="flex flex-col gap-1">
        <span className="font-semibold text-zinc-900">{title}</span>

        {description && (
          <p className="text-zinc-600 text-sm">
            <Markdown>{description}</Markdown>
          </p>
        )}
      </div>
    </div>
  );
};
