import React from 'react';

import { get } from 'dot-prop-immutable';

import PagedList from '~/components/PagedList';

const columns = [
  {
    label: 'Título',
    value: 'history.$end.title',
    getValue: (element) => {
      const title = get(element, 'history.$end.tagged_title');

      return <td key={element.id}>{title}</td>;
    },
  },
  {
    label: 'Tipo',
    value: 'type',
    getValue: (element) => {
      let cell = 'Não preenchido';

      switch (element.type) {
        case 'link':
          cell = 'Link';
          break;
        case 'material':
          cell = 'Material';
          break;
        case 'video':
          cell = 'Vídeo';
          break;
        case 'challenge':
          cell = 'Desafio';
          break;
        case 'navigation':
          cell = 'Navigation';
          break;
        case 'international-career':
          cell = 'Carreira Internacional';
          break;
        default:
          cell = 'Não preenchido';
      }

      return <td key={element.id}>{cell}</td>;
    },
  },
];

function LessonsList() {
  return <PagedList resource="lessons" header="Aulas" columns={columns} />;
}

export default LessonsList;
