export const types = [
  { id: 'DEFAULT', title: 'Padrão (Time)' },
  { id: 'CONTRACT', title: 'Contrato (B2B)' },
];

export const contentTypes = [
  { id: 'ONE', title: 'Rocketseat One' },
  { id: 'SPECIALIZATION', title: 'Formação' },
  { id: 'COURSE', title: 'Curso' },
  { id: 'MENTORING', title: 'Mentoria' },
  { id: 'IMMERSION', title: 'Imersão' },
  { id: 'POST_GRADUATION', title: 'Pós graduação' },
  { id: 'GRADUATION', title: 'Graduação' },
  { id: 'EXTRA', title: 'Extra' },
];
