import styled from 'styled-components';

export const Container = styled.div`
  label {
    text-align: start;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
