import React from 'react';

import { useSelector } from 'react-redux';
import { get, isFunction } from 'lodash';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { FaInfoCircle } from 'react-icons/fa';
// import { addHours, subHours } from 'date-fns';

export const min = (x, y) => (x < y ? x : y);

export const slugify = (text) => {
  return text.toLowerCase().replace(/\s+/g, '-').replace(/-+/g, '-');
};

export function showError(response, cb) {
  const { data } = response;

  if (cb && isFunction(cb)) {
    const message = cb(response);

    if (message) {
      return toast.error(message);
    }
  }

  if (Array.isArray(data) && data.length) {
    return data.forEach((item) => {
      item.message && toast.error(item.message);
    });
  }

  const message = get(data, 'error.message', 'Erro interno do servidor');

  return toast.error(message);
}

export function useCan() {
  const userPermissions = useSelector((state) =>
    get(state, 'user.userData.permissions', null),
  );

  return function can(permissions) {
    if (!userPermissions) {
      return false;
    }

    const permissionsArray = Array.isArray(permissions)
      ? permissions
      : [permissions];

    if (permissionsArray.length === 0) return true;

    return !!permissionsArray.find((permission) =>
      userPermissions.includes(permission),
    );
  };
}

export function getLabel(
  title,
  description = '',
  isRequired = false,
  tooltip = '',
) {
  return (
    <div>
      {title}

      {isRequired && <span style={{ color: '#DD5554' }}> *</span>}

      {tooltip && (
        <>
          {' '}
          <FaInfoCircle
            size={12}
            data-tooltip-id="tooltip"
            data-tooltip-content={tooltip}
            style={{ display: 'inline' }}
          />
        </>
      )}

      {description && (
        <span
          style={{
            display: 'block',
            marginTop: '4px',
            fontSize: 12,
            color: '#757575',
          }}
        >
          {description}
        </span>
      )}
    </div>
  );
}

export function hasAccountBlockedStatus(status) {
  return status === 'blocked' || status === 'permablocked';
}

export function emojifyAccountStatus(status) {
  switch (status) {
    case 'permablocked':
      return <span title="Bloqueado permanentemente">🚫</span>;
    case 'blocked':
      return <span title="Bloqueado temporariamente">⚠️</span>;
    default:
      return <span title="Ativo">✅</span>;
  }
}

export function fromUTCToTimezonedBrazilianDateTime(date) {
  // const BRAZILIAN_TIMEZONE_HOURS = 3;
  const providedDate = new Date(date);

  // if (process.env.NODE_ENV === 'development') {
  // return subHours(providedDate, BRAZILIAN_TIMEZONE_HOURS);
  return providedDate;
  // }
}

export function getSearchParam(location) {
  const params = new URLSearchParams(location.search);
  return params.get('s') || '';
}

/**
 * Formats a CPF or CNPJ based on the length of the input.
 * - CPF (11 digits) format: XXX.XXX.XXX-XX
 * - CNPJ (14 digits) format: XX.XXX.XXX/XXXX-XX
 *
 * @param {string} value - The raw CPF or CNPJ input, can include non-numeric characters.
 * @returns {string} - The formatted CPF or CNPJ. If the input length is not valid, returns the original input.
 */
export const formatCpfOrCnpj = (value) => {
  if (typeof value !== 'string' || !value.trim()) {
    return value;
  }

  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length === 11) {
    return cleanedValue.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4',
    );
  }

  if (cleanedValue.length === 14) {
    return cleanedValue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5',
    );
  }

  return value;
};

/**
 * Formats a CNPJ.
 *
 * @deprecated Use formatCpfOrCnpj instead. This function will be removed in future versions.
 *
 * @param {string} cnpj - The raw CNPJ input.
 * @returns {string} - The formatted CNPJ.
 */
export function formatCnpj(cnpj) {
  return formatCpfOrCnpj(cnpj);
}

export function transformDateFromDatePicker(date) {
  if (!date) return null;

  return format(
    date,
    date?.includes?.(':') ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD',
  );
}

export function isDateInPeriod(start_date, end_date) {
  if (!start_date || !end_date) return false;

  const now = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  const isInPeriod = startDate <= now && endDate >= now;

  return isInPeriod;
}

export const DEFAULT_DEBOUNCE_VALUE = 500;

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
