import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  li {
    display: inline-block;
    user-select: none;
    padding: 7px 14px;
    margin: 0 8px;
    font-size: 13px;
  }
`;

export const Navigation = styled.li`
  background: #7159c1;
  color: #fff;
  border: 1px solid #7159c1;
  /* background: ${(props) => (props.disabled ? '#292530' : '#292530')};
  border: 2px solid ${(props) => (props.disabled ? '#292530' : '#292530')}; */
  

  border-radius: 8px;
  margin: 0 4px !important;
  /* cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; */
  transform: ${(props) => (props.left ? 'rotate(180deg)' : 'rotate(0deg)')};
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      background-color: #7159c1;
    }
  }

  &:hover {
    background: #9375f5;
    color: #fff;
  }

  > div {
    mask: url(${(props) => props.icon}) no-repeat center;
    color: #ffffff;
    background-color: #ffffff;
    /* cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')}; */
    font-size: 11px;
    &::before {
      content: 'btn';
    }
  }

`;

export const Page = styled.li`
  border: 2px solid #7159c1;
  border-radius: 6px;
  background: ${(props) => (props.active ? '#fff' : '#7159c1')};
  color: ${(props) => (props.active ? '#7159c1' : '#fff')};

  ${(props) =>
    props.active &&
    `
    &:hover {
      color: #9375f5;
      border-color: #9375f5;
    }
  `}

  padding: 10px;
  cursor: pointer;
`;
