import React from 'react';
import PropTypes from 'prop-types';

import * as Accordion from '@radix-ui/react-accordion';

export const AccordionContent = React.forwardRef((props, forwardedRef) => {
  const { children, ...rest } = props;

  return (
    <Accordion.Content
      className="overflow-hidden data-[state=open]:animate-accordion-slide-down data-[state=closed]:animate-accordion-slide-up"
      {...rest}
      ref={forwardedRef}
    >
      <div className="flex flex-col px-4 pb-4 gap-5">{children}</div>
    </Accordion.Content>
  );
});

AccordionContent.displayName = 'AccordionContent';

AccordionContent.propTypes = {
  children: PropTypes.node.isRequired,
};
