/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';

import { MdAdd, MdFileUpload, MdOpenInNew, MdSearch } from 'react-icons/md';
import { escape } from 'querystring-browser';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FileInputContainer, FormContainer, HeaderContainer } from './styles';

import Button from '~/components/Button';
import { Input } from '~/components/Form';
import { showError, useCan } from '~/utils';
import api from '~/services/api';

export function BadgesListHeader() {
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [file, setFile] = useState(null);
  const history = useHistory();
  const can = useCan();

  const [progressPercent, setProgressPercent] = useState(0);

  function handleProgress(progressEvent) {
    const progressEventPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setProgressPercent(progressEventPercent);
  }

  async function handleFileSubmit() {
    if (!file) return;

    setUploadIsLoading(true);

    const data = new FormData();

    data.append('file', file);

    try {
      const response = await api.post('user_badges/upload', data, {
        onUploadProgress: handleProgress,
      });

      setFile(null);

      toast.success(
        `Arquivo importado com sucesso, total de ${response.data.length} registros criados.`,
      );
    } catch (err) {
      showError(err.response);
    } finally {
      setUploadIsLoading(false);
      setFile(null);
    }
  }

  function handleSubmitSearch(e) {
    e.preventDefault();
    history.push(`/badges/1/?s=${escape(search)}`);
  }

  return (
    <HeaderContainer>
      <h1>Insígnias</h1>

      <div>
        <FormContainer>
          <form onSubmit={handleSubmitSearch}>
            <Input
              type="text"
              placeholder="Digite sua busca..."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              unform={false}
            />

            <Button type="submit" icon={MdSearch} color="success" />
          </form>

          {can('edit_badges') && (
            <Button icon={MdAdd} color="success" to="/badges/new">
              Novo
            </Button>
          )}
        </FormContainer>

        {can('edit_users-badges') && (
          <div>
            {uploadIsLoading && <span>{`Carregando ${progressPercent}%`}</span>}

            {!uploadIsLoading && file && (
              <Button
                icon={MdFileUpload}
                type="button"
                iconSize={16}
                onClick={handleFileSubmit}
              >
                {`Enviar ${file.name}`}
              </Button>
            )}

            {!uploadIsLoading && !file && (
              <FileInputContainer>
                <label htmlFor="upload-csv">
                  <MdFileUpload size={16} />
                  Importar insígnias para usuários
                  <input
                    type="file"
                    id="upload-csv"
                    accept=".csv, text/csv"
                    onChange={(event) => setFile(event.target.files[0])}
                  />
                </label>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1mR5NxRaEole_OWEvaETRaCXKRWLjb7U5MhCLBo5TalE/edit?usp=sharing"
                >
                  <MdOpenInNew size={16} />
                  Acessar template
                </a>
              </FileInputContainer>
            )}
          </div>
        )}
      </div>
    </HeaderContainer>
  );
}
