import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';

import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import SweetAlert2 from 'react-sweetalert2';
import { addYears } from 'date-fns';
import { FaTrashAlt } from 'react-icons/fa';

import { contractTypes } from '../List';
import LicensesList from './LicensesList';

import Button from '~/components/Button';
import { Datepicker, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import Separator from '~/components/Separator';
import { getLabel } from '~/utils';

const schema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  company_id: Yup.string().required('Campo obrigatório'),
  team_ids: Yup.array().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  start_date: Yup.string().required('Campo obrigatório'),
  end_date: Yup.string().required('Campo obrigatório'),
  licenses_total: Yup.number('Deve ser um número válido').required(
    'Campo obrigatório',
  ),
});

export const teamsTypes = [
  { value: 'product', label: 'Produtos' },
  { value: 'specialization', label: 'Especializações' },
  { value: 'course', label: 'Cursos' },
  { value: 'event', label: 'Eventos' },
];

export default function ContractsForm({ match, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalExcludeOpen, setModalExcludeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [teams, setTeams] = useState([]);

  const [contract, setContract] = useState({
    title: '',
    company_id: '',
    team_ids: [],
    type: '',
    start_date: new Date(),
    end_date: addYears(new Date(), 1),
    licenses_total: 0,
  });

  const isUpdating = !!match.params.id;
  const { id } = match.params;

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true);

      await api.postOrPut('/b2b/contracts', id, {
        ...data,
      });

      toast.success('Contrato salvo com sucesso!');
      history.push('/b2b/contracts');
    } catch (err) {
      toast.error('Algo deu errado.');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function loadCompanies() {
    if (companies.length) return;

    try {
      setIsLoadingCompanies(true);

      const { data: response } = await api.get(`/b2b/companies`, {
        params: {
          per_page: 9999,
        },
      });

      setCompanies(response.data);
      return response.data;
    } finally {
      setIsLoadingCompanies(false);
    }
  }

  async function loadTeams() {
    if (teams.length) return;

    try {
      setIsLoadingTeams(true);

      const { data: response } = await api.get('/teams', {
        params: {
          withType: true,
          per_page: 9999,
        },
      });

      setTeams(response.data);
      return response.data;
    } finally {
      setIsLoadingTeams(false);
    }
  }

  async function loadContract(contractId) {
    try {
      setIsLoading(true);

      const { data: response } = await api.get(`/b2b/contracts/${contractId}`);

      const output = {
        ...response,
        team_ids: response?.teams?.map((team) => ({
          id: team?.id,
          title: team?.title,
        })),
      };

      setContract(output);
    } catch (err) {
      toast.error('Erro ao buscar dados do contrato.');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveContract(contractId) {
    try {
      await api.delete(`/b2b/contracts/${contractId}`);

      toast.success('Contrato excluído com sucesso');
      history.push('/b2b/contracts');
    } catch {
      toast.error('Não foi possível excluir o contrato');
    }

    setModalExcludeOpen(false);
    loadContract(id);
  }

  useEffect(() => {
    if (id) {
      loadContract(id);
    }

    loadCompanies();
    loadTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, match.params]);

  const companiesOptions = useMemo(() => {
    return companies?.map((company) => ({
      id: company.id,
      title: company.name,
    }));
  }, [companies]);

  const teamOptions = useMemo(() => {
    return teamsTypes?.map((team) => ({
      label: team.label,
      options: teams?.filter((filTeam) => filTeam?.type === team.value),
    }));
  }, [teams]);

  return (
    <Page loading={isLoading}>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Contrato`}</h1>

        <div>
          <Button
            icon={FaTrashAlt}
            type="button"
            color="danger"
            onClick={() => setModalExcludeOpen(true)}
          >
            EXCLUIR
          </Button>
          <Button icon={MdClose} color="cancel" to="/b2b/contracts">
            CANCELAR
          </Button>
          <Button form="contract-form" type="submit" loading={isSubmitting}>
            Salvar
          </Button>
        </div>
      </header>

      <Form
        id="contract-form"
        schema={schema}
        initialData={contract}
        onSubmit={handleSubmit}
      >
        <section>
          <Input
            name="title"
            label={getLabel(
              'Nome do contrato',
              'Nome de identificação do contrato.',
              true,
            )}
          />

          <Dropdown
            name="company_id"
            label={getLabel('Empresa', 'Empresa vinculada ao contrato.', true)}
            options={companiesOptions}
            placeholder="Selecione uma empresa"
            disbaled={isLoadingCompanies}
          />
        </section>

        <section>
          <Dropdown
            name="team_ids"
            label={getLabel(
              'Produtos',
              'Produtos que os usuários terão acesso.',
              true,
            )}
            options={teamOptions}
            disabled={isLoadingTeams}
            multiple
            placeholder="Selecione um ou mais acessos."
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label={getLabel(
              'Tipo de contrato',
              'Tipo de vínculo do contrato.',
              true,
            )}
            options={contractTypes.map((type) => ({
              id: type.value,
              title: type.label,
            }))}
            placeholder="Selecione o tipo de contrato"
          />

          <Datepicker
            name="start_date"
            label={getLabel(
              'Início da vigência',
              'Data de início do contrato.',
              true,
            )}
            showTimeSelect
          />

          <Datepicker
            name="end_date"
            label={getLabel(
              'Fim da vigência',
              'Data de final do contrato.',
              true,
            )}
            showTimeSelect
          />

          <Input
            name="licenses_total"
            label={getLabel(
              'Quantidade de licenças',
              'Número de usuários das licenças do contrato.',
              true,
            )}
            type="number"
          />
        </section>
      </Form>

      {isUpdating && (
        <>
          <Separator label="LICENÇAS" style={{ marginTop: 30 }} />
          <LicensesList contract={contract} isUpdating={isUpdating} />
        </>
      )}

      <SweetAlert2
        show={modalExcludeOpen}
        title="Excluir contrato?"
        text="Esta ação não poderá ser desfeita"
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonColor="#DD5554"
        confirmButtonText="Sim, excluir"
        cancelButtonText="Cancelar"
        onConfirm={() => handleRemoveContract(id)}
        onResolve={() => setModalExcludeOpen(false)}
      />
    </Page>
  );
}

ContractsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
