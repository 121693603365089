import React from 'react';

import { Input } from '~/components/HookForm';
import { Alert } from '~/components/ui/alert';

export function HubspotTab() {
  return (
    <div>
      <Alert
        title="Configurações da Hubspot (IMPORTANTE)"
        description="Essas configurações são responsáveis por enviar os dados da inscrição do evento para a hubspot."
        className="mb-4"
      />

      <section>
        <Input name="hubspot_form_id" label="Form ID" />
        <Input name="hubspot_portal_id" label="Portal ID" />

        <Input
          name="hubspot_trail_id"
          label="Nome interno da trilha no Hubspot"
          description="Este campo é o nome da propriedade personalizada que será criada no Hubspot para armazenar a trilha do usuário."
        />
      </section>
    </div>
  );
}
