/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';

import api from '~/services/api';
import Page from '~/components/Page';

async function getExperiment(id) {
  const { data } = await api.get(`/experiments/${id}`);

  return data;
}

function makeDefaultValues({ experiment }) {
  const isUpdating = !!experiment?.id;

  return {
    title: experiment.title,
    slug: experiment.slug,
    limit: experiment.limit || null,
    description: experiment.description,
    should_limit: !!experiment.limit || null,
    is_active: isUpdating ? experiment?.is_active : null,
  };
}

export const ExperimentFormProvider = ({ experimentId, children }) => {
  const [experiment, setExperiment] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const defaultValues = useMemo(
    () =>
      makeDefaultValues({
        experiment,
      }),
    [experiment],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true);

        const [experimentData] = await Promise.all([
          experimentId ? getExperiment(experimentId) : {},
        ]);

        setExperiment(experimentData);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [experimentId]);

  if (isLoading) {
    return <Page loading>Carregando</Page>;
  }

  return (
    <ExperimentFormContext.Provider
      value={{
        experiment,
        isLoading,
        defaultValues,
      }}
    >
      <Page loading={false}>{children}</Page>
    </ExperimentFormContext.Provider>
  );
};

export const ExperimentFormContext = createContext({});

export const useExperimentForm = () => {
  const context = useContext(ExperimentFormContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};
