import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .line {
    flex-grow: 1;
    height: 0;
    border-top: 1px dashed #302b3e1a;
  }

  .label {
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    color: #302b3eb2;
  }
`;
