import React from 'react';

import { format, isBefore, isAfter } from 'date-fns';
import pt from 'date-fns/locale/pt';

import PagedList from '~/components/PagedList';

function formatDate(field) {
  return (element) => {
    return (
      <td key={`${field}-${element.id}`}>
        {element[field]
          ? format(element[field], 'DD/MM/YYYY', { locale: pt })
          : 'Não definido'}
      </td>
    );
  };
}

const columns = [
  {
    label: 'Template',
    value: 'template',
  },
  {
    label: 'Título',
    value: 'title',
  },
  {
    label: 'Início',
    value: 'start_date',
    getValue: formatDate('start_date'),
  },
  {
    label: 'Fim',
    value: 'end_date',
    getValue: formatDate('end_date'),
  },
  {
    label: 'Ativo',
    value: 'active',
    getValue: (element) => {
      if (
        isBefore(new Date(element.start_date), new Date()) &&
        isAfter(new Date(element.end_date), new Date())
      ) {
        return (
          <td key={element.id}>
            <strong>ATIVO</strong>
          </td>
        );
      }

      return (
        <td key={element.id}>
          <strong>INATIVO</strong>
        </td>
      );
    },
  },
];

export default function TopBannersList() {
  return (
    <PagedList resource="top-banners" header="Top Banners" columns={columns} />
  );
}
