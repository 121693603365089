/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { useLazyOffers } from '@shared/skylab/graphql/pluto';

import api from '~/services/api';
import certificateApi from '~/services/certificates.api';
import Page from '~/components/Page';

export const EventFormContext = createContext({});

async function getTrails(search) {
  const { data } = await api.get('/trails', {
    params: {
      per_page: 999,
      search,
    },
  });

  const trails = data.data.map((trail) => ({
    value: trail.id,
    label: trail.title,
    slug: trail.slug,
  }));

  return trails;
}

async function getEducators(search) {
  const { data } = await api.get('/educators', {
    params: {
      per_page: 999,
      search,
    },
  });

  const educators = data.data.map((educator) => ({
    value: educator.id,
    label: educator.user.name,
  }));

  return educators;
}

async function getCertificateCourses() {
  try {
    const { data } = await certificateApi.get('/courses?limit=0');

    const certificateCourses = data.courses.map((course) => ({
      value: { courseId: course.id, courseSlug: course.slug },
      label: course.title,
    }));

    return certificateCourses;
  } catch {
    return [];
  }
}

async function getEvent(id) {
  const { data } = await api.get(`/events/${id}`);

  return data;
}

export const defaultEvent = {
  place: 'Online',
  price: null,

  cta_text_no_subscriber: 'Inscreva-se agora',
  cta_text_subscriber: 'Ir para o evento',
  lp_cta_text: 'Mais informações',
  lp_cta_url: 'https://www.rocketseat.com.br/eventos/nlw',
  referral_text: 'Convidar pessoas',
  referral_url: 'https://www.rocketseat.com.br/eventos/nlw/inscricao/',
  challenges_text: 'Complete a missão e concorra a camisetas exclusivas',
};

async function mapEvent({ eventData }) {
  eventData.place = eventData?.place || defaultEvent.place;
  eventData.color = eventData?.metadata?.color;
  eventData.event_guide_url = eventData?.metadata?.event_guide_url;

  eventData.start_date = eventData.start_date && new Date(eventData.start_date);

  eventData.end_date = eventData.end_date && new Date(eventData.end_date);

  eventData.available_at =
    eventData.available_at && new Date(eventData.available_at);

  eventData.unavailable_at =
    eventData.unavailable_at && new Date(eventData.unavailable_at);

  eventData.offers_starts_at =
    eventData.offers_starts_at && new Date(eventData.offers_starts_at);

  eventData.offers_ends_at =
    eventData.offers_ends_at && new Date(eventData.offers_ends_at);

  return eventData;
}

export const EventFormProvider = ({ eventId, children }) => {
  const [fetchOffers] = useLazyOffers();
  const [trails, setTrails] = useState([]);
  const [event, setEvent] = useState({});
  const [offers, setOffers] = useState([]);
  const [educators, setEducators] = useState([]);
  const [certificateCourses, setCerfificateCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getOffers = useCallback(async () => {
    const { data } = await fetchOffers();

    return (
      data?.offers?.map((offer) => ({
        value: offer.slug,
        label: `${offer.title} (${offer.slug})`,
      })) || []
    );
  }, [fetchOffers]);

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true);

        const [
          eventData,
          offersData,
          trailsList,
          educatorsList,
          certificateCoursesList,
        ] = await Promise.all([
          eventId ? getEvent(eventId) : {},
          getOffers(),
          getTrails(),
          getEducators(),
          getCertificateCourses(),
        ]);

        const currentEventData = await mapEvent({
          eventData,
        });

        setEvent(currentEventData);
        setOffers(offersData);
        setTrails(trailsList);
        setEducators(educatorsList);
        setCerfificateCourses(certificateCoursesList);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [eventId, fetchOffers, getOffers]);

  if (isLoading) {
    return <Page loading>Carregando</Page>;
  }

  // return JSON.stringify(event, null, 4);

  return (
    <EventFormContext.Provider
      value={{
        trails,
        educators,
        offers,
        event,
        certificateCourses,
        isLoading,
      }}
    >
      <Page loading={false}>{children}</Page>
    </EventFormContext.Provider>
  );
};

export const useEventForm = () => {
  const context = useContext(EventFormContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};
