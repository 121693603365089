import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  margin: 20px 0;

  > section {
    border: 1px solid #eee;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 20px;

    > strong {
      color: #7159c1;
      font-size: 16px;
    }

    > footer {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
`;
