import PropTypes from 'prop-types';
import React, { useRef, useContext } from 'react';

import pt from 'date-fns/locale/pt';
import format from 'date-fns/format';
import { useDrag, useDrop } from 'react-dnd';
import { FaMapSigns } from 'react-icons/fa';
import { MdDelete, MdUndo, MdLink, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import JourneyContext from '../context';
import { Container, DeletedContainer, BoxColor } from './styles';

import Button from '~/components/Button';

const resourcesByType = {
  cluster: 'clusters',
  lesson: 'lessons',
  group: 'lesson-groups',
  challenge: 'challenges',
  'micro-certificate': 'micro-certificates',
};

export default function NodeItem({ node, selected, index }) {
  const ref = useRef();
  const { move, selectCurrent, toggleDeleteNode, journey } = useContext(
    JourneyContext,
  );

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'NODE', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'NODE',
    canDrop: () => false,
    hover(item) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex !== targetIndex) {
        move(draggedIndex, targetIndex);

        item.index = targetIndex;
      }
    },
  });

  dragRef(dropRef(ref));

  function renderAuthor() {
    return node.author && <small>{`· ${node.author}`}</small>;
  }

  function renderReleaseAt() {
    return (
      node.release_at && (
        <small className="time">
          <b>Liberação </b>
          {format(node.release_at, 'DD/MM/YYYY HH:mm', { locale: pt })}
        </small>
      )
    );
  }

  function renderNodeAccessLink() {
    if (node.type !== 'challenge') {
      return (
        node.slug && (
          <a
            href={`${process.env.REACT_APP_FRONT_URL}/node/${node.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdLink size="16" />
            acesso ao node
          </a>
        )
      );
    }

    let link = `${process.env.REACT_APP_FRONT_URL}/`;

    if (journey.type === 'ignite') {
      link += `/ignite/${journey.slug}`;
    } else {
      link += `/explorer`;
    }

    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <MdLink size="16" />
        acesso a jornada
      </a>
    );
  }

  function renderEditLink() {
    const resource = resourcesByType[node.type];

    const resourceId =
      node?.cluster_id ||
      node?.lesson_id ||
      node?.lesson_group_id ||
      node?.challenge_id ||
      node?.micro_certificate_id;

    if (!resource || !resourceId) return null;

    return (
      <Link
        to={`/${resource}/edit/${resourceId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <MdOpenInNew />
      </Link>
    );
  }

  function renderFooter() {
    return (
      <>
        <footer>
          <div>
            <div>
              {node.type && (
                <small className="tag">
                  {node.type}
                  {renderEditLink()}
                </small>
              )}
              {node.layout && <small className="tag">{node.layout}</small>}
            </div>
            {renderReleaseAt()}
          </div>
          {renderNodeAccessLink()}
        </footer>
      </>
    );
  }

  function handleDelete(e) {
    e.stopPropagation();
    toggleDeleteNode(index);
  }

  function handleRestore() {
    toggleDeleteNode(index);
  }

  function handleClick() {
    selectCurrent(index);
  }

  return (
    <Container
      ref={ref}
      color={node.color}
      onClick={handleClick}
      isDragging={isDragging}
      selected={selected}
    >
      {node.deleted && (
        <DeletedContainer onClick={(e) => e.stopPropagation()}>
          <Button color="danger" icon={MdUndo} onClick={handleRestore}>
            Restaurar
          </Button>
        </DeletedContainer>
      )}
      <header>
        <content>
          {node.color && <BoxColor color={node.color} />}
          {node.is_milestone && <FaMapSigns size={11} />}
          <b>{node.title || 'Adicionar node'}</b>

          {renderAuthor()}
        </content>

        <button type="button" onClick={handleDelete}>
          <MdDelete size={16} />
        </button>
      </header>

      <main>
        <small>{node.description}</small>
      </main>

      {renderFooter()}
    </Container>
  );
}

NodeItem.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    release_at: PropTypes.string,
    type: PropTypes.string,
    layout: PropTypes.string,
    is_milestone: PropTypes.bool,
    color: PropTypes.string,
    slug: PropTypes.string,
    author: PropTypes.string,
    deleted: PropTypes.bool,
    cluster_id: PropTypes.string,
    lesson_id: PropTypes.string,
    lesson_group_id: PropTypes.string,
    challenge_id: PropTypes.string,
    micro_certificate_id: PropTypes.string,
    partnero_id: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

NodeItem.defaultProps = {
  selected: false,
};
