import PropTypes from 'prop-types';
import React from 'react';

import logoImage from '~/assets/images/logo.svg';

import { Wrapper, Content } from './styles';

function AuthLayout({ children }) {
  return (
    <Wrapper>
      <img src={logoImage} alt="Rocketseat" />
      <Content>{children}</Content>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLayout;
