import styled from 'styled-components';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;

  > div {
    margin: 0 !important;
  }
`;
