import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Scope } from '@rocketseat/unform';
import { MdAdd, MdDelete, MdRestore } from 'react-icons/md';

import RuleFields from './RuleFields';
import { RuleContainer } from './styles';

import { Input } from '~/components/Form';
import Button from '~/components/Button';

export default function RulesForm({ banner }) {
  const [fields, setFields] = useState([]);

  function handleAdd() {
    setFields([
      ...fields,
      {
        id: String(new Date().getTime()),
        type: null,
        resource_field: null,
        resource_value: null,
        deleted: false,
      },
    ]);
  }

  function handleRemove(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? true : field.deleted,
      })),
    );
  }

  function handleRestore(id) {
    setFields((prev) =>
      prev.map((field) => ({
        ...field,
        deleted: field.id === id ? false : field.deleted,
      })),
    );
  }

  useEffect(() => {
    setFields(banner.rules);
  }, [banner.rules]);

  return (
    <>
      {fields.map((rule, index) => (
        <Scope path={`rules[${index}]`} key={`field-rule-${rule.id}`}>
          <RuleContainer isDeleted={rule.deleted}>
            {rule.deleted && (
              <main>
                <Button icon={MdRestore} onClick={() => handleRestore(rule.id)}>
                  Restaurar
                </Button>
              </main>
            )}

            <Input type="hidden" name="id" />
            <Input type="hidden" name="deleted" value={rule.deleted} />
            <section>
              <RuleFields rule={rule} />

              <Button icon={MdDelete} onClick={() => handleRemove(rule.id)}>
                Remover
              </Button>
            </section>
          </RuleContainer>
        </Scope>
      ))}

      <Button icon={MdAdd} onClick={handleAdd}>
        Nova regra
      </Button>
    </>
  );
}

RulesForm.propTypes = {
  banner: PropTypes.shape({
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        resource_field: PropTypes.string,
        resource_value: PropTypes.string,
      }),
    ),
  }).isRequired,
};
