import PropTypes from 'prop-types';
import React from 'react';

import { ErrorIndicatorBullet } from './styles';

function ErrorIndicator({ hasError }) {
  return hasError ? <ErrorIndicatorBullet /> : null;
}

ErrorIndicator.propTypes = {
  hasError: PropTypes.bool,
};

ErrorIndicator.defaultProps = {
  hasError: false,
};

export default ErrorIndicator;
