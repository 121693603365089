import styled from 'styled-components';

export const DeletedContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.li`
  background: ${({ selected }) => (selected ? '#f0f0f0' : '#fff')};
  padding: 16px 10px;

  cursor: move;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid rgba(48, 43, 62, 0.15);
  border-top: none;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #302b3e;
    font-size: 14px;

    content {
      flex: 1;
      display: flex;
      justify-content: start;
      align-items: center;

      b {
        margin-right: 3px;
        margin-left: 0;
      }

      svg {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    button {
      background: transparent;
      border: none;
    }
  }

  main {
    font-size: 14px;
    small {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 300px;
      display: inline-block;
      color: rgba(48, 43, 62, 0.5);
    }
  }

  footer {
    display: flex;
    margin-top: 8px;
    font-size: 12px;
    flex-direction: column;

    > div {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    > a {
      display: flex;
      align-items: center;
      line-height: 20px;
      margin-top: 8px;

      svg {
        margin-right: 4px;
      }
    }

    small.time {
      color: #717171;
      align-self: flex-end;
      font-size: 11px;
    }

    small.tag {
      margin-right: 5px;
      padding: 0px 7px;
      border: 1px solid #91d5ff;
      border-radius: 4px;
      display: inline-flex;
      color: #1890ff;
      background: #e6f7ff;

      a {
        color: #1890ff;
        padding: 2px;
        margin: 0 -2px 0 2px;
      }
    }
  }

  &:first-child {
    border-top: 1px solid rgba(48, 43, 62, 0.15);
  }
`;

export const BoxColor = styled.div`
  max-width: 5px;

  width: 5px;
  height: 5px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 5px;
`;
