import styled from 'styled-components';

export const SearchForm = styled.form`
  display: flex;
`;

export const Thumbnail = styled.div`
  img {
    border-radius: 20px;
    object-fit: cover;
    height: fit-content;
    width: 60px;
  }
`;

export const Filters = styled.div`
  display: flex;

  > div {
    width: 200px;
    height: 38px;

    & + div {
      margin-left: 10px;
    }
  }
`;
export const CheckboxContainer = styled.div`
  margin: 0 10px 0 0;
`;

export const Tabs = styled.div`
  display: flex;
  margin: 12px 0;
  border-bottom: 1px solid #ddd;

  button {
    font-size: 15px;
    padding: 10px 12px;
    border-bottom: 2px solid transparent;
    transform: translateY(1px);
    color: #444;

    &[data-active='true'] {
      border-color: #7159c1;
      color: #7159c1;
      font-weight: bold;
    }
  }
`;
